import { Route, Routes } from "react-router-dom";
import MyCampaigns from "../Campaigns/MyCampaigns";
import DataMiningHeader from "./DataMiningHeader";
import { Page404 } from "../Page404/Page404";
import DataMiningOverview from "./pages/overview/DataMiningOverview";
import DataMiningPixel from "./pages/pixel/DataMiningPixel";
import ConnectPixelPopup from "./popups/ConnectPixelPopup";

const DataMining = () => {
  return (
    <>
      <DataMiningHeader />
      <Routes>
        <Route path={"/overview"} element={<DataMiningOverview />} />
        <Route path={"/pixel/*"} element={<DataMiningPixel />} />
        <Route path={"*"} element={<Page404 />} />
      </Routes>
      <ConnectPixelPopup />
    </>
  );
};

export default DataMining;
