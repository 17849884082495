//Core
import React from "react";
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
//Styles
import {Typography} from "@mui/material";
import {SectionHeadBackground, SectionHeadContainer} from "../../styled/Layout";
import {BillingButtonBox, ButtonBilling} from "./BillingCenterPage.styles";
//Hooks
import {useBillingCenter} from "./useBillingCenter";
//Components
import {Details} from "./blocks/Details";
import {DepositWithdraw} from "./blocks/DepositWithdraw";
import {History} from "./blocks/History";

export const BillingCenterPage = () => {
    const {isDeposit, isDetails, isHistory} = useBillingCenter();
    const navigate = useNavigate();

    return (
        <>
            <SectionHeadBackground>
                <SectionHeadContainer>
                    <Typography variant={'h2'}>{'Billing center'}</Typography>
                    <BillingButtonBox>
                        <ButtonBilling className={isDetails ? 'active' : ''} onClick={() => navigate('/billing/details')}>{'Billing details'}</ButtonBilling>
                        <ButtonBilling className={isDeposit ? 'active' : ''} onClick={() => navigate('/billing/deposit_withdraw')}>{'Deposit / Withdraw'}</ButtonBilling>
                        <ButtonBilling className={isHistory ? 'active' : ''} onClick={() => navigate('/billing/history')}>{'Billing history'}</ButtonBilling>
                    </BillingButtonBox>
                </SectionHeadContainer>
            </SectionHeadBackground>
            <Routes>
                <Route path={'/'} element={<Navigate to={'/billing/details'}/>}/>
                <Route path={'/details'} element={<Details/>}/>
                <Route path={'/deposit_withdraw'} element={<DepositWithdraw/>}/>
                <Route path={'/history'} element={<History/>}/>
            </Routes>
        </>
    )
}