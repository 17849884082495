import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { checkUserCampaigns } from "../../api/statisticsRequests";
import { userSelector } from "../../utils/selectors";

export const useMain = () => {
  const [loading, setLoading] = useState(true);
  const [noUser, setNoUser] = useState(false);
  const [sideBarOpen, setSideBarOpen] = useState(true);
  const { id, hasCampaigns, userData } = useSelector(userSelector);
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    if (hasCampaigns === undefined || userData.email === "") return;
    setLoading(false);
    if (!userData?.wallet_address || userData?.wallet_address === "" || !userData?.has_nft) {
      navigate(`/settings`);
    } else {
      if (hasCampaigns && location.pathname === "/") {
        // console.log(location);
        navigate("/dashboard");
      }
      if (!hasCampaigns && location.pathname !== "/") {
        navigate("/");
      }
    }
  }, [hasCampaigns]);

  useEffect(() => {
    if (!id) return;
    const func = async () => {
      const res = await checkUserCampaigns();
      // if (res && location.pathname === "/") {
      //   navigate("/dashboard");
      // }
    };

    void func();
  }, [id]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) setNoUser(true);
    if (isAuthenticated) setNoUser(false);
  }, [isAuthenticated, isLoading]);

  return { loading, noUser, sideBarOpen, setSideBarOpen };
};
