import styled from "@emotion/styled";
import { BlockContainer, FlexBox } from "../../styled/Layout";
import theme from "../../theme/theme";

export const AdExpandedContainer = styled(BlockContainer)`
  background-image: linear-gradient(to bottom, #e5eaf3, #e7ebf1);
  border: solid 1px #dee1e8;
  box-shadow: none;
  padding-top: 5.7rem;
  transition: 0.3s;

  height: fit-content;
  transform-origin: top right;
  margin-bottom: 3rem;

  transition-timing-function: ease-in-out;

  > button {
    background-color: #242f57;

    &:hover {
      background-color: #242f57;
    }
  }

  .block-expanded {
    > div {
      background-color: #d1dded;
      box-shadow: none;

      th:nth-of-type(3),
      td:nth-of-type(3) {
        background-color: #c1cfe1;
      }
    }
  }

  &.hidden {
    padding: 0;
    margin: 0;
    opacity: 0;
    scale: 0.7 0;
  }
  
  ${theme.breakpoints.down('mobL')}{
    &.hidden{
      min-height: unset;
    }
  }
`;

export const AdExpandedHead = styled(FlexBox)`
  margin-bottom: 2.9rem;
  .banner {
    width: 13.2rem;
    border-radius: 1rem;
    margin-right: 2rem;
  }

  > div:last-of-type {
    margin-left: auto;
    width: auto;

    > div {
      flex-basis: 20%;
      max-width: 20%;
      min-width: fit-content;
      > div {
        background-color: #667197;

        svg {
          path {
            fill: #7ebdfc;
          }
        }

        span {
          color: #fff;
        }

        p {
          color: #ccd2e8;
        }
      }
    }
  }
`;

export const AdExpandedChannelData = styled(FlexBox)`
  h4 {
    font-size: 1.77rem;
    font-weight: 600;
    letter-spacing: 0.2px;
    color: #4f5c87;
    text-transform: capitalize;
  }

  p {
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: 0.41px;
    color: #4f5c87;
    text-transform: capitalize;
  }

  img {
    width: 5rem;
    height: 5rem;
    margin-right: 1rem;
  }
`;
