import {DialogContent, IconButton, Stack, Typography} from "@mui/material";
import {ClosePopupIcon} from "../../../assets/svg/icons";
import {PopupContainer, PopupContent} from "../Popup.styles";
import {FlexBox} from "../../../styled/Layout";
import ThankYou from "../../../assets/svg/thankYou";
import {
    SupportPopupContainer,
    SupportThankYouStack,
    SupportThankYouText,
    SupportThankYouTitle
} from "../../../components/Forms/Forms.styles";

export const SupportPopup = ({closePopup}: { closePopup: () => void }) => {
    return (
        <SupportPopupContainer open onClose={closePopup}>
            <DialogContent sx={{p: "1rem 0", textAlign: "center"}}>
                <IconButton className="close" onClick={closePopup}>
                    <ClosePopupIcon/>
                </IconButton>
                <SupportThankYouStack>
                    <FlexBox marginTop={{xs: 0, mobL: '4.3rem'}}><ThankYou/></FlexBox>
                    <SupportThankYouTitle>{'Thank you !'}</SupportThankYouTitle>
                    <SupportThankYouText>{'Your message was successfully sent, we will answer you as soon as possible.'}</SupportThankYouText>
                </SupportThankYouStack>
            </DialogContent>
        </SupportPopupContainer>
    )
}