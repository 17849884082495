// Utils
import { Cell, Label, Pie, PieChart } from "recharts";
// import { ContextViewMode } from "../../pages/Campaigns/ContextViewMode";

// Components
import MonitorTable from "../Tables/MonitorTable";

// Styles
import { Typography } from "@mui/material";
import { BlockContainer, FlexBox } from "../../styled/Layout";
import { CustomDevicesLabel } from "./CustomDevicesLabel";
import { CheckboxGroups } from "../CheckboxGroups/checkboxGroups";

// Hooks
import { useDevices } from "./useDevices";
// import { useContext } from "react";
import { usePathname } from "../../utils/hooks/usePathname";
import { useLoading } from "../../utils/hooks/useLoading";
import BlockContent from "../../layout/blockContent/BlockContent";

type TDevices = {
  adId?: string | null;
};

const Devices = ({ adId = null }: TDevices) => {
  const { COLORS, devices, active, selectedGroups, setSelectedGroups } = useDevices(adId);

  const { isCampaignPage } = usePathname();
  const isLoading = useLoading(isCampaignPage ? "campaign" : "target_info");
  // if (!devices?.length) return null;

  return (
    <BlockContainer>
      <FlexBox
        mb={active.campaign === 1 ? "1.5rem" : "0"}
        flexDirection={{ xs: "column", mobL: "row" }}
        sx={{ alignItems: "flex-start" }}
        rowGap={"2rem"}
      >
        <Typography variant="h3">{"Devices"}</Typography>
        {active.campaign === 1 && !adId && <CheckboxGroups selectedGroups={selectedGroups} setSelectedGroups={setSelectedGroups} />}
      </FlexBox>
      <BlockContent loading={isLoading}>
        <FlexBox sx={!devices.length ? { display: "none" } : {}} mt={{ xs: "5rem", mobL: 0 }}>
          {/* <ResponsiveContainer width={300} height={300}> */}
          <PieChart width={300} height={300} startAngle={0} endAngle={180} className={"desktopOnly"}>
            <Pie data={devices} dataKey={"total_clicks"} labelLine={false} label={<CustomDevicesLabel />}>
              {devices.map((entry: any, index: number) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
              <Label value={"Usage:"} position="outside" transform="translate(20, -110)" color="#242f57" fontSize={"1.2rem"} />
            </Pie>
          </PieChart>
          {/* </ResponsiveContainer> */}
          {devices && <MonitorTable data={devices} colors={COLORS} />}
        </FlexBox>
      </BlockContent>
    </BlockContainer>
  );
};

export default Devices;
