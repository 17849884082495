//Core
import React from "react";
//Styles
import {Grid, IconButton, InputAdornment, Typography} from "@mui/material";
import {
    BillingContent,
    BillingInfoBox,
    BillingInput, DepositInfoGroupBox, DepositInfoGroupNumber, DepositInfoGroupText,
    InfoBoxContainer, InputAdornmentText,
    InputLabel,
} from "../BillingCenterPage.styles";
import {LoaderButton} from "../../../components/Forms/Forms.styles";
//Utils
import NumberFormatCustom from "../../../utils/NumberFormatInput";
//Images
import {QuestionSvg} from "../../../assets/svg/icons";
//Hooks
import {useDepositWithdraw} from "./useDepositWithdraw";
import {isSampleData} from "../../../utils/constants";

export const DepositWithdraw = () => {
    // const ref = React.createRef()
    const {
        isLoading,
        amountDeposit,
        setAmountDeposit,
        handleDeposit,
        amountWithdraw,
        setAmountWithdraw,
        handleWithdraw,
        available_balance,
        currency
    } = useDepositWithdraw()

    return (
        <BillingContent className={'deposit'}>
            <Grid container spacing={3}>
                <Grid item xs={12} br1024={6}>
                    <BillingInfoBox className={'deposit'}>
                        <Typography variant={'h3'}>{'Deposit funds'}</Typography>
                        <InfoBoxContainer className={'deposit'}>
                            <InputLabel variant={'subtitle2'}>
                                {'Enter value'}
                                <span>{'( Minimum $100 )'}</span>
                            </InputLabel>
                            <BillingInput
                                className={'deposit'}
                                placeholder={'Start typing...'}
                                value={amountDeposit ? amountDeposit.toString() : ''}
                                onChange={(e) => setAmountDeposit(e.target.value)}
                                InputProps={{
                                    inputComponent: NumberFormatCustom as any,
                                    endAdornment: <InputAdornment position={'end'}>
                                        <InputAdornmentText>{'USDT'}</InputAdornmentText>
                                    </InputAdornment>,
                                }}/>
                            <LoaderButton variant={"contained"}
                                          color={"success"}
                                            // type={"submit"}
                                          loading={isLoading.deposit}
                                          onClick={() => !isSampleData && handleDeposit()}
                                          disabled={Number(amountDeposit) < 100 || isSampleData}
                            >
                                {"Make Deposit"}
                            </LoaderButton>
                        </InfoBoxContainer>
                    </BillingInfoBox>
                </Grid>
                <Grid item xs={12} br1024={6}>
                    <BillingInfoBox className={'deposit'}>
                        <Typography variant={'h3'}>{'Withdraw'}</Typography>
                        {/*Deposit group info*/}
                        <DepositInfoGroupBox>
                            <DepositInfoGroupText>
                                {'Available balance:'}
                                <IconButton><QuestionSvg/></IconButton>
                            </DepositInfoGroupText>
                            <DepositInfoGroupNumber>{`${currency}${available_balance}`}</DepositInfoGroupNumber>
                        </DepositInfoGroupBox>

                        <InfoBoxContainer className={'deposit'}>
                            <InputLabel variant={'subtitle2'}>
                                {'Enter value to withdraw'}
                                <span>{'( Minimum $100 )'}</span>
                            </InputLabel>
                            <BillingInput
                                className={'deposit'}
                                placeholder={'Start typing...'}
                                value={amountWithdraw ? amountWithdraw.toString() : ''}
                                onChange={(e) => setAmountWithdraw(e.target.value)}
                                InputProps={{
                                    inputComponent: NumberFormatCustom as any,
                                    endAdornment: <InputAdornment position={'end'}>
                                        <InputAdornmentText>{'USDT'}</InputAdornmentText>
                                    </InputAdornment>,
                                }}/>
                            <LoaderButton variant={"contained"}
                                          color={"success"}
                                // type={"submit"}
                                          loading={isLoading.withdraw}
                                          onClick={() => !isSampleData && handleWithdraw()}
                                          disabled={Number(amountWithdraw) < 100 || isSampleData}
                            >
                                {"Withdraw"}
                            </LoaderButton>
                        </InfoBoxContainer>
                    </BillingInfoBox>
                </Grid>
            </Grid>
        </BillingContent>
    )
}