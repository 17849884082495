import Mac from "../../assets/img/devices/Mac.webp";
import Windows from "../../assets/img/devices/Windows.webp";
import Linux from "../../assets/img/devices/Linux.webp";
import Android from "../../assets/img/devices/Android.webp";
import iOS from "../../assets/img/devices/IOS.webp";

export const deviceImages = {
  Mac,
  Windows,
  Linux,
  Android,
  iOS,
};
