import { useDispatch, useSelector } from "react-redux";
import { filtersSelector } from "../../../utils/selectors";
import { useState } from "react";
import { setStatus } from "../../../redux/filters/filtersSlice";
import TransparentPopup from "../TransparentPopup";
import { PopupTitle } from "../TransparentPopup.styles";
import {
  ArrowUpDownIcon,
  CalendarIcon,
  EyeIcon,
} from "../../../assets/svg/icons";
import { capitalize, Stack, Typography } from "@mui/material";
import {
  CheckboxContainer,
  TextFieldStyled,
} from "../../../layout/subheader/Subheader.styles";
import { CheckboxWithLabel } from "../../../styled/checkboxes/checkbox";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useDashboard } from "../../../pages/Dashboard/useDashboard";
import {DatePickerMob, DateRangeInputsMobile, DateRangeTableMob} from "./DateRangePopup.styles";
import KeyValueTable from "../../Tables/KeyValueTable";
import {format} from "date-fns";
import {DATE_FORMAT, DATE_FORMAT_SLASH} from "../../../utils/constants";

const DateRangePopup = ({ open, closePopup }: any) => {
  // const { status } = useSelector(filtersSelector);
  // const dispatch = useDispatch();
  const { datesRange, handleChangeDatesRange, refetchData } = useDashboard(true);

  const handleSave = () => {
    void refetchData();
    closePopup();
  };

  const data = [
    { title: "From date", value: format(datesRange.start, DATE_FORMAT_SLASH) },
    { title: "To date", value: format(datesRange.end, DATE_FORMAT_SLASH) },
  ];

  return (
    <TransparentPopup open={open} closePopup={closePopup} onApply={handleSave}>
      <PopupTitle>
        <CalendarIcon />
        <Typography>Filter by date range</Typography>
      </PopupTitle>
        <DateRangeTableMob>
      <KeyValueTable data={data} />
        </DateRangeTableMob>
      <DateRangeInputsMobile>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePickerMob
            // label={'From Date'}
            onChange={(newValue) =>
              handleChangeDatesRange(newValue as Date, "start")
            }
            value={datesRange.start}
            renderInput={(params) => (
              <TextFieldStyled
                {...params}
                InputProps={{
                  startAdornment: <CalendarIcon />,
                  endAdornment: <ArrowUpDownIcon />,
                }}
                inputProps={{ value: "From Date" }}
              />
            )}
            maxDate={datesRange.end}
            // minDate={minDate || availableDates.start}
            inputFormat={"dd/MM/yyyy"}
            views={["year", "month", "day"]}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePickerMob
            // label={'To Date'}
            onChange={(newValue) =>
              handleChangeDatesRange(newValue as Date, "end")
            }
            value={datesRange.end}
            renderInput={(params) => (
              <TextFieldStyled
                {...params}
                InputProps={{
                  startAdornment: <CalendarIcon />,
                  endAdornment: <ArrowUpDownIcon />,
                }}
                inputProps={{ value: "To Date" }}
              />
            )}
            minDate={datesRange.start}
            maxDate={new Date()}
            inputFormat={"dd/MM/yyyy"}
            views={["year", "month", "day"]}
          />
        </LocalizationProvider>
      </DateRangeInputsMobile>
    </TransparentPopup>
  );
};

export default DateRangePopup;
