//Data
import worldMill from "@react-jvectormap/world/dist/worldMill.json";
import europeMill from "@react-jvectormap/europe/dist/europeMill.json";
import southAmericaMill from "@react-jvectormap/southamerica/dist/southAmericaMill.json";
import northAmericaMill from "@react-jvectormap/northamerica/dist/northAmericaMill.json";
import africaMill from "@react-jvectormap/africa/dist/africaMill.json";
import {TObjectBoolean} from "../../types/GeneralTypes";

 type TCountry = {
    [key: string]: string
}

export const regionsName: TCountry = {
    all: 'All',
    north_america: 'Northern America',
    south_america: 'South America',
    cis: 'CIS',
    europe: 'Europe',
    western_asia: 'Western Asia',
    southern_asia: 'Southern Asia',
    south_eastern_asia: 'South-Eastern Asia',
    eastern_asia: 'Eastern Asia',
    africa: 'Africa',
    australia: 'Australia and New Zealand',
}

const transformData = () => {
    // console.log(worldMill.content)
    const newData: TCountry = {}
    Object.entries(worldMill.content.paths).forEach((obj) => {
        newData[obj[0]] = obj[1].name
    })
    return newData
}

const codeTransformData = () => {
    const newData: TCountry = {}
    Object.entries(worldMill.content.paths).forEach((obj) => {
        newData[obj[1].name.toLowerCase()] = obj[0]
    })
    return newData
}

export const defaultRegions = () => {
    const regions: TObjectBoolean = {}
    Object.keys(regionsName).forEach(reg => regions[reg] = false)
    regions['all'] = true
    return regions
}

export const codesList: TCountry = codeTransformData()
export const countriesList: TCountry = transformData()
const europe: string[] = Object.keys(europeMill.content.paths).filter(e => Object.keys(worldMill.content.paths).includes(e))
const south_america: string[] = Object.keys(southAmericaMill.content.paths).filter(e => Object.keys(worldMill.content.paths).includes(e))
const north_america: string[] = Object.keys(northAmericaMill.content.paths).filter(e => Object.keys(worldMill.content.paths).includes(e))
const australia: string[] = ['AU', 'NZ', 'VU', 'SB', 'FJ', 'NC', 'PG'].filter(e => Object.keys(worldMill.content.paths).includes(e))
const africa: string[] = Object.keys(africaMill.content.paths).filter(e => Object.keys(worldMill.content.paths).includes(e))
const cis: string[] = ['AM', 'AZ', 'BY', 'KG', 'KZ', 'MD', 'RU', 'TJ', 'TM', 'UZ']
const western_asia: string[] = ["ae", "am", "az", "bh", "cy", "eg", "ge", "il", "iq", "ir", "jo", "kw", "lb", "om", "ps", "qa", "sa", "sy", "tr", "ye"]
    .map(country => country.toUpperCase())
    .filter(e => Object.keys(worldMill.content.paths).includes(e))
const south_eastern_asia: string[] = ["bn", "id", "kh", "la", "mm", "my", "ph", "sg", "th", "tl", "vn"]
    .map(country => country.toUpperCase())
    .filter(e => Object.keys(worldMill.content.paths).includes(e))
const southern_asia: string[] = ["af", "bd", "bt", "in", "io", "lk", "mv", "np", "pk"]
    .map(country => country.toUpperCase())
    .filter(e => Object.keys(worldMill.content.paths).includes(e))
const eastern_asia: string[] = ['CN', 'HK', 'MO', 'JP', 'MN', 'KP', 'KR', 'TW'].filter(e => Object.keys(worldMill.content.paths).includes(e))
export const all: string[] = [...north_america, ...south_america, ...europe, ...cis, ...western_asia, ...southern_asia, ...south_eastern_asia, ...eastern_asia, ...australia, ...africa]

export const regionsData: { [key: string]: string[] } = {
    all: all,
    north_america: north_america,
    south_america: south_america,
    europe: europe,
    cis: cis,
    western_asia: western_asia,
    southern_asia: southern_asia,
    south_eastern_asia: south_eastern_asia,
    eastern_asia: eastern_asia,
    australia: australia,
    africa: africa
}