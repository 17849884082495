import { Button, styled } from "@mui/material";
import { FlexBox } from "../../styled/Layout";
import theme from "../../theme/theme";
import { mobileMediumScreen, mobileScreen } from "../../utils/breakpoints";

export const CampaignItemContainer = styled(FlexBox)`
  position: relative;
  justify-content: space-between;
  background-color: #fff;
  padding: 2rem 2.5rem;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.06);
  z-index: 1;
  cursor: ${(props: { status: string | undefined }) => (props.status !== "draft" ? "pointer" : "inherit")};
  margin-bottom: 0.7rem;

  ${theme.breakpoints.down("mobL")} {
    background-image: linear-gradient(69deg, #f3fcff 0%, #e3f8ff 0%);
    box-shadow: -4px 0 8px 0 rgba(0, 0, 0, 0.08);
    border-radius: 20rem 5rem 5rem 20rem;
    padding: 1rem;
    transition: 0.3s;
  }
`;

export const CampaignItemBrandData = styled(FlexBox)`
  justify-content: flex-start;
  margin-left: 2.3rem;
  width: 37rem;
  margin-right: 2rem;

  .MuiAvatar-root {
    width: 5rem;
    height: 5rem;
    margin-right: 0.9rem;
  }

  > div:nth-of-type(2) {
    width: 50%;
    margin-right: 2rem;

    &.brand-info {
      width: fit-content;
      overflow: hidden;
    }

    h5 {
      font-family: "Hellix-Bold", "Hellix", sans-serif;
      font-size: 1.42rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      text-transform: uppercase;
      line-height: 1.47;
      letter-spacing: 0.16px;

      &.active {
        color: #f0af18;
      }

      &.expired {
        color: #b7c9d0;
      }
      //&.rejected {
      //  color: #fff;
      //}
      &.pause {
        color: #3d9ccd;
      }

      &.draft {
        color: #6e748b;
      }
    }
    p:first-of-type {
      font-size: 1.7rem;
      font-weight: 500;
      letter-spacing: 0.53px;
      color: #394368;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    p:last-of-type {
      font-size: 1.2rem;
      font-weight: 500;
      letter-spacing: 0.38px;
      color: #636e95;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  ${theme.breakpoints.down("mobL")} {
    margin-left: 0;

    > div:nth-of-type(2) {
      > p:first-of-type {
        font-size: 1.89rem;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.1;
        letter-spacing: 0.21px;
        color: #141e40;
      }
    }
  }
`;

export const CampaignItemStatus = styled(FlexBox)`
  position: relative;
  margin-left: auto;
  border-radius: 1.7rem;
  padding: 0.5rem 1.1rem;
  width: 13rem;
  min-width: 13rem;
  height: 4.4rem;
  justify-content: space-between;

  background-color: #6e748b;

  p {
    font-size: 1.4rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-transform: capitalize;
    letter-spacing: 0.44px;
    color: #fff;
    padding-left: 0.7rem;
  }

  svg {
    margin-right: 0.5rem;
  }

  .MuiSwitch-root {
    background-color: #fff;
    border-radius: 1.6rem;
    height: 2.5rem;
    width: 4.7rem;
    display: block;
  }

  .MuiSwitch-input {
  }

  .Mui-disabled {
    opacity: 0.5;
  }

  .MuiSwitch-switchBase {
    transform: translate(-0.6rem, -0.6rem);

    &.Mui-checked {
      transform: translate(1.5rem, -0.6rem);
    }
  }

  .MuiSwitch-thumb {
    width: 2rem;
    height: 2rem;
    background-color: #f2a626;
    //transform: translate(-.5rem, -.6rem);
  }

  &.active {
    background-color: #f2a626;
  }

  &.pause {
    background-color: #dedcd8;
  }

  &.draft {
    background-color: #6e748b;
  }
  
  &.rejected{
    background-color: red;
    //color: #fff;
    
    > svg{
      height: 2.5rem;

      path{
        fill: #fff;
        opacity: 1;
      }
    }
  }

  &.expired {
    border: solid 1px #6e748b;
    background-color: #fff;

    p {
      color: #6e748b;
    }
  }

  &.disabled {
    cursor: default;
    opacity: 0.5;
  }

  .waiting {
    position: absolute;
    color: #080606;
    top: 100%;
    left: 0;
    font-size: 1.2rem;
    text-transform: none;
    padding: .5rem 0 0;
    white-space: nowrap;
  }

  ${mobileScreen} {
    display: none;
  }
`;

export const AddToList = styled(Button)`
  border: none;
  /* margin-left: 5.5rem; */

  &:hover {
    border: none;
  }
  svg {
    width: 2.1rem;
    margin-right: 0.5rem;
  }
  p {
    font-size: 2.3rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.72px;
    color: #0080ff;
    transform: translateY(-0.5rem);
  }
  

`;

export const CampaignsTablePagination = styled(FlexBox)`
  margin: 4.8rem auto !important;
  gap: 1.2rem;
  > span {
    width: 11px;
    height: 11px;
    border: solid 1px #a0a7c3;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.3s;

    &.active {
      background-color: #2191ff;
      animation: scale 0.2s;
      border: none;
    }
  }
`;

export const ListButtons = styled(FlexBox)`
  margin-left: 3rem;
  column-gap: 1.1rem;
`;

export const DashedButton = styled(Button)`
  border-radius: 6px;
  border: dashed 1px rgba(99, 110, 149, 0.44);
  padding: 0.7rem 1.5rem;

  font-weight: 500;
  line-height: normal;
  text-transform: none;
  letter-spacing: 0.44px;
  color: #636e95;
  cursor: default;

  &:hover {
    border: dashed 1px rgba(99, 110, 149, 0.44);
    background-color: #fff;
  }
`;

export const CampaignItemActionsMob = styled(FlexBox)`
  margin-top: 0 !important;
  position: absolute;
  flex-direction: row-reverse;
  width: 80%;
  top: 0;
  right: 1rem;
  z-index: 0;
  height: 7.9rem;
  background-color: #256484;
  //padding-right: 5%;
  border-radius: 0 1rem 1rem 0;
  transition: 0.3s;

  ${theme.breakpoints.up("mobL")} {
    display: none;
  }

  &.visible {
    right: -1.6rem;
    border-radius: 0;
  }

  button {
    border: none;
    font-size: 1.89rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: 0.21px;
    color: #fff;
    height: 100%;
    background-color: #3d9ccd;
    padding: 0 4rem;
    border-radius: 0 2rem 2rem 0;
    transform: translateX(-4rem);

    &:not(:first-of-type) {
      margin-right: -1.5rem;
    }

    &:hover {
      background-color: #3d9ccd;
    }

    &:nth-of-type(even) {
      background: #051347;

      &:hover {
        background: #051347;
      }
    }
  }
`;
