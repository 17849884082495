import { Dialog, DialogContent, Typography } from "@mui/material";
import React from "react";
import { ImgBox } from "../../../styled/Layout";
import { ConnectPixelPopupContainer, ConnectPixelPopupContent } from "./ConnectPixel.styles";
import clockImg from './clock.svg';

const ConnectPixelPopup = ({open=false}) => {
  return (
    <ConnectPixelPopupContainer open={open}>
      <ConnectPixelPopupContent>
        <Typography variant="h2">COLLECTING DATA . . .</Typography>
        <Typography variant="h4">
          <b>Status:</b> Waiting for Pixel connection
        </Typography>
        <ImgBox>
        <img src={clockImg}/></ImgBox>

        <Typography>Data Mining info will be available within 48 hours after PIXEL activation</Typography>
      </ConnectPixelPopupContent>
    </ConnectPixelPopupContainer>
  );
};

export default ConnectPixelPopup;
