import { Avatar, Box, Button, capitalize, Grid, Stack, Typography } from "@mui/material";
import BlockContent from "../../../../layout/blockContent/BlockContent";
import { BlockContainer } from "../../../../styled/Layout";
import { useLoading } from "../../../../utils/hooks/useLoading";
import { RunningCampaignItem } from "./RunningCampaigns.styles";
import { useRunningCampaigns } from "./useRunningCampaigns";
import Grid2 from "@mui/material/Unstable_Grid2";
import { EqualizerIcon, LineGraphIcon } from "../../../../assets/svg/icons";
import { Link } from "react-router-dom";
import { appLink } from "../../../../utils/constants";
import SectionSlide from "../../../../layout/sectionSlide/SectionSlide";
import { useBreakpoints } from "../../../../utils/hooks/useBreakpoints";
import { AddToList } from "../../../../components/CampaignsTable/CampaignsTable.styles";

const RunningCampaigns = () => {
  const { runningAds } = useRunningCampaigns();
  const isLoading = useLoading("campaign_list");
  const { mobileLarge } = useBreakpoints();

  return (
    <BlockContainer sx={{ height: "fit-content", padding: "3.5rem 4rem" }}>
      <Typography variant="h3" mb="4.7rem">
        Current campaigns
      </Typography>
      <BlockContent loading={isLoading}>
        <Grid2 container spacing={"2.4rem"}>
          {runningAds.map((ad) => (
            <Grid2 xs={12} md={6} key={ad.campaign_id}>
              <RunningCampaignItem>
                <Avatar src={ad.campaign_logo}>{ad.campaign_name[0]}</Avatar>
                <Stack>
                  <Typography>{capitalize(ad?.campaign_name)}</Typography>
                  <Typography>{ad?.campaign_url}</Typography>
                </Stack>
                <a href={`${appLink}/${ad.campaign_id}/summary`} target={"app"}>
                  {mobileLarge ? (
                    <AddToList>
                      <LineGraphIcon />
                    </AddToList>
                  ) : (
                    <Button startIcon={<EqualizerIcon />}>Edit</Button>
                  )}
                </a>
              </RunningCampaignItem>
            </Grid2>
          ))}
        </Grid2>
      </BlockContent>
    </BlockContainer>
  );
};

export default RunningCampaigns;
