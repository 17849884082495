import { TKeyData } from "../../../../../types/TableTypes";
import { BinocularIcon, CoinsIcon, HoverIcon, LoudspeakerIcon, PersonIcon, RocketIcon, UmbrellaIcon } from "../../../../../assets/svg/icons";
import numeral from "numeral";
import { useSelector } from "react-redux";
import { overviewSelector } from "../../../../../utils/selectors";
import {useState} from "react";
import { usePixel } from "../../../../dataMining/usePixel";
import { getStringNumber } from "../../../../../utils/calc";

export const useDataMiningCardData = () => {
  const { total_info } = useSelector(overviewSelector);
  const [cardOpen, setCardOpen] = useState(true);
  const {data, loading} = usePixel('daily_visitors');
  const balanceData = usePixel('balance');
  
  const graphData = data?.data?.map(([date, value]: [string, number]) => ({ date, value }));


  const ppcCardsData: TKeyData[] = [
    { title: "Daily Visitors", icon: <PersonIcon />, value: balanceData?.data?.avg_daily_visitors ?? 0 },
    {
      title: "Rewards",
      icon: <CoinsIcon />,
      value: `${Math.round(balanceData?.data?.total_rewards_balance ?? 0)} $WEBM`,
    },
  ];

  const dailyVisitors = getStringNumber(balanceData?.data?.avg_daily_visitors);

  // const graphData = [
  //   {date: 'Sep', value: 1000},
  //   {date: 'Oct', value: 3000},
  //   {date: 'Nov', value: 3850},
  //   {date: 'Dec', value: 3600},
  // ]

  return { ppcCardsData, graphData, cardOpen, setCardOpen, loading, dailyVisitors };
};
