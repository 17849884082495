//Core
import React, {useEffect, useRef, useState} from "react";
//Types
import {IMapObject} from "@react-jvectormap/core/dist/types";
import {TObjectBoolean} from "../../types/GeneralTypes";
import {TGadget} from "../../types/reduxData/dataTypes";
//Utils
import {filterData, formatFromCodeToBoolean} from "./helpMethods";
//Data
import {all, codesList, countriesList, defaultRegions, regionsData, regionsName} from "./CountriesData";


export const useCountriesHandler = (countriesData: TGadget[]) => {
    const [regions, setRegions] = useState<TObjectBoolean>(defaultRegions())
    const [regionsLocations, setRegionsLocations] = useState<TObjectBoolean>(formatFromCodeToBoolean(all))
    const [visibleData, setVisibleData] = useState<TGadget[]>([])
    const [regionsDisabled, setRegionsDisabled] = useState<string[]>([])
    const mapRef = useRef<IMapObject>(null);

    //Mobile visibility of filter
    const [isFilter, setIsFilter] = useState<boolean>(false)

    const setData = (data: TGadget[]) => {
        const tmp: string[] = [...data]?.map((country) => country.name)
        const codeData = tmp.map((name) => {
            const el = Object.entries(countriesList).find(([_, country]) => country.toLowerCase() === name)
            if (el) return el[0]
            else return ''
        }).filter((code) => code !== '')
        setVisibleData(filterData(all, data))
        const tmpLocations = {...regionsLocations}
        Object.entries(tmpLocations).forEach(([code, _]) => tmpLocations[code] = false)
        codeData?.forEach((code) => {
            if (code) {
                if (all.includes(code)) tmpLocations[code] = true
            }

        })
        setRegionsLocations(tmpLocations)
        if(codeData) {
            setRegionsDisabled([])
            Object.entries(regionsData).forEach(([region, locations]) => {
                if (codeData?.filter((code) => locations.includes(code)).length === 0) {
                    setRegionsDisabled(prev => prev.includes(region) ? prev : [...prev, region])
                }
            })
        }
    }    

    useEffect(() => {
        setData(countriesData)
    }, [countriesData])

    useEffect(() => {
        // console.log(regionsLocations)
        if (Boolean(mapRef?.current?.setSelectedRegions) && Boolean(mapRef?.current?.reset)) {
            mapRef!.current!.setSelectedRegions({});
            mapRef!.current!.setSelectedRegions(regionsLocations);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [regionsLocations])

    const onChangeRegions = async (event: React.ChangeEvent<HTMLInputElement>, reg: string) => {
        const codeData = [...countriesData]?.map((country) => codesList[country.name])
        setRegions({...regions, [event.target.name]: event.target.checked})
        const tmpLocationsRegions: TObjectBoolean = {...regionsLocations}
        Object.entries(tmpLocationsRegions).forEach(([code, _]) => tmpLocationsRegions[code] = false)
        Object.entries({...regions, [event.target.name]: event.target.checked})
            .forEach(([region, value]) => {
                if (value) regionsData[region].forEach(code => {
                    if (codeData.includes(code)) tmpLocationsRegions[code] = true
                });
            })
        setRegionsLocations(tmpLocationsRegions)
        const tmpLocations = Object.entries(tmpLocationsRegions).filter(([_, boolean]) => boolean).map(([code, _]) => code)
        setVisibleData(filterData(tmpLocations, countriesData))
    }
    

    return {
        regionsName,
        mapRef,
        regions,
        onChangeRegions,
        visibleCode: regionsLocations,
        countriesTable: visibleData,
        regionsDisabled,
        countriesData,
        isFilter,
        setIsFilter
    }
}