import { forwardRef } from "react";

export function TriangleSvg<T>(props: T) {
  return (
    <svg viewBox="0 0 16 22" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        transform="translate(-1013 -2616) translate(465.5 1721.946) translate(45.744 731.365) translate(274.949) translate(0 .028) translate(0 159.816) translate(228.395 5.254)"
        stroke="#90B3D4"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <path
          strokeWidth={1.87218494}
          strokeLinecap="round"
          d="M0 17.7078518L0 0 11.8902965 8.53347425 3.12900892 15.3411278 3.12900892 11.2831911"
        />
        <ellipse
          strokeWidth={0.936092469}
          fill="#D8D8D8"
          cx={3.13342505}
          cy={7.98773694}
          rx={1}
          ry={1}
        />
      </g>
    </svg>
  );
}

export const TooltipArrow = forwardRef(({ ownerState, ...props }: any, ref) => {
  return (
    <svg
      width="23px"
      height="28px"
      viewBox="0 0 23 28"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className="tooltip-arrow"
      {...props}
    >
      <defs>
        <path d="M0 1h9.974L5.151 15.41 0 1z" id="b" />
        <filter
          x="-110.3%"
          y="-48.6%"
          width="320.6%"
          height="252.7%"
          filterUnits="objectBoundingBox"
          id="a"
        >
          <feOffset dy={4} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation={3}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feComposite
            in="shadowBlurOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.138876748 0"
            in="shadowBlurOuter1"
          />
        </filter>
      </defs>
      <g
        transform="translate(-953 -445) translate(959.915 446.606)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <use fill="#000" filter="url(#a)" xlinkHref="#b" />
        <path
          stroke="#CACDD6"
          d="M9.28 1.5L5.134 13.882.71 1.5h8.57z"
          // strokeLinejoin="square"
          fill="#FFF"
        />
        <path
          fill="#FFF"
          d="M0 2.27373675e-12L8.85871871e-14 1.8637869e-13 9.97403057 2.27373675e-12 9.97403057 2.32570907e-12 5.15097576 5 1.89355112e-13 2.32570907e-12 4.54009334e-14 2.27373675e-12z"
        />
      </g>
    </svg>
  );
});
