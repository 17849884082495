import { setStatus } from "../../../redux/filters/filtersSlice";
import { useDispatch, useSelector } from "react-redux";
import { filtersSelector } from "../../../utils/selectors";
import { usePathname } from "../../../utils/hooks/usePathname";
import { useContext, useEffect, useState } from "react";
import { ContextViewMode } from "../../../pages/Campaigns/ContextViewMode";
import { ContextCheckbox } from "../../CheckboxGroups/ContextCheckbox";
import { useViewModeDashboard } from "./useViewModeDashboard";
import { useViewModeCampaign } from "./useViewModeCampaign";

export const useViewModePopup = (open: boolean, closePopup: () => void) => {
  const dispatch = useDispatch();
  const { isCampaignPage, isDashboardPage } = usePathname();

  const { localStatus, setLocalStatus, saveDashboard } = useViewModeDashboard(open);
  const { campaignMode, localChannels, handleSelectChannels, handleSelectCampaignMode, onSaveCampaign } = useViewModeCampaign(
    open,
    closePopup
  );

  const handleSave = () => {
    if (isDashboardPage) {
      saveDashboard();
    }
    if (isCampaignPage) {
      onSaveCampaign();
    }
    closePopup();
  };

  return { handleSave, localStatus, setLocalStatus, campaignMode, localChannels, handleSelectChannels, handleSelectCampaignMode };
};
