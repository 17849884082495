// Utils
import { getStringNumber } from "../../utils/calc";
import getUnicodeFlagIcon from "country-flag-icons/unicode";

// Styles
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { Separator } from "../../styled/Layout";
import { MonitorTableContainer } from "./MonitorTable.styles";

// Types and enums
import { TGadget } from "../../types/reduxData/dataTypes";

const iconStyle = {
  width: "1.6rem",
  height: "1.6rem",
  borderRadius: "50%",
  border: "1.3px solid #fff",
  alignSelf: "center",
};

type PropTypes = {
  data: TGadget[];
  colors?: string[];
};

const MonitorTable = ({ data, colors }: PropTypes) => {
  // const sortedByClicks = data.sort((a, b) => b.clicks - a.clicks);

  return (
    <MonitorTableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              <Typography>{"Spent"}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{"Clicks"}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{"Views"}</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((el, i) => (
            <TableRow key={el.name}>
              <TableCell>
                {el?.icon}
                {colors && <div style={{ ...iconStyle, backgroundColor: colors[i] }} className="icon" />}
                {el.code && <span style={{ marginRight: "0.5rem" }}>{getUnicodeFlagIcon(el.code)}</span>}
                <Typography variant="body2">{el.name} </Typography>
                <Separator />
              </TableCell>
              <TableCell>
                <Typography variant="numbersLight">${el.total_amount_spent}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="numbersLight">{getStringNumber(el.total_clicks)}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="numbersLight">{getStringNumber(el.total_impressions)}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </MonitorTableContainer>
  );
};

export default MonitorTable;
