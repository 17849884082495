import { ButtonComingSoon } from "../../styled/buttons";
import { ComingSoonContainer } from "./ComingSoon.styles";

const ComingSoon = ({buttonProps}: any) => {
  return (
    <ComingSoonContainer>
      <ButtonComingSoon {...buttonProps} />
    </ComingSoonContainer>
  );
};

export default ComingSoon;
