import { SidebarAccordion, SidebarMainMenuItem, SidebarPageList } from "../Sidebar.styles";
import { AccordionDetails, AccordionSummary, IconButton, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { FlexBox } from "../../../styled/Layout";
import { Link } from "react-router-dom";

type TSidebarMenuItem = {
  folded: boolean;
  title: string;
  titleShort: string;
  link: string;
  disabled?: boolean;
  children: any;
};
const SidebarMenuItem = ({ folded, title, titleShort, link, disabled, children }: TSidebarMenuItem) => {
  const displayedTitle = folded ? titleShort : title;

  if(folded) return <SidebarMainMenuItem><Link to={link}> {titleShort} </Link></SidebarMainMenuItem>

  return (
    <SidebarAccordion disabled={disabled}>
      <AccordionSummary>
        <FlexBox>
          <SidebarMainMenuItem>{title}</SidebarMainMenuItem>
          {!folded && (
            <>
              <IconButton className={"plus"}>+</IconButton>
              <IconButton className={"minus"}>-</IconButton>
            </>
          )}
        </FlexBox>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </SidebarAccordion>
  );
};

export default SidebarMenuItem;
