import * as React from "react"
import {useBreakpoints} from "../../utils/hooks/useBreakpoints";

function AddIcon() {
    const {mobileLarge} = useBreakpoints();

    if(mobileLarge)   return (
        <svg
            width="6.7rem"
            height="6.7rem"
            viewBox="0 0 67 67"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <path
                    d="M32.584 20.546c1.08 0 1.955.958 1.955 2.14l-.001 8.615h9.324c1.181 0 2.14.875 2.14 1.954v.037c0 1.08-.959 1.954-2.14 1.954h-9.324v8.616c0 1.181-.874 2.14-1.954 2.14h-.036c-1.08 0-1.954-.959-1.954-2.14l-.001-8.616h-7.907c-1.182 0-2.14-.875-2.14-1.954v-.037c0-1.079.958-1.954 2.14-1.954h7.907v-8.615c0-1.182.876-2.14 1.955-2.14h.036z"
                    id="b"
                />
                <filter
                    x="-27.5%"
                    y="-19.6%"
                    width="155.0%"
                    height="155.0%"
                    filterUnits="objectBoundingBox"
                    id="a"
                >
                    <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur
                        stdDeviation={2}
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                    />
                    <feColorMatrix
                        values="0 0 0 0 0.601792935 0 0 0 0 0.643880721 0 0 0 0 0.788849762 0 0 0 1 0"
                        in="shadowBlurOuter1"
                    />
                </filter>
            </defs>
            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <circle
                    cx={33.2736168}
                    cy={33.2736168}
                    r={33.2736168}
                    fill="#B2BBDA"
                    transform="translate(-301 -1361) translate(269.225 1327.91) translate(31.926 33.298)"
                />
                <g
                    fillRule="nonzero"
                    transform="translate(-301 -1361) translate(269.225 1327.91) translate(31.926 33.298)"
                >
                    <use fill="#000" filter="url(#a)" xlinkHref="#b" />
                    <use fill="#FFF" xlinkHref="#b" />
                </g>
            </g>
        </svg>
    )

    return (
        <svg
            width="34px"
            height="34px"
            viewBox="0 0 34 34"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M58.5 39.435c-8.32 0-15.065 6.745-15.065 15.065S50.18 69.565 58.5 69.565 73.565 62.82 73.565 54.5 66.82 39.435 58.5 39.435M42 54.5C42 45.386 49.386 38 58.5 38S75 45.386 75 54.5 67.614 71 58.5 71 42 63.614 42 54.5m10.253-6.247a.72.72 0 011.016 0l5.231 5.233 5.231-5.233a.72.72 0 011.016 0 .72.72 0 010 1.016L59.514 54.5l5.233 5.231a.72.72 0 010 1.016.72.72 0 01-1.016 0L58.5 55.514l-5.231 5.233a.72.72 0 01-1.016 0 .72.72 0 010-1.016l5.233-5.231-5.233-5.231a.72.72 0 010-1.016"
                transform="translate(-631 -1197) translate(589.423 1159.65) rotate(45 58.5 54.5)"
                // fill="#9FB0C6"
                fill={'rgba(130,131,136,0.45)'}
                stroke="none"
                strokeWidth={1}
                fillRule="evenodd"
            />
        </svg>
    )
}

export default AddIcon