import { createSlice } from "@reduxjs/toolkit";
import {TBillingInfo, TUpdateUserRequestBody} from "../../types/reduxData/generalSettingsTypes";
import { TCheckCampaignsResponse } from "../../types/reduxData/userTypes";
import {TBillingHistory, TBudgetInfo} from "../../types/billingTypes";

type TInitialState = {
  id: string;
  isLoadingUser: boolean;
  hasCampaigns: boolean | undefined;
  hasDrafts: boolean | undefined;
  userData: TUpdateUserRequestBody;
  budget_info: TBudgetInfo;
  billing_info: TBillingInfo | null;
  available_balance: number;
  user_balance: number;
};

const initialState: TInitialState = {
  id: "",
  isLoadingUser: true,
  hasCampaigns: undefined,
  hasDrafts: undefined,
  userData: {
    first_name: "",
    last_name: "",
    email: "",
    wallet_address: "",
    has_nft: false,
    companies: [{ company_name: "", company_url: "https://" }],
  },
  budget_info: {
    balance: 0,
    currency: "USD",
    billing_history: []
  },
  billing_info: null,
  available_balance: 0,
  user_balance: 0,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserField: (state: TInitialState, {payload}) => {
      return {...state, ...payload};
    },
    setUserId: (state: TInitialState, { payload }: {payload: string}) => {
      state.id = payload;
    },
    setHasCampaigns: (state: TInitialState, { payload }: { payload: TCheckCampaignsResponse }) => {
      state.hasCampaigns = payload.has_campaigns;
      state.hasDrafts = payload.has_drafts;
    },
    setUserDataAction: (state: TInitialState, { payload }: { payload: TUpdateUserRequestBody }) => {
      state.userData = payload;
    },
    setBillingHistory:  (state: TInitialState, { payload }: { payload: TBillingHistory[] }) => {
      state.budget_info = {...state.budget_info, billing_history: payload};
    },
  },
});

export const { setUserId, setHasCampaigns, setUserDataAction, setUserField, setBillingHistory } = userSlice.actions;

export default userSlice.reducer;
