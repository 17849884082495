import { Button, DialogContent, IconButton, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { ClosePopupIcon, TokenHandIcon } from "../../../assets/svg/icons";
import { PopupButtons, PopupContainer, PopupContent } from "../Popup.styles";

const WithdrawRequestPopup = ({ closePopup }: any) => {
  return (
    <PopupContainer open onClose={closePopup}>
      <DialogContent>
        <IconButton className="close" onClick={closePopup}>
          <ClosePopupIcon />
        </IconButton>
        <PopupContent width={"95%"}>
          <TokenHandIcon width={"14rem"} />
          <Stack>
            <Typography variant="h3">
              Withdraw budget - <b>$800</b> available
            </Typography>
            <Typography>
              To withraw budget you have to suspend the campaign. After your confirmation within 48 hours, the campaign will be
              suspended. Then you will get the option to ask for withraw. <b>Available budget may change.</b>
            </Typography>
          </Stack>
        </PopupContent>
        <PopupButtons>
          <Button>Suspend campaign</Button>
          <Button onClick={closePopup}>Cancel</Button>
        </PopupButtons>
      </DialogContent>
    </PopupContainer>
  );
};

export default WithdrawRequestPopup;
