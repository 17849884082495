import { format, isBefore, parse, startOfToday, sub } from "date-fns";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getCampaignData,
  getComparisonCampaignData,
} from "../../../../api/statisticsRequests";
import { DATE_FORMAT } from "../../../../utils/constants";
import { campaignsSelector } from "../../../../utils/selectors";

export type TDatesRange = {
  start: Date | null;
  end: Date | null;
};

export const useCampaignSubheader = () => {
  const [datesRange, setDatesRange] = useState<TDatesRange | undefined>();

  const { list } = useSelector(campaignsSelector);

  const params = useParams();
  const navigate = useNavigate();

  const currChannel = list?.find((el) => el.campaign_id === params.id);

  const minDate = currChannel?.campaign_start_date
    ? parse(currChannel?.campaign_start_date as string, 'yyyy-MM-dd', new Date())
    : null;

  useEffect(() => {
    if (currChannel) {
      const parsedEndDate = currChannel?.campaign_end_date
        ? parse(
            currChannel?.campaign_end_date as string,
            DATE_FORMAT,
            new Date()
          )
        : null;
      const startDate = currChannel.campaign_start_date
          ? parse(currChannel.campaign_start_date as string, 'yyyy-MM-dd', new Date())
          : null;
      const endDate = parsedEndDate
        ? isBefore(parsedEndDate, new Date())
          ? parsedEndDate
          : new Date()
        : null;
      setDatesRange({
        start: startDate,
        end: endDate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currChannel]);

  useEffect(() => {
    if (list.length && !currChannel) navigate("/404");
  }, [list, currChannel]);

  const handleChangeDatesRange = (newData: Date, index: string) => {
    setDatesRange({ ...(datesRange as TDatesRange), [index]: newData });
  };

  // const dates = datesRange
  //   ? {
  //       start: datesRange?.start
  //         ? format(datesRange?.start, DATE_FORMAT)
  //         : format(sub(startOfToday(), { weeks: 1 }), DATE_FORMAT),
  //       end: datesRange?.end
  //         ? format(datesRange?.end, DATE_FORMAT)
  //         : format(new Date(), DATE_FORMAT),
  //     }
  //   : null;

  useEffect(() => {
    const dates = datesRange
        ? {
          start: datesRange?.start
              ? format(datesRange?.start, 'yyyy-MM-dd')
              : format(sub(startOfToday(), { weeks: 1 }), 'yyyy-MM-dd'),
          end: datesRange?.end
              ? format(datesRange?.end, 'yyyy-MM-dd')
              : format(new Date(), 'yyyy-MM-dd'),
        }
        : null;
    if (dates) {
      if (params?.id) {
        void getComparisonCampaignData({
          start_date: dates.start,
          end_date: dates.end,
          comparison: "ad",
          id: params?.id as string,
          channels: ["bitmedia", "coinzilla"],
        });
        void getComparisonCampaignData({
          start_date: dates.start,
          end_date: dates.end,
          comparison: "group",
          id: params?.id as string,
          channels: ["bitmedia", "coinzilla"],
        });
        void getCampaignData(params?.id as string, dates);

      }
    }
    // void getCampaignData('3baf44e6-25ae-488e-b10a-bc390ac0ef44', {start: '2022-10-20', end: '2022-11-19'});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datesRange]);

  return { currChannel, datesRange, minDate, handleChangeDatesRange };
};
