import axios from "axios";
import {DEV_ONLY, STAGING} from "../utils/constants";

const baseURL = `https://mvp-web3-${DEV_ONLY ? 'develop-' : STAGING ? 'staging-' : ''}dot-winky-apis.ew.r.appspot.com`

const web3Client = axios.create({
    baseURL,
});

export default web3Client;
