import { Box, Container } from "@mui/material";
import React from "react";
import Interest from "./components/Interest";
import TokenProfitAmount from "./components/TokenProfitAmount";
import VisitorsAmount from "./components/VisitorsAmount";
import comingSoon1 from "./components/comingSoon/avg_tr_amount.svg";
import comingSoon2 from "./components/comingSoon/wal_int.svg";
import comingSoon3 from "./components/comingSoon/hold_int.svg";
import { BlockContainer, ImgBox } from "../../../../styled/Layout";
import { useBreakpoints } from "../../../../utils/hooks/useBreakpoints";
import BlockContent from "../../../../layout/blockContent/BlockContent";
import { Swiper, SwiperSlide } from "swiper/react";
import SidebarRewards from "../../../../layout/sidebar/components/rewards/SidebarRewards";

const Layout = ({ children }: any) => {
  const { mobileLarge } = useBreakpoints();

  if (mobileLarge) {
    return (
      <Box width="100vw" ml="-12.5%">
        <Swiper centeredSlides spaceBetween={15} slidesPerView={1.2} style={{ paddingBottom: "10rem" }}>
          {children
            .slice(0, 3)
            // .filter((section: any) => isValidElement(section))
            .map((section: any, index: number) => (
              <SwiperSlide key={index}>
                <BlockContainer> {section} </BlockContainer>
              </SwiperSlide>
            ))}
        </Swiper>
        <Box sx={{ backgroundColor: "#242f57", p: "2.7rem", width: "102vw" }}>
          <SidebarRewards />
        </Box>
      </Box>
    );
  }

  return children;
};

const DataMiningOverview = () => {
  return (
    <Container sx={{ mt: { mobL: "12rem" }, width: "90%" }}>
      <Layout>
        <VisitorsAmount />
        <TokenProfitAmount />
        <Interest />
        <ImgBox mt={"7.8rem"}>
          <img src={comingSoon1} />
        </ImgBox>
        <ImgBox mt={"7.8rem"}>
          <img src={comingSoon2} />
        </ImgBox>
        <ImgBox mt={"7.8rem"} mb="10rem">
          <img src={comingSoon3} />
        </ImgBox>
      </Layout>
    </Container>
  );
};

export default DataMiningOverview;
