import { Box } from "@mui/material";
import { CustomTooltip } from "./customTooltip";

type TCustomGraphDot = {
  title: string | JSX.Element;
  x: number;
  y: number;
  color: string;
};

export const CustomGraphDot = ({ title, x, y, color }: TCustomGraphDot) => (
  <foreignObject x={x} y={y} width={30} height={30} transform={"translate(-15,-15)"}>
    <CustomTooltip sx={{ padding: "1rem 2rem" }} title={title}>
      <Box
        sx={{
          width: "30px",
          height: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "&:hover > div": {
            border: "1px solid #fff",
            boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.35)",
          },
        }}
      >
        <Box
          sx={{
            width: "13px",
            height: "13px",
            backgroundColor: color,
            borderRadius: "50%",
          }}
        ></Box>
      </Box>
    </CustomTooltip>
  </foreignObject>
);
