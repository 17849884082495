// Components
import AdSelect from "../AdSelect/AdSelect";
import Age from "../Age/Age";
import Countries from "../Countries/Countries";
import DataCards from "../DataCards/DataCards";
import Devices from "../Devices/Devices";
import Gender from "../Gender/Gender";
import Websites from "../Websites/Websites";

// Styles
import { Grid, Typography, Stack } from "@mui/material";
import { FlexBox, ImgBox } from "../../styled/Layout";
import { ExpandButton, expandButtonUp } from "../../styled/buttons";
import { AdExpandedChannelData, AdExpandedContainer, AdExpandedHead } from "./AdExpanded.styles";
import { channelsLogo } from "../../assets/img/channels/channelsLogo";

const AdExpanded = ({ data, resetExpanded }: any) => {
  if (!data) return <AdExpandedContainer className="hidden"></AdExpandedContainer>;

  const cardsData = {
    spent: data.numbers[0].value,
    views: data.numbers[1].value,
    clicks: data.numbers[2].value,
    budget: data.numbers[3].value,
    avgCpc: data.numbers[4].value,
  };

  return (
    <AdExpandedContainer id="creative-expanded">
      <ExpandButton sx={expandButtonUp} onClick={resetExpanded}>
        Collapse
      </ExpandButton>
      <AdExpandedHead>
        <FlexBox flex={"1"}>
          <ImgBox>{data.image && <img src={data.image} alt="banner" className="banner" />}</ImgBox>
          <Stack flex="1 0 auto" maxWidth={"40rem"}>
            <Typography variant="h3" mb={"1.4rem"} maxWidth={"100%"}>
              {data.title}
            </Typography>
            <AdExpandedChannelData>
              <ImgBox>
                <img src={channelsLogo[data.channel]} alt="logo" />
              </ImgBox>
              <Stack>
                <Typography variant="h4">{data.channel}</Typography>
                <Typography>{data.group_name}</Typography>
              </Stack>
            </AdExpandedChannelData>
          </Stack>
        </FlexBox>
        <DataCards data={cardsData} />
      </AdExpandedHead>
      <Grid container spacing={"2.7rem"}>
        {/*<Grid item xs={12}>*/}
        {/*  <AdSelect data={data} />*/}
        {/*</Grid>*/}
        <Grid item xs={5.3} className="block-expanded">
          <Countries adId={data.id} />
        </Grid>
        <Grid item xs={6.7}>
          <Stack spacing={"2.7rem"} className="block-expanded">
            <Websites adId={data.id} />
            <Devices adId={data.id} />
          </Stack>
        </Grid>
        {/*<Grid item xs={4.5} className="block-expanded">*/}
        {/*  <Gender />*/}
        {/*</Grid>*/}
        {/*<Grid item xs={7.5} className="block-expanded">*/}
        {/*  <Age />*/}
        {/*</Grid>*/}
      </Grid>
    </AdExpandedContainer>
  );
};

export default AdExpanded;
