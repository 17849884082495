import { Button, Typography, useMediaQuery } from "@mui/material";
import { Routes, Route, Navigate } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import MyCampaigns from "../../pages/Campaigns/MyCampaigns";
import Dashboard from "../../pages/Dashboard/Dashboard";
import HomePage from "../../pages/home/Home";
import { MainBody } from "./Main.styles";
import { useMain } from "./useMain";
import { Page404 } from "../../pages/Page404/Page404";
import { Support } from "../../pages/Support/Support";
import OverviewPage from "../../pages/Overview/OverviewPage";
import { GeneralSettings } from "../../pages/GeneralSettings/GeneralSettings";
import Sidebar from "../sidebar/Sidebar";
import Header from "../header/Header";
import NoMobilePage from "../../components/noMobile/NoMobile";
import { BillingCenterPage } from "../../pages/BillingCenter/BillingCenterPage";
import { GreenButton } from "../../styled/buttons";
import {useAuth0} from "@auth0/auth0-react";
import PPC from "../../pages/PPC/PPC";
import DataMining from "../../pages/dataMining/DataMining";
// import {useBreakpoints} from "../../utils/hooks/useBreakpoints";

const Main = () => {
  const { noUser, sideBarOpen, setSideBarOpen } = useMain();
  const { loginWithPopup } = useAuth0()
  // const {tabletResolutions} = useBreakpoints()
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("br1024"));

  if (noUser) {
    return (
      <MainBody className={'login'}>
        <Typography variant="h2" pt={{ xs: "10rem", mobL: "5rem" }} textAlign={"center"}>
          Please log in in order to continue!
        </Typography>

        <GreenButton onClick={loginWithPopup}>Log in</GreenButton>
      </MainBody>
    );
  }
  // if (loading) {
  //   return (
  //     <MainBody>
  //       <Loading />
  //     </MainBody>
  //   );
  // }

  // if (tabletResolutions) {
  //   return <NoMobilePage />;
  // }
  return (
    <>
      <Header offset={sideBarOpen ? "37rem" : "12rem"} />
      <Sidebar open={sideBarOpen} setOpen={setSideBarOpen} />

      <MainBody sx={{ ml: { mobL: sideBarOpen ? "35rem" : "10rem" } }}>
        <Routes>
          {/*<Route path="/" element={<HomePage />} />*/}
            <Route path="/" element={<Navigate to={"/dashboard"} />}/>
          {/*<Route path="overview" element={<OverviewPage />} />*/}
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="ppc/*" element={<PPC />} />
          <Route path="data_mining/*" element={<DataMining />} />
          {/*<Route path="mycampaigns/*" element={<MyCampaigns />} />*/}
          <Route path="settings" element={<GeneralSettings />} />
          <Route path="support" element={<Support />} />
          <Route path="billing/*" element={<BillingCenterPage />} />
          {/*<Route path="*" element={<Navigate to={"/"} />} />*/}
          <Route path="*" element={<Page404 />} />
        </Routes>
      </MainBody>
    </>
  );
};
export default Main;
