import { deviceImages } from "./devicesData";

type TDevice = "Mac" | "Windows" | "Linux" | "Android" | "iOS";

export const CustomDevicesLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, payload }: any) => {
  const RADIAN = Math.PI / 180;

  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const imgX = outerRadius * Math.cos(-midAngle * RADIAN);
  const imgY = outerRadius * Math.sin(-midAngle * RADIAN);

  const xMulti = x < cx ? 12 : -12;

  if (payload.name === "Desktop") return <DesktopLabel x={x - 25} y={y - 10} percent={Math.round(percent * 100)} />;
  if (payload.name === "Mobile") return <MobileLabel x={x - 19} y={y - 10} percent={Math.round(percent * 100)} />;

  return (
    <>
      <text
        x={x + xMulti}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        fontWeight={"bold"}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
      <image
        href={deviceImages[payload.payload.name as TDevice]}
        width={30}
        height={30}
        x={135}
        y={135}
        transform={`translate(${imgX}, ${imgY})`}
      />
    </>
  );
};

function DesktopLabel<T>({ percent, ...restProps }: any) {
  return (
    <svg width="50px" height="20px" viewBox="0 0 50 20" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <g fill="#FFF" stroke="none" strokeWidth={1} fillRule="evenodd">
        <text
          fontFamily="Hellix-Regular, Hellix"
          fontSize={7}
          fontWeight="normal"
          letterSpacing={0.21875}
          transform="translate(-1028 -657) translate(1028.189 657)"
        >
          <tspan x={21.1983176} y={6}>
            {"Desktop"}
          </tspan>
        </text>
        <text
          fontFamily="Hellix-Bold, Hellix"
          fontSize={12}
          fontWeight="bold"
          letterSpacing={0.375}
          transform="translate(-1028 -657) translate(1028.189 657)"
        >
          <tspan x={21.1983176} y={17.9999752}>
            {`${percent}%`}
          </tspan>
        </text>
        <path
          d="M0 3.671C0 2.471.853 1.5 1.905 1.5h13.714c1.052 0 1.905.972 1.905 2.171v8.684c0 1.2-.853 2.171-1.905 2.171H9.143v2.606h2.666c.21 0 .381.194.381.434s-.17.434-.38.434H5.713c-.21 0-.38-.195-.38-.434 0-.24.17-.434.38-.434h2.667v-2.606H1.905C.853 14.526 0 13.555 0 12.356V3.67zm1.905-1.303c-.631 0-1.143.584-1.143 1.303v8.684c0 .72.512 1.303 1.143 1.303h13.714c.63 0 1.143-.584 1.143-1.303V3.671c0-.719-.512-1.303-1.143-1.303H1.905z"
          transform="translate(-1028 -657) translate(1028.189 657)"
        />
      </g>
    </svg>
  );
}

function MobileLabel<T>({ percent, ...restProps }: any) {
  return (
    <svg width="38px" height="20px" viewBox="0 0 38 20" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <g fill="#FFF" stroke="none" strokeWidth={1} fillRule="evenodd">
        <text
          fontFamily="Hellix-Bold, Hellix"
          fontSize={12}
          fontWeight="bold"
          letterSpacing={0.375}
          transform="translate(-920 -657) translate(920.5 657)"
        >
          <tspan x={14.3559965} y={17.9999752}>
            {`${percent}%`}
          </tspan>
        </text>
        <text
          fontFamily="Hellix-Regular, Hellix"
          fontSize={7}
          fontWeight="normal"
          letterSpacing={0.21875}
          transform="translate(-920 -657) translate(920.5 657)"
        >
          <tspan x={14.3559965} y={6}>
            {"Mobile"}
          </tspan>
        </text>
        <path
          d="M0 3.293C0 2.303.7 1.5 1.563 1.5h7.5c.863 0 1.562.803 1.562 1.793v12.913c0 .991-.7 1.794-1.562 1.794h-7.5C.699 18 0 17.197 0 16.206V3.293zm1.563-1.076c-.518 0-.938.482-.938 1.076v12.913c0 .594.42 1.077.938 1.077h7.5c.517 0 .937-.483.937-1.077V3.293c0-.594-.42-1.076-.937-1.076h-7.5zm2.812 12.555c0-.198.14-.359.313-.359h1.25c.172 0 .312.16.312.359 0 .198-.14.358-.312.358h-1.25c-.173 0-.313-.16-.313-.358z"
          transform="translate(-920 -657) translate(920.5 657)"
        />
      </g>
    </svg>
  );
}
