//Core
import {ChangeEvent, useContext, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
//Redux
import {campaignsSelector, filtersSelector, overviewSelector, userSelector} from "../../utils/selectors";
//Requests
import {getComparisonOverviewData} from "../../api/statisticsRequests";
//Data
import {channelsLogo} from "../../assets/img/channels/channelsLogo";
import {ContextCheckbox} from "../CheckboxGroups/ContextCheckbox";
import {colorsComparison, parameterListComparison} from "./AdsComparison.helpers";
//Hooks
import {usePathname} from "../../utils/hooks/usePathname";
import {useBreakpoints} from "../../utils/hooks/useBreakpoints";
//Components
import {creativeTemplates, TCretiveTemplateKey} from "../CreativeCard/CreativeTemplates";
//Types
import {TObjectBoolean} from "../../types/GeneralTypes";
import {TComparison} from "../../types/reduxData/dataTypes";
import {TBrand, TCampaignComparisonGraph, TGraph, TGraphMobile, TParameter} from "../../types/AdsComparisonTypes";


//TO DO REFACTOR !!!!!!!!!!!!!
export const useAdsComparison = (activePage?: number) => {
    //active page - index of button's array View Mode
    const {isDashboardPage, isCampaignPage} = usePathname();

    const {comparison, info_by_campaigns} = useSelector(overviewSelector);
    const {comparisonCampaign, data} = useSelector(campaignsSelector);
    const {dates, status} = useSelector(filtersSelector);
    const {id} = useSelector(userSelector);
    const {mobileLarge} = useBreakpoints();

    //Checkbox or radio filter for all the campaign
    const [, , , channelsSelected, channelRadio] = useContext(ContextCheckbox);

    //Select input Comparison
    const [comparisonSelect, setComparisonSelect] = useState<string>(isDashboardPage ? "campaign" : "ad");
    //Select input Parameter
    const [parameter, setParameter] = useState<string>("ROI");
    //Data for the graph
    const [graphData, setGraphData] = useState<{ [key: string]: number | string }[]>([]);
    //IDs of lines in the graph
    const [graphLines, setGraphLines] = useState<string[]>([]);
    //Status of checkboxes
    const [checkboxData, setCheckboxData] = useState<TObjectBoolean>({});
    //Info in the checkbox about comparison element in the graph
    const [brandCheckbox, setBrandCheckbox] = useState<{ [key: string]: TBrand }>();
    //Mobile Popup open
    const [popup, setPopup] = useState<string>('')
    //Popup brand data
    const [brandDataPopup, setBrandDataPopup] = useState<TBrand>()
    //Popup graph data
    const [graphDataPopup, setGraphDataPopup] = useState<{ [key: string]: number | string }[]>([])

    const params = useParams();

    const comparisonSelectList = isDashboardPage ? ["campaign", "channel"] : ["ad", "group"];

    //Desktop data
    const setDashboardData = (parameterData: TParameter) => {
        if (comparison) {
            const graph: TGraph = {}
            const comparisonGraph = Object.entries(comparison)
                .filter(([_, dates]) => Object.keys(dates).length)
                .splice(0, 5);
            comparisonGraph.forEach(([key, datesData]) => {
                Object.entries(datesData).forEach(([date, data]) => {
                    graph[date] = {...graph[date], [key]: data[parameterData]};
                });
            });
            const {brand, checkbox} = setDashboardCheckBoxData(comparisonGraph)
            setGraphLines(Object.keys(brand).filter((line) => checkbox[line]));
            setGraphData(Object.entries(graph).slice(-6).map(([date, data]) => ({...data, name: date}))
            )
        }
    };

    const setDashboardCheckBoxData = (comparisonGraph: [string, { [p: string]: TComparison }][]) => {
        const checkbox: TObjectBoolean = {};
        const brand: { [key: string]: TBrand } = {};
        comparisonGraph.forEach(([key, _], i) => {
            checkbox[key] = true;
            if (comparisonSelect === "campaign" && info_by_campaigns) {
                brand[key] = {
                    name: info_by_campaigns?.[key]?.campaign_name ?? "",
                    id: info_by_campaigns?.[key]?.campaign_id ?? key,
                    image: info_by_campaigns?.[key]?.campaign_logo ?? "",
                    link: info_by_campaigns?.[key]?.campaign_url ?? "",
                    color: colorsComparison[i],
                };
            } else if (comparisonSelect === "channel") {
                brand[key] = {
                    name: key,
                    id: key,
                    image: channelsLogo[key] ?? "",
                    color: colorsComparison[i],
                };
            }
        });
        setBrandCheckbox(brand);
        setCheckboxData(checkbox);
        return {brand, checkbox}
    }

    const setCampaignData = (parameterData: TParameter) => {
        // console.log(comparisonCampaign)
        const comparisonType = comparisonSelect + 's'
        if (comparisonCampaign[comparisonType]) {
            const graph: TGraph = {}
            const selectedChannels = Object.entries(channelsSelected)
                .filter(([_, value]) => value)
                .map(([channel, _]) => channel);
            const comparisonGraph =
                Object.entries(comparisonCampaign[comparisonType])
                            .filter(([_, data]) => Object.keys(data.dates).length && selectedChannels.includes(data.general_info.channel))
                            .splice(0, 5)
            comparisonGraph.forEach(([key, datesData]) => {
                Object.entries(datesData.dates).forEach(([date, data]) => {
                    if (date) {
                        graph[date] = {
                            ...graph[date],
                            [key]: data[parameterData],
                        };
                    }
                });
            });
            const {brand, checkbox} = setCampaignCheckboxData(comparisonGraph, comparisonSelect)
            setGraphLines(Object.keys(brand).filter((line) => checkbox[line]));
                setGraphData(
                    Object.entries(graph)
                        .slice(-6)
                        .map(([date, data]) => ({...data, name: date}))
                );
        }
    };

    const setCampaignCheckboxData = (comparisonGraph: [string, TCampaignComparisonGraph][], comparison='ad') => {
        const checkbox: TObjectBoolean = {};
        const brand: { [key: string]: TBrand } = {};
        comparisonGraph.forEach(([key, data], i) => {
            checkbox[key] = true;
            if (comparison === 'group') {
                brand[key] = {
                    name:
                        data.general_info.group_name ??
                        (data.general_info.group_num ? `Group ${data.general_info.group_num}` : `Group ${i + 1}`),
                    id: key,
                    link: data.general_info.channel ?? "",
                    color: colorsComparison[i],
                    image: mobileLarge ? channelsLogo[data.general_info.channel] : "",
                };
            } else {
                brand[key] = {
                    name: `Ad ${i + 1}`,
                    id: key,
                    link: data.general_info.channel ?? "",
                    color: colorsComparison[i],
                    image: mobileLarge ? channelsLogo[data.general_info.channel] : "",
                };
            }
        });
        setBrandCheckbox(brand);
        setCheckboxData(checkbox);
        return {brand, checkbox};
    }


    //MOBILE DATA
    const filterGraphData = (
        comparisonGraph: [string, { [p: string]: TComparison }][],
        parameterData: TParameter,
        popup = false
    ) => {
        const graph: TGraphMobile = {}
        const newComp: { [date: string]: { [key: string]: TComparison } } = {}
        comparisonGraph.forEach(([key, datesData]) => {
            Object.entries(datesData).forEach(([date, data]) => {
                newComp[date] = {
                    ...newComp[date],
                    [key]: data
                }
            })
        });
        Object.entries(newComp).forEach(([date, datesData]) => {
            let maxValue: number = 0;
            let maxKey: string = '';
            Object.entries(datesData).forEach(([key, data]) => {
                if (data[parameterData] > maxValue) {
                    maxValue = data[parameterData]
                    maxKey = key
                }
                graph[date] = {key: maxKey, value: maxValue};
            });
        });
        popup
            ? setGraphDataPopup(Object.entries(graph)
                .filter(([_, {key, value}]) => value !== 0)
                .slice(-5)
                .map(([date, data]) => ({...data, name: date}))
            )
            : setGraphData(
                Object.entries(graph)
                    .filter(([_, {key, value}]) => value !== 0)
                    .slice(-3)
                    .map(([date, data]) => ({...data, name: date}))
            )
        return newComp;
    }

    const setDashboardMobileData = (parameterData: TParameter) => {
        if (comparison) {
            const comparisonGraph = Object.entries(comparison)
                .filter(([_, dates]) => Object.keys(dates).length)
                .splice(0, 5);
            filterGraphData(comparisonGraph, parameterData)
            setDashboardCheckBoxData(comparisonGraph)
        }
    }

    const filterCampaignGraphData = (
        comparisonGraph: [string, TCampaignComparisonGraph][],
        parameterData: TParameter,
        popup = false
    ) => {
        const graph: TGraphMobile = {}
        const newComp: { [date: string]: { [key: string]: TComparison } } = {}
        comparisonGraph.forEach(([id, datesData]) => {
            Object.entries(datesData.dates).forEach(([date, data]) => {
                newComp[date] = {
                    ...newComp[date],
                    [id]: data
                }
            })
        });
        Object.entries(newComp).forEach(([date, datesData]) => {
            let maxValue: number = 0;
            let maxKey: string = '';
            Object.entries(datesData).forEach(([id, data]) => {
                if (data[parameterData] > maxValue) {
                    maxValue = data[parameterData]
                    maxKey = id
                }
                graph[date] = {key: maxKey, value: maxValue};
            });
        });
        popup
            ? setGraphDataPopup(Object.entries(graph)
                .filter(([_, {key, value}]) => value !== 0)
                .slice(-5)
                .map(([date, data]) => ({...data, name: date})))
            :
            setGraphData(Object.entries(graph)
                .filter(([_, {key, value}]) => value !== 0)
                .slice(-3)
                .map(([date, data]) => ({...data, name: date})))
    }

    const setCampaignMobileData = (parameterData: TParameter) => {
        if (comparisonCampaign?.groups && comparisonCampaign?.ads) {
            const selectedChannels = Object.entries(channelsSelected)
                .filter(([_, value]) => value)
                .map(([channel, _]) => channel);
            const comparisonGraph =
            Object.entries(comparisonSelect === 'group' ? comparisonCampaign.groups : comparisonCampaign.ads)
                            .filter(([_, data]) => Object.keys(data.dates).length && selectedChannels.includes(data.general_info.channel))
                            .splice(0, 5);
            filterCampaignGraphData(comparisonGraph, parameterData)
            setCampaignCheckboxData(comparisonGraph, comparisonSelect)
        }
    }

    const setData = (par?: string) => {
        const parameterData = par ? parameterListComparison[par] : parameterListComparison[parameter];
        if (isDashboardPage) {
            if (mobileLarge) setDashboardMobileData(parameterData)
            else setDashboardData(parameterData);
        }
        if (isCampaignPage) {
            if (mobileLarge) setCampaignMobileData(parameterData)
            else setCampaignData(parameterData)
        }
    };

    //Get new data in the Dashboard page, when we change the Comparison select input
    useEffect(() => {
        if (isDashboardPage && id) getComparisonOverviewData(comparisonSelect as "campaign" | "channel");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dates, id, status]);

    //Set a new data on Campaign page, when we change the Parameter select input (select input is disabled)
    useEffect(() => {
        isCampaignPage && setComparisonSelect("ad");
        setData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [info_by_campaigns, comparisonCampaign, activePage, channelRadio, channelsSelected]);

    const handleComparison = async (value: string) => {
        setComparisonSelect(value);
        if (isDashboardPage) {
            await getComparisonOverviewData(value as "campaign" | "channel");
        }
        if (isCampaignPage && comparisonCampaign?.groups && comparisonCampaign?.ads) {
            const graph: TGraph = {}
            const selectedChannels = Object.entries(channelsSelected)
                .filter(([_, value]) => value)
                .map(([channel, _]) => channel);
            const comparisonGraph =
                Object.entries(value === "group" ? comparisonCampaign.groups : comparisonCampaign.ads)
                    .filter(([_, data]) => Object.keys(data.dates).length && selectedChannels.includes(data.general_info.channel))
                    .splice(0, 5)
            if (mobileLarge) {
                filterCampaignGraphData(comparisonGraph, parameterListComparison[parameter])
                setCampaignCheckboxData(comparisonGraph, value)
            } else {
                comparisonGraph.forEach(([key, datesData]) => {
                    Object.entries(datesData.dates).forEach(([date, data]) => {
                        if (date) {
                            graph[date] = {
                                ...graph[date],
                                [key]: data[parameterListComparison[parameter]],
                            };
                        }
                    });
                });
                const {brand, checkbox} = setCampaignCheckboxData(comparisonGraph, value)
                setGraphLines(Object.keys(brand).filter((line) => checkbox[line]));
                    setGraphData(
                        Object.entries(graph)
                            .slice(-6)
                            .map(([date, data]) => ({...data, name: date}))
                    );
            }
        }
    };

    const handleParameter = (value: string) => {
        setParameter(value);
        if (mobileLarge) {
            if (isDashboardPage && comparison) {
                const comparisonGraph = Object.entries(comparison)
                    .filter(([key, dates]) => !!Object.keys(dates).length && checkboxData[key])
                    .splice(0, 5);
                filterGraphData(comparisonGraph, parameterListComparison[value])
            } else if (isCampaignPage && comparisonCampaign?.groups && comparisonCampaign?.ads) {
                    const selectedChannels = Object.entries(channelsSelected)
                        .filter(([_, value]) => value)
                        .map(([channel, _]) => channel);
                    const comparisonGraph =
                            Object.entries(comparisonSelect === "group" ? comparisonCampaign.groups : comparisonCampaign.ads)
                                .filter(([key, data]) =>
                                    Object.keys(data.dates).length &&
                                    selectedChannels.includes(data.general_info.channel)
                                    && checkboxData[key]
                                )
                                .splice(0, 5);
                    filterCampaignGraphData(comparisonGraph, parameterListComparison[value])
            }
        } else {
            setData(value);
        }

    };

    const handleCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
        const newCheckbox = {...checkboxData, [event.target.name]: event.target.checked};
        setCheckboxData(newCheckbox);
        if (mobileLarge) {
            if (isDashboardPage && comparison) {
                const comparisonGraph = Object.entries(comparison)
                    .filter(([key, dates]) => newCheckbox[key] && !!Object.keys(dates).length)
                    .splice(0, 5);
                filterGraphData(comparisonGraph, parameterListComparison[parameter])
            } else if (isCampaignPage && comparisonCampaign?.groups && comparisonCampaign?.ads) {
                const selectedChannels = Object.entries(channelsSelected)
                    .filter(([_, value]) => value)
                    .map(([channel, _]) => channel);
                const comparisonGraph =
                        Object.entries(comparisonSelect === 'ad' ? comparisonCampaign.ads : comparisonCampaign.groups)
                            .filter(([key, data]) =>
                                Object.keys(data.dates).length &&
                                selectedChannels.includes(data.general_info.channel)
                                && newCheckbox[key]
                            )
                            .splice(0, 5);
                filterCampaignGraphData(comparisonGraph, parameterListComparison[parameter])
            }
        } else setGraphLines(Object.keys(newCheckbox).filter((line) => newCheckbox[line]));
    };

    //Data for the tooltip in the graph
    const getAdData = (id: string) => {
        if (!data) return;
        const ad = data[params.id as string]?.find((el) => el.ad_id === id);

        if (!ad || !ad?.creative?.type) return;

        return creativeTemplates[ad?.creative?.type as TCretiveTemplateKey]({
            data: {
                title: ad.creative?.title || ad.creative?.headline || " ",
                description: ad.creative?.desc_1 || ad.creative?.small_desc || ad.creative?.desc,
                description2: ad.creative?.desc_2 || ad.creative?.long_desc,
                image: ad.creative?.image,
                logo: ad.creative?.logo,
                dUrl: ad.creative?.call_to_action || "",
            },
        });
    };

    const getAdTitle = (id: string) => {
        if (!data) return "Banner title";

        if (activePage === 1) return "Banner title";

        const ad = data[params.id as string]?.find((el) => el.ad_id === id);

        if (!ad) return "Banner title";

        const title = ad.creative?.title || ad.creative?.headline || "Banner title";

        return title.split(" ").slice(0, 4).join(" ") + (title.split(" ").length > 4 ? "..." : "");
    };

    const openPopup = (line: string) => {
        setPopup('comparison')
        if (comparison && isDashboardPage) {
                const comparisonGraph = Object.entries(comparison)
                    .filter(([key, dates]) => !!Object.keys(dates).length && key === line)
                    .splice(0, 5);
                filterGraphData(comparisonGraph, parameterListComparison[parameter], true)
        }
        if (isCampaignPage && comparisonCampaign?.groups && comparisonCampaign?.ads) {
            const selectedChannels = Object.entries(channelsSelected)
                .filter(([_, value]) => value)
                .map(([channel, _]) => channel);
            const comparisonGraph =
                    Object.entries(comparisonSelect === 'ad' ? comparisonCampaign.ads : comparisonCampaign.groups)
                        .filter(([key, data]) =>
                            Object.keys(data.dates).length &&
                            selectedChannels.includes(data.general_info.channel)
                            && key === line
                        )
                        .splice(0, 5);
            filterCampaignGraphData(comparisonGraph, parameterListComparison[parameter], true)
        }
    }

    return {
        graphLines,
        graphData,
        comparison: comparisonSelect,
        handleComparison,
        parameter,
        handleParameter,
        parameterSelectList: Object.keys(parameterListComparison),
        comparisonSelectList,
        isCampaignPage,
        isDashboardPage,
        checkboxData,
        handleCheckbox,
        brandCheckbox,
        getAdData,
        getAdTitle,
        popup,
        setPopup,
        brandDataPopup,
        setBrandDataPopup,
        graphDataPopup,
        setGraphDataPopup,
        openPopup
    };
};
