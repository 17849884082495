import { CampaignPageTitle, FlexBox, SectionHeadBackground } from "../../../../styled/Layout";
import { Container, Stack, Typography } from "@mui/material";
import { TCampaignListItem } from "../../../../types/reduxData/campaignsTypes";
import { TDatesRange } from "./useCampaignSubheader";
import DateFilter from "../../../../components/DateFilter/DateFilter";
import { SecondaryButton } from "../../../../styled/buttons";
import { EqualizerIcon } from "../../../../assets/svg/icons";
import { CampaignSwitch } from "../../../../styled/switch";
import { appLink } from "../../../../utils/constants";
import {useEffect, useState} from "react";
import {updateCampaignStatus} from "../../../../api/campaignsRequests";

type TCampaignHeadProps = {
  currChannel: TCampaignListItem | undefined;
  datesRange: TDatesRange | undefined;
  handleChangeDatesRange: (newData: Date, index: string) => void;
};
const CampaignHead = ({ currChannel, datesRange, handleChangeDatesRange }: TCampaignHeadProps) => {
  const [status, setStatus] = useState<string>(currChannel?.campaign_status.status ?? '');
  const [statusChanged, setStatusChanged] = useState<boolean>(false);

  const currStatus = currChannel?.campaign_status
  const isSwitch = currStatus?.status === 'waiting' &&
        (currStatus?.status_obj?.next_status === 'active' || currStatus?.status_obj?.next_status === 'pause') &&
        (currStatus?.status_obj?.prev_status !== 'draft')
  const handleStatusChange = async () => {
    if (status === "active") {
      const response = await updateCampaignStatus(currChannel!.campaign_id, 'pause', 'active', []);
      if (response?.status === 'success') {
        setStatusChanged(true);
        setStatus("pause");
      }
    } else {
      const response = await updateCampaignStatus(currChannel!.campaign_id, 'active', 'pause', []);
      if (response?.status === 'success') {
        setStatusChanged(true);
        setStatus("active");
      }
    }

  };

  useEffect(() => {
    if(currChannel){
      setStatus(currChannel?.campaign_status.status)
    }
    if (isSwitch) {
      setStatus(currStatus?.status_obj?.next_status ?? '')
      setStatusChanged(true)
    }
  }, [currChannel])
  // console.log(currChannel);
  return (
    <SectionHeadBackground>
      <Container sx={{ display: "flex", justifyContent: "space-between", p: "4rem 0" }}>
        <Stack>
          {currChannel && (
            <CampaignPageTitle variant={"h2"} mb={"2rem"}>
              {currChannel?.campaign_name} <span> (PPC Ads)</span>
            </CampaignPageTitle>
          )}
          <FlexBox>
            <a href={`${appLink}/summary/${currChannel?.campaign_id}`} target={"app"}>
              <SecondaryButton startIcon={<EqualizerIcon />}>{'Edit campaign'}</SecondaryButton>
            </a>
            <Stack ml={"6rem"}>
              <FlexBox mb={'1rem'}>
                {(['active', 'pause'].includes(status) || isSwitch) &&
                    <>
                      <Typography mr={"1.5rem"} color={"#8a8a8a"} textTransform={"capitalize"} fontWeight={500}>
                        {status}
                      </Typography>
                      <CampaignSwitch checked={status === "active"} disabled={statusChanged} onChange={handleStatusChange}/>
                    </>
                }
            </FlexBox>
              {statusChanged &&
                  <Typography className={'waiting'}>{'Waiting for status update...'}</Typography>}
            </Stack>
          </FlexBox>
        </Stack>
        <DateFilter datesRange={datesRange} handleChangeDatesRange={handleChangeDatesRange} />
      </Container>
    </SectionHeadBackground>
  );
};

export default CampaignHead;
