import { LinkDivider, PPCHeaderLinks } from "./PPCHeader.styles";
import { Typography } from "@mui/material";
import {FlexBox, SectionHeadBackground, SectionHeadContainer} from "../../styled/Layout";
import { Link } from "react-router-dom";
import { usePathname } from "../../utils/hooks/usePathname";

const PpcHeader = () => {
  const { isCampaignsPage, isPPCOverviewPage, isCampaignPage } = usePathname();

  if(isCampaignPage) return null;
  return (
    <SectionHeadBackground>
      <SectionHeadContainer>
        <FlexBox flexDirection={{xs: 'column', mobL: 'row'}} width='100%'>
          <Typography variant={"h2"} alignSelf={'flex-start'}>PPC Ads</Typography>
          <PPCHeaderLinks>
            <Link to={"/ppc/overview"}>
              <Typography className={isPPCOverviewPage ? "active" : ""}>Overview</Typography>
            </Link>
            <LinkDivider>|</LinkDivider>
            <Link to={"/ppc/mycampaigns"}>
              <Typography className={isCampaignsPage ? "active" : ""}>My campaigns</Typography>
            </Link>
          </PPCHeaderLinks>
        </FlexBox>
      </SectionHeadContainer>
    </SectionHeadBackground>
  );
};

export default PpcHeader;
