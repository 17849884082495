import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCampaignList } from "../../api/statisticsRequests";
import {campaignsSelector, filtersSelector, userSelector} from "../../utils/selectors";
import {useLocation} from "react-router-dom";

export const useMyCampaigns = () => {
  const { list } = useSelector(campaignsSelector);
  const { id } = useSelector(userSelector);
  const {dates_available} = useSelector(filtersSelector);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (id && location.pathname.startsWith('/ppc')) {
      const func = async () => {
        setLoading(true);
        await getCampaignList();
        setLoading(false);
      };
      void func();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dates_available, location.pathname]);



  return { loading };
};
