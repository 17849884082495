//Libraries
import {Container, Grid, Stack} from "@mui/material";
//Components
import {CampaignChannelName} from "../../../../components/CampaignChannelName/CampaignChannelName";
import AdCreatives from "../../../../components/AdCreatives/AdCreatives";
import AdPlacement from "../../../../components/AdPlacement/AdPlacement";
import AdsComparison from "../../../../components/AdsComparison/AdsComparison";
import Age from "../../../../components/Age/Age";
import Countries from "../../../../components/Countries/Countries";
import DataCards from "../../../../components/DataCards/DataCards";
import Devices from "../../../../components/Devices/Devices";
import Duration from "../../../../components/Duration/Duration";
import Gender from "../../../../components/Gender/Gender";
import Websites from "../../../../components/Websites/Websites";
import CampaignProgress from "../../../../components/CampaignProgress/CampaignProgress";
import {ViewMode} from "../../../../components/ViewModePanel/ViewMode";
import {Subheader} from "../../../../layout/subheader/Subheader";
//Styles
import {FlexBox} from "../../../../styled/Layout";
import {CheckboxChannel} from "../../../../styled/checkboxes/checkboxChannel";
import {RadioChannel} from "../../../../styled/radio/radioChannel";
import {CheckboxGroups} from "../../../../components/CheckboxGroups/checkboxGroups";
//Context
import {ContextCheckbox} from "../../../../components/CheckboxGroups/ContextCheckbox";
//Hooks
import {useCampaignPage} from "./useCampaignPage";
import {useCampaignSubheader} from "./useCampaignSubheader";
import {useBreakpoints} from "../../../../utils/hooks/useBreakpoints";
import CampaignPageMobile from "./CampaignPageMobile";
import CampaignHead from "./CampaignHead";

export const CampaignPage = () => {
    const subHeaderData = useCampaignSubheader();
    const {
        channelsSelected,
        setChannelsSelected,
        channelRadio,
        setChannelRadio,
        handleSelected,
        filteredData,
        mainSelected,
        setMainSelected,
        campaignCardsData,
        progressData,
        campaignInList,
        active,
    } = useCampaignPage();
    // const activePage = active.campaign;
    const {mobileLarge} = useBreakpoints();
    if (mobileLarge) return <CampaignPageMobile/>

    return (
        <>
            {/*<Subheader {...subHeaderData} />*/}
            <CampaignHead {...subHeaderData}/>

            {/*<ViewMode/>*/}
            <ContextCheckbox.Provider
                value={[mainSelected, setMainSelected, filteredData, channelsSelected, channelRadio, setChannelsSelected, setChannelRadio]}>
                <Container sx={{mt: "4.8rem"}}>
                    <Grid container spacing={"2.7rem"} pb={"14.5rem"}>
                        <Grid item xs={7}>
                            {/*{activePage !== 1 ? (*/}
                                <FlexBox>
                                    {Object.keys(channelsSelected).map((channel, i) => (
                                        <CheckboxChannel
                                            channel={channel}
                                            onChange={() => handleSelected(channel)}
                                            checked={channelsSelected[channel]}
                                            key={channel + i}
                                        />
                                    ))}
                                </FlexBox>
                            {/*) : (*/}
                            {/*    <RadioChannel data={channelRadio} setData={setChannelRadio}*/}
                            {/*                  channelsList={channelsSelected}/>*/}
                            {/*)}*/}
                        </Grid>
                        <Grid item xs={5}>
                            {/*{activePage === 0 && */}
                            {campaignInList?.campaign_status?.status === 'active' && <CampaignProgress data={progressData}/>}
                            {/*}*/}
                            {/*{activePage === 1 && <CheckboxGroups main/>}*/}
                        </Grid>
                        {/*{activePage === 1 && (*/}
                        {/*    <Grid item xs={4}>*/}
                        {/*        <CampaignChannelName channel={channelRadio}/>*/}
                        {/*    </Grid>*/}
                        {/*)}*/}
                        {/*{activePage !== 2 && (*/}
                            <Grid item xs={8}>
                                <DataCards data={campaignCardsData}/>
                            </Grid>
                        {/*)}*/}
                        {/*{activePage === 0 && (*/}
                            <Grid item xs={4}>
                                <Duration/>
                            </Grid>
                        {/*)}*/}
                        <Grid item xs={12}>
                            <AdsComparison activePage={0}/>
                        </Grid>
                        {/*{activePage === 0 && (*/}
                        {/*    <Grid item xs={4}>*/}
                        {/*        <AdPlacement/>*/}
                        {/*    </Grid>*/}
                        {/*)}*/}

                        <Grid item xs={12}>
                            <AdCreatives/>
                        </Grid>
                        {/*{activePage !== 2 && (*/}
                            <>
                                <Grid item xs={5.3}>
                                    <Countries/>
                                </Grid>
                                <Grid item xs={6.7}>
                                    <Stack spacing={"2.7rem"}>
                                        <Websites/>
                                        <Devices/>
                                    </Stack>
                                </Grid>
                                {/*<Grid item xs={4.5}>*/}
                                {/*    <Gender/>*/}
                                {/*</Grid>*/}
                                {/*<Grid item xs={7.5}>*/}
                                {/*    <Age/>*/}
                                {/*</Grid>*/}
                            </>
                        {/*)}*/}
                    </Grid>
                </Container>
            </ContextCheckbox.Provider>
        </>
    );
};
