import {styled} from "@mui/material";
import {ChannelImgBox} from "../../../components/CreativeCard/CreativeCard.styles";
import {FlexBox} from "../../../styled/Layout";

export const ComparisonImageBox = styled(ChannelImgBox)`
  border: 1px solid #FFFFFF;
  border-radius: 50px;
  overflow: hidden;
  width: 5rem;
  height: 5rem;
  img {
    object-fit: cover;
    scale: 1.05
  }
`

export const AdBoxPopup = styled(FlexBox)`
  border-radius: 1rem;
  overflow: hidden;
  background-color: #FFFFFF;
  & > div {
    margin-top: 0;
  }
`