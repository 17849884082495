import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { TKeyData } from "../../types/TableTypes";
import { campaignsSelector } from "../../utils/selectors";

export const useDuration = () => {
  const { list } = useSelector(campaignsSelector);
  const params = useParams();

  const currCampaign = list?.find((el) => el.campaign_id === params.id);

  const durationData: TKeyData[] = [
    { title: "Campaign Start", value: currCampaign?.campaign_start_date?.replaceAll("-", " / ") || "" },
    { title: "Campaign End", value: currCampaign?.campaign_end_date?.replaceAll("-", " / ") || "" },
  ];

  return { durationData };
};
