import React from "react";
import {NumericFormat, InputAttributes, NumericFormatProps} from "react-number-format";

const NumberFormatCustom = React.forwardRef<NumericFormatProps>(function NumberFormatCustom(props, ref) {
    return (
        <NumericFormat
            {...props}
            getInputRef={ref}
            thousandSeparator=","
            valueIsNumericString
        />
    );
});

export default NumberFormatCustom;