import React, {Dispatch, SetStateAction} from "react";
import {TObjectBoolean} from "../../types/GeneralTypes";
import {TAd, TPublisher} from "../../types/reduxData/dataTypes";


export const ContextCheckbox = React.createContext<
    [
        TObjectBoolean,
        Dispatch<TObjectBoolean>,
        TAd[],
        TObjectBoolean,
        TPublisher | string,
        Dispatch<TObjectBoolean>,
        Dispatch<TPublisher>
    ]
>([
    {},
    (value) => {},
    [],
    {},
    '',
    (channel) => {},
    (channel) => {}
])