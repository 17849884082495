import {Box, Stack, styled, Typography} from "@mui/material";
import {FlexBox} from "../../../styled/Layout";
import {PopupContainer} from "../Popup.styles";
import theme from "../../../theme/theme";

export const MainTitle = styled(Typography)`
  font-size: 2.34rem;
  font-weight: bold;
  letter-spacing: 0.26px;
  color: #510813;
  text-transform: uppercase;
  margin-bottom: 3rem;

  ${theme.breakpoints.down("mobL")} {
    font-size: 2.94rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: 0.33px;
    color: #f83b3b;
    margin-bottom: 2rem;
  }
`

export const SecondTitle = styled(Typography)`
  font-size: 2.04rem;
  font-weight: bold;
  letter-spacing: 0.23px;
  color: #d60524;
  text-transform: uppercase;
  ${theme.breakpoints.down("mobL")} {
    display: none;
  }
`

export const HowToFix = styled(Typography)`
  font-size: 1.54rem;
  font-weight: bold;
  line-height: 1.17;
  letter-spacing: 0.17px;
  color: rgba(0, 0, 0, 0.4);
  margin: 0.8rem 0;
`

export const SmallText = styled(Typography)`
  font-size: 1.44rem;
  line-height: 1.25;
  letter-spacing: 0.16px;
  color: #000;
  margin-bottom: 0.5rem;
  span {
    font-weight: bold;
  }

  ${theme.breakpoints.down("mobL")} {
    opacity: 0.69;
    font-size: 1.94rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: 0.22px;
    color: #fff;
    max-width: 100%;
    a,
    a:visited {
      color: #fff;
    }
  }
`

export const LinkToSignUp = styled('a')`
  margin-top: 1.7rem;
  text-decoration: underline;
  color: rgba(74, 87, 103, 0.52);
  &:visited {
    color: rgba(74, 87, 103, 0.52);
  }
`

export const InfoBlock = styled(Stack)`
  z-index: 2;
`

export const SvgContainer = styled(Box)`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  overflow: hidden;
  svg {
    position: absolute;
    z-index: 0;
    top: -12.5rem;
    left: -4rem;
    height: 54rem;
  }

  ${theme.breakpoints.down("mobL")} {
    position: relative;
    width: 95%;
    margin: 5rem 0 3.5rem;

    svg {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
    }
  }
`

export const NoNFTContent = styled(FlexBox)`
  margin-left: 24rem;
  width: fit-content;
  min-width: 40rem;
  
  ${theme.breakpoints.down("mobL")} {
    flex-direction: column;
    min-width: auto;
    margin: 0 auto;
    width: 80%;

    .MuiButtonBase-root {
      margin-top: 5rem;
    }
  }
`

export const NoNFTPopupContainer = styled(PopupContainer)`
  .MuiDialog-container {
    height: fit-content;
    margin-top: 340px;
  }
  .MuiDialog-paper {
    padding: 8rem 8.5rem 9rem !important;
    overflow-y: unset;
    margin: 32px 0 300px 64px;
  }

  ${theme.breakpoints.down("mobL")} {
    .MuiDialog-container {
      width: 100vw;
      height: 100vh;
      margin: 0;
      background-color: rgba(0, 0, 0, 0.8);

      .MuiIconButton-root.close {
        right: 3rem;

        svg {
          width: 4.2rem;
          height: 4.2rem;

          path {
            fill: #fff;
            opacity: 1;
          }
        }
      }
    }

    .MuiDialog-paper {
      width: 100%;
      height: 100%;
      padding: 0 !important;
      margin: 0;
      background: none;
    }

    .MuiDialogContent-root {
      width: 100vw;
      height: 100vh;
    }
  }
`