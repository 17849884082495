import { Box, Button, styled } from "@mui/material";
import theme from "../../theme/theme";

export const RabbitTipContainer = styled(Box)`
  position: relative;
  //padding: 4rem 3.3rem 4rem 5.6rem;
  padding: 4rem 2.5rem 3rem;
  border-radius: 2rem;
  margin-bottom: 5rem;
  background-color: #677194;
  
  &.light{
    background-color: #fff;
    border: solid 2px #677194;
    
    p{
      color: #5d678d;
    }

    > svg:last-of-type{
      left: 10%;
    }
  }
  
  &.channel {
    background-color: #20263F;
    > svg:first-of-type {
      #white {
        fill: none;
      }
    }
  }
  
  &.error{
    background-color: #ff6384;
    
    > svg:first-of-type{
      width: 5rem;
      height: 5rem;
      border: 4px solid #fff;
      background-color: #ff6384;
      border-radius: 50%;
      //padding: 1rem;
      
      path{
        transform: scale(0.55);
        transform-origin: center;
        fill: #fff;
        stroke-width: .5px;
        stroke: #fff;
      }
    }
    
    > svg:last-of-type{
      path{
        fill: #ff6384;
      }
    }
    button > svg{
      path{
        fill: #fff;
      }
    }
  }

  ${theme.breakpoints.up("mobL")} {
    display: none;
  }

  > svg {
    position: absolute;

    &:first-of-type {
      top: 0;
      left: 0;
      transform: translate(-40%, -50%);
      width: 5rem;
    }
    &:last-of-type {
      height: 3rem;
      top: 100%;
      left: 5%;
      transform: translateY(-4px);
    }
  }

  > button:first-of-type {
    position: absolute;
    top: 0rem;
    right: 0.5rem;
    padding: 0;
    border: none;

    > svg {
      &.dots {
        padding: 2rem 1rem;
        width: 5rem;
      }

      &.cross {
        width: 6rem;
        padding: 1.3rem 1.5rem 0.7rem 2.5rem;
      }
    }
  }

  > p {
    color: #fff;
    font-size: 2rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: 0.22px;

    //overflow: hidden;
    //text-overflow: ellipsis;
    //display: -webkit-box;
    //-webkit-line-clamp: 4; /* number of lines to show */
    //line-clamp: 4;
    //-webkit-box-orient: vertical;

    span {
      font-weight: 500;
      color: #aeb7d5;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const TurnOffButton = styled(Button)`
  border-radius: 3.3rem;
  border: solid 2px #fff;
  margin: 2rem auto;
  padding: 1.6rem 5.5rem;
  display: block;

  font-size: 2.3rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.26px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
`;
