import {useAuth0} from "@auth0/auth0-react";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {setUserField, setUserId} from "../../redux/user/userSlice";
// import {getUser} from "../../api/dashboardRequests";
import {useNavigate} from "react-router-dom";
import gatewayClient, {findToken} from "../../api/gatewayClient";
import {getAvailableBalance, getUser} from "../../api/campaignsRequests";

export const useAuth = () => {
    const {isLoading, isAuthenticated, getAccessTokenSilently, user} = useAuth0();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Connected if already authorized in another project
    useEffect(() => {
        if (!isLoading) {
            const getToken = async () => {
                try {
                    const token = await getAccessTokenSilently();
                    // localStorage.setItem('token', token)
                    gatewayClient.defaults.headers.common['Authorization'] = `Bearer ${token}`
                } catch (err: any) {
                    console.log(err?.message);
                }
            };
            void getToken();
        }
    }, [isAuthenticated, isLoading]);

    const getUserWithLoader = async () => {
        if (user) {
            dispatch(setUserField({isLoadingUser: true}))
            dispatch(setUserId(user.sub as string));
            const userData = await getUser(user.sub as string)
            if (!userData?.payment_info?.has_nft) navigate('settings')
            await getAvailableBalance()
            dispatch(setUserField({isLoadingUser: false}))
        }
    }
    useEffect(() => {
        void getUserWithLoader()
    }, [user]);

    return {};
};
