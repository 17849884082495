import DepositPopup from "./budget/DepositPopup";
import WithdrawPopup from "./budget/WithdrawPopup";
import ConfirmSuspendingPopup from "./suspend/ConfirmSuspendingPopup";
import WithdrawRequestPopup from "./suspend/WithdrawRequestPopup";
import { GeneralSettingsPopup } from "./generalSettings/generalSettingsPopup";
import {NoMetaMaskPopup} from "./generalSettings/noMetaMAskPopup";
import {NoNFTPopup} from "./generalSettings/noNFTPopup";
import {ConnectYourWalletPopup} from "./generalSettings/connectYourWalletPopup";
import {ComparisonPopup} from "./comparison/ComparisonPopup";
import {SupportPopup} from "./support/SupportPopup";

export const popupList = {
  pause: (props: any) => <ConfirmSuspendingPopup {...props} />,
  withdraw: (props: any) => (props?.status === "pause" ? <WithdrawPopup {...props} /> : <WithdrawRequestPopup {...props} />),
  deposit: (props: any) => <DepositPopup {...props} />,
  generalSettings: (props: any) => <GeneralSettingsPopup {...props} />,
  noMetaMask: (props: any) => <NoMetaMaskPopup {...props} />,
  noNFT: (props: any) => <NoNFTPopup {...props} />,
  connectWallet: (props: any) => <ConnectYourWalletPopup {...props} />,
  comparison: (props: any) => <ComparisonPopup {...props} />,
  support: (props: any) => <SupportPopup {...props} />
};

export type TPopup = keyof typeof popupList;
