import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { overviewSelector } from "../../utils/selectors";
import { ContextCheckbox } from "../CheckboxGroups/ContextCheckbox";
import { TObjectBoolean } from "../../types/GeneralTypes";
import { ContextViewMode } from "../../pages/Campaigns/ContextViewMode";
import { formatCreatives } from "../../utils/dataFormat/formatCreatives";
import { usePathname } from "../../utils/hooks/usePathname";

type TCreativeCard = {
  id: string;
  channel: string;
  campaign: string;
  group_name: string;
  title: string;
  description: string;
  description2: string;
  image: string | undefined;
  image_size: {
    width: number;
    height: number;
  };
  logo: string | undefined;
  dUrl: string | undefined;
  type: string | undefined;
  numbers: {
    name: string;
    value: number;
  }[];
};

export const useAdCreatives = () => {
  const { ads_list } = useSelector(overviewSelector);
  //Creative expanded
  const [expanded, setExpanded] = useState("");
  //Visible creatives
  const [filteredCreatives, setFilteredCreatives] = useState<TCreativeCard[]>();
  //Groups checkboxes
  const [selectedGroups, setSelectedGroups] = useState<TObjectBoolean>({});

  const { isCampaignPage } = usePathname();
  const [active] = useContext(ContextViewMode);
  const [, , filteredByChannels] = useContext(ContextCheckbox);
  const curr_ad_list = isCampaignPage ? filteredByChannels : ads_list;

  const handleSetExpanded = (id: string) => {
    setExpanded(id);
    const anchor = document.getElementById("creative-expanded");
    if (!anchor) return;
    anchor.scrollIntoView({ behavior: "smooth" });
  };

  const creatives: TCreativeCard[] = formatCreatives(curr_ad_list);

  useEffect(() => {
    const selected = Object.entries(selectedGroups)
      // .filter(([_, value]) => value)
      .map(([key, _]) => key);
    const tmp: TCreativeCard[] =
      active.campaign === 1
        ? creatives.filter((el) => el.id !== expanded && selected.includes(el.group_name)).slice(0, 5)
        : creatives.filter((el) => el.id !== expanded).slice(0, 5);
    setFilteredCreatives(tmp);
  }, [ads_list, selectedGroups, filteredByChannels, isCampaignPage, expanded]);

  return {
    creatives,
    filteredCreatives,
    expanded,
    setExpanded,
    handleSetExpanded,
    active,
    selectedGroups,
    setSelectedGroups,
  };
};
