import { AccordionDetails, AccordionSummary, Drawer, IconButton, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import {
  SidebarAccordion,
  SidebarBody,
  SidebarContainer,
  SidebarDivider,
  SideBarHead,
  SidebarMainMenuItem,
  SidebarPageList,
} from "./Sidebar.styles";
import { Logo } from "../../assets/svg/logo";
import { DashboardEQIcon, SidebarFoldIcon, SidebarUnfoldIcon } from "./Sidebar.icons";
import { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";
import { FlexBox } from "../../styled/Layout";
import SidebarMenuItem from "./components/SidebarMenuItem";
import {useSelector} from "react-redux";
import {userSelector} from "../../utils/selectors";
import {usePathname} from "../../utils/hooks/usePathname";
import SidebarRewards from "./components/rewards/SidebarRewards";

type TSidebar = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const Sidebar = ({ open, setOpen }: TSidebar) => {
  const {hasCampaigns, userData} = useSelector(userSelector);
  const {has_nft} = userData;
  const {isPPCOverviewPage, isCampaignsTable, isDataminingOverview, isDataminingPixel} = usePathname()

  return (
    <SidebarContainer open anchor={"left"} variant={"persistent"} PaperProps={{ sx: { width: open ? "35rem" : "10rem" } }}>
      <SideBarHead>
        {open && (
          <IconButton>
            <Link to={'/'} className={hasCampaigns ? 'link-disabled' : ''}>
            <Logo />
            </Link>
          </IconButton>
        )}

        <IconButton onClick={() => setOpen(!open)}>{open ? <SidebarFoldIcon /> : <SidebarUnfoldIcon />}</IconButton>
      </SideBarHead>
      <SidebarBody>
        <Link to={"/dashboard"} className={!has_nft ? 'link-disabled' : ''}>
          <FlexBox columnGap={".6rem"}>
            <DashboardEQIcon />
            {open && <SidebarMainMenuItem>Dashboard</SidebarMainMenuItem>}
          </FlexBox>
        </Link>
        <SidebarDivider className={!open ? "folded" : ""} />
        <SidebarMenuItem folded={!open} title={'PPC Ads'} titleShort={'PPC'} link={'/ppc/overview'} disabled={!has_nft || !hasCampaigns}>
          <SidebarPageList>
            <ListItem>
              <Link to={"/ppc/overview"} className={isPPCOverviewPage ? 'active' : ''}>
                {open && <ListItemIcon></ListItemIcon>}
                <ListItemText>Overview</ListItemText>
              </Link>
            </ListItem>
            <ListItem>
              <Link to={"/ppc/mycampaigns"} className={isCampaignsTable ? 'active' : ''}>
                {open && <ListItemIcon></ListItemIcon>}
                <ListItemText>My campaigns</ListItemText>
              </Link>
            </ListItem>
          </SidebarPageList>
        </SidebarMenuItem>
        <SidebarDivider className={!open ? "folded" : ""} />

        <SidebarMenuItem folded={!open} title={'Data mining'} titleShort={'DM'} link={'/data_mining/overview'} disabled={!has_nft}>
          <SidebarPageList>
            <ListItem>
              <Link to={"/data_mining/overview"} className={isDataminingOverview ? 'active' : ''}>
                {open && <ListItemIcon></ListItemIcon>}
                <ListItemText>Overview</ListItemText>
              </Link>
            </ListItem>
            <ListItem>
              <Link to={"/data_mining/pixel"} className={isDataminingPixel ? 'active' : ''}>
                {open && <ListItemIcon></ListItemIcon>}
                <ListItemText>Pixel</ListItemText>
              </Link>
            </ListItem>
          </SidebarPageList>
        </SidebarMenuItem>
        <SidebarDivider className={!open ? "folded" : ""} />
        <SidebarAccordion disabled>
          <AccordionSummary>
            <FlexBox>
              <SidebarMainMenuItem>{open ? "Influencer Marketing" : "IM"}</SidebarMainMenuItem>
              {open && <IconButton className={"plus"}>+</IconButton> }

            </FlexBox>
          </AccordionSummary>
        </SidebarAccordion>
        <SidebarDivider className={!open ? "folded" : ""} />
        <SidebarAccordion disabled>
          <AccordionSummary>
            <FlexBox>
              <SidebarMainMenuItem>PR {open && "Campaigns"}</SidebarMainMenuItem>
              {open && <IconButton className={"plus"}>+</IconButton> }
            </FlexBox>
          </AccordionSummary>
        </SidebarAccordion>

        {open && <SidebarRewards/>}
      </SidebarBody>
    </SidebarContainer>
  );
};

export default Sidebar;
