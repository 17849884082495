import { usePathname } from "../../../utils/hooks/usePathname";
import { useDispatch, useSelector } from "react-redux";
import { filtersSelector } from "../../../utils/selectors";
import { useEffect, useState } from "react";
import { setStatus } from "../../../redux/filters/filtersSlice";

const defaultState = {
  active: true,
  ended: true,
  suspended: true,
  deleted: true,
};
export const useViewModeDashboard = (open: boolean) => {
  const { status } = useSelector(filtersSelector);
  const [localStatus, setLocalStatus] = useState(status || defaultState);
  const dispatch = useDispatch();

  const saveDashboard = () => {
    dispatch(setStatus(localStatus));
  };

  useEffect(() => {
    setLocalStatus(status);
  }, [open, status]);

  return { localStatus, setLocalStatus, saveDashboard };
};
