import {
  ClosePopupButton,
  PopupButtons,
  TransparentPopupContainer,
} from "./TransparentPopup.styles";
import { Button, DialogContent } from "@mui/material";
import { CrossIcon } from "../../assets/svg/icons";

type TTransparentPopupProps = {
  open: boolean;
  closePopup: () => void;
  onApply?: () => void;
  children?: any;
};

const TransparentPopup = ({
  open,
  closePopup,
  onApply,
  children,
}: TTransparentPopupProps) => {
  return (
    <TransparentPopupContainer open={open}>
      <DialogContent>
        <ClosePopupButton onClick={closePopup}>
          <CrossIcon />
        </ClosePopupButton>
        {children}
          <PopupButtons>
              {!!onApply && <Button onClick={onApply}>Apply</Button>}
            <Button onClick={closePopup}>{onApply ? 'Cancel' : 'Close'}</Button>
          </PopupButtons>
      </DialogContent>
    </TransparentPopupContainer>
  );
};

export default TransparentPopup;
