import { Stack, Typography } from "@mui/material";
import {DashedDivider, MobileStatisticsMainItem, MobileStatisticsNumberBox} from "./MobileStatisticsTable.styles";
import {
  CreativeNumber,
  CreativeNumberText,
  NumbersBox,
} from "../CreativeCard/CreativeCard.styles";
import { getStringNumber } from "../../utils/calc";

const MobileStatisticsTable = ({ data }: any) => {
  return (
    <Stack mt={'3.8rem'}>
      {data.map((el: any) => (
        <Stack key={el.name} >
          <MobileStatisticsMainItem>
            <Typography>{el.name}</Typography>
          </MobileStatisticsMainItem>
          <MobileStatisticsNumberBox>
            <Stack>
              <CreativeNumber>{el.total_amount_spent}</CreativeNumber>
              <CreativeNumberText>Spent</CreativeNumberText>
            </Stack>
            <Stack>
              <CreativeNumber>{el.total_clicks}</CreativeNumber>
              <CreativeNumberText>Clicks</CreativeNumberText>
            </Stack>
            <Stack>
              <CreativeNumber>{getStringNumber(el.total_impressions)}</CreativeNumber>
              <CreativeNumberText>Views</CreativeNumberText>
            </Stack>
          </MobileStatisticsNumberBox>
          <DashedDivider/>
        </Stack>
      ))}
    </Stack>
  );
};

export default MobileStatisticsTable;
