import Coinzilla from "../img/channels/coinzilla.png";

export const channelIcons = {
  bitmedia: <BitMediaSvg />,
  coinzilla: <img src={Coinzilla} alt="coinzilla icon" />,
};

export function BitMediaSvg<T>(props: T) {
  return (
    <svg viewBox="0 0 12 19" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M233.454 254.239c.038.002.062.005.086.005h3.874a.74.74 0 00.144-.014c.758-.153 1.073-1.059.556-1.639-.558-.625-1.138-1.23-1.708-1.844l-.648-.7.615-.663c.587-.632 1.18-1.258 1.759-1.897.275-.304.351-.672.18-1.052-.173-.387-.49-.602-.917-.604-1.27-.006-2.542-.002-3.813-.002-.042 0-.085.004-.128.006v8.404zm-3.268-11.455H237.373c2 0 3.693 1.456 3.994 3.444.201 1.336-.19 2.484-1.111 3.457l-.342.36c.117.126.228.249.343.367a4.07 4.07 0 01-.141 5.823 3.901 3.901 0 01-2.715 1.074c-1.368.007-2.736.003-4.104 0a.32.32 0 00-.252.107c-.913.931-1.828 1.86-2.742 2.79-.034.034-.06.076-.089.115l-.028-.019v-17.518z"
        transform="translate(-1566 -2855) translate(465.5 1721.946) translate(45.744 731.365) translate(824.846 .028) translate(0 159.816)"
        fill="#8DACD4"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </svg>
  );
}
