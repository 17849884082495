import pixelClient from "./pixelClient";
import {isSampleData, userId} from "../utils/constants";

export const getPixelData = async (user_id: string, command: string) => {
  const params = {
    user_id: isSampleData ? userId() : user_id,
    command
  }
  const res = await pixelClient.get("", { params });
  return res.data;
};
