// Styles
import { Typography } from "@mui/material";
import { DataCardContainer } from "./DataCards.styles";

// Types and enums
import { TKeyData } from "../../types/TableTypes";

type PropTypes = {
  data: TKeyData;
};

const DataCard = ({ data }: PropTypes) => {
  return (
    <DataCardContainer>
      {data.icon}
      <Typography variant="numbersDark">{data.value}</Typography>
      <Typography>{data.title}</Typography>
    </DataCardContainer>
  );
};

export default DataCard;
