import {Avatar, Grid, Stack, Typography} from "@mui/material";
import { creativeTemplates, TCretiveTemplateKey } from "../../../../components/CreativeCard/CreativeTemplates";
import BlockContent from "../../../../layout/blockContent/BlockContent";
import {BlockContainer, FlexBox} from "../../../../styled/Layout";
import { useLoading } from "../../../../utils/hooks/useLoading";
import {
    OverviewAdContainer,
    OverviewAdsGrid,
    OverviewEditButton,
    OverviewReasonText,
    OverviewReasonTitle
} from "../../Overview.styles";
import { useWaitingAds } from "./useWaitingAds";
import SectionSlide from "../../../../layout/sectionSlide/SectionSlide";
import {DeleteIcon, EqualizerIcon} from "../../../../assets/svg/icons";
import {appLink} from "../../../../utils/constants";

const WaitingConfirmation = () => {
  const { waitingCreatives, campaignData } = useWaitingAds();
  const isLoading = useLoading("ad_status_list");

  if (!isLoading && !waitingCreatives.length) return null;

  return (
      <BlockContainer sx={{ height: "fit-content" }}>
        <Typography variant="h3" mb="4.7rem">
          Waiting for confirmation ads
        </Typography>

        <BlockContent loading={isLoading}>
          <>{/*<OverviewAdsGrid container>*/}
          {/*  {waitingCreatives?.map((ad) => (*/}
          {/*    <Grid item xs={12} md={6} key={ad.id}>*/}
          {/*      {creativeTemplates[ad.type as TCretiveTemplateKey]({ data: ad })}*/}
          {/*    </Grid>*/}
          {/*  ))}*/}
          {/*</OverviewAdsGrid>*/}</>
            <OverviewAdsGrid container spacing={"3rem"}>
                {waitingCreatives?.map((ad) => (
                    <Grid item xs={12} md={6} key={ad.id}>
                        <OverviewAdContainer>
                            {ad.campaign_id && campaignData(ad.campaign_id) && (
                                <FlexBox>
                                    <Avatar
                                        src={campaignData(ad.campaign_id)?.campaign_logo}>{campaignData(ad.campaign_id)?.campaign_name}</Avatar>
                                    <Stack ml={'1rem'}>
                                        <Typography> {campaignData(ad.campaign_id)?.campaign_name}</Typography>
                                    </Stack>
                                </FlexBox>
                            )}
                            {creativeTemplates[ad.type as TCretiveTemplateKey]({data: ad})}
                        </OverviewAdContainer>
                    </Grid>
                ))}
            </OverviewAdsGrid>
        </BlockContent>
      </BlockContainer>
  );
};

export default WaitingConfirmation;
