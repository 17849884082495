import {FlexBox} from "../Layout";
import {CheckboxWithLabel} from "./checkbox";
import {Box, Link, Stack, styled, Typography} from "@mui/material";
import React from "react";
import {useBreakpoints} from "../../utils/hooks/useBreakpoints";

const BannerBox = styled(Box)`
  padding: 0.5rem;
  position: relative;
  margin-left: 0.8rem;
`

const BannerLine = styled(Box)`
  position: absolute;
  height: 1.2rem;
  width: 1.4rem;
`

const LeftTopLine = styled(BannerLine)`
  border-left: 1px solid ${(props: { color: string }) => props.color};
  border-top: 1px solid ${(props: { color: string }) => props.color};
  border-top-left-radius: 0.8rem;
  top: 0;
  left: 0;
`

const RightTopLine = styled(BannerLine)`
  border-right: 1px solid ${(props: { color: string }) => props.color};
  border-top: 1px solid ${(props: { color: string }) => props.color};
  border-top-right-radius: 0.8rem;
  top: 0;
  right: 0;
`
const LeftBottomLine = styled(BannerLine)`
  border-left: 1px solid ${(props: { color: string }) => props.color};
  border-bottom: 1px solid ${(props: { color: string }) => props.color};
  border-bottom-left-radius: 0.8rem;
  bottom: 0;
  left: 0;
`
const RightBottomLine = styled(BannerLine)`
  border-right: 1px solid ${(props: { color: string }) => props.color};
  border-bottom: 1px solid ${(props: { color: string }) => props.color};
  border-bottom-right-radius: 0.8rem;
  bottom: 0;
  right: 0;
`

const BannerBody = styled(Box)`
  border-radius: 0.5rem;
  background: ${(props: { color: string }) => props.color};
  padding: 0.5rem 1.1rem;

  p {
    color: #FFFFFF;
    font-size: 1rem;
    letter-spacing: 0.31px;
  }
`

type TBrand = {
    name: string,
    image: string,
    link?: string,
    id?: string,
    color: string,
}
type TCheckboxBanner = {
    brand: TBrand,
    bannerName: string,
    checked: boolean,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClick?: () => void
}

const LabelBanner = (brand: TBrand, bannerName: string, onClick?: () => void) => {
    const {mobileLarge} = useBreakpoints()
    return (
        <Stack>
            <FlexBox>
                <Stack alignItems={'flex-start'}>
                    <Typography textTransform={'capitalize'}>{brand.name}</Typography>
                    <Typography fontSize={'1rem'} alignSelf={'flex-start !important'} textTransform={'capitalize'}>
                        {brand.link}
                        {/*{'Click to full info'}*/}
                    </Typography>

                </Stack>
                <BannerBox>
                    <LeftTopLine color={brand.color}/>
                    <RightTopLine color={brand.color}/>
                    <RightBottomLine color={brand.color}/>
                    <LeftBottomLine color={brand.color}/>
                    <BannerBody color={brand.color}><Typography>{bannerName}</Typography></BannerBody>
                </BannerBox>
            </FlexBox>
            {mobileLarge && <Link fontSize={'1rem'}
                   mt={'1rem'}
                   alignSelf={'flex-start !important'}
                   color={'#636e95'}
                   sx={{pointerEvents: 'auto'}}
                   onClick={onClick}
            >
                {'Click to full info'}
            </Link>}
        </Stack>
    )
}
export const CheckboxBanner = ({brand, bannerName, checked, onChange, onClick}: TCheckboxBanner) => {
    return (
        <CheckboxWithLabel label={LabelBanner(brand, bannerName, onClick)}
                           name={brand.id ?? brand.name}
                           checked={checked}
                           onChange={onChange}
                           className={'comparison'}
        />
    )
}