import axios from "axios";
import {DEV_ONLY, storageToken} from "../utils/constants";

const baseURL = DEV_ONLY
    ? "https://gateway-develop-dot-winky-apis.ew.r.appspot.com/"
    : "https://gateway-dot-winky-apis.ew.r.appspot.com/";

export const findToken = () => {
    if (typeof window !== 'undefined') {
        return localStorage.getItem('token')
        // const user_data = localStorage.getItem(storageToken);
        // if (user_data) {
        //     return JSON.parse(user_data)?.body?.access_token;
        // }
    }
};

const gatewayClient = axios.create({
    baseURL,
})

gatewayClient.defaults.timeout = 60000

export default gatewayClient