import { Dialog, IconButton, Stack, styled } from "@mui/material";
import {FlexBox} from "../../styled/Layout";

export const TransparentPopupContainer = styled(Dialog)`
  .MuiDialog-paper {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    margin: 0;
    background-color: rgba(52,60,87,0.9);
  }

  .MuiDialogContent-root {
    display: flex;
    flex-direction: column;
    padding: 3rem 10%;
  }
`;

export const ClosePopupButton = styled(IconButton)`
  position: absolute;
  right: 3rem;
  top: 3rem;

  svg {
    path {
      fill: #fff;
    }
  }
`;

export const PopupButtons = styled(Stack)`
  margin-top: auto;
  row-gap: 3rem;
  
  button{
    border-radius: 3.3rem;
    border: solid 2px #fff;
    padding: 2rem;

    font-size: 2.34rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.26px;
    text-align: center;
    color: #fff;
    
    
    &:last-of-type{
      border: none;
      text-transform: none;
    }
  }
`;

export const PopupTitle = styled(FlexBox)`
  position: absolute;
  top: 4rem;
svg{
  height: 2.2rem;
  opacity: .59;
  margin-right: .7rem;
}
  p{
    font-size: 2.6rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: 0.29px;
    color: #fff;
    opacity: 0.59;
  }
`