//Core
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
//Types
import { TObjectBoolean } from "../../../../types/GeneralTypes";
import { TPublisher } from "../../../../types/reduxData/dataTypes";
//Utils
import { campaignsSelector } from "../../../../utils/selectors";
import { calcCampaignProgress } from "../../../../utils/calc";
//Context
import { ContextViewMode } from "../../ContextViewMode";
import {useBreakpoints} from "../../../../utils/hooks/useBreakpoints";


export const useCampaignPage = () => {
  const params = useParams();
  //Checkboxes of channels on campaign page
  const [channelsSelected, setChannelsSelected] = useState<TObjectBoolean>({ bitmedia: true, coinzilla: true });
  //Radio buttons of channels on campaign page
  const [channelRadio, setChannelRadio] = useState<TPublisher>("bitmedia");
  //Checkboxes of groups for all the campaign
  const [mainSelected, setMainSelected] = useState<TObjectBoolean>({});
  const { list, data } = useSelector(campaignsSelector);
  const currentCampaign = data[params?.id as string];
  const [active] = useContext(ContextViewMode);

  const campaignInList = list?.find((el) => el.campaign_id === params?.id);

  const progress = calcCampaignProgress(campaignInList?.campaign_start_date as string, campaignInList?.campaign_end_date as string);
  const progressData = {
    progress,
    spent: campaignInList?.spent || 0,
    remain: campaignInList?.balance || 0
  }

  const filteredData = currentCampaign ?? []
    // currentCampaign?.filter((ad) => ((active.campaign === 1 && !mobileLarge) ? ad.channel === channelRadio : channelsSelected[ad.channel])) || [];

  const campaignCardsData = filteredData?.reduce(
    (acc, next, index, arr) => {
      return {
        spent: acc.spent + next.total_amount_spent,
        views: acc.views + next.total_impressions,
        clicks: acc.clicks + next.total_clicks,
        ads: acc.ads + 1,
        budget: acc.budget + next.budget,
        avgCpc: Math.round((acc.avgCpc + next.cpc / arr.length) * 100) / 100,
      };
    },
    { spent: 0, views: 0, clicks: 0, ads: 0, budget: 0, avgCpc: 0 }
  );

  const handleSelected = (channel: string) => {
    const newData = {
      ...channelsSelected,
      [channel]: !channelsSelected[channel],
    };

    if (!Object.values(newData).find((el) => el)) return;

    setChannelsSelected(newData);
  };

  useEffect(() => {
    if (!list.length) return;

    const newData: any = {};
    const currCamp = list.find((camp) => camp.campaign_id === params?.id);
    currCamp?.channels?.forEach((ch: string) => (newData[ch] = true));

    setChannelsSelected(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);


  return {
    channelsSelected,
    channelRadio,
    setChannelRadio,
    filteredData,
    setChannelsSelected,
    handleSelected,
    mainSelected,
    setMainSelected,
    campaignCardsData,
    progressData,
    active,
    campaignInList
  };
};
