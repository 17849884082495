import { Box, styled } from "@mui/system";

export const DataCardContainer = styled(Box)`
  border-radius: 1rem;
  box-shadow: 0 2px 4px 0 rgba(28, 41, 90, 0.04);
  border: solid 1px #eaedf7;
  background-color: #fff;
  min-width: 13.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem;

  svg {
    width: 3.2rem;
    height: 3.2rem;
    margin-bottom: .5rem;
  }

  span {
    font-family: "F37 Judge", sans-serif;
    font-size: 2.88rem;
    letter-spacing: 0.9px;
    line-height: normal;
  }

  p {
    font-family: "F37 Judge", sans-serif;
    font-size: 1.62rem;
    font-weight: 500;
    letter-spacing: 0.51px;
    line-height: normal;
    color: #7a86b4;
  }
`;
