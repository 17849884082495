import { styled } from "@mui/material";
import { CampaignItemBrandData } from "../../../../components/CampaignsTable/CampaignsTable.styles";
import { mobileScreen } from "../../../../utils/breakpoints";

export const RunningCampaignItem = styled(CampaignItemBrandData)`
  border-radius: 0.9rem;
  background-color: #fbf9f9;
  //border: solid 1px rgba(151, 151, 151, 0.26);
  justify-content: flex-start;
  padding: 2.1rem;
  margin-left: 0;

  ${mobileScreen} {
    background-image: linear-gradient(66deg, #f3fcff 9%, #e3f8ff 83%);
    border-radius: 2rem;
    margin-left: -5%;
    width: 110%;

    > div:nth-of-type(2) {
      width: fit-content;
      p:first-of-type {
        white-space: pre-wrap;
      }
    }

    a {
      margin-left: auto;
    }
  }

  > a > button {
    opacity: 0.41;
    font-size: 1.4rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-transform: none;
    color: #000;
    border: none;
    ${mobileScreen} {
      padding: 0;
      min-width: fit-content;
      opacity: 1;
    }

    &:hover {
      border: none;
    }

    svg {
      height: 1.1rem;

      path {
        fill: #000;
      }
      ${mobileScreen} {
        height: 2rem;

        path {
          fill: #b2bbda;
        }
      }
    }
  }
`;
