import web3Client from "./web3mClient";
import {T404RequestBody, TSupportRequestBody, TSupportResponse, TUploadLogoBody} from "../types/SupportTypes";
import {store} from "../redux/store";
import {isSampleData, userId} from "../utils/constants";

export const SendSupportForm = async (data: TSupportRequestBody) => {
    const {id} = store.getState().user
    const body: TSupportRequestBody = {
        ...data,
        user_id: isSampleData ? userId() : id
    }

    console.log(body)
    const response = await web3Client.post('/send_support_form', body)
    return response.data as TSupportResponse
}

export const Send404Form = async (data: TSupportRequestBody) => {
    const {id} = store.getState().user
    const body: T404RequestBody = {
        ...data,
        user_id: isSampleData ? userId() : id,
        origin_of_bug: 'dashboard.web3m.io'
    }
    const response = await web3Client.post('/send_404_form', body)
    return response?.data as TSupportResponse
}

export const CheckNFT = async (wallet: string) => {
    try {
        const response = await web3Client.post('validate_nft', {
            wallet_address: wallet,
        })
        // console.log(response?.data)
        return response?.data
    } catch (err) {
        console.log(err)
    }
}

export const UploadLogo = async (data: TUploadLogoBody) => {
    try {
        const response = await web3Client.post('upload_logo_base64', data)
        return response?.data
    } catch (error) {
        console.log(error)
    }
}

