import {useDispatch, useSelector} from "react-redux";
import {formatCreatives} from "../../../../utils/dataFormat/formatCreatives";
import {campaignsSelector, overviewSelector} from "../../../../utils/selectors";
import {updateAdStatus} from "../../../../api/campaignsRequests";
import {TAdsStatus} from "../../../../types/reduxData/overviewTypes";
import {setOverviewData} from "../../../../redux/overview/overviewSlice";
import {useState} from "react";

export const useRejectedAds = () => {
    const {ads_by_status} = useSelector(overviewSelector);
    const {list} = useSelector(campaignsSelector);
    const dispatch = useDispatch();
    const [deleteLoader, setDeleteLoader] = useState<boolean>(false);

    const tmp = ads_by_status?.rejected.map((ad: TAdsStatus) => ({ad_id: ad.ad_id, campaign_id: ad.campaign_id, ...ad.data}));

    const rejectedCreatives = formatCreatives(tmp).map((el, i) => ({
        ...el,
        campaign_id: ads_by_status?.rejected[i].campaign_id ?? '',
        group_id: ads_by_status?.rejected[i].data.group_id ?? '',
    }));

    const campaignData = (id: string) => {
        // console.log(list.find(el => el.campaign_id === id))
        return list.find(el => el.campaign_id === id)
    }

    const deleteAd = async (ad_id: string, campaign_id: string, group_id: string) => {
        setDeleteLoader(true);
        const response = await updateAdStatus({ad_id, campaign_id, group_id, status: 'archive'})
      if (response?.status && ads_by_status) {
          const newRejectedList = [...ads_by_status.rejected].filter((ad) => ad.ad_id !== ad_id)
          dispatch(setOverviewData({ads_by_status: {...ads_by_status, rejected: newRejectedList}}))
      }
      setDeleteLoader(false);
    }

    return {
        rejectedCreatives: rejectedCreatives.filter((ad) => ad.type),
        campaignData,
        deleteAd,
        deleteLoader
    };
};
