import { Box, styled } from "@mui/material";
import { NumbersBox } from "../CreativeCard/CreativeCard.styles";
import theme from "../../theme/theme";

export const MobileStatisticsMainItem = styled(Box)`
  border-radius: 1.43rem;
  background-color: #86a7c8;
  padding: 1.6rem 1rem;
  margin-bottom: 1rem;

  p {
    font-size: 1.82rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.57px;
    color: #fff;
    text-align: center;
  }
`;

export const DashedDivider = styled(Box)`
  height: 1px;
  width: 100%;
  background: repeating-linear-gradient(
    to right,
    transparent,
    transparent 3px,
    rgba(151, 151, 151, 0.65) 5px,
    rgba(151, 151, 151, 0.65) 10px
  );

  margin-bottom: 5.4rem;
`;

export const MobileStatisticsNumberBox = styled(NumbersBox)`
  ${theme.breakpoints.down("mobL")} {
    padding: 1rem 0;
    justify-content: space-between;
  }
`;
