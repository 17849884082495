//Styles
import {DialogContent, IconButton} from "@mui/material";
import {
    HowToFix, InfoBlock, LinkToSignUp,
    MainTitle, NoNFTContent, NoNFTPopupContainer,
    SecondTitle,
    SmallText,
    SvgContainer
} from "./noNFTPopup.styles";
//Images
import {ClosePopupIcon} from "../../../assets/svg/icons";
import {NoNFTSvg} from "../../../assets/svg/GeneralSettingsPopupSvg";
//Utils
import {LinkSignUp} from "../../../utils/constants";
import BigArrowImage from "../../../assets/img/GeneralSettingsBigArrow.webp";
import {ArrowContainer} from "./connectYourWalletPopup.styles";
import {TransparentButton} from "../../../styled/buttons";

export const NoNFTDecision = () => {
    return <>
        <HowToFix>{'How to fix:'}</HowToFix>
        <SmallText><span>{'1'}</span>{'  Go to MetaMask extension'}</SmallText>
        <SmallText><span>{'2'}</span>{' Switch to your other wallet, containing Webm NFT'}</SmallText>
        <LinkToSignUp href={LinkSignUp}>{`I don't own Webm NFT`}</LinkToSignUp>
    </>
}
export const NoNFTPopup = ({closePopup}: { closePopup: () => void }) => {
    return (
        <NoNFTPopupContainer open onClose={closePopup}>
            <ArrowContainer className={'desktopOnly'}>
                <img src={BigArrowImage} alt={'arrow'}/>
            </ArrowContainer>
            <DialogContent sx={{p: "0", width: '65rem', overflow: 'hidden'}}>
                <IconButton className="close" onClick={closePopup}>
                    <ClosePopupIcon/>
                </IconButton>
                <NoNFTContent>
                    <SvgContainer>
                        <NoNFTSvg/>
                    </SvgContainer>
                    <InfoBlock>
                        <SecondTitle>{'OOOOOOOOOPS!!!'}</SecondTitle>
                        <MainTitle>{'THERE IS NO WEBM NFT DETECTED'}</MainTitle>
                        <SmallText>{'Follow the instructions below to fix it.'}</SmallText>
                        {/*<NoNFTDecision/>*/}
                    </InfoBlock>
                    <TransparentButton className={'mobileOnly'} onClick={closePopup}>Ok</TransparentButton>

                </NoNFTContent>
            </DialogContent>
        </NoNFTPopupContainer>
    )
}