import { ThemeProvider } from "@mui/material";
import theme from "./theme/theme";
import Header from "./layout/header/Header";
import Main from "./layout/main/Main";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import ScrollToTop from "./utils/ScrollToTop";
import { Auth0Provider } from "@auth0/auth0-react";
import {useState} from "react";
import { Context404 } from "./Context404";
import Sidebar from "./layout/sidebar/Sidebar";
import "swiper/css";


function App() {
  const [is404, setIs404] = useState(false);

  const redirectUri = () => {
    if (typeof window !== 'undefined') {
      return window?.location.origin
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Provider store={store}>
          <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
            audience="https://dev-s5y764on.us.auth0.com/api/v2/"
            scope="read:current_user update:current_user_metadata"
            useRefreshTokens
            redirectUri={redirectUri()}
              // onRedirectCallback={onRedirectCallback}
            // cacheLocation="localstorage"
          >
            <ScrollToTop>
              {/*<>{!is404 && <Header />}</>*/}
              <Context404.Provider value={[is404, setIs404]}><Main/></Context404.Provider>
              <CssBaseline />
            </ScrollToTop>
          </Auth0Provider>
        </Provider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
