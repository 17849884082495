import { Box, Stack, styled, Typography } from "@mui/material";
import { FlexBox, ImgBox } from "../../styled/Layout";
import theme from "../../theme/theme";
import CreativeBg from "../../assets/img/interface/CreativeBox.webp";

export const CreativesContainer = styled(FlexBox)`
  width: 100%;
  height: max-content;
  margin-bottom: 5.1rem;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 3rem;
  
  ${theme.breakpoints.down('lg')}{
    flex-wrap: wrap;
    row-gap: 3rem;
    justify-content: center;
  }
  
  ${theme.breakpoints.down('mobL')}{
    flex-direction: column;
    margin: 0 -2rem;
    width: calc(100% + 4rem);
  }
`;

export const CreativeCardContainer = styled(Stack)`
  width: 18.5%;
  height: min-content;
  ${theme.breakpoints.down("lg")} {
    width: 27%;
  }
  ${theme.breakpoints.down("md")} {
    width: 35%;
  }
  ${theme.breakpoints.down("br768")} {
    width: 100%;
    max-width: 45rem;
    margin: 0 auto 8rem;
  }
`;

export const BackgroundCreative = styled(Box)`
  /* z-index: -1; */
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url(${CreativeBg});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  
  ${theme.breakpoints.down('mobL')}{
    background-image: none;
  }
`;
export const CreativeTop = styled(Stack)`
  width: 100%;
  position: relative;
  
  ${theme.breakpoints.down('mobL')}{}
`;

export const ChannelBox = styled(FlexBox)`
  z-index: 1;
  padding: 1.6rem;

  .groupName {
    font-size: 1.6rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #4f5c87;
    text-transform: capitalize;
    padding: 1.1rem 1.6rem;

    border-radius: 6px;
    border: dashed 1px rgba(79, 92, 135, 0.24);
  }

  ${theme.breakpoints.down("br768")} {
    padding: 1.9rem 2rem 1.5rem;
  }

  ${theme.breakpoints.down("mobL")} {
    padding-bottom: 2.5rem;
    background-color: #b6b2cd;
    border-radius: 1.2rem 1.2rem 0 0;
    justify-content: center;
  }
`;

export const NumbersBox = styled(ChannelBox)`
  z-index: 1;

  margin: 0 0.7rem 1rem;
  justify-content: space-between;
  ${theme.breakpoints.down("br768")} {
    justify-content: space-around;
    margin: 0 0.7rem 2.2rem;
  }

  ${theme.breakpoints.down("mobL")} {
    background-color: #fff;
  }
`;

export const ChannelImgBox = styled(ImgBox)`
  max-width: 3.6rem;
  max-height: 3.6rem;
  margin-right: 1rem;
  ${theme.breakpoints.down("br768")} {
    max-width: 5rem;
    max-height: 5rem;
    margin-top: 1rem;
  }
`;

export const TitleChannel = styled(Typography)`
  font-size: 1.27rem;
  font-weight: 600;
  letter-spacing: 0.14px;
  color: #4f5c87;
  text-transform: capitalize;
  ${theme.breakpoints.down("br768")} {
    font-size: 1.8rem;
  }

  ${theme.breakpoints.down("mobL")} {
    color: #fff;
  }
`;

export const SubtitleChannel = styled(Typography)`
  font-size: 0.94rem;
  font-weight: 500;
  letter-spacing: 0.29px;
  color: #4f5c87;
  text-transform: capitalize;
  ${theme.breakpoints.down("br768")} {
    font-size: 1.4rem;
    line-height: 0.7;
  }

  ${theme.breakpoints.down("mobL")} {
    color: #fff;
  }
`;

export const CreativeNumber = styled(Typography)`
  font-family: "F37 Judge", sans-serif;
  font-size: 2.1rem;
  font-weight: bold;
  letter-spacing: 0.61px;
  line-height: normal;
  color: #242f57;
  text-align: center;
  ${theme.breakpoints.down("br768")} {
    font-size: 3rem;
    margin-top: 1rem;
  }

  ${theme.breakpoints.down("mobL")} {
    color: #677194;
    margin-bottom: .7rem;
  }
`;

export const CreativeNumberText = styled(Typography)`
  font-family: "F37 Judge", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.35px;
  text-align: center;
  color: #7a86b4;
  ${theme.breakpoints.down("br768")} {
    font-size: 1.8rem;
    line-height: 1;
  }

  ${theme.breakpoints.down("mobL")} {
    color: #677194;
    opacity: 0.53;

  }
`;

export const AdContainer = styled(Stack)`
  position: relative;
  border: solid 1px #f0f0f0;
  padding: 3.5rem;
  height: 100%;
  background-color: #fff;

  .imageBox {
    height: 15rem;
    overflow: hidden;
    ${theme.breakpoints.down("br768")} {
      height: unset;
    }
  }
  img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    align-self: center;
    object-fit: cover;
  }

  .icon {
    position: absolute;
    width: 3.1rem;
    height: 2.9rem;
    right: 0;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 1.7rem;
      transform: translateX(10%);
    }
  }
  p {
    span {
      color: #222;
    }
  }

  .infoBox {
    padding: 2rem 2.4rem 2.8rem;
    ${theme.breakpoints.down("br768")} {
      padding: 2rem 3.4rem 2.8rem;
    }
  }

  .title {
    font-size: 1.44rem;
    font-weight: bold;
    letter-spacing: 0.17px;
    color: #182fcd;
    margin-bottom: 1.5rem;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    ${theme.breakpoints.down("br768")} {
      font-size: 2.4rem;
      line-height: 1.2;
      overflow: unset;
      white-space: unset;
    }
  }

  .desc1 {
    font-size: 1.54rem;
    letter-spacing: 0.17px;
    color: #020202;
    margin-bottom: 2rem;
  }
  .desc2 {
    font-size: 1.54rem;
    letter-spacing: 0.17px;
    color: #020202;
    font-weight: bold;
    margin-bottom: 1.5rem;
  }
  .logo {
    width: 4rem;
    height: 4rem;
    border-radius: 0.7rem;
    margin-right: 0.9rem;
    align-self: flex-start;
    & img {
      //width: 100%;
      width: auto;
      height: 100%;
    }
    ${theme.breakpoints.down("br768")} {
      width: 6rem;
      height: 6rem;
    }
  }
  .respDesc {
    font-size: 1.16rem;
    letter-spacing: 0.13px;
    line-height: 1;
    color: #020202;
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    text-overflow: ellipsis;
    width: 100%;
    margin-right: 1rem;
    word-break: break-word;
    ${theme.breakpoints.down("br768")} {
      font-size: 1.8rem;
      margin-top: 0.5rem;
    }
  }
  .businessName {
    font-size: 1.07rem;
    font-weight: 500;
    letter-spacing: 0.13px;
    color: #9ba39c;
    margin-top: 0.5rem;
    ${theme.breakpoints.down("br768")} {
      font-size: 1.6rem;
      margin-top: 0.7rem;
    }
  }

  .url {
    align-items: center;
    margin-bottom: 1.5rem;

    svg {
      width: 1.1rem;
      height: 1.1rem;
      margin-right: 0.5rem;
    }

    p {
      font-size: 1.14rem;
      font-weight: 500;
      letter-spacing: 0.13px;
      color: #9ba39c;
    }
  }

  .channelIcon {
    position: absolute;
    bottom: 0.6rem;
    right: 0.8rem;
    width: fit-content;
    height: 1.8rem;
    svg {
      height: 100%;
    }

    img {
      width: auto;
    }
  }

  button {
    border-radius: 4px;
    background-color: #5b5b5b;
    width: 9.4rem;
    height: 3.4rem;
    align-self: flex-start;
    transition: 0.3s;
    margin-left: 0.7rem;
    &:disabled {
      background-color: #5b5b5b;
    }

    svg {
      height: 1.4rem;
      transform: scale(1.5);
      path {
        fill: #fff;
      }
    }

    &.resp {
      background-color: #182fcd;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      ${theme.breakpoints.down("br768")} {
        width: 4.5rem;
        height: 4.5rem;
      }
    }
  }
`;
