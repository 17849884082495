import {Button, Stack, styled} from "@mui/material";
import theme from "../../theme/theme";
import {FlexBox} from "../../styled/Layout";


export const Box404 = styled(Stack)`
  width: 100%;
  min-height: 40rem;
  height: 100vh;
  background-color: #FFFFFF;
  // height: calc(100vh - 64px - 2.5rem - 8.14rem);
  // ${theme.breakpoints.down('br1024')} {
  //   height: calc(100vh - 48px - 2.5rem - 24.3rem);
  // }
  // ${theme.breakpoints.down('sm')} {
  //   height: calc(100vh - 48px - 2.5rem - 21.2rem);
  // }
  justify-content: center;
  align-items: center;
  img {
    max-width: 53rem;
    max-height: 50vh;
    ${theme.breakpoints.down('br480')} {
      max-width: 40rem;
    }
  }
`

export const LinkBox = styled(FlexBox)`
  margin-top: 5rem;
  a {
    text-decoration: underline;
    margin-right: 4rem;
    font-size: 1.6rem;
    &:visited {
      color: black;
    }
  }
`

export const ButtonReport = styled(Button)`
  text-decoration: underline;
  text-transform: capitalize;
  font-size: 1.6rem;
  color: black;
  font-weight: 400;
  &:hover {
    text-decoration: underline;
    background-color: unset;
  }
`