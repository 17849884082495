import {Box, Button, Container, Stack, styled} from "@mui/material";
import theme from "../../theme/theme";
import {FlexBox} from "../../styled/Layout";

export const ViewModeContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  margin: 5.9rem auto;

  ${theme.breakpoints.down("mobL")} {
    flex-direction: column;
    background-color: #fff;
    margin: 0 auto;
    padding: 5.9rem 3rem;
    h2 {
      color: #5d678d;
      margin-top: 3rem;
    }
  }
`;

export const ViewModeContainerMobile = styled(Stack)`
  margin: 10rem 0;
  row-gap: 3.7rem;
  justify-content: center;
  height: 100%;

  > button {
    padding: 2.5rem;
    border-radius: 1.2rem;
    border: solid 2px #fff;

    font-size: 1.82rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-transform: none;
    letter-spacing: 0.57px;
    color: #fff;

    svg {
      height: 3rem;
      path {
        fill: #fff;
      }
    }

    &.active {
      background-color: #fff;
      color: #39405c;

      svg {
        path {
          fill: #39405c;
        }
      }
    }
  }
`;

export const ViewModeChannelsMobile = styled(FlexBox)`
  margin-top: 7rem;
  flex-direction: column;
`

export const ViewModeChannelItem = styled(FlexBox)`
  
  .MuiAvatar-root{
    width: 5rem;
    height: 5rem;
    border: solid 1px #dfe4f6;
    margin-right: 1rem;
  }
  
  p{
    font-size: 1.79rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-transform: capitalize;
    letter-spacing: 0.2px;
    color: #fff;
  }
  

`

export const ViewModeDivider = styled(Box)`
width: 100%;
  height: 1px;
  background: repeating-linear-gradient( to right, transparent, transparent 5px, rgba(255,255,255,.29) 5px, rgba(255,255,255,.29) 12px );  
  margin: 3rem 0;
`

