import TransparentPopup from "../popups/TransparentPopup";
import { Stack, Typography } from "@mui/material";
import {
  ChannelBox,
  ChannelImgBox,
  SubtitleChannel,
  TitleChannel,
} from "./CreativeCard.styles";
import { channelsLogo } from "../../assets/img/channels/channelsLogo";
import { FlexBox } from "../../styled/Layout";

type TCreativePopupMobileProps = {
  open: boolean;
  ad: JSX.Element;
  closePopup: () => void;
  data?: any;
};
const CreativePopupMobile = ({
  open,
  closePopup,
  ad,
  data,
}: TCreativePopupMobileProps) => {
  return (
    <TransparentPopup open={open} closePopup={closePopup}>
      <Stack mt={"7rem"}>
        <FlexBox mb={"5vh"}>
          <ChannelImgBox>
            <img src={channelsLogo[data.channel]} alt={"ch"} />
          </ChannelImgBox>
          <Stack>
            <TitleChannel>{data.channel}</TitleChannel>
            <SubtitleChannel>{data.group_name}</SubtitleChannel>
          </Stack>
        </FlexBox>
        {ad}
      </Stack>
    </TransparentPopup>
  );
};

export default CreativePopupMobile;
