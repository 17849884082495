import { Box, Stack, styled } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

export const DateRangeInputsMobile = styled(Stack)``;

export const DateRangeTableMob = styled(Box)`
  margin-top: 15rem;
  margin-bottom: 6rem;
  padding: 10%;
  background-color: #4b567b;
  border-radius: 1rem;

  > div > div > p,
  span {
    color: #fff;
  }

  > div > div > p {
    font-size: 1.6rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #fff;
  }

  > div > div > span {
    font-family: "F37 Judge", sans-serif;
    font-size: 1.8rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: 0.9px;
    text-align: right;
    color: #fff;
  }
`;

export const DatePickerMob = styled(DatePicker)`
  width: 100%;
  max-width: 100%;
  border-radius: 1.4rem;
  margin-left: 0 !important;
  background-color: #636e95;
  &:first-of-type {
    margin-bottom: 4rem;
  }

  .MuiInputBase-input {
    background-color: #636e95;
    border-radius: 1.4rem;
    color: #fff;
    padding-left: 0.7rem;

    font-size: 1.96rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.61px;

    &:focus {
      color: #fff;
    }
  }

  svg {
    &:first-of-type {
      height: 3.3rem;
    }
    &:last-of-type {
      height: 2rem;
      margin-right: 0.5rem;
    }
  }

  fieldset {
    border: solid 2px #fff !important;
  }
`;
