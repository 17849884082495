import {Box, Checkbox, FormControlLabel, Link, Stack, styled, Typography} from "@mui/material";
import React from "react";
import {IconCheckbox, CheckedIcon} from "./checkbox";
import {FlexBox, ImgBox} from "../Layout";
import {channelsLogo} from "../../assets/img/channels/channelsLogo";
import theme from "../../theme/theme";
import {useBreakpoints} from "../../utils/hooks/useBreakpoints";
import {usePathname} from "../../utils/hooks/usePathname";

const CheckboxIcon = styled(IconCheckbox)`
  background: ${theme.palette.secondary.main};
`;

const BorderCheckbox = styled(Box)`
  width: 2rem;
  height: 2rem;
  border: 2px solid ${theme.palette.secondary.main};
  z-index: 2;
  border-radius: 50%;
  display: flex;

  & > span {
    padding: 0;
    font-size: unset;
    align-self: center;
  }
`;

export const CheckboxCustom = ({...props}) => {
    return (
        <BorderCheckbox>
            <Checkbox {...props} icon={<CheckboxIcon/>} checkedIcon={<CheckedIcon/>} sx={{pointerEvents: 'auto'}}/>
        </BorderCheckbox>
    );
};

type TLabelWithImage = {
    image?: string;
    name: string;
    type: string;
    link?: string;
    color?: string;
    onClick?: () => void
};

const ImgBoxStyled = styled(ImgBox)`
  width: 4rem;
  height: 4rem;
`;
const ImgBoxLogo = styled(ImgBoxStyled)`
  border: 1px solid ${(props: { color: string | undefined }) => (props.color ? props.color : "#C1DCE8")};
  border-radius: 50%;
  overflow: hidden;

  img {
    transform: scale(1.05);
    object-fit: cover;
  }

  //padding: 0.7rem;
  //position: relative;
`;
const Ball = styled(Box)`
  position: absolute;
  top: -0.4rem;
  left: 2.6rem;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  background: ${(props: { color: string | undefined }) => (props.color ? props.color : "unset")};
  border: 1px solid #ffffff;
  z-index: 1;

  ${theme.breakpoints.down('mobL')} {
    display: none;
  }
`;
export const LabelWithImage = ({name, type, image, link, color, onClick}: TLabelWithImage) => {
    const {mobileLarge} = useBreakpoints();

    return (
        <FlexBox sx={{position: "relative", left: "-0.8rem", pointerEvents: 'none'}}>
            {type === "channel" ? (
                <ImgBoxStyled>
                    <img src={channelsLogo[name]} alt={name}/>
                </ImgBoxStyled>
            ) : (
                <ImgBoxLogo color={color}>
                    {color && <Ball color={color}/>}
                    <img src={image} alt={name}/>
                </ImgBoxLogo>
            )}
            <Stack className={"labelText"}>
                <Typography>{name}</Typography>
                {mobileLarge
                    ? <Link fontWeight={400}
                            fontSize={"1rem"}
                            onClick={(e) => {
                                e.preventDefault()
                                onClick && onClick()
                            }}
                    >
                        {'Click to full Info'}
                    </Link>
                    : <Typography fontWeight={400} fontSize={"1rem"}>
                        {link?.toLowerCase() || ''}
                    </Typography>
                }
            </Stack>
        </FlexBox>
    );
};


export const FormControlLabelStyled = styled(FormControlLabel)`
  display: flex;
  padding-left: ${(props) => props.labelPlacement === "end" && "1rem"};
  width: fit-content;
  align-items: ${(props) => props.labelPlacement === "top" && "flex-start"};
  pointer-events: none;

  .labelText {
    margin-left: 0.5rem;

    a {
      color: #636e95;
      pointer-events: auto;
      text-decoration: underline;
      text-transform: initial;

      &:visited {
        color: #636e95;
      }
    }
  }

  p:first-of-type {
    font-weight: 600;
    font-size: 1.3rem;
    letter-spacing: 0.13px;
    text-transform: capitalize;
  }

  p:nth-of-type(2) {
    text-transform: lowercase;
  }

  .MuiFormControlLabel-label {
    text-transform: capitalize;
    opacity: ${(props) => (props.checked ? 1 : 0.5)};

    & > div {
      left: ${(props) => props.labelPlacement === "top" && "-0.8rem !important"};
      padding-bottom: ${(props) => props.labelPlacement === "top" && "0.5rem !important"};
    }
  }
`;
type TCheckboxChannel = {
    channel: string;
    checked: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    labelPlacement?: "bottom" | "top" | "end" | "start" | undefined;
    onClick?: () => void
};

export const CheckboxChannel = ({
                                    channel,
                                    checked,
                                    onChange,
                                    disabled,
                                    onClick,
                                    labelPlacement = "end"
                                }: TCheckboxChannel) => {
    return (
        <FormControlLabelStyled
            control={<CheckboxCustom checked={checked} name={channel} onChange={onChange} disabled={disabled}/>}
            label={LabelWithImage({name: channel, type: "channel", onClick: onClick})}
            checked={checked}
            labelPlacement={labelPlacement}
            // onChange={() => {}}
        />
    );
};

type TCheckboxBrand = {
    brand: {
        name: string;
        link?: string;
        image: string;
        selected?: boolean;
        id?: string;
    };
    checked: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    labelPlacement?: "bottom" | "top" | "end" | "start" | undefined;
    color?: string;
    onClick?: () => void
};

export const CheckboxBrand = ({
                                  brand,
                                  checked,
                                  onChange,
                                  disabled,
                                  labelPlacement = "end",
                                  color,
                                  onClick
                              }: TCheckboxBrand) => {
    return (
        <FormControlLabelStyled
            control={
                <CheckboxCustom
                    checked={checked}
                    name={brand.id ?? brand.link}
                    onChange={onChange}
                    disabled={disabled}
                />
            }
            sx={{pointerEvents: 'none'}}
            onSubmit={(e) => e.preventDefault()}
            onChange={(e) => e.stopPropagation()}
            // onClick={(e) => e.preventDefault()}
            label={LabelWithImage({
                name: brand.name,
                type: "brand",
                image: brand.image,
                link: brand.link,
                color,
                onClick
            })}
            checked={checked}
            labelPlacement={labelPlacement}
        />
    );
};
