import TransparentPopup from "../TransparentPopup";
import { PopupTitle } from "../TransparentPopup.styles";
import { EyeIcon, LoudspeakerIcon, RocketIcon, UmbrellaIcon } from "../../../assets/svg/icons";
import { Avatar, Button, capitalize, Divider, Stack, Typography } from "@mui/material";
import { CheckboxContainer } from "../../../layout/subheader/Subheader.styles";
import { CheckboxWithLabel, TransparentCheckbox } from "../../../styled/checkboxes/checkbox";
import { Fragment } from "react";
import { usePathname } from "../../../utils/hooks/usePathname";
import {
  ViewModeChannelItem,
  ViewModeChannelsMobile,
  ViewModeContainerMobile,
  ViewModeDivider,
} from "../../ViewModePanel/ViewMode.styles";

import { TPublisher } from "../../../types/reduxData/dataTypes";
import { channelsLogo } from "../../../assets/img/channels/channelsLogo";
import { useViewModePopup } from "./useViewModePopup";

const ViewModePopup = ({ open, closePopup }: any) => {
  const { handleSave, localStatus, setLocalStatus, campaignMode, localChannels, handleSelectChannels, handleSelectCampaignMode } =
    useViewModePopup(open, closePopup);
  const { isCampaignPage, isDashboardPage } = usePathname();

  const renderDashboardOptions = () => {
    if (isDashboardPage)
      return (
        <CheckboxContainer>
          {Object.entries(localStatus).map(([title, data]) => (
            <CheckboxWithLabel
              key={title}
              label={capitalize(title)}
              checked={data}
              name={title}
              onChange={() => setLocalStatus({ ...localStatus, [title]: !data })}
              iconDark
            />
          ))}
        </CheckboxContainer>
      );
  };

  const renderCampaignOptions = () => {
    if (isCampaignPage)
      return (
        <ViewModeContainerMobile>
          <Button startIcon={<RocketIcon />} className={campaignMode === 0 ? "active" : ""} onClick={() => handleSelectCampaignMode(0)}>
            Campaign
          </Button>
          <Button startIcon={<UmbrellaIcon />} className={campaignMode === 1 ? "active" : ""} onClick={() => handleSelectCampaignMode(1)}>
            Channels
          </Button>
          <Button
            startIcon={<LoudspeakerIcon />}
            className={campaignMode === 2 ? "active" : ""}
            onClick={() => handleSelectCampaignMode(2)}
          >
            Ads
          </Button>
          <ViewModeChannelsMobile>
            {Object.keys(localChannels).map((channel: string, i, arr) => (
              <Fragment key={channel + i}>
                <ViewModeChannelItem>
                  <Avatar src={channelsLogo[channel as TPublisher]}></Avatar>
                  <Typography>{channel}</Typography>
                  <TransparentCheckbox
                    radio={campaignMode === 1}
                    checked={localChannels[channel]}
                    onChange={() => handleSelectChannels(channel as TPublisher)}
                  />
                </ViewModeChannelItem>
                {i !== arr.length - 1 && <ViewModeDivider />}
              </Fragment>
            ))}
          </ViewModeChannelsMobile>
        </ViewModeContainerMobile>
      );
  };

  return (
    <TransparentPopup open={open} closePopup={closePopup} onApply={handleSave}>
      <PopupTitle>
        <EyeIcon />
        <Typography>View mode</Typography>
      </PopupTitle>
      {renderDashboardOptions()}
      {renderCampaignOptions()}
    </TransparentPopup>
  );
};

export default ViewModePopup;
