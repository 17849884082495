import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { RewardsDistribution, SidebarRewardsContainer, SidebarRewardsDataList } from "./SidebarRewards.styles";
import { useRewards } from "./useRewards";

const SidebarRewards = () => {
  const { rewards } = useRewards();

  return (
    <SidebarRewardsContainer>
      <Typography variant="h3">Rewards</Typography>
      <SidebarRewardsDataList>
        <Stack>
          <Typography variant="h5">Total rewards:</Typography>
          <Typography>
            <b>{rewards.total}</b> $WEBM
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="h5">Avg. daily rewards:</Typography>
          <Typography>
            <b>{rewards.daily}</b> $WEBM
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="h5">$WEBM current value:</Typography>
          <Typography>
            <b>$1</b> USDT <span> = </span> <b>50</b> $WEBM
          </Typography>
        </Stack>
      </SidebarRewardsDataList>
      <RewardsDistribution>
        <Typography variant="h3">REWARDS DISTRIBUTION</Typography>
        <Typography>
          Split the rewards with your visitors. They will be able to claim the rewards by connecting their wallet, which give you insights
          on their on-chain data activities
        </Typography>
        <Button>Coming soon</Button>
      </RewardsDistribution>
    </SidebarRewardsContainer>
  );
};

export default SidebarRewards;
