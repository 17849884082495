import { Button, DialogContent, IconButton, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { CargoIcon, ClosePopupIcon } from "../../../assets/svg/icons";
import { PopupButtons, PopupContainer, PopupContent } from "../Popup.styles";

const ConfirmSuspendingPopup = ({ closePopup }: { closePopup: () => void }) => {
  return (
    <PopupContainer open onClose={closePopup}>
      <DialogContent>
        <IconButton className="close" onClick={closePopup}>
          <ClosePopupIcon />
        </IconButton>
        <PopupContent width={"90%"}>
          <CargoIcon width={"11.3rem"} />
          <Stack>
            <Typography variant="h3">Confirm suspending</Typography>
            <Typography>
              After your confirmation within 48 hours, the campaign will be suspended. You can start it again by selecting the
              “Resume” option.
            </Typography>
          </Stack>
        </PopupContent>
        <PopupButtons>
          <Button>Suspend campaign</Button>
          <Button onClick={closePopup}>Cancel</Button>
        </PopupButtons>
      </DialogContent>
    </PopupContainer>
  );
};

export default ConfirmSuspendingPopup;
