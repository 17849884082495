//Core
import { Formik } from "formik";
//Styles
import {
  ButtonChangeWallet,
  ButtonsBox,
  ConnectWalletBlock,
  FormAddButton,
  FormBox,
  FormCompanyItem,
  FormInput,
  FormInputName,
  GeneralSettingsBox,
  LoaderButton,
  NoNFTDetected,
  SettingsTitle,
} from "../Forms.styles";
import { Button, Stack, Typography } from "@mui/material";
import { FlexBox } from "../../../styled/Layout";
import { HowToFix } from "../../../layout/popups/generalSettings/noNFTPopup.styles";
//Components
import { NoNFTDecision } from "../../../layout/popups/generalSettings/noNFTPopup";
import BlockContent from "../../../layout/blockContent/BlockContent";
//Hooks
import { useGeneralSettings } from "./useGeneralSettings";
//Popup
import { popupList, TPopup } from "../../../layout/popups/PopupList";
//Images
import { EqualizerIcon, Lightning } from "../../../assets/svg/icons";
import { AttentionSvg } from "../../../assets/svg/GeneralSettingsPopupSvg";
//Utils
import {isSampleData, LinkSignUp} from "../../../utils/constants";
import { Trash } from "../../../assets/svg/trash";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../../loading/Loading";

type TCompany = {
  company_name: string;
  company_url: string;
};

export const GeneralSettingsForms = () => {
  const {
    userData,
    isLoadingUser,
    sendForm,
    validationSchema,
    popup,
    setPopup,
    connectWithWallet,
    walletChecking,
    hasNFT,
    wallet,
    // theSameWallet
    companySelected,
    setCompanySelected,
  } = useGeneralSettings();
  const { isLoading } = useAuth0();

  const walletView = wallet.replace(wallet.slice(wallet.length / 2, -4), "...");

  const renderCompanyForm = ({ values, setValues, handleChange, handleBlur, className }: any) => (
    <FormBox marginBottom={"2.8rem"} className={className}>
      <SettingsTitle>{"Company info"}</SettingsTitle>
      <FormInputName>{"Brand / Company Name"}</FormInputName>
      <FormInput
        placeholder={"Brand / Company Name"}
        name={`companies[${companySelected}].company_name`}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.companies[companySelected]?.company_name}
      />
      <FormInputName>{"Company website URL"}</FormInputName>
      <FormInput
        placeholder={"Company website URL"}
        name={`companies[${companySelected}].company_url`}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.companies[companySelected]?.company_url}
      />
      <FormAddButton
        onClick={() => {
          setValues({
            ...values,
            companies: [...values.companies, { company_name: "", company_url: "https://" }],
          });
          setCompanySelected(values.companies.length);
        }}
        sx={{ mb: { xs: "3rem", mobL: "5rem" } }}
      >
        + Add Company
      </FormAddButton>
      {values.companies.length > 1 && (
        <Stack>
          {values.companies.map((el: TCompany, i: number) => (
            <FormCompanyItem key={el.company_url + i} className={companySelected === i ? "selected" : ""}>
              <Stack>
                <Typography variant={"h4"}>{el.company_name}</Typography>
                <Typography>{el.company_url}</Typography>
              </Stack>
              <FlexBox>
                <Button startIcon={<EqualizerIcon />} onClick={() => setCompanySelected(i)}>
                  Edit
                </Button>
                <Button
                  startIcon={<Trash />}
                  onClick={() => {
                    setValues({
                      ...values,
                      companies: values.companies.filter((el: TCompany, ind: number) => ind !== i),
                    });
                    setCompanySelected(0);
                  }}
                >
                  Delete
                </Button>
              </FlexBox>
            </FormCompanyItem>
          ))}
        </Stack>
      )}
    </FormBox>
  );

  return (
    <>
      {isLoading || isLoadingUser ? (
        <Loading />
      ) : (
        <GeneralSettingsBox>
          <Formik
            initialValues={userData}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              !isSampleData && await sendForm(values);
              setSubmitting(false);
            }}
          >
            {({ values, setValues, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, resetForm }) => (
              <form onSubmit={handleSubmit}>
                <FlexBox
                  height={"fit-content"}
                  alignItems={"stretch !important"}
                  mb={"5.3rem"}
                  flexDirection={{ xs: "column", mobL: "row" }}
                >
                  <Stack mr={{ mobL: "2.8rem" }} width={"50%"}>
                    <FormBox mb={{ mobL: "2.8rem" }}>
                      <SettingsTitle>{"Personal info"}</SettingsTitle>
                      <FormInputName>{"First Name"}</FormInputName>
                      <FormInput
                        placeholder={"Type your first name"}
                        name={"first_name"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.first_name}
                        error={touched.first_name && Boolean(errors.first_name)}
                        helperText={errors.first_name && touched.first_name && errors.first_name}
                      />
                      <FormInputName>{"Last Name"}</FormInputName>
                      <FormInput
                        placeholder={"Type your last name"}
                        name={"last_name"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.last_name}
                        error={touched.last_name && Boolean(errors.last_name)}
                        helperText={errors.last_name && touched.last_name && errors.last_name}
                      />
                      <FormInputName>{"Email"}</FormInputName>
                      <FormInput
                        placeholder={"No email found"}
                        name={"email"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        disabled
                        error={touched.email && Boolean(errors.email)}
                        helperText={errors.email && touched.email && errors.email}
                      />
                    </FormBox>
                    {renderCompanyForm({
                      values,
                      setValues,
                      handleChange,
                      handleBlur,
                      className: "mobileOnly",
                    })}

                    <FormBox>
                      <BlockContent loading={walletChecking}>
                        {!hasNFT && wallet ? (
                          <>
                            <NoNFTDetected>
                              <AttentionSvg />
                              {"THERE IS NO WEBM NFT DETECTED"}
                            </NoNFTDetected>
                            {/*{!theSameWallet &&*/}
                            {/*    <Button variant={'outlined'}*/}
                            {/*            color={'success'}*/}
                            {/*            sx={{margin: '1rem 0 0'}}*/}
                            {/*            onClick={connectWithWallet}*/}
                            {/*    >*/}
                            {/*        {'Update wallet by MetaMask'}*/}
                            {/*    </Button>}*/}
                            <HowToFix>{"Current wallet:"}</HowToFix>
                            <FormInput
                              value={walletView}
                              InputProps={{
                                endAdornment: (
                                  <ButtonChangeWallet
                                    // startIcon={<ReloadIcon/>}
                                    variant={"outlined"}
                                    color={"success"}
                                    sx={{ margin: "0.5rem" }}
                                    onClick={connectWithWallet}
                                  >
                                    {"Update wallet by MetaMask"}
                                  </ButtonChangeWallet>
                                ),
                              }}
                            />
                            <NoNFTDecision />
                          </>
                        ) : wallet !== "" ? (
                          <>
                            <SettingsTitle>{"Payment settings"}</SettingsTitle>
                            <FormInputName>{"Wallet"}</FormInputName>
                            <FormInput
                              placeholder={"Type here"}
                              name={"wallet_address"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={wallet}
                              disabled
                              error={touched.wallet_address && Boolean(errors.wallet_address)}
                              helperText={errors.wallet_address && touched.wallet_address && errors.wallet_address}
                            />
                          </>
                        ) : (
                          <ConnectWalletBlock>
                            <Typography>{"Connect Wallet with Webm NFT assigned"}</Typography>
                            <ButtonChangeWallet startIcon={<Lightning />} onClick={() => connectWithWallet()} color={"success"}>
                              <Typography>{"Connect wallet"}</Typography>
                            </ButtonChangeWallet>
                            <a href={LinkSignUp}>{`I don't own Webm NFT`}</a>
                          </ConnectWalletBlock>
                        )}
                      </BlockContent>
                    </FormBox>
                  </Stack>
                  <Stack height={"auto"} width={"50%"}>
                    {renderCompanyForm({
                      values,
                      setValues,
                      handleChange,
                      handleBlur,
                      className: "desktopOnly",
                    })}
                    <FormBox empty={"empty"} height={"100% !important"} display={values.companies.length > 1 ? "none" : "block"} />
                  </Stack>
                </FlexBox>
                <ButtonsBox sx={{ justifyContent: "flex-start" }}>
                  <LoaderButton variant={"contained"} color={"success"} type={"submit"} loading={isSubmitting} disabled={isSubmitting}>
                    {"Save Changes"}
                  </LoaderButton>
                  <Button onClick={() => resetForm()}>Cancel</Button>
                </ButtonsBox>
              </form>
            )}
          </Formik>
          {popupList[popup as TPopup] && popupList[popup as TPopup]({ closePopup: () => setPopup("") })}
        </GeneralSettingsBox>
      )}
    </>
  );
};
