import { styled, Typography } from "@mui/material";
import { FlexBox } from "../../styled/Layout";
import theme from "../../theme/theme";

export const CheckboxesContainer = styled(FlexBox)`
  & label {
    margin-right: 4rem;
  }
  
  ${theme.breakpoints.down('mobL')}{
    flex-direction: column;
    row-gap: 1rem;
    label{
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
      width: 100%;
      padding: 1rem 2.5rem 1rem 2rem;
      border-radius: .6rem;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      margin: 0;
    }
    
    .MuiButtonBase-root{
      color: #fff;
      &.Mui-checked{
        span:first-of-type{
          background-color: #04b78a;
        }
      }
      
      span{
        height: 3.3rem;
        width: 3.3rem;
        border-radius: .66rem;
        
        ::before{
          position: relative;
          width: 2.5rem;
          height: 2.5rem;
          margin: .3rem auto 0;
          z-index: 1;
        }
      }
    }
  }
`;

export const ComparisonDotBrandInfoBox = styled(FlexBox)`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  > div:first-of-type {
    width: 2.5rem;

    img {
      border-radius: 50%;
    }
  }

  p {
    margin-left: 0.6rem;
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 0.47px;
    color: #242f57;
    text-transform: capitalize;
  }
`;

export const ComparisonDotParam = styled(Typography)`
  font-family: "F37 Judge";
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0.38px;
  color: #7a86b4;
  text-align: center;
`;

///////////////////////////////////// MOBILE ///////////////////////////////////////////

export const ComparisonCombinedNameMob = styled(FlexBox)`
  justify-content: center;
  margin: 5rem 0 3rem;
  
  p {
    font-size: 1.86rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-transform: capitalize;
    letter-spacing: 0.21px;
    color: #9ca0ad;
    flex: 1;
    //width: fit-content;
    margin: 0 1rem;
    white-space: nowrap;
  }
  
  > span{
    height: 1px;
    flex: 1;
    background-color: #9ca0ad;
    opacity: 0.4;
  }
  
  &.popup {
    width: 100%;
    justify-content: flex-start;
    
    p {
      flex: unset;
      color: #FFFFFF;
      width: fit-content;
    }
    span {
      background-color: #FFFFFF;
    }
  }

    ${theme.breakpoints.up('mobL')}{
      display: none;
    }
`