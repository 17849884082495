import { Box, styled, Typography } from "@mui/material";
import { FlexBox } from "../../../styled/Layout";
import theme from "../../../theme/theme";
import { PopupContainer } from "../Popup.styles";

export const MainTitle = styled(Typography)`
  font-size: 2.34rem;
  font-weight: bold;
  letter-spacing: 0.26px;
  color: #08513f;
  text-transform: uppercase;

  ${theme.breakpoints.down("mobL")} {
    font-size: 2.94rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: 0.33px;
    color: #fff;
    width: 80%;
    margin-bottom: 2rem;
  }
`;

export const SecondTitle = styled(Typography)`
  font-size: 1.74rem;
  font-weight: bold;
  letter-spacing: 0.19px;
  color: #078767;
  text-transform: capitalize;
  margin-bottom: 1rem;

  ${theme.breakpoints.down("mobL")} {
    font-family: Hellix;
    font-size: 2.04rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.76;
    letter-spacing: 0.23px;
    color: #f83b3b;
    margin-bottom: 1.8rem;
  }
`;

export const SmallText = styled(Typography)`
  font-size: 1.44rem;
  line-height: 1.25;
  letter-spacing: 0.16px;
  color: #000;
  margin-top: 2rem;
  max-width: 26rem;
  a {
    text-decoration: underline;
    color: #000000;
  }
  a:visited {
    color: #000000;
  }

  ${theme.breakpoints.down("mobL")} {
    opacity: 0.69;
    font-size: 1.94rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: 0.22px;
    color: #fff;
    max-width: 100%;
    a,
    a:visited {
      color: #fff;
    }
  }
`;

export const SvgContainer = styled(Box)`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  overflow: hidden;
  svg {
    position: absolute;
    z-index: 0;
    top: -9.5rem;
    left: -10rem;
    height: 54rem;
  }

  ${theme.breakpoints.down("mobL")} {
    position: relative;
    width: 95%;
    margin: 5rem 0 3.5rem;

    svg {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
    }
  }
`;

export const ArrowContainer = styled(Box)`
  position: absolute;
  z-index: 1500;
  width: 30rem;
  bottom: -18rem;
  left: -16rem;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const GeneralSettingsContent = styled(FlexBox)`
  margin-left: 26rem;
  width: fit-content;
  min-width: 40rem;

  ${theme.breakpoints.down("mobL")} {
    flex-direction: column;
    min-width: auto;
    margin: 0 auto;
    width: 80%;

    .MuiButtonBase-root {
      margin-top: 5rem;
    }
  }
`;

export const GeneralSettingsPopupContainer = styled(PopupContainer)`
  .MuiDialog-container {
    height: fit-content;
    margin-top: 300px;
  }
  .MuiDialog-paper {
    overflow-y: unset;
    //margin: 32px 0 300px 64px;
    margin: 32px 0 300px 240px;
  }

  ${theme.breakpoints.down("mobL")} {
    .MuiDialog-container {
      width: 100vw;
      height: 100vh;
      margin: 0;
      background-color: rgba(0, 0, 0, 0.8);

      .MuiIconButton-root.close {
        right: 3rem;

        svg {
          width: 4.2rem;
          height: 4.2rem;

          path {
            fill: #fff;
            opacity: 1;
          }
        }
      }
    }

    .MuiDialog-paper {
      width: 100%;
      height: 100%;
      padding: 0 !important;
      margin: 0;
      background: none;
    }

    .MuiDialogContent-root {
      width: 100vw;
      height: 100vh;
    }
  }
`;
