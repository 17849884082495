import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "../redux/store";

export const overviewSelector = createSelector(
  (state: AppState) => state.overview,
  (x) => x
);

export const campaignsSelector = createSelector(
  (state: AppState) => state.campaigns,
  (x) => x
);

export const filtersSelector = createSelector(
  (state: AppState) => state.filters,
  (x) => x
);

export const userSelector = createSelector(
  (state: AppState) => state.user,
  (x) => x
);

export const appSelector = createSelector(
  (state: AppState) => state.app,
  (x) => x
);
