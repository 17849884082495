import { styled } from "@mui/material";
import { FlexBox } from "../../styled/Layout";
import theme from "../../theme/theme";

export const TableListItem = styled(FlexBox)`
  &:not(:last-of-type) {
    /* margin-bottom: 2.4rem; */
  }
  svg {
    width: 2.3rem;
    margin-right: 1.2rem;
  }

  ${theme.breakpoints.down("mobL")} {
    
    svg{
      width: 2rem;
      path{
        fill: #b2bad5;
        stroke: #b2bad5;
        stroke-width: 1px;
      }
    }
    p,
    span {
      font-family: 'Hellix-Medium','Hellix', sans-serif;
      font-size: 1.84rem;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.2px;
    }
    p {
      color: #636e95;
    }

    span {
      color: #4e5a86;
    }
  }
`;
