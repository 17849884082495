import {Avatar, Grid, Stack, Typography} from "@mui/material";
import {DeleteIcon, EqualizerIcon} from "../../../../assets/svg/icons";
import {creativeTemplates, TCretiveTemplateKey} from "../../../../components/CreativeCard/CreativeTemplates";
import BlockContent from "../../../../layout/blockContent/BlockContent";
import {BlockContainer, FlexBox} from "../../../../styled/Layout";
import {appLink} from "../../../../utils/constants";
import {useLoading} from "../../../../utils/hooks/useLoading";
import {
    OverviewAdContainer,
    OverviewAdsGrid,
    OverviewEditButton,
    OverviewReasonText,
    OverviewReasonTitle
} from "../../Overview.styles";
import {useRejectedAds} from "./useRejectedAds";
import SectionSlide from "../../../../layout/sectionSlide/SectionSlide";

const RejectedAds = () => {
    const {rejectedCreatives, campaignData, deleteAd, deleteLoader} = useRejectedAds();
    const isLoading = useLoading("ad_status_list");

    if (!isLoading && !rejectedCreatives.length) return null;

    return (
        <BlockContainer sx={{height: "fit-content"}}>
            <Typography variant="h3" mb="4.7rem">
                Rejected ads
            </Typography>
            <BlockContent loading={isLoading}>
                {!rejectedCreatives.length ? (
                    <Typography variant="body2">No rejected ads found</Typography>
                ) : (
                    <OverviewAdsGrid container spacing={"3rem"}>
                        {rejectedCreatives?.map((ad) => (
                            <Grid item xs={12} md={6} key={ad.id}>
                                <OverviewAdContainer>
                                    {ad.campaign_id && campaignData(ad.campaign_id) && (
                                        <FlexBox>
                                            <Avatar
                                                src={campaignData(ad.campaign_id)?.campaign_logo}>{campaignData(ad.campaign_id)?.campaign_name}</Avatar>
                                            <Stack ml={'1rem'}>
                                                <Typography> {campaignData(ad.campaign_id)?.campaign_name}</Typography>
                                            </Stack>
                                        </FlexBox>
                                    )}
                                    {creativeTemplates[ad.type as TCretiveTemplateKey]({data: ad})}
                                    <Stack m={'3rem 1rem 0'}>
                                        <OverviewReasonTitle>{'Reason for rejection:'}</OverviewReasonTitle>
                                        <OverviewReasonText>{''}</OverviewReasonText>
                                    </Stack>
                                    <FlexBox m={'3rem 1rem 2rem'}
                                             justifyContent={'space-between'}
                                             flexDirection={{xs: 'column', mobL: 'row'}}
                                    >
                                        <OverviewEditButton
                                            startIcon={<EqualizerIcon/>}
                                            onClick={() => window.open(`${appLink}/${ad.campaign_id}/summary`, ad.campaign_id)}
                                        >
                                            {'Edit ad'}
                                        </OverviewEditButton>
                                        <OverviewEditButton
                                            startIcon={<DeleteIcon/>}
                                            className={"delete"}
                                            loading={deleteLoader}
                                            onClick={() => deleteAd(ad.id, ad.campaign_id, ad.group_id)}
                                        >
                                            {'Delete ad'}
                                        </OverviewEditButton>
                                    </FlexBox>
                                </OverviewAdContainer>
                            </Grid>
                        ))}
                    </OverviewAdsGrid>
                )}
            </BlockContent>
        </BlockContainer>
    );
};

export default RejectedAds;
