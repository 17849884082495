import { Button, DialogContent, IconButton, Input, OutlinedInput, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { ClosePopupIcon, DepositIcon } from "../../../assets/svg/icons";
import { FlexBox } from "../../../styled/Layout";
import { PopupButtons, PopupContainer, PopupContent, PopupInputContainer } from "../Popup.styles";

const DepositPopup = ({ closePopup }: { closePopup: () => void }) => {
  return (
    <PopupContainer open onClose={closePopup}>
      <DialogContent sx={{ p: "1rem 15rem" }}>
        <IconButton className="close" onClick={closePopup}>
          <ClosePopupIcon />
        </IconButton>
        <PopupContent>
          <DepositIcon width={"8.1rem"} />
          <Stack>
            <Typography variant="h3">Deposit to campaign</Typography>
            <Typography variant="h4">Campaign name</Typography>
          </Stack>
        </PopupContent>
        <PopupContent>
          <Typography mt={"2.7rem"}>
            Enter the amount you want to deposit. After confirmation, this amount will be transferred to your campaign budget.
          </Typography>
        </PopupContent>

        <PopupInputContainer>
          <Typography>Amount to deposit</Typography>
          <FlexBox>
            <Typography className="currency">$</Typography>
            <OutlinedInput type="number" placeholder="0"/>
          </FlexBox>
        </PopupInputContainer>
        <PopupButtons>
          <Button>Deposit</Button>
          <Button onClick={closePopup}>Cancel</Button>
        </PopupButtons>
      </DialogContent>
    </PopupContainer>
  );
};

export default DepositPopup;
