//Core
import React from "react";
// Components
import AdCreatives from "../../components/AdCreatives/AdCreatives";
import Age from "../../components/Age/Age";
import Devices from "../../components/Devices/Devices";
import Gender from "../../components/Gender/Gender";
import RoiComparison from "../../components/RoiComparison/RoiComparison";
import Total from "../../components/Total/Total";
import AdsComparison from "../../components/AdsComparison/AdsComparison";
import Websites from "../../components/Websites/Websites";
import AdChannels from "../../components/AdChannels/AdChannels";
import Countries from "../../components/Countries/Countries";
// Styles
import {Container, Typography} from "@mui/material";
import "swiper/css";
import Grid2 from "@mui/material/Unstable_Grid2";
import { ImgBox, SectionHeadBackground, SectionHeadContainer } from "../../styled/Layout";
import PRImg from "../../assets/img/PR-Coming-soon.webp";
import MetaverseImg from "../../assets/img/Metaverse-Coming-soon.webp";
// Hooks
import { useDashboard } from "./useDashboard";
// import { Subheader } from "../../layout/subheader/Subheader";
// import DashboardMobile from "./DashboardMobile";
// import { useBreakpoints } from "../../utils/hooks/useBreakpoints";
import PPCDashboardCard from "./components/dashboardCard/PPC/PPCDashboardCard";
import { InfluencerComingSoon } from "./components/dashboardCard/DashboardComingSoon";
import {
  ComingSoonAccordionMob,
  ComingSoonSign,
  // DashboardCardAccordionButton,
  DashboardCardTitle,
} from "./components/dashboardCard/DashboardCard.styles";
import { DocIcon, LoudspeakerIcon, VRIcon } from "../../assets/svg/icons";
import DataMiningDashboardCard from "./components/dashboardCard/dataMining/DataMiningDashboardCard";

const Dashboard = () => {
  const { datesRange, handleChangeDatesRange, noData, closePopup } = useDashboard();
  // const { mobileLarge } = useBreakpoints();

  // if(mobileLarge) return <DashboardMobile/>

  return (
    <>
      {/*<Subheader datesRange={datesRange} handleChangeDatesRange={handleChangeDatesRange} />*/}
      <SectionHeadBackground className={"desktopOnly"}>
        <SectionHeadContainer>
          <Typography variant={"h2"}>Dashboard</Typography>
        </SectionHeadContainer>
      </SectionHeadBackground>
      <Container sx={{ pt: { xs: "5rem", mobL: 0 }, pb: {xs: '10rem',mobL:'5rem'} }}>
        <Grid2 container spacing={{ xs: "2rem", mobL: "6rem" }} mt={"6rem"}>
          <Grid2 xs={12} mobL={6}>
            <PPCDashboardCard />
          </Grid2>
          <Grid2 xs={12} mobL={6}>
            <DataMiningDashboardCard />
          </Grid2>
          <Grid2 xs={12} mobL={6}>
            <InfluencerComingSoon />
            <ComingSoonAccordionMob className={"mobileOnly"}>
              <DashboardCardTitle>
                <LoudspeakerIcon />
                <Typography variant={"h3"}>Influencer marketing</Typography>
              </DashboardCardTitle>
              <ComingSoonSign>Coming soon</ComingSoonSign>
            </ComingSoonAccordionMob>
          </Grid2>
          <Grid2 xs={12} mobL={6}>
            <ImgBox className={"desktopOnly"}>
              <img src={PRImg} alt={"pr coming soon"} />
            </ImgBox>
            <ComingSoonAccordionMob className={"mobileOnly"}>
              <DashboardCardTitle>
                <DocIcon />
                <Typography variant={"h3"}>pr campaigns</Typography>
              </DashboardCardTitle>
              <ComingSoonSign>Coming soon</ComingSoonSign>
            </ComingSoonAccordionMob>
          </Grid2>
          <Grid2 xs={12} mobL={6}>
            <ImgBox className={"desktopOnly"}>
              <img src={MetaverseImg} alt={"metaverse coming soon"} />
            </ImgBox>
            <ComingSoonAccordionMob className={"mobileOnly"}>
              <DashboardCardTitle>
                <VRIcon />
                <Typography variant={"h3"}>METAVERSE</Typography>
              </DashboardCardTitle>
              <ComingSoonSign>Coming soon</ComingSoonSign>
            </ComingSoonAccordionMob>
          </Grid2>
        </Grid2>
      </Container>
    </>
  );
};

export default Dashboard;
