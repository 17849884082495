import {useDispatch, useSelector} from "react-redux";
import {userSelector} from "../../../utils/selectors";
import {useEffect, useState} from "react";
import {TBillingInfo} from "../../../types/reduxData/generalSettingsTypes";
import {updateUserField} from "../../../api/campaignsRequests";
import {setUserField} from "../../../redux/user/userSlice";

export const useDetails = () => {
    const {billing_info, id} = useSelector(userSelector)
    const [isButton, setIsButton] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [info, setInfo] = useState<TBillingInfo>({
        company_name: '',
        company_incorporation: '',
        company_address: '',
        company_registration_number: ''
    })
    const dispatch = useDispatch()

    useEffect(() => {
        if (billing_info && Object.keys(billing_info).length) {
            setInfo(billing_info)
            setIsButton(false)
        } else setIsButton(true)
    }, [billing_info])

    const updateBillingDetails = async () => {
        setIsLoading(true)
        const response = await updateUserField(id, {billing_info: info})
        // console.log(response)
        if(response?.updated === 'success'){
            setIsButton(false)
            dispatch(setUserField({billing_info: info}))
        }
        setIsLoading(false)
    }


    return {
        isButton,
        info,
        setInfo,
        updateBillingDetails,
        isLoading
    }
}