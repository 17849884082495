import { styled, TableContainer } from "@mui/material";
import theme from "../../theme/theme";

export const MonitorTableContainer = styled(TableContainer)`
  td,
  th {
    border: none;
    text-align: center;

    &:first-of-type {
      display: flex;
      padding-right: 0;
      width: 105%;
      translate: 0 3px;
    }

    &:nth-of-type(3) {
      background-color: #f0f1f3;
      padding-bottom: 0.7rem;
    }
  }

  th {
    p {
      font-weight: 500;
    }

    &:nth-of-type(even) {
      padding: .7rem 0 0;
      //padding-top: 0.7rem;
    }
  }

  td {
    padding: 0.7rem;

    .icon {
      margin-right: 0.4rem;
    }
  }

  tr:last-of-type {
    height: 3rem;
  }
  
  ${theme.breakpoints.down('mobL')}{
    th{
      //padding-bottom: 3rem;
      //padding-top: 0;
      padding: 1.4rem .5rem;

      &:nth-of-type(3) {
        width: 0;
      }
      p{
        font-family: 'F37 Judge';
        font-size: 1.5rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.47px;
        text-align: center;
        color: #677194;
        opacity: 0.53;

      }
    }
    
    tr{
      td:first-of-type{
        max-width: 35vw;
        p{
          font-size: 1.4rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }


  }
  
  ${theme.breakpoints.down('mobM')}{
   width: 110%;
    margin-left: -5%;
    
    tbody{
      td{
        span{
          font-size: 1.6rem;
        }
      }
    }
  }


`;
