import { ViewMode } from "../../components/ViewModePanel/ViewMode";
import { GeneralSettingsForms } from "../../components/Forms/GeneralSettingsForm/GeneralSettingsForms";
import { SectionHeadBackground, SectionHeadContainer } from "../../styled/Layout";
import { Typography } from "@mui/material";

export const GeneralSettings = () => {
  return (
    <>
      {/*<ViewMode/>*/}
      <SectionHeadBackground>
        <SectionHeadContainer>
          <Typography variant={"h2"}>General Settings</Typography>
        </SectionHeadContainer>
      </SectionHeadBackground>
      <GeneralSettingsForms />
    </>
  );
};
