// Components
import KeyValueTable from "../Tables/KeyValueTable";

// Styles
import { BlockContainer } from "../../styled/Layout";

// Hooks
import { useDuration } from "./useDuration";

const Duration = () => {
  const { durationData } = useDuration();

  return (
    <BlockContainer sx={{boxShadow:'none', border: 'solid 1px #eaedf7'}}>
      <KeyValueTable data={durationData} />
    </BlockContainer>
  );
};

export default Duration;
