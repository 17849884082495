import { useSelector } from "react-redux";
import { formatCreatives } from "../../../../utils/dataFormat/formatCreatives";
import {campaignsSelector, overviewSelector} from "../../../../utils/selectors";
import {TAdsStatus} from "../../../../types/reduxData/overviewTypes";

export const useWaitingAds = () => {
  const { ads_by_status } = useSelector(overviewSelector);
  const {list} = useSelector(campaignsSelector);

  const tmp = ads_by_status?.waiting.map((ad: TAdsStatus) => ({ ad_id: ad.ad_id, campaign_id: ad.campaign_id, ...ad.data }));

  const waitingCreatives = formatCreatives(tmp).map((el, i) => ({
    ...el,
    campaign_id: ads_by_status?.waiting[i].campaign_id
  }));

  const campaignData = (id: string) => {
    return list.find(el => el.campaign_id === id)
  }
  return { waitingCreatives, campaignData };
};
