import {TUpdateUserBody, TUpdateUserRequestBody} from "../../../types/reduxData/generalSettingsTypes";
// import {updateUser} from "../../../api/dashboardRequests";
import {useDispatch, useSelector} from "react-redux";
import * as yup from "yup";
import {userSelector} from "../../../utils/selectors";
import {useEffect, useState} from "react";
import { CheckNFT } from "../../../api/web3Requests";
import { connectWallet } from "../../../api/connectWallet";
import { store } from "../../../redux/store";
import { updateUserField } from "../../../api/campaignsRequests";
import { setUserDataAction } from "../../../redux/user/userSlice";


export const useGeneralSettings = () => {
    const { userData, isLoadingUser, id } = useSelector(userSelector);

    const [popup, setPopup] = useState<string>('')
    const [wallet, setWallet] = useState<string>("");
    const [hasNFT, setHasNFT] = useState<boolean>(false)
    const [walletChecking, setWalletChecking] = useState<boolean>(false);
    const [theSameWallet, setTheSameWallet] = useState<boolean>(true);
    const [companySelected, setCompanySelected] = useState(0);

    const validationSchema = yup.object({
        first_name: yup.string().min(1).required('Name is required'),
        last_name: yup.string().min(1).required('Name is required')
    })

    const dispatch = useDispatch()

    const sendForm = async (data: TUpdateUserRequestBody) => {
        // console.log(data)
        const body: TUpdateUserBody = {
            personal_info: {
                first_name: data.first_name,
                last_name: data.last_name,
                mail_address: data.email,
            },
            payment_info: {
                wallet_address: wallet,
                has_nft: hasNFT
            },
            companies: data.companies
        }
        const response = await updateUserField(id, body)
        if (!response || !response?.updated) {
            setPopup('generalSettings')
        } else {
            await dispatch(setUserDataAction({...data, wallet_address: wallet, has_nft: hasNFT}))
        }
    }

    const checkNFT = async (wallet: string) => {
        setWalletChecking(true)
        try {
            const res = await CheckNFT(wallet);
            console.log(res)
            if (res?.valid) {
                setHasNFT(true)
                return res.valid
            } else {
                setPopup('noNFT')
                setHasNFT(false)
                window.scroll({top: 0, behavior: 'smooth'})
                return false
            }
        } catch (err) {
            console.log(err)
            setHasNFT(false);
            setPopup('noNFT')
            window.scroll({top: 0, behavior: 'smooth'})
        } finally {
            setWalletChecking(false)
        }
    }

    const connectWithWallet = async () => {
        const address = await connectWallet();
        if (!address) {
            setPopup('noMetaMask')
        }
        else {
            setWallet(address);
            await checkNFT(address)
            return address
        }
    };

    useEffect(() => {
        setWallet(userData?.wallet_address)
        setHasNFT(userData?.has_nft)
        if (userData && userData?.wallet_address && userData?.wallet_address !== "") {
            if (!userData?.has_nft) {
                void checkNFT(userData.wallet_address)
                connectWallet().then((address) => {
                    if (address === userData?.wallet_address) setTheSameWallet(true)
                    else setTheSameWallet(false)
                });
            }
            setPopup('')
        }else {
            setPopup('connectWallet')
        }
    },[userData, isLoadingUser])

    const func = async (userData: TUpdateUserRequestBody) => {
        if ((userData && userData.wallet_address && userData.wallet_address !== '' && !userData.has_nft) ||
            (!userData?.wallet_address || userData?.wallet_address === '')) {
            setPopup('')
            setWalletChecking(true)
            console.log('func')
            await connectWithWallet();
            setWalletChecking(false)
        }
    };

    useEffect(() => {
        // @ts-ignore
        window.ethereum?.on("accountsChanged", async () => {
            await func(store.getState().user.userData)
        });
        // @ts-ignore
        window.ethereum?.on("disconnect", () => console.log("disconnected"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        userData,
        isLoadingUser,
        sendForm,
        validationSchema,
        popup,
        setPopup,
        connectWithWallet,
        walletChecking,
        hasNFT,
        wallet,
        theSameWallet,
        companySelected, setCompanySelected
    }
}