import { MobileMenuDivider, MobileMenuItemAccordion, MobileMenuItemList, MobileMenuItemTitle } from "./MobileMenuItem.styles";
import { IconButton, List, Typography } from "@mui/material";
import {ForwardedRef, forwardRef, RefObject} from "react";

type TMobileMenuItem = {
  title: string;
  children?: any;
  disabled?: boolean;
};
const MobileMenuItem = forwardRef(({ title, children, disabled=false }: TMobileMenuItem, ref: ForwardedRef<HTMLDivElement>) => {
  return (
    <>
      <MobileMenuItemAccordion disabled={disabled}>
        <MobileMenuItemTitle ref={ref}>
          <Typography variant={"h4"}>{title}</Typography>
          <IconButton>
            <Typography className={"plus"}>+</Typography> <Typography className={"minus"}>-</Typography>
          </IconButton>
        </MobileMenuItemTitle>
        <MobileMenuItemList>
          <List>{children}</List>
        </MobileMenuItemList>
      </MobileMenuItemAccordion>
      <MobileMenuDivider />
    </>
  );
});

export default MobileMenuItem;
