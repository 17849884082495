import { Avatar, DialogContent, IconButton, ListItem, Stack, Typography } from "@mui/material";
import { FlexBox } from "../../../styled/Layout";
import { BellIcon, TrianglesIcon } from "../../../assets/svg/icons";
import { HeaderNavigation, HeaderUserData, LogOutButton } from "../Header.styles";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import { userSelector } from "../../../utils/selectors";
import { Link, useLocation } from "react-router-dom";
import MobileMenuItem from "./MobileMenuItem/MobileMenuItem";
import { usePathname } from "../../../utils/hooks/usePathname";
import { Ref, useEffect, useRef } from "react";

const HeaderMobileMenu = ({ pages, toggleMobileMenu }: any) => {
  const { isAuthenticated, user, logout, loginWithPopup } = useAuth0();
  const { hasCampaigns, hasDrafts, userData } = useSelector(userSelector);
  const userName = userData.first_name.length || userData.last_name.length ? `${userData.first_name} ${userData.last_name}` : " ";
  const { pathname } = useLocation();
  const { isPPCOverviewPage, isCampaignsTable, isDataminingOverview, isDataminingPixel } = usePathname();
  const accRef = useRef<HTMLDivElement>(null);
  const ppcRef = useRef<HTMLDivElement>(null);
  const pixelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!accRef.current) return;
    const accRoutes = ["/dashboard", "/settings", "/billing/details", "/billing/deposit_withdraw", "/billing/history", "/support"];
    if (accRoutes.includes(pathname)) {
      accRef?.current?.click();
    } else if (pathname.startsWith("/ppc")) {
      ppcRef?.current?.click();
    } else if (pathname.startsWith("/data_mining")) {
      pixelRef?.current?.click();
    }
  }, [pathname, accRef]);

  return (
    <DialogContent>
      <Stack className={"mobileOnly"} alignItems={"center"} mt={"5rem"}>
        {/*{isAuthenticated && (*/}
        {/*  <>*/}
        {/*    <HeaderUserData>*/}
        {/*      <Stack>*/}
        {/*        <Typography variant="h4">{userName}</Typography>*/}
        {/*        <Typography>Admin</Typography>*/}
        {/*      </Stack>*/}
        {/*    </HeaderUserData>*/}
        {/*  </>*/}
        {/*)}*/}
        <MobileMenuItem title={"Account"} ref={accRef}>
          <ListItem className={pathname === "/dashboard" ? "active" : ""}>
            <Link to={"/dashboard"} onClick={() => toggleMobileMenu(false)}>
              <Typography>{pathname.startsWith("/dashboard") && <TrianglesIcon />}Dashboard</Typography>
            </Link>
          </ListItem>
          {pages?.map((page: any) => (
            <ListItem key={page?.link} className={pathname.startsWith(page.link) ? "active" : ""}>
              <Link to={page?.link} onClick={() => toggleMobileMenu(false)}>
                <Typography>
                  {pathname.startsWith(page.link) && <TrianglesIcon />} {page?.title}
                </Typography>
              </Link>
            </ListItem>
          ))}

          <ListItem>
            <Typography onClick={() => logout({ returnTo: window.location.origin })}>Logout</Typography>
          </ListItem>
        </MobileMenuItem>
        <MobileMenuItem title={"PPC Ads"} ref={ppcRef}>
          <ListItem className={isPPCOverviewPage ? "active" : ""}>
            <Link to={"/ppc/overview"} onClick={() => toggleMobileMenu(false)}>
              <Typography>{isPPCOverviewPage && <TrianglesIcon />}Overview</Typography>
            </Link>
          </ListItem>
          <ListItem className={isCampaignsTable ? "active" : ""}>
            <Link to={"/ppc/mycampaigns"} onClick={() => toggleMobileMenu(false)}>
              <Typography>{isCampaignsTable && <TrianglesIcon />}My campaigns</Typography>
            </Link>
          </ListItem>
        </MobileMenuItem>
        <MobileMenuItem title={"Data Mining"} ref={pixelRef}>
          <ListItem className={isDataminingOverview ? "active" : ""}>
            <Link to={"/data_mining/overview"} onClick={() => toggleMobileMenu(false)}>
              <Typography>{isDataminingOverview && <TrianglesIcon />}Overview</Typography>
            </Link>
          </ListItem>
          <ListItem className={isDataminingPixel ? "active" : ""}>
            <Link to={"/data_mining/pixel"} onClick={() => toggleMobileMenu(false)}>
              <Typography>{isDataminingPixel && <TrianglesIcon />}Pixel</Typography>
            </Link>
          </ListItem>
        </MobileMenuItem>
        <MobileMenuItem title={"Influencer Marketing"} disabled />
        <MobileMenuItem title={"PR Campaigns"} disabled />
      </Stack>
    </DialogContent>
  );
};

export default HeaderMobileMenu;
