import { styled, Switch } from "@mui/material";

export const CampaignSwitch = styled(Switch)`
  //.MuiSwitch-root {
  background-color: #fff;
  border-radius: 1.6rem;
  height: 3.1rem;
  width: 5.8rem;
  display: block;
  box-shadow: 0 1px 6px 0 rgba(255, 255, 255, 0.15);
  border: solid 1px #ddd;

  //}

  .Mui-disabled {
    opacity: .5;
  }

  .MuiSwitch-switchBase {
    transform: translate(-0.7rem, -0.8rem);

    &.Mui-checked {
      transform: translate(1.9rem, -0.8rem);
    }
  }

  .MuiSwitch-thumb {
    width: 2.6rem;
    height: 2.6rem;
    background-color: #f2a626;
    //transform: translate(-.5rem, -.6rem);
  }
`;
