import { ArrowForwardIos } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { channelIcons } from "../../assets/svg/channels";
import { TriangleSvg } from "../../assets/svg/interface";
// import Creative1 from "../../assets/img/creatives/creative1.webp";
// import BrandLogo from "../../assets/img/creatives/BrandLogo.webp";
import { FlexBox } from "../../styled/Layout";
import { TPublisher } from "../../types/reduxData/dataTypes";
import { AdContainer } from "./CreativeCard.styles";

type TChannel = "bitmedia" | "coinzilla";

type TResponsiveTemplate = {
  data: {
    image: string;
    title: string;
    description: string;
    logo: string;
    campaign: string;
    dUrl: string;
    channel: TPublisher;
  };
};

const ResponsiveTemplate = ({ data }: TResponsiveTemplate) => {
  // const {brandProfile} = useSelector((state) => state.campaign);
  // const {name, logo} = brandProfile;

  // if(!data) return null;

  return (
    <AdContainer sx={{ padding: 0 }} mt={"1rem"}>
      <Box className={"imageBox"}>{data.image && <img src={data.image} alt={"Creative"} />}</Box>
      <Box className="icon">
        <TriangleSvg />
      </Box>
      <Box className="infoBox">
        <Typography className="title">{data.title}</Typography>
        <FlexBox>
          <Box className="logo">
            <img src={data.logo} width="4rem" height="4rem" alt={"Logo"} />
          </Box>
          <Box flex={1}>
            <Typography className="respDesc">{data.description}</Typography>
            <Typography className="businessName">{data.dUrl}</Typography>
          </Box>
          <IconButton disabled disableRipple className="resp">
            <ArrowForwardIos />
          </IconButton>
        </FlexBox>
        <Box className="channelIcon">{channelIcons[data?.channel as TChannel]}</Box>
      </Box>
    </AdContainer>
  );
};

export default ResponsiveTemplate;
