import { Stack, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { channelsLogo } from "../../assets/img/channels/channelsLogo";
import { ContextViewMode } from "../../pages/Campaigns/ContextViewMode";
import { ExpandButton, expandButtonUp } from "../../styled/buttons";
import { getStringNumber } from "../../utils/calc";
import { usePathname } from "../../utils/hooks/usePathname";
import {
  BackgroundCreative,
  ChannelBox,
  ChannelImgBox,
  CreativeCardContainer,
  CreativeNumber,
  CreativeNumberText,
  CreativeTop,
  NumbersBox,
  SubtitleChannel,
  TitleChannel,
} from "./CreativeCard.styles";
import { creativeTemplates, TCretiveTemplateKey } from "./CreativeTemplates";
import CreativePopupMobile from "./CreativePopupMobile";
import {useBreakpoints} from "../../utils/hooks/useBreakpoints";

const CreativeCard = ({ data, setExpanded }: any) => {
  const { isCampaignPage } = usePathname();
  const {mobileLarge} = useBreakpoints()

  const [active] = useContext(ContextViewMode);
  const [popupOpenMob, setPopupOpenMob] = useState(false);
  const closePopup = () => setPopupOpenMob(false);

  const isChannelsComparison = active.campaign === 1;

  if (!data.type) return null;

  return (
    <>
      <CreativeCardContainer
        onClick={() => mobileLarge && isCampaignPage && setPopupOpenMob(true)}
      >
        <CreativeTop>
          <BackgroundCreative />
          <ChannelBox>
            {!isChannelsComparison ? (
              <>
                <ChannelImgBox>
                  <img src={channelsLogo[data.channel]} alt={"ch"} />
                </ChannelImgBox>
                <Stack>
                  <TitleChannel>
                    {isCampaignPage ? data.channel : data.campaign}
                  </TitleChannel>
                  <SubtitleChannel>
                    {isCampaignPage ? data.group_name : data.channel}
                  </SubtitleChannel>
                </Stack>
              </>
            ) : (
              <Typography className="groupName">{data.group_name}</Typography>
            )}
          </ChannelBox>
          <NumbersBox>
            {data.numbers.slice(0, 3).map((num: any, i: number) => (
              <Stack key={i}>
                <CreativeNumber>
                  {num.name === "spent"
                    ? `$${getStringNumber(num.value)}`
                    : getStringNumber(num.value)}
                </CreativeNumber>
                <CreativeNumberText>{num.name}</CreativeNumberText>
              </Stack>
            ))}
          </NumbersBox>

          {!!isCampaignPage && (
            <ExpandButton sx={expandButtonUp} onClick={setExpanded}>
              Expand
            </ExpandButton>
          )}
        </CreativeTop>
        {creativeTemplates[data.type as TCretiveTemplateKey]({ data })}
      </CreativeCardContainer>
      <CreativePopupMobile
        open={popupOpenMob}
        closePopup={closePopup}
        ad={creativeTemplates[data.type as TCretiveTemplateKey]({ data })}
        data={data}
      />
    </>
  );
};

export default CreativeCard;
