import {Button, Stack, styled, TableCell, TableHead, TextField, Typography} from "@mui/material";
import {FlexBox} from "../../styled/Layout";
import {LoadingButton} from "@mui/lab";
import theme from "../../theme/theme";
import Loading from "../../components/loading/Loading";

export const BillingContent = styled(FlexBox)`
  background-color: #FFFFFF;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 7rem;

  ${theme.breakpoints.down('mobL')} {
    padding: 0 3rem 7rem;

    &.deposit {
      padding: 0 0 7rem;
    }
  }
`

export const ButtonBilling = styled(Button)`
  margin-left: 1.4rem;
  border-radius: 6px;
  text-transform: unset;
  font-size: 1.7rem;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.19px;
  padding: 1.3rem 2.15rem;
  color: #8392A4;
  &:hover {
    border-color: #8392A4;
  }
  &.active {
    color: #FFFFFF;
    background-color: #8392A4;
    &:hover {
      color: #FFFFFF;
      background-color: #8392A4;
    }
  }
  border-color: #8392A4;
  ${theme.breakpoints.down('mobL')} {
    width: 100%;
    margin-left: 0;
    margin-bottom: 1.4rem;
  }
`

export const BillingButtonBox = styled(FlexBox)`
  margin-left: auto;
  ${theme.breakpoints.down('mobL')} {
    width: 100%;
    flex-direction: column;
    margin-top: 3.6rem;
  }
`

export const BillingInfoBox = styled(Stack)`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 70rem;
  padding: 3.5rem 9.5rem 9.7rem;
  border-radius: 1rem;
  box-shadow: 0 15px 18px 0 rgba(28, 41, 90, 0.14);
  background-color: #fff;
  align-items: center;
  justify-content: center;

  & > h3 {
    left: 4rem;
    top: 3.5rem;
    position: absolute;
  }

  ${theme.breakpoints.down('mobL')} {
    box-shadow: unset;
    padding: 2rem 0;
    min-height: fit-content;

    & > h3 {
      position: relative;
      left: 0;
    }

    &.details {
      & > h3 {
        align-self: flex-start;
        text-transform: unset;
        font-size: 2.57rem;
        line-height: 0.81;
        letter-spacing: 0.29px;
        color: rgba(36, 47, 87, 0.81);
      }
    }

    &.deposit {
      padding: 2rem 3rem;
      background-color: rgba(216, 216, 216, 0.21);

      & > h3 {
        font-family: 'F37 Judge', sans-serif;
        font-size: 2.7rem;
        font-weight: normal;
        font-style: normal;
        line-height: 1.11;
        letter-spacing: 1.35px;
        color: #242f57;
      }
    }
  }
`

export const InfoBoxContainer = styled(Stack)`
  width: 100%;
  margin-top: 9rem;

  button {
    width: fit-content;
  }

  ${theme.breakpoints.down('mobL')} {
    &.deposit {
      align-items: center;

      button {
        width: 100%;
        padding: 1.2rem;
        margin-bottom: 7rem;
      }

      h6 {
        font-size: 1.67rem;

        span {
          font-size: 1.67rem;
        }
      }
    }

    &.history {
      margin-top: 0;
    }
  }
`

export const InputLabel = styled(Typography)`
  margin-bottom: 1rem;

  &.info {
    margin-top: 5.5rem;
  }

  & span {
    font-size: 1.1rem;
    letter-spacing: 0.12px;
    color: #8494a8;
    margin-left: .5rem;
  }


`

export const BillingInput = styled(TextField)`
  width: 100%;
  margin-bottom: 2.8rem;
  background-color: #f7f7f7;

  & .MuiOutlinedInput-root.Mui-disabled {
    & .MuiOutlinedInput-notchedOutline {
      border-width: 0;
    }
  }

  & .MuiOutlinedInput-input {
    color: rgba(9, 27, 50, 0.53);
  }

  ${theme.breakpoints.down('mobL')} {
    &.deposit {
      background-color: #fafcfe;
    }

    & .MuiOutlinedInput-input {
      font-size: 1.8rem;
    }

  }
`

export const UserNameTitle = styled(Typography)`
  font-size: 2.3rem;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.72px;
  color: #242f57;
  margin-top: 2rem;
`

export const UserRoleTitle = styled(Typography)`
  font-size: 1.3rem;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.41px;
  color: rgba(36, 47, 87, 0.5);
  text-transform: uppercase;
  margin-top: 1.1rem;
`

export const InputAdornmentText = styled(Typography)`
  font-size: 1.54rem;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.17px;
  color: rgba(9, 27, 50, 0.31);

  ${theme.breakpoints.down('mobL')} {
    font-size: 1.9rem;
  }
`


export const HistoryTableHeader = styled(TableHead)`
  border-color: #979797;
  border-style: dashed;
  border-width: 1.5px;
  border-left: none;
  border-right: none;
`

export const HistoryTableCell = styled(TableCell)(({theme}) => ({
    padding: '2rem 0',
    fontSize: '1.6rem',
    lineHeight: 'normal',
    letterSpacing: '0.18px',
    fontWeight: 500,
    color: '#4a5767',
    textAlign: 'center',
    borderBottom: 'none',
    textTransform: 'capitalize',
    '&.header': {
        fontWeight: 600,
    },
    '&.value': {
        fontSize: '2rem',
        fontWeight: 'bold',
        letterSpacing: '0.22px',
    },
    '&.completed': {
        color: '#69913d'
    },
    '&.pending': {
        color: '#396a8c'
    },
    '&:not(:nth-of-type(odd))': {
        backgroundColor: 'rgba(216,216,216,0.17)',
    },
    '&:last-of-type': {
        width: '25%'
    },
    '&:not(:last-of-type)': {
        width: '15%'
    },
    [theme.breakpoints.down('mobL')]: {
        borderColor: '#979797',
        '&:last-of-type': {
            width: '35%',
            borderLeft: '1.5px dashed'
        },
        '&:first-of-type': {
            borderRight: '1.5px dashed'
        },
        '&:not(:last-of-type)': {
            width: '32.5%'
        },
    }
}));

export const ButtonInvoice = styled(LoadingButton)`
  min-width: 18rem;
  background-color: #e25858;
  color: #FFFFFF;
  text-transform: unset;

  &:hover {
    background-color: #e25858;
  }
  
  ${theme.breakpoints.down('mobL')} {
    min-width: fit-content;
    svg {
      margin-left: 0.5rem;
    }
  }
`

export const DepositInfoGroupBox = styled(Stack)`
  position: absolute;
  top: 3rem;
  right: 9rem;
  padding: 2rem 2.5rem;
  border-radius: 1rem;
  background-color: #f9f8f8;

  ${theme.breakpoints.down('mobL')} {
    position: relative;
    right: unset;
    top: 6rem;
    background-color: #FFFFFF;
  }
`

export const DepositInfoGroupText = styled(Typography)`
  color: rgba(9, 27, 50, 0.52);
  font-size: 1.22rem;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.14px;
  margin-bottom: 1rem;
  display: inline-flex;
  align-items: center;

  button {
    margin-left: .5rem;
    padding: 0;
  }
`

export const DepositInfoGroupNumber = styled(Typography)`
  color: #69913d;
  font-size: 3.65rem;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 1.14px;
`

export const DetailsButton = styled(LoadingButton)`
  box-shadow: unset;
  border-radius: 1rem;
  margin-top: 1rem;
`