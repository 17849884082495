import { Stack } from "@mui/material";
import { Box, styled } from "@mui/system";
import { mobileScreen } from "../../../../utils/breakpoints";

export const DataMiningPixelGuide = styled(Box)`
  position: relative;
  border-radius: 0.9rem;
  box-shadow: 0 12px 22px 0 rgba(0, 0, 0, 0.07);
  border-style: solid;
  border-width: 1px;
  border-image-source: linear-gradient(329deg, #fff 54%, #efefef 33%);
  border-image-slice: 1;
  background-image: linear-gradient(to bottom, #fff, #fff), linear-gradient(329deg, #fff 54%, #efefef 33%);
  background-origin: border-box;
  background-clip: content-box, border-box;

  width: 95%;
  margin-left: auto;
  overflow: hidden;

  .divider {
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    top: 6rem;
    background: repeating-linear-gradient(to right, transparent, transparent 3px, #979797 5px, #979797 10px);
  }

  ${mobileScreen} {
    border: solid 1px rgba(220, 220, 220, 0.67);
    box-shadow: none;
    width: 100%;
  }

  .MuiGrid2-container {
    .MuiGrid2-root {
      padding: 2.2rem 2.5rem;

      h4 {
        font-size: 1.6rem;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.18px;
        text-align: left;
        color: #4a5767;
        margin-bottom: 6rem;

        ${mobileScreen} {
          margin-bottom: 2rem;
        }
      }

      &:last-of-type {
        background-color: rgba(0, 0, 0, 0.07);
      }
    }
  }
`;

export const PixelCopyCode = styled(Box)`
  position: relative;
  border-radius: 0.8rem;
  border: solid 1px #e1e1e1;
  background-color: #fff;
  padding: 3rem;
  overflow: hidden;

  ${mobileScreen} {
    padding: 1rem;
  }

  p {
    width: 80%;
    word-break: break-all;
    ${mobileScreen} {
      width: 100%;
    }
  }

  > button {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #396a8c;
    border-radius: 0 0.8rem 0 0.8rem;
    padding: 1.2rem 2rem;
    border: none;

    font-size: 1.18rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-transform: none;
    text-decoration: underline;
    letter-spacing: 0.13px;
    text-align: left;
    color: #fff;

    &:hover {
      background-color: #396a8c;
      color: #fff;
      border: none;
      text-decoration: underline;
    }
  }
`;

export const DataMiningMobileButtons = styled(Stack)`
  margin-top: 5rem;
  display: none;

  ${mobileScreen}{
    display: flex;
  }

  button {
    border-radius: 3.26rem;
    padding: 1.5rem;
    border: solid 1px #04b78a;

    font-size: 2.32rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.26px;
    text-align: center;
    &:first-of-type {
      color: #fff;
      background-color: #04b78a;
      margin-bottom: 3rem;
    }
    &:last-of-type {
      color: #04b78a;
      background-color: #fff;
    }
  }
`;
