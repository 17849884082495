import {FormControl, MenuItem, Select, styled} from "@mui/material";
import theme from "../theme/theme";

const FormControlStyled = styled(FormControl)`
  & span {
    text-transform: capitalize;
    margin-left: .5rem;
  }
  & legend span {
    margin-left: 0;
    text-transform: capitalize;
  }

  em{
    font-style: normal;
  }
    margin-right: 2rem;

  ${theme.breakpoints.down('mobL')}{
    width: 100%;
    margin-right: 0;
  }
`

const Item = styled(MenuItem)`
  text-transform: capitalize;
  & span {
    text-transform: capitalize;
    margin-left: .5rem;
  }
  em{
    font-style: normal;
  }
  
  ${theme.breakpoints.down('mobL')}{
    &:first-of-type{
      min-height: unset;
    }
  }
`
type TSelectCustom = {
    value: string,
    setValue: (value: string) => void,
    placeholder?: string,
    data: string[],
    width?: string,
    disabled?: boolean
}
    export const SelectCustom = (props: TSelectCustom) => {
    const {value, setValue, placeholder, data, width, disabled} = props
    return (
        <FormControlStyled fullWidth sx={{width: `${width ? width : '25rem'}`}}>
            <Select
                id={value}
                displayEmpty
                value={value}
                onChange={(e) => setValue(e.target.value as string)}
                renderValue={(selected) => {
                    if (selected?.length === 0) {
                        return <em>{placeholder}</em>;
                    }
                    return selected;
                }}
                disabled={disabled}
                className={'swiper-no-swiping'}
            >
                <Item disabled value="" >
                    <em>{placeholder}</em>
                </Item>
                {data?.map((option) => (
                    <Item value={option} key={option} className={'swiper-no-swiping'}>{option}</Item>
                ))}
            </Select>
        </FormControlStyled>
    )
    }
