import { Box, CircularProgress } from "@mui/material";
import React from "react";

type TBlockContent = {
  loading?: boolean;
  minHeight?: string;
  children: any;
};

const BlockContent = ({ loading = false, minHeight='auto', children }: TBlockContent) => {
  if (loading)
    return (
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", padding: '3rem', minHeight}}>
        <CircularProgress />
      </Box>
    );

  return children;
};

export default BlockContent;
