import {DialogContent, IconButton, Stack} from "@mui/material";
import {ClosePopupIcon} from "../../../assets/svg/icons";
import {
    GeneralSettingsContent,
    GeneralSettingsPopupContainer,
    MainTitle,
    SecondTitle,
    SmallText,
    SvgContainer,
    ArrowContainer
} from "./connectYourWalletPopup.styles";
import {ConnectYourWallet} from "../../../assets/svg/GeneralSettingsPopupSvg";
import {LinkSignUp} from "../../../utils/constants";
import BigArrowImage from '../../../assets/img/GeneralSettingsBigArrow.webp'
import {TransparentButton} from "../../../styled/buttons";

export const ConnectYourWalletPopup = ({closePopup}: { closePopup: () => void }) => {
    return (
        <GeneralSettingsPopupContainer open onClose={closePopup}>
            <ArrowContainer className={'desktopOnly'}>
                <img src={BigArrowImage} alt={'arrow'}/>
            </ArrowContainer>
            <DialogContent sx={{p: "0", width: '68rem'}}>
                <IconButton className="close" onClick={closePopup}>
                    <ClosePopupIcon/>
                </IconButton>
                <GeneralSettingsContent>
                    <SvgContainer>
                        <ConnectYourWallet/>
                    </SvgContainer>

                    <Stack zIndex={2}>
                        <MainTitle>
                            {'LAST STEP BEFORE YOU START !'}
                        </MainTitle>
                        <SecondTitle>
                            {'PLEASE CONNECT YOUR WALLET'}
                        </SecondTitle>
                        <SmallText>
                            {'Make sure you hold Web3m NFT in your wallet.'}
                        </SmallText>
                        <SmallText>
                            {'Still doesn\'t own Web3m NFT?!'}<br/>
                            <a href={LinkSignUp}>{'Click here'}</a>
                            {' to mint yours'}<br/>
                            {/*{'or'}<br/>*/}
                            {/*{'learn more '}*/}
                            {/*Link to page NFT*/}
                            {/*<a href={''}>{'here.'}</a>*/}
                        </SmallText>
                    </Stack>
                    <TransparentButton className={'mobileOnly'} onClick={closePopup}>Ok</TransparentButton>
                </GeneralSettingsContent>
            </DialogContent>
        </GeneralSettingsPopupContainer>
    )
}