//Libraries
import { Typography } from "@mui/material";
//Components
import { ExpandButton } from "../../styled/buttons";
import MonitorTable from "../Tables/MonitorTable";
import { CheckboxGroups } from "../CheckboxGroups/checkboxGroups";
import MobileStatisticsTable from "../Tables/MobileStatisticsTable";
import BlockContent from "../../layout/blockContent/BlockContent";

//Styles
import { BlockContainer, FlexBox } from "../../styled/Layout";
//Hooks
import { useWebsites } from "./useWebsites";
import { usePathname } from "../../utils/hooks/usePathname";
import { useLoading } from "../../utils/hooks/useLoading";
import {useBreakpoints} from "../../utils/hooks/useBreakpoints";

type TWebsites = {
  adId?: string | null;
};

const Websites = ({ adId = null }: TWebsites) => {
  const { websites, active, selectedGroups, setSelectedGroups } = useWebsites(adId);
  // if (!websites.length) return null;
  const { isCampaignPage } = usePathname();
  const isLoading = useLoading(isCampaignPage ? "campaign" : "target_info");
  const {mobileLarge} = useBreakpoints()

  return (
    <BlockContainer>
      <FlexBox mb={active.campaign === 1 ? "3rem" : "0"} flexDirection={{xs: 'column', mobL: 'row'}} sx={{alignItems: 'flex-start'}} rowGap={'2rem'}>
        <Typography variant="h3">{"Websites"}</Typography>
        {active.campaign === 1 && !adId && (
          <CheckboxGroups selectedGroups={selectedGroups} setSelectedGroups={setSelectedGroups} />
        )}
      </FlexBox>
      <BlockContent loading={isLoading}>
          {!mobileLarge && <MonitorTable data={websites}/>}
          {mobileLarge && <MobileStatisticsTable data={websites}/>}
        <ExpandButton>Expand</ExpandButton>
      </BlockContent>
    </BlockContainer>
  );
};

export default Websites;
