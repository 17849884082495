import {FlexBox} from "../../styled/Layout";
import {CheckboxWithLabel} from "../../styled/checkboxes/checkbox";
import {useContext, useEffect, useState} from "react";
import {TObjectBoolean} from "../../types/GeneralTypes";
import {ContextCheckbox} from "./ContextCheckbox";
import {TAd} from "../../types/reduxData/dataTypes";
import {useBreakpoints} from "../../utils/hooks/useBreakpoints";
import {SelectCustom} from "../../styled/select";

// const data: number[] = Array(4).fill(1)

type TCheckboxGroup = {
    main?: boolean,
    selectedGroups?: TObjectBoolean,
    setSelectedGroups?: (value: TObjectBoolean) => void
}

const formatData = (data: TAd[]) => {
    const tmp: TObjectBoolean = {}
    data.forEach((el) => tmp[el.group_name] = true)
    // console.log(tmp)
    return tmp
}
export const CheckboxGroups = ({main = false, selectedGroups = {}, setSelectedGroups}: TCheckboxGroup) => {
    //Checkboxes and filtered data for all campaign
    const [mainSelected, setMainSelected, filteredData,, channelRadio] = useContext(ContextCheckbox)
    const {mobileLarge} = useBreakpoints();
    // console.log(selectedGroups)

    useEffect(() => {
        if (main) setMainSelected(formatData(filteredData))
        setSelectedGroups && setSelectedGroups(formatData(filteredData))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        //set new selected checkboxes when the channel changed
        setMainSelected(formatData(filteredData))
    },[channelRadio])

    useEffect(() => {
        //set selected group for all block when the main checkboxes changed
        setSelectedGroups && setSelectedGroups(mainSelected)
    }, [mainSelected])


    const handleChange = (name: string) => {
        //selecting of checkbox
        if(main) {
            setMainSelected({
                ...mainSelected,
                [name]: !mainSelected[name]
            })
        }
        setSelectedGroups && setSelectedGroups({
                ...selectedGroups,
                [name]: !selectedGroups[name]
            })
    }

    const selectValue = Object.entries(selectedGroups).find(el => el[1]);

    const handleChooseGroup = (newValue: string) => {
        const newData: TObjectBoolean = {...selectedGroups};
        for (const [key, value] of Object.entries(selectedGroups)) {
            if(newValue === key){
                newData[key] = true
            } else{
                newData[key] = false
            }
        }
        // console.log(selectedGroups)
        // console.log(newData)
        setSelectedGroups && setSelectedGroups(newData)

    }

    if(mobileLarge && selectValue?.length) return (
        <SelectCustom value={selectValue[0]} setValue={handleChooseGroup} data={Object.keys(selectedGroups)}/>
    )
    return (
        <FlexBox marginLeft={'auto'} width={'fit-content'} alignSelf={'center'}>
            {Object.keys(formatData(filteredData)).map((gr, i) => {
                const group = filteredData?.find((el) => el.group_name === gr)
                return <CheckboxWithLabel label={group!.group_name}
                                   name={group!.group_name}
                                   key={group!.group_id}
                                   checked={main ? mainSelected[group!.group_name] : selectedGroups[group!.group_name]}
                                   onChange={(e) => handleChange(e.target.name)}
                />
            })}
        </FlexBox>
    )
}