import Web3 from "web3";

export async function connectWallet() {
    // @ts-ignore
    if (window.ethereum) {
        // @ts-ignore
        await window.ethereum.request({ method: "eth_requestAccounts" });

        // @ts-ignore
        window.web3 = new Web3(window.ethereum);
        // @ts-ignore
        const account = window.web3.eth.accounts;
        // @ts-ignore
        // console.log(window.ethereum.isMetaMask)
        //Get the current MetaMask selected/active wallet
        const walletAddress = account.givenProvider.selectedAddress;
        // @ts-ignore
        const balance = await window.web3.eth.getBalance(walletAddress);

        // console.log(window.web3.eth);

        // console.log(`Wallet: ${walletAddress}`);
        // console.log(`Balance: ${balance}`);
        return walletAddress;
    } else {
        console.log("No wallet");
    }
}
