//Core
import {Link, useLocation, useNavigate} from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
// Styles
import {
  Avatar,
  IconButton,
  ListItem,
  Toolbar,
  Typography,
  Stack,
  useMediaQuery,
  Button,
  Box,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  CreateCampaignLink,
  HeaderBalance,
  HeaderContainer,
  HeaderNavigation,
  HeaderOffset,
  HeaderPlusButton,
  HeaderUserData,
  HeaderUserDropdownMenu,
  HeaderUserMenu,
  LogOutButton,
  MobileMenuContainer,
  ThreeDotsHeader,
} from "./Header.styles";
import { FlexBox } from "../../styled/Layout";
//Hooks
import { userSelector } from "../../utils/selectors";
import { useAuth } from "./useAuth";
import { useHeaderData } from "./useHeaderData";
// Images
import { ArrowDownIcon, BellIcon } from "../../assets/svg/icons";
// Utils
// import {pages} from "./HeaderData";
import { appLink } from "../../utils/constants";
import theme from "../../theme/theme";
import HeaderMobileMenu from "./components/HeaderMobileMenu";
import { useBreakpoints } from "../../utils/hooks/useBreakpoints";
import { Logo } from "../../assets/svg/logo";

type THeader = {
  offset: string;
};

const Header = ({ offset }: THeader) => {
  const { pathname } = useLocation();
  const { loginWithPopup, logout, user, isAuthenticated } = useAuth0();
  const { hasCampaigns, hasDrafts, userData } = useSelector(userSelector);
  const { tabletResolutions } = useBreakpoints();
  const navigate = useNavigate()
  useAuth();

  const { pages, mobileMenuOpen, toggleMobileMenu, userMenuOpen, setUserMenuOpen, balance, currency } = useHeaderData();

  const userName = userData.first_name.length || userData.last_name.length ? `${userData.first_name} ${userData.last_name}` : " ";
  const filteredPages = hasCampaigns ? pages : pages.filter((el) => ["General Settings", "Finances", "Support"].includes(el.title));
  const noWallet = !userData?.wallet_address || userData?.wallet_address === "" || !userData?.has_nft;

  return (
    <>
      <>
        <HeaderContainer elevation={0} sx={{ pl: { mobL: offset } }} className={mobileMenuOpen ? "mobOpen" : ""}>
          <Toolbar>
            <CreateCampaignLink href={appLink} target={"app"} className={`${noWallet ? "disabled" : ""} desktopOnly`}>
              <Typography>+ CREATE NEW CAMPAIGN</Typography>
            </CreateCampaignLink>
            {!mobileMenuOpen ? (
              <IconButton className={"mobileOnly logo"}>
                <Logo />
              </IconButton>
            ) : (
              <HeaderUserData className={"mobileOnly"}>
                <Avatar src={user?.picture}/>
                <Stack>
                  <Typography variant="h4">{userName}</Typography>
                  <Typography>Admin</Typography>
                </Stack>{" "}
              </HeaderUserData>
            )}
            <FlexBox columnGap={"1.7rem"} className={"desktopOnly"} sx={{display: isAuthenticated ? 'flex' : "none"}}>
              <HeaderBalance>
                <Stack>
                  <Typography variant={"h5"}>Balance</Typography>
                  <Typography>{currency}{balance}</Typography>
                </Stack>
                <Button startIcon={<span>+</span>} onClick={() => navigate('/billing/deposit_withdraw')}>Add funds</Button>
              </HeaderBalance>
              <HeaderUserMenu className={"desktopOnly"} onClick={() => setUserMenuOpen(!userMenuOpen)}>
                <HeaderUserData>
                  <Avatar src={user?.picture}/>
                  <Stack>
                    <Typography variant="h4">{userName}</Typography>
                    <Typography>Admin</Typography>
                  </Stack>
                  <ArrowDownIcon className={`arrow ${userMenuOpen ? "open" : ""}`} />
                  <HeaderUserDropdownMenu className={!userMenuOpen ? "hidden" : ""}>
                    {pages.map((page) => (
                      <ListItem key={page.link}>
                        <Link to={page.link} className={page.disabled ? 'link-disabled' : ''}>
                          <ListItemIcon>
                            <Box />
                          </ListItemIcon>
                          <ListItemText>{page.title}</ListItemText>
                        </Link>
                      </ListItem>
                    ))}

                    <ListItem>
                      {isAuthenticated ? (
                        <div className={"authBtn"} onClick={() => logout({ returnTo: window.location.origin })}>
                          <ListItemIcon>
                            <Box />
                          </ListItemIcon>
                          <ListItemText>Logout</ListItemText>
                        </div>
                      ) : (
                        <div className={"authBtn"} onClick={loginWithPopup}>
                          <ListItemIcon>
                            <Box />
                          </ListItemIcon>
                          <ListItemText>Login</ListItemText>
                        </div>
                      )}
                    </ListItem>
                  </HeaderUserDropdownMenu>
                </HeaderUserData>
              </HeaderUserMenu>
            </FlexBox>

            {!mobileMenuOpen && (
              <a className={"mobileOnly"} href={appLink} style={{ transform: "translateX(30%)" }} target={"app"}>
                <HeaderPlusButton>+</HeaderPlusButton>
              </a>
            )}

            <ThreeDotsHeader open={mobileMenuOpen} handleOpen={toggleMobileMenu} />
          </Toolbar>
          {mobileMenuOpen && (
            <HeaderBalance className={"mobileOnly"}>
              <Stack>
                <Typography variant={"h5"}>Balance</Typography>
                <Typography>{currency}{balance}</Typography>
              </Stack>
              <Button startIcon={<span>+</span>} onClick={() => {
                navigate('/billing/deposit_withdraw')
                toggleMobileMenu()
              }}>Add funds</Button>
            </HeaderBalance>
          )}
        </HeaderContainer>
        <HeaderOffset />
        <MobileMenuContainer open={mobileMenuOpen} className={'mobileOnly'}>
          <HeaderMobileMenu pages={pages} toggleMobileMenu={toggleMobileMenu} />
        </MobileMenuContainer>
      </>
    </>
  );
};

export default Header;
