import { Grid, Skeleton, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import ComingSoon from "../../../../components/ComingSoon/ComingSoon";
import { BlockContainer } from "../../../../styled/Layout";
import { WhatsNewTextSkeleton } from "./WhatsNew.styles";
import SectionSlide from "../../../../layout/sectionSlide/SectionSlide";

const WhatsNew = () => {
  return (
    <BlockContainer sx={{ height: "fit-content" }}>
      <ComingSoon />
      <Typography variant="h3" mb={"2.9rem"} mt={{ xs: "3rem", mobL: 0 }}>
        What's new in PPC?
      </Typography>
      <Stack sx={{ height: "fit-content !important", opacity: "1 !important", visibility: "visible !important" }}>
        <WhatsNewTextSkeleton width={"40%"} />
        <WhatsNewTextSkeleton width={"80%"} />
        <WhatsNewTextSkeleton width={"70%"} sx={{ mb: "4.7rem" }} />
        <WhatsNewTextSkeleton width={"100%"} />
        <WhatsNewTextSkeleton width={"100%"} sx={{ mb: "2.9rem" }} />
        <Grid container spacing={"3.2rem"}>
          <Grid item xs={6}>
            <WhatsNewTextSkeleton width={"100%"} sx={{ height: "30rem" }} />
          </Grid>
          <Grid item xs={6}>
            <WhatsNewTextSkeleton width={"100%"} sx={{ height: "30rem" }} />
          </Grid>
        </Grid>
      </Stack>
    </BlockContainer>
  );
};

export default WhatsNew;
