import TransparentPopup from "../../../components/popups/TransparentPopup";
import {FlexBox} from "../../../styled/Layout";
import {SubtitleChannel, TitleChannel} from "../../../components/CreativeCard/CreativeCard.styles";
import {Stack, Typography} from "@mui/material";
import React, {ReactNode} from "react";
import {ComparisonCombinedNameMob} from "../../../components/AdsComparison/AdsComparison.styles";
import {AdsComparisonMobile} from "../../../components/AdsComparison/AdsComparisonMobile";
import {AdBoxPopup, ComparisonImageBox} from "./ComparisonPopup.styles";
import {TBrand} from "../../../types/AdsComparisonTypes";

type TProps = {
    closePopup: () => void,
    brandData: TBrand,
    ad?: ReactNode,
    comparison: string,
    parameter: string,
    graphData: { [p: string]: string | number }[],
    brandCheckbox: { [p: string]: TBrand },
}
export const ComparisonPopup = ({closePopup, brandData, ad, comparison, parameter, graphData, brandCheckbox}: TProps) => {
    return (
        <TransparentPopup open closePopup={closePopup}>
            <Stack mt={"7rem"}>
                <FlexBox mb={"5vh"}>
                    <ComparisonImageBox>
                        <img src={brandData.image} alt={"ch"}/>
                    </ComparisonImageBox>
                    <Stack>
                        <TitleChannel>{brandData.name}</TitleChannel>
                        {brandData.link &&
                            <SubtitleChannel sx={{textTransform: comparison === 'campaign' ? 'lowercase !important' : 'capitalize'}}>
                                {brandData.link}
                            </SubtitleChannel>
                        }

                    </Stack>
                </FlexBox>
                <AdBoxPopup>{ad}</AdBoxPopup>
                <ComparisonCombinedNameMob className={'popup'}>
                <Typography>{comparison} {parameter}</Typography>
                <span/>
            </ComparisonCombinedNameMob>
                {!!graphData.length && <AdsComparisonMobile graphData={graphData} brandCheckbox={brandCheckbox} popup/>}
            </Stack>
        </TransparentPopup>
    )
}