import { createSlice } from "@reduxjs/toolkit";
import {TCampaignData, TCampaignListItem, TComparisonCampaignData} from "../../types/reduxData/campaignsTypes";

type TInitialState = {
  list: TCampaignListItem[];
  data: TCampaignData;
  comparisonCampaign: TComparisonCampaignData
};

const initialState: TInitialState = {
  list: [],
  data: {},
  comparisonCampaign: {}
};

const campaignsSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    setCampaignListData: (state: TInitialState, { payload }) => {
      state.list = payload;
    },
    clearCampaignData: (state: TInitialState) => {

    },
    setCampaignData: (state: TInitialState, { payload }) => {
      state.data[payload.id as string] = payload.data.ad_list;
    },
    setComparisonData: (state: TInitialState, { payload }) => {
      state.comparisonCampaign = {
        ...state.comparisonCampaign,
        ...payload
      }
    }
  },
});

export const { setCampaignListData, setCampaignData, setComparisonData } = campaignsSlice.actions;

export default campaignsSlice.reducer;
