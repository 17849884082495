import { Button, DialogContent, IconButton, Input, OutlinedInput, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useState } from "react";
import { withdrawBudget } from "../../../api/statisticsRequests";
import { ClosePopupIcon, WithdrawIcon } from "../../../assets/svg/icons";
import { FlexBox } from "../../../styled/Layout";
import { PopupButtons, PopupContainer, PopupContent, PopupInputContainer } from "../Popup.styles";

const WithdrawPopup = ({ closePopup, campaignId, remain }: any) => {
  const [input, setInput] = useState(remain);

  const handleSetInput = (e: any) => {
    const { value } = e.target;
    setInput(value);
  };

  const handleWithdrawClick = async () => {
    const res = await withdrawBudget(campaignId, input);
    console.log(res);
    if (res) closePopup();
  };

  return (
    <PopupContainer open onClose={closePopup}>
      <DialogContent sx={{ p: "1rem 10rem" }}>
        <IconButton className="close" onClick={closePopup}>
          <ClosePopupIcon />
        </IconButton>
        <PopupContent>
          <WithdrawIcon width={"5.3rem"} />
          <Stack>
            <Typography variant="h3">
              Withdraw budget - <b>${remain ?? 0}</b> available
            </Typography>
            <Typography>
              Enter the amount you want to withdraw. After confirmation, this amount will be transferred to your wallet within 48
              hours.
            </Typography>
          </Stack>
        </PopupContent>
        <PopupInputContainer>
          <Typography>Amount to withdraw</Typography>
          <FlexBox>
            <Typography className="currency">$</Typography>
            <OutlinedInput type="number" placeholder="0" inputProps={{ max: remain }} value={input} onChange={handleSetInput} />
          </FlexBox>
        </PopupInputContainer>
        <PopupButtons>
          <Button disabled={input > remain} onClick={handleWithdrawClick}>
            Withdraw
          </Button>
          <Button onClick={closePopup}>Cancel</Button>
        </PopupButtons>
      </DialogContent>
    </PopupContainer>
  );
};

export default WithdrawPopup;
