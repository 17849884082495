import {useContext, useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { TGadget } from "../../types/reduxData/dataTypes";
import { overviewSelector } from "../../utils/selectors";
import { sortSliceForTable } from "../../utils/sortSliceForTable";
import { ContextCheckbox } from "../CheckboxGroups/ContextCheckbox";
import {ContextViewMode} from "../../pages/Campaigns/ContextViewMode";
import {TObjectBoolean} from "../../types/GeneralTypes";
import { usePathname } from "../../utils/hooks/usePathname";

export const useWebsites = (adId: string | null) => {
  // const { websites } = useSelector(overviewSelector);
  const { isCampaignPage } = usePathname();
  const [,, filteredByChannels] = useContext(ContextCheckbox);
  const [active] = useContext(ContextViewMode);
  // const websitesSort = websites ? sortSliceForTable(websites as TGadget[]) : [];
  //Groups checkboxes
  const [selectedGroups, setSelectedGroups] = useState<TObjectBoolean>({})
  //filtered data
  const [campData, setCampData] = useState<TGadget[]>([])

  useEffect(() => {
    const selected = Object.entries(selectedGroups).filter(([_, value]) => value).map(([key, _]) => key)
    const groupsFilterData = active.campaign === 1
        ? filteredByChannels?.filter((el) => selected.includes(el.group_name))
        : filteredByChannels
    const tmp = groupsFilterData?.reduce((acc: any, next) => {
      if (!next) return [];

      const newAcc = [...acc];
      next.websites.forEach((ws) => {
        const accInd = acc.findIndex((el: any) => el.name === ws.name);

        if (accInd === -1) {
          newAcc.push(ws);
        } else {
          const summedData = {
            total_amount_spent: newAcc[accInd].total_amount_spent + ws.total_amount_spent,
            total_clicks: newAcc[accInd].total_clicks + ws.total_clicks,
            total_impressions: newAcc[accInd].total_impressions + ws.total_impressions,
          };

          newAcc.splice(accInd, 1, { ...newAcc[accInd], ...summedData });
        }
      });
      return newAcc;
    }, []);
    setCampData(tmp)
  },[selectedGroups, filteredByChannels])

  // const campData = filteredByChannels.reduce((acc: any, next) => {
  //   const newAcc = { ...acc };

  //   next.websites.forEach((ws) => {
  //     console.log(newAcc);
  //     newAcc[ws.name] = {
  //       total_amount_spent: acc[ws.name]?.total_amount_spent || 0 + ws.total_amount_spent,
  //       total_clicks: acc[ws.name]?.total_clicks || 0 + ws.total_clicks,
  //       total_impressions: acc[ws.name]?.total_impressions || 0 + ws.total_impressions,
  //     };
  //   });

  //   return newAcc;
  // }, {});


  const adData = filteredByChannels?.find((el) => el.ad_id === adId)?.websites || [];
  const websites = adId ? sortSliceForTable(adData) :
      // isCampaignPage ?
          sortSliceForTable(campData)
          // : websitesSort

  return {
    websites,
    active,
    selectedGroups,
    setSelectedGroups,
  };
};
