import {userSelector} from "../../../utils/selectors";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {currencyList} from "../../../utils/currencyList";
import {checkHistory, getAvailableBalance} from "../../../api/campaignsRequests";
import {store} from "../../../redux/store";
import {setBillingHistory} from "../../../redux/user/userSlice";
import {format} from "date-fns";

type TTableData = {
    action: string,
    value: number,
    currency: string,
    date: string,
    time: string,
    status: string,
    invoice: string
}
export const useBillingHistory = () => {
    const {budget_info: {billing_history}, id} = useSelector(userSelector)
    const [history, setHistory] = useState<TTableData[]>([])

    const headerTable = ['action', 'value', 'date', 'time', 'status', 'invoice']
    const headerTableMobile = ['value', 'date', 'invoice']

    const dispatch = useDispatch()

    useEffect(() => {
        if (billing_history) {
            const historySorted: TTableData[]  = [...billing_history].map((el) => {
                const date = new Date(el.date)
                return {
                action: el.action,
                value: el.amount,
                currency: currencyList[el.currency.toUpperCase()] ?? el.currency,
                // date: el.date.split(' ')[0].split('-').reverse().join('/'),
                // time: el.date.split(' ')[1],
                date: format(date, 'dd/MM/yyyy'),
                time: format(date.getTime(), 'HH:mm:ss'),
                status: el.status.toLowerCase(),
                invoice: el.invoice
            }})
            setHistory(historySorted)
        }
    },[billing_history])

    const check = async () => {
        const pending = store.getState().user.budget_info?.billing_history?.find((el) => el.status.toLowerCase() === 'pending' || el.status.toLowerCase() === 'new')
        const completeList = store.getState().user.budget_info?.billing_history?.filter((el) => el.status.toLowerCase() === 'completed')
        // console.log('check')
        if (pending && id) {
            const response = await checkHistory(id)
            response && dispatch(setBillingHistory(response))
            const responseCompleteList = response?.filter((el) => el.status.toLowerCase() === 'completed') ?? []
            if (completeList?.length !== responseCompleteList?.length) {
                await getAvailableBalance()
            }
        }

    }

    useEffect(() => {
        id && checkHistory(id).then((response) => {
            // console.log('first')
            response && dispatch(setBillingHistory(response))
        })
        let count = 0
        const checkForStatusUpdate = setInterval(async () => {
            await check()
        }, 10000);
        return () => {
            clearInterval(checkForStatusUpdate);
        };
    }, [id]);

    const downloadFile = (file: string) => {
        const a: any = document.createElement("a"); //Create <a>
        a.href = file; //Image Base64 Goes here
        a.target = '_blank'
        a.download = "Invoice.pdf"; //File name Here
        // a.dispatchEvent(new MouseEvent('click'))
        if ( navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
            document.location = a.href;
            // window event not working here
        }else {
            const evt = new MouseEvent('click', {
                'view': window,
                'bubbles': true,
                'cancelable': false
            });
            a.dispatchEvent(evt);
        }
            // a.click(); //Downloaded file
    }

    return {
        history,
        headerTable,
        headerTableMobile,
        downloadFile
    }
}