import { RabbitTipContainer, TurnOffButton } from "./RabbitTip.styles";
import { Typography } from "@mui/material";
import {TipDialog, TipDialogChannel, TipDialogWhite, TipRabbitIcon} from "./RabbitTip.icons";
import { CrossButton, ThreeDotsButton } from "../../styled/buttons";
import { ReactNode, useState } from "react";
import {AlertIcon} from "../../assets/svg/icons";

type TRabbitTip = {
  text: string;
  light?: boolean;
  variant?: string;
  channel?: boolean;
  // onMenuClick?: Function;
};
const RabbitTip = ({ text, light=false, channel=false, variant='default' }: TRabbitTip) => {
  const [display, setDisplay] = useState("text");
  const [fullText, setFullText] = useState(false);

  const handleClick = () => {
    setDisplay(display === "text" ? "button" : "text");
  };

  const contentList: { [key: string]: ReactNode } = {
    text: (
      <Typography>
        {fullText ? text : text.slice(0, 200)}{" "}
        {!fullText && text.length > 200 && (
          <>
            ... <span onClick={() => setFullText(!fullText)}>Read more</span>
          </>
        )}
        {fullText && text.length > 200 && (
          <>
            <span onClick={() => setFullText(!fullText)}>Hide</span>
          </>
        )}{" "}
      </Typography>
    ),
    button: <TurnOffButton>Turn off</TurnOffButton>,
  };

  return (
    <RabbitTipContainer className={`${light ? "light" : channel ? 'channel' : ''} ${variant}`}>
      {variant === 'error'
          ? <AlertIcon className={'alertIcon'}/>
          : <TipRabbitIcon channel={'channel'}/>
      }
      {display === "button" ? (
        <CrossButton onClick={handleClick} className={"cross"} />
      ) : (
        <ThreeDotsButton onClick={handleClick} className={"dots"} />
      )}
      {light ? <TipDialogWhite/> : channel ? <TipDialogChannel/> : <TipDialog />}
      {contentList[display]}
      {/*<Typography>{text}</Typography>*/}
      {/*<TurnOffButton>Turn off</TurnOffButton>*/}
    </RabbitTipContainer>
  );
};

export default RabbitTip;
