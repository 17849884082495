import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { calcCampaignProgress } from "../../utils/calc";
import {updateCampaignStatus} from "../../api/campaignsRequests";
import {campaignsSelector} from "../../utils/selectors";

export const NUM_ITEMS_ON_PAGE = 7;

export type TCampaign = {
    id: string;
    title: string;
    logo: string;
    url: string;
    status: string;
    next_status: string;
    prev_status: string;
    progress: number;
    spent: number;
    remain: number;
    startDate: string;

}
export const useCampaignTable = () => {
  const { list } = useSelector(campaignsSelector);
  const [popup, setPopup] = useState("");
  //Pagination
  const [activePage, setActivePage] = useState(0);
  //Table data
  const [campaignsTable, setCampaignsTable] = useState<TCampaign[]>([]);

  useEffect(() => {
      const campaignList: TCampaign[] = !list?.length
          ? []
          : list.map((el) => ({
              id: el.campaign_id,
              title: el.campaign_name,
              logo: el.campaign_logo,
              url: el.campaign_url,
              status: el.campaign_status.status,
              next_status: el.campaign_status.status_obj?.next_status || '',
              prev_status: el.campaign_status.status_obj?.prev_status || '',
              progress: calcCampaignProgress(el.campaign_start_date, el.campaign_end_date),
              spent: el.spent || 0,
              remain: el.balance || 0,
              startDate: el.campaign_start_date,
          }));
      campaignsTable.length !== campaignList.length && setCampaignsTable(campaignList);
  },[list])

  const updateStatus = async (nextStatus: string, prevStatus: string, id: string) => {
    const response = await updateCampaignStatus(id, nextStatus, prevStatus, []);
    if (response?.status === "success") {
      const newCampaignList = campaignsTable.map((el) => {
        if (el.id === id) {
          return {
            ...el,
            prev_status: el.status,
            next_status: nextStatus,
            status: 'waiting',
          };
        }
        return el;
      });
      setCampaignsTable(newCampaignList);
    }
  };

  const closePopup = () => setPopup("");


  return {
    activePage,
    setActivePage,
    popup,
    closePopup,
    updateStatus,
    campaigns: campaignsTable,
    paginationDots: Math.ceil(campaignsTable.length / NUM_ITEMS_ON_PAGE)
  };
};
