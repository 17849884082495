import {useDispatch, useSelector} from "react-redux";
import {userSelector} from "../../utils/selectors";
import {useEffect, useState} from "react";
// import {checkPayment} from "../../api/web3Requests";
import {setBillingHistory, setUserField} from "../../redux/user/userSlice";
import {currencyList} from "../../utils/currencyList";
import {checkHistory, getAvailableBalance, getUser} from "../../api/campaignsRequests";


export const useHeaderData = () => {
    const {hasCampaigns, hasDrafts, userData, id, user_balance} = useSelector(userSelector);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const {has_nft} = userData;
    const [balanceView, setBalance] = useState<number>(0)
    const [currency, setCurrency] = useState<string>("USD")

    const dispatch = useDispatch()

    const [userMenuOpen, setUserMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    }

    const checkBalance = async () => {
        // const response = await getUser(id)
        const response = await getAvailableBalance()
        // console.log(response)
        if (response?.user_balance && response.user_balance !== user_balance) {
            setBalance(response.user_balance)
            // setCurrency(response.budget_info.currency)
        }
    }

    useEffect(() => {
        id && checkBalance()
        // const checkForStatusUpdate = setInterval(async () => {
        //     await checkBalance()
        // }, 5000);
        // return () => {
        //     clearInterval(checkForStatusUpdate);
        // };
    }, [id]);


    const pages = [
        // { title: "Overview", link: "/overview", disabled: !has_nft || !hasCampaigns },
        // { title: "Dashboard", link: "/dashboard", disabled: !has_nft || !hasCampaigns },
        // { title: "My campaigns", link: "/mycampaigns", disabled: (!hasCampaigns && !hasDrafts) || !has_nft },
        {title: "General Settings", link: "/settings"},
        {title: "Billing center", link: "/billing", disabled: !has_nft},
        {title: "Support", link: "/support"},
    ]

    useEffect(() => {
        user_balance && user_balance !== balanceView && setBalance(user_balance)
    }, [user_balance])


    return {
        pages,
        mobileMenuOpen,
        toggleMobileMenu,
        userMenuOpen,
        setUserMenuOpen,
        balance: balanceView,
        currency: currencyList[currency] ?? currency
    }
}