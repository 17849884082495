import { styled, Box, Typography, Button } from "@mui/material"

export const ImageMetamaskBox = styled(Box)`
  max-width: 35rem;
  width: 100%;
  img {
    width: 100%;
  }
`

export const TitleMetamask = styled(Typography)`
  font-size: 2.8rem !important;
  font-weight: 600;
  color: #091B32;
  letter-spacing: 0.31px;
  line-height: 1.14;
  margin-top: 2.8rem;
`

export const InfoMetamask = styled(Typography)`
  font-size: 1.4rem !important;
  line-height: 1.6;
  letter-spacing: 0.16px;
  color: #091b32;
  margin-top: 1.4rem;
  max-width: 80%;
`

export const ButtonMetamask = styled(Button)`
  border-radius: 3rem;
  color: #FFFFFF;
  background-color: #ec8b17;
  box-shadow: unset;
  width: 24.6rem;
  padding: 1rem 3.2rem;
  font-size: 1.89rem;
  font-weight: 600;
  margin-top: 4.7rem;
  &:hover {
    background-color: #ec8b17;
    box-shadow: unset;
  }
`