import { AppBar, Button, Dialog, IconButton, List, ListItem, styled, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import { FlexBox } from "../../styled/Layout";
import theme from "../../theme/theme";
import { ArrowLeftIcon, CrossIcon } from "../../assets/svg/icons";
import { useBreakpoints } from "../../utils/hooks/useBreakpoints";
import { mobileScreen } from "../../utils/breakpoints";

export const HeaderContainer = styled(AppBar)`
  background-color: #fff;
  padding: 0.5rem 1.5rem;
  transition: 0.3s;

  .MuiToolbar-root {
    justify-content: space-between;
  }
  > div > div > a {
    flex-shrink: 0;
  }

  ${theme.breakpoints.down("mobL")} {
    background-color: #fff;

    &.mobOpen {
      background-color: #242f57;
    }

    .logo {
      svg {
        path {
          fill: #0080fe;
        }
      }
    }
  }
`;

export const HeaderLogo = styled(IconButton)`
  /* width: 11rem; */
  padding: 0;
  max-width: 6rem;
  svg {
    width: 100%;
    path {
      fill: #0080ff;
    }
  }
`;

export const HeaderTitle = styled(Typography)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "F37 Judge", sans-serif;
  font-size: 1.6rem;
  letter-spacing: 0.5px;
  /* background-color: #0080ff; */
  color: #fff;
  font-weight: normal;
  line-height: 1.2;
  margin-left: 4px;
  margin-bottom: 3px;
  padding: 0.5rem 1rem;
  border-radius: 1rem;

  > svg {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }

  > span {
    z-index: 2;
    color: #fff;
    display: block;
    margin-left: 3px;
    margin-top: 1px;
    white-space: nowrap;
  }
`;

export const CreateCampaignLink = styled("a")`
  background-color: #0080ff;
  //display: flex;
  //align-items: center;
  //opacity: 1 !important;
  border-radius: 0.8rem;

  &.disabled {
    pointer-events: none;
    cursor: default;
    background-color: rgba(160, 160, 160, 0.1);
  }

  p {
    font-size: 1.7rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.53px;
    color: #fff;
    padding: 1.35rem 4.75rem;
  }

  //@media only screen and (max-width: 1650px) {
  //  padding: 0 1.5rem;
  //
  //  p {
  //    font-size: 1.8rem;
  //  }
  //}

  // ${theme.breakpoints.down("xl")} {
  //   padding: 0 1rem 0;
  //   margin-left: 1.5vw;
  //   flex-shrink: 1 !important;
  //   min-width: 10vw;
  //
  //   p {
  //     font-size: 1vw;
  //     white-space: unset;
  //   }
  // }
`;

export const HeaderBalance = styled(FlexBox)`
  border-radius: 0.7rem;
  border: solid 1px #e9f3fd;
  padding: 1rem 1.4rem;

  ${mobileScreen} {
    width: 100vw;
    background-color: #051347;
    border: none;
    flex-direction: column;
    margin-top: 3rem;
    margin-left: -1.5rem;
    padding: 3.4rem;
    border-bottom: 1px dashed rgba(255, 255, 255, 0.41);
    border-radius: 0;
  }
  h5 {
    font-size: 0.7rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.22px;
    color: #636e95;

    ${mobileScreen} {
      font-size: 1.54rem;
      color: #fff;
      opacity: 0.69;
    }
  }

  p {
    font-size: 1.9rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.59px;
    color: #636e95;

    ${mobileScreen} {
      color: #fff;
      font-size: 4.18rem;
    }
  }

  button {
    display: block;
    //height: 100%;
    //margin-top: -2.5rem;
    border: none;
    border-radius: 1.1rem;
    padding: 1.6rem 2rem;
    margin: -1rem -1.4rem -1rem 1.2rem;

    font-size: 1.02rem;
    font-weight: 600;
    text-transform: none;
    letter-spacing: 0.32px;
    color: #0080ff;
    border-radius: 1.1rem 0.7rem 0.7rem 1.1rem;
    background-color: #e9f3fd;
    display: flex;

    ${mobileScreen} {
      margin: 0;
      background: none;
      font-size: 2rem;
    }

    &:hover {
      border: none;
    }

    .MuiButton-startIcon {
      background-color: #0080ff;
      border-radius: 50%;
      width: 1.6rem;
      height: 1.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.8rem;

      ${mobileScreen} {
        width: 3.2rem;
        height: 3.2rem;
      }

      > span {
        color: #fff;
        font-size: 1.52rem;

        ${mobileScreen} {
          font-size: 3rem;
        }
      }
    }
  }
`;

export const HeaderUserMenu = styled(FlexBox)`
  position: relative;
  border-radius: 0.7rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  border: solid 1px rgba(151, 151, 151, 0.12);
  padding: 1rem;
`;

export const HeaderNavigation = styled(List)`
  display: flex;
  column-gap: 1rem;

  li {
    opacity: 0.5;

    &.active {
      opacity: 1;
    }
  }
  a {
    text-decoration: none;
    white-space: nowrap;
  }

  @media only screen and (max-width: 1650px) {
    column-gap: 0;

    li {
      width: fit-content;
    }
  }

  ${theme.breakpoints.down("xl")} {
    flex-wrap: wrap;
    justify-content: center;
  }

  ${theme.breakpoints.down("lg")} {
    li {
      padding: 0.5rem 1rem;

      p {
        font-size: 1.4rem;
      }
    }
  }

  ${theme.breakpoints.down("mobL")} {
    margin-top: 5vh;
    flex-direction: column;
    align-items: center;
    row-gap: 2vh;

    a {
      width: 100%;
      text-align: center;
    }
  }
`;

export const HeaderUserData = styled(FlexBox)`
  //margin-left: 1rem;
  //margin-right: 2.7rem;
  min-width: 19rem;
  cursor: pointer;

  .MuiAvatar-root {
    width: 2.9rem;
    height: 2.9rem;
    margin-right: 0.8rem;
  }
  h4 {
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 0.38px;
    color: #242f57;
  }
  p {
    font-size: 0.8rem;
    letter-spacing: 0.25px;
    color: #242f57;
    text-transform: uppercase;
    opacity: 0.5;
  }

  .arrow {
    margin-left: auto;
    width: 1.2rem;
    transition: 0.3s;

    &.open {
      transform: rotate(180deg);
    }
  }

  ${theme.breakpoints.down("mobL")} {
    align-items: center;
    margin: 2rem 0;

    .MuiAvatar-root {
      width: 5.2rem;
      height: 5.2rem;
      margin-right: 1.2rem;
    }

    h4 {
      font-size: 2.16rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.67px;
      color: #fff;
    }
    p {
      opacity: 0.6;
      font-family: Hellix;
      font-size: 1.44rem;
      letter-spacing: 0.45px;
      color: #fff;
    }
  }
`;

export const HeaderUserDropdownMenu = styled(List)`
  position: absolute;
  top: 90%;
  left: -0.5%;
  background-color: #fff;
  width: 101%;
  padding: 3rem 0 3rem 2.5rem;
  cursor: default;

  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14);
  border: solid 1px rgba(151, 151, 151, 0.12);
  border-top: none;
  border-radius: 0 0 0.7rem 0.7rem;

  transition: 0.3s;
  transform-origin: top;
  &.hidden {
    transform: scaleY(0);
    opacity: 0;
  }

  .MuiListItemIcon-root {
    min-width: auto;
    width: 0.6rem;
    height: 0.6rem;
    border: 1px solid #979797;
    border-radius: 50%;
    margin-right: 0.8rem;
  }

  a {
    display: flex;
    align-items: center;
  }

  .MuiListItemText-root {
    font-size: 1.3rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.14px;
    color: #091b32;
  }

  li {
    a,
    .authBtn {
      opacity: 0.48;
      transition: 0.3s;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }

    .authBtn {
      display: flex;
      align-items: center;
    }
    //margin-bottom: .5rem;
  }
`;

export const LogOutButton = styled(Button)`
  padding: 0.6rem 1.5rem;
  border-radius: 0.6rem;
  border: solid 1px #2191ff;

  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.31px;
  color: #2191ff;

  ${theme.breakpoints.down("mobL")} {
    font-size: 1.5rem;
    padding: 1rem 2.2rem;
    border-radius: 0.9rem;
    border-width: 2px;
  }
`;

export const HeaderOffset = styled(Box)`
  height: 7.6rem;

  ${theme.breakpoints.down("mobL")} {
    display: none;
  }
`;

export const HeaderPlusButton = styled(IconButton)`
  //opacity: 0.21;
  border-radius: 0.8rem;
  border: solid 3px #0080ff;
  font-size: 4rem;
  font-weight: 500;
  width: 4rem;
  height: 4rem;
  color: #0080ff;
  //cursor: not-allowed;

  ${theme.breakpoints.up("mobL")} {
    display: none;
  }
`;

export const ThreeDotsHeader = ({ open, handleOpen }: any) => {
  const { mobileLarge } = useBreakpoints();

  if (!mobileLarge) return null;

  // if (open)
  //   return (
  //     <IconButton
  //       disableRipple
  //       onClick={handleOpen}
  //       sx={{
  //         display: { xs: "block", mob500: "none" },
  //         "> svg > path": { fill: "#5d678d" },
  //       }}
  //     >
  //       <ArrowLeftIcon />
  //       {/*<ArrowR*/}
  //       {/*<CrossIcon width={"1.7rem"} height={"1.7rem"} />*/}
  //     </IconButton>
  //   );

  return (
    <IconButton disableRipple onClick={handleOpen} sx={{ display: { xs: "block", mob500: "none" } }}>
      <svg viewBox="0 0 53 9" width={"5.3rem"} height={".9rem"} xmlns="http://www.w3.org/2000/svg">
        <g
          transform="translate(-490 -164) translate(490.183 164.945)"
          fill={open ? "#fff" : "#0080ff"}
          stroke="none"
          strokeWidth={1}
          fillRule="evenodd"
        >
          <circle cx={3.8006293} cy={3.8006293} r={3.8006293} />
          <circle cx={26.4018879} cy={3.8006293} r={3.8006293} />
          <circle cx={49.0031465} cy={3.8006293} r={3.8006293} />
        </g>
      </svg>
    </IconButton>
  );
};

export const MobileMenuContainer = styled(Dialog)`
  height: 100vh;
  z-index: 1000;

  .MuiPaper-root {
    max-height: 100vh;
    //width: 100vw;
    margin: 0;
  }

  .MuiDialogContent-root {
    height: 100vh;
    width: 100vw;
    padding-top: 30rem;
    background-color: #242f57;
  }
`;
