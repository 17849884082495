import { TKeyData } from "../../../../../types/TableTypes";
import { BinocularIcon, CoinsIcon, HoverIcon, LoudspeakerIcon, RocketIcon, UmbrellaIcon } from "../../../../../assets/svg/icons";
import numeral from "numeral";
import { useSelector } from "react-redux";
import {appSelector, overviewSelector} from "../../../../../utils/selectors";
import {useEffect, useState} from "react";

export const usePPCCardData = () => {
  const { total_info, info_by_campaigns } = useSelector(overviewSelector);
  const [cardOpen, setCardOpen] = useState<boolean>(true);
  const {api: {ad_data}} = useSelector(appSelector);
  const loading = ad_data.loading

  const ppcGraphData: {date: string, value: number}[] = []
  info_by_campaigns && Object.keys(info_by_campaigns).length && Object.values(info_by_campaigns)[0].weekly_ctr?.ctr_points?.forEach((data) => {
      ppcGraphData.push({date: data.date, value: data.rate})
    })

  const ppcCardsData: TKeyData[] = [
    { title: "Campaigns", icon: <RocketIcon />, value: total_info?.total_campaigns || 0 },
    {
      title: "Spent",
      icon: <CoinsIcon />,
      value: `${numeral(total_info?.total_spent).format("0,0").replace(",", ".") || 0} USDT`,
    },
    { title: "Ads", icon: <LoudspeakerIcon />, value: total_info?.total_ads || 0 },

    { title: "Clicks", icon: <HoverIcon />, value: total_info?.total_clicks || 0 },
  ];

  const graphData = [
    {date: 'Sep', value: 1000},
    {date: 'Oct', value: 3000},
    {date: 'Nov', value: 3850},
    {date: 'Dec', value: 3600},
  ]

  const ctr = info_by_campaigns ? (Object.values(info_by_campaigns)[0]?.weekly_ctr?.ctr_value || 0) : 0

  return {
    ppcCardsData,
    graphData: ppcGraphData.length ? (ppcGraphData.length > 7 ? ppcGraphData.splice(-7) : ppcGraphData) : graphData,
    sample: !ppcGraphData.length,
    cardOpen,
    setCardOpen,
    ctr: Math.round(ctr),
    loading
  };
};
