import { useSelector } from "react-redux";
import {
  BinocularIcon,
  CoinsIcon, DevelopGraphIcon,
  GasIcon,
  HoverIcon,
  LoudspeakerIcon,
  RocketIcon,
  UmbrellaIcon
} from "../../assets/svg/icons";
import { TKeyData } from "../../types/TableTypes";
import { overviewSelector } from "../../utils/selectors";
import numeral from "numeral";
import {TTotalData, TTotalProps} from "./Total";

export const useTotal = (data: TTotalData | undefined) => {
  const { total_info } = useSelector(overviewSelector);

  const totalData: TKeyData[] = [
    { title: "Campaigns", icon: <RocketIcon />, value: total_info?.total_campaigns || 0 },
    { title: "Channels", icon: <UmbrellaIcon />, value: total_info?.total_channels || 0 },
    { title: "Ads", icon: <LoudspeakerIcon />, value: total_info?.total_ads || 0 },
    {
      title: "Spent",
      icon: <CoinsIcon />,
      value: `${numeral(total_info?.total_spent).format("0,0").replace(",", ".") || 0} USDT`,
    },
    { title: "Clicks", icon: <HoverIcon />, value: total_info?.total_clicks || 0 },
    { title: "Views", icon: <BinocularIcon />, value: total_info?.total_views || 0 },
  ];

  const campaignData = [
    {
      title: "Spent",
      icon: <CoinsIcon />,
      value: `$${data?.spent || 0}`,
    },
    { title: "Views", icon: <BinocularIcon />, value: data?.views || 0 },
    { title: "Clicks", icon: <HoverIcon />, value: data?.clicks || 0 },
    { title: "Ads", icon: <LoudspeakerIcon />, value: data?.ads || 0 },

    { title: "Budget", icon: <GasIcon />, value: "$" + (data?.budget || 0) },
    { title: "Avg. cpc", icon: <DevelopGraphIcon />, value: "$" + (data?.avgCpc || 0) },

  ]

  return { totalData: data ? campaignData : totalData };
};
