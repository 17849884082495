import { Accordion, Drawer, List, Stack, styled, Typography } from "@mui/material";
import { FlexBox } from "../../styled/Layout";
import {mobileScreen} from "../../utils/breakpoints";

export const SidebarContainer = styled(Drawer)`
  .MuiDrawer-paper {
    transition: 0.3s;
    background-color: #242f57;
    //width: 35rem;
  }
  ${mobileScreen}{
    display: none;
  }
`;

export const SideBarHead = styled(FlexBox)`
  background-color: #1e284d;
  justify-content: space-between;

  padding: 1.2rem 2.4rem;

  .MuiIconButton-root {
    padding: 1rem;
  }
`;

export const SidebarBody = styled(Stack)`
  padding: 5rem 3.5rem;
`;

export const SidebarMainMenuItem = styled(Typography)`
  font-size: 1.3rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.14px;
  color: #b1b5c3;
  transition: .3s;
  
  > a{
    color: #b1b5c3;
  }
`;

export const SidebarDivider = styled("div")`
  width: 100%;
  height: 1px;
  margin: 2.2rem 0;
  background: repeating-linear-gradient(
    to right,
    transparent,
    transparent 3px,
    rgba(255, 255, 255, 0.21) 4px,
    rgba(255, 255, 255, 0.21) 10px
  );
  
  &.folded{
    height: 0;
  }
`;

export const SidebarAccordion = styled(Accordion)`
  background-color: transparent;
  box-shadow: none;

  .MuiAccordionSummary-content {
    > div {
      justify-content: space-between;
      width: 100%;

      .MuiIconButton-root {
        font-size: 1.3rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.14px;
        color: #fff;
      }

      .minus {
        display: none;
      }
    }
    &.Mui-expanded {
      .minus {
        display: block;
      }

      .plus {
        display: none;
      }
    }
    
    &:hover{
      p{
        color: #fff;
      }
    }
  }

  .MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded{
    margin: 0;
  }
  
  &.Mui-expanded{
    margin: 0;
  }

  .MuiAccordionSummary-root, .MuiAccordionSummary-root.Mui-expanded{
    min-height: auto;

  }
  
  &.Mui-disabled{
    background-color: transparent;
  }
`;

export const SidebarPageList = styled(List)`
  
  
  li {
    a {
      display: flex;
      align-items: center;
      opacity: 0.39;
      transition: 0.3s;
      &:hover, &.active {
        opacity: 1;

        .MuiListItemIcon-root {
          background-color: #fff;
          opacity: 1;
        }
      }
    }
    
    &.folded{
      padding: .5rem 0;
    }
  }

  .MuiListItemIcon-root {
    min-width: auto;
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
    border: solid 1px #fff;
    opacity: 0.39;
    margin-right: 0.8rem;
    transition: 0.3s;
  }

  .MuiListItemText-primary {
    font-family: Hellix;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.14px;
    color: #fff;
  }
`;
