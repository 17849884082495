import {TGadget} from "../../types/reduxData/dataTypes";
import {sortSliceForTable} from "../../utils/sortSliceForTable";
import {codesList, countriesList} from "./CountriesData";

export const formatFromCodeToBoolean = (data: string[]) => {
    const newData: { [key: string]: boolean } = {};
    data?.forEach(code => newData[code] = true)
    return newData
}

export const filterData = (locations: string[], data: TGadget[]) => {
    return sortSliceForTable(data.filter((country) => locations.includes(codesList[country.name])))
        ?.map((country) => {
            return {
                ...country,
                name: countriesList[codesList[country.name]],
                code: codesList[country.name]
            }})
}

export const sortCountries = (data: TGadget[]) => {
    return data?.sort((prev, curr) => {
        if (prev.name < curr.name) return -1
        if (prev.name > curr.name) return 1
        return 0
    }).reduce((prev, curr) => {
        const newArr = [...prev]
        const arrIndex = newArr.findIndex((country) => country.name === curr.name)
        if (arrIndex !== -1) {
            const newValue: TGadget = {
                ...curr,
                total_amount_spent: newArr[arrIndex].total_amount_spent + curr.total_amount_spent,
                total_clicks: newArr[arrIndex].total_clicks + curr.total_clicks,
                total_impressions: newArr[arrIndex].total_impressions + curr.total_impressions
            }
            newArr.splice(arrIndex, 1, newValue)
        } else newArr.push(curr)
        return newArr
    }, [] as TGadget[])
}