import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { FlexBox } from "../../styled/Layout";
import { mobileScreen } from "../../utils/breakpoints";

export const ProgressTableDivider = styled(Box)`
  width: 100%;
  height: 1px;
  background: repeating-linear-gradient(to right, transparent, transparent 3px, #d8dbe5 5px, #d8dbe5 10px);
`;

export const ProgressTableItem = styled(FlexBox)`
  width: 100%;
  align-items: center;
  margin: 2.6rem 0;

  ${mobileScreen}{
    justify-content: space-between;
  }

  p {
    font-size: 1.9rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: 0.21px;
    text-align: left;
    color: #091b32;

    &:first-of-type{
        width: 18rem;
    }
  }


`;

export const CustomProgressBar = styled(Box)`
position: relative;
  flex: 1;
  height: 3rem;
  border-radius: 1.6rem;
  background-color: #f9f6f6;
  margin: 0 1.7rem;

  > div {
    position: absolute;
    border-radius: 1.6rem;
    background-color: #15aa95;
    height: 1.8rem;
    top: 0.6rem;
    left: 0.6rem;
  }
`;
