import { useState } from "react";
import { endOfWeek, format, parse, startOfMonth, startOfWeek } from "date-fns";

type TProps = [string, number][];
export type TPeriod = "Daily" | "Weekly" | "Monthly";
export const usePixelDateFilter = (data: TProps) => {
  const [period, setPeriod] = useState<TPeriod>("Daily");

  const dailyData = data?.map(([date, value]: [string, number]) => ({ date, value }));

  const weeklyData = data?.reduce((acc: { date: string; value: number }[], next) => {
    const parsedDate = parse(next[0], "yyyy-MM-dd", new Date());
    const weekStart = format(startOfWeek(parsedDate), "MM-dd");
    const weekEnd = format(endOfWeek(parsedDate), "MM-dd");
    const date = `${weekStart} - ${weekEnd}`;
    const indexInData = acc.findIndex((el) => el.date === date);
    const newAcc = [...acc];
    if (indexInData === -1) {
      return [...acc, { date, value: next[1] }];
    } else {
      newAcc[indexInData].value += next[1];
      return newAcc;
    }
  }, []);

  const monthlyData = data?.reduce((acc: { date: string; value: number }[], next) => {
    const parsedDate = parse(next[0], "yyyy-MM-dd", new Date());
    // const weekStart = format(startOfMonth(parsedDate), "MMMM");
    // const weekEnd = format(endOfMonth(parsedDate), "MMMM");
    const date = format(startOfMonth(parsedDate), "MMMM");
    const index = acc.findIndex((el) => el.date === date);
    const newAcc = [...acc];
    if (index === -1) {
      return [...acc, { date, value: next[1] }];
    } else {
      newAcc[index].value += next[1];
      return newAcc;
    }
  }, []);

  const graphDataOptions = {
    Daily: dailyData,
    Weekly: weeklyData,
    Monthly: monthlyData,
  };

  return { period, setPeriod, graphData: graphDataOptions[period as TPeriod] };
};
