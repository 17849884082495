import React from "react";

export function FilterSvg<T>(props: T) {
    return (
        <svg viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg"{...props}>
            <g
                transform="translate(-468 -447) translate(468.935 447.6)"
                stroke="none"
                strokeWidth={1}
                fill="none"
                fillRule="evenodd"
            >
                <circle fill="#0080FF" cx={20.3} cy={20.3} r={20.3} />
                <path
                    d="M12.57 12.584c-1.271 0-1.966 1.483-1.152 2.46l6.802 8.164c.375.45.58 1.016.58 1.601v4.275c0 .473.223.917.6 1.2l1.6 1.2a.5.5 0 00.8-.4V24.81c0-.585.206-1.152.58-1.6l6.803-8.165c.814-.977.119-2.46-1.152-2.46H12.57zm-1.92 3.101c-1.357-1.628-.2-4.1 1.92-4.1h15.46c2.12 0 3.277 2.472 1.92 4.1l-6.802 8.163a1.502 1.502 0 00-.349.961v6.275c0 1.236-1.41 1.941-2.4 1.2l-1.6-1.2a2.501 2.501 0 01-1-2V24.81c0-.352-.122-.692-.346-.96l-6.803-8.164z"
                    fill="#FFF"
                />
            </g>
        </svg>
    )
}

export function PlanetSvg<T>(props: T) {
    return (
        <svg viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg"{...props}>
            <g
                transform="translate(-468 -447) translate(468.935 447.6)"
                stroke="none"
                strokeWidth={1}
                fill="none"
                fillRule="evenodd"
            >
                <circle stroke="#0080FF" fill="#FFF" cx={20.3} cy={20.3} r={19.8} />
                <path
                    d="M9.812 19.8h5.992c.046-2.884.49-5.502 1.197-7.449.342-.938.752-1.742 1.227-2.346-4.648.93-8.187 4.929-8.416 9.795zM20.3 8.8c-6.351 0-11.5 5.149-11.5 11.5s5.149 11.5 11.5 11.5c6.352 0 11.5-5.149 11.5-11.5S26.652 8.8 20.3 8.8zm0 1c-.351 0-.752.194-1.177.69-.425.495-.831 1.239-1.181 2.203-.66 1.815-1.091 4.314-1.138 7.107h6.992c-.046-2.793-.477-5.292-1.137-7.107-.351-.964-.756-1.708-1.181-2.203-.426-.496-.826-.69-1.178-.69zm4.496 10c-.046-2.884-.49-5.502-1.198-7.449-.341-.938-.751-1.742-1.225-2.346 4.648.93 8.187 4.929 8.416 9.795h-5.993zm-1 1h-6.992c.047 2.793.478 5.292 1.138 7.107.35.964.756 1.708 1.18 2.203.426.496.827.69 1.178.69.352 0 .752-.194 1.178-.69.425-.495.83-1.239 1.18-2.203.66-1.815 1.092-4.314 1.138-7.107zm-1.423 9.795c.474-.604.884-1.408 1.225-2.346.708-1.947 1.152-4.565 1.198-7.449h5.993c-.23 4.866-3.768 8.865-8.416 9.795zm-4.145 0c-.475-.604-.885-1.408-1.227-2.346-.707-1.947-1.151-4.565-1.197-7.449H9.812c.229 4.866 3.768 8.865 8.416 9.795z"
                    fill="#0080FF"
                />
            </g>
        </svg>
    )
}
export function RegionLocationSvg<T> (props: T) {
    return (
        <svg viewBox="0 0 19 25" xmlns="http://www.w3.org/2000/svg"{...props}>
            <path
                d="M103.473 334.273a7 7 0 00-7 7c0 2.831 1.261 5.575 2.823 7.874 1.517 2.234 3.257 3.955 4.177 4.797.92-.842 2.66-2.563 4.177-4.797 1.562-2.299 2.823-5.043 2.823-7.874a7 7 0 00-7-7m-9 7a9 9 0 019-9 9 9 0 019 9c0 3.402-1.501 6.544-3.168 8.998-1.676 2.467-3.586 4.336-4.552 5.212a1.894 1.894 0 01-2.56 0c-.966-.876-2.876-2.745-4.552-5.212-1.667-2.454-3.168-5.596-3.168-8.998m9-2a2 2 0 10.001 4.002 2 2 0 00-.001-4.002m-4 2a4 4 0 118 0 4 4 0 01-8 0"
                transform="translate(-178 -781) translate(84.492 331.705) translate(0 117.612)"
                fill="#B2BBDA"
                stroke="none"
                strokeWidth={1}
                fillRule="evenodd"
            />
        </svg>
    )
}