import { Accordion, AccordionDetails, AccordionSummary, styled } from "@mui/material";

export const MobileMenuItemAccordion = styled(Accordion)`
  background: none;
  box-shadow: none;
  width: 100%;

  &.Mui-disabled{
    background: none;
  }

  h4 {
    font-family: "F37 Judge", sans-serif;
    font-size: 2.6rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.29px;
    color: #fff;
  }
`;

export const MobileMenuItemTitle = styled(AccordionSummary)`
  .MuiAccordionSummary-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.Mui-expanded {
      .MuiButtonBase-root {
        opacity: 1;

        .plus {
          display: none;
        }

        .minus {
          display: block;
        }
      }
    }

    .MuiButtonBase-root {
      opacity: 0.5;

      .minus {
        display: none;
      }

      p {
        font-family: "Hellix-Light", "Hellix", sans-serif;
        font-size: 5.2rem;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.58px;
        color: #fff;
      }
    }
  }
`;

export const MobileMenuItemList = styled(AccordionDetails)`
  li {
    width: 100vw;
    margin-left: -4rem;
    padding: 3.4rem;
    transition: 0.3s;

    &.active {
      background-color: rgba(255, 255, 255, 0.11);

      p {
        color: #04b78a;
        
        svg{
          margin-right: .8rem;
        }
      }
    }
  }

  a {
    display: block;
    //margin: 3.5rem 0;
  }

  p {
    font-size: 2.6rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.29px;
    color: #fff;
    cursor: pointer;
  }
`;

export const MobileMenuDivider = styled('div')`
width: 100vw;
  height: 1px;
  background: repeating-linear-gradient( to right, transparent, transparent 3px, rgba(255, 255, 255, 0.21) 4px, rgba(255, 255, 255, 0.21) 10px );
`