import { Box, Typography } from "@mui/material";
import ProgressTable from "../../../../../components/Tables/ProgressTable";
import BlockContent from "../../../../../layout/blockContent/BlockContent";
import { usePixel } from "../../../usePixel";

const Interest = () => {
  const { data, loading } = usePixel("visitors_interests");
  const graphData = data?.data.map((el: any) => ({ title: el?.interest, value: el?.visitors_score }));

  return (
    <Box minHeight={"30rem"}>
      <BlockContent loading={loading}>
        <Typography variant="h3" mt={{mobL: "12rem"}} mb="1.4rem">
          USERS INTEREST
        </Typography>
        <Typography width={{mobL: "80%"}} mb="4.6rem">
          Here are your audience's interests according to their browsing and wallet-based activity.

          All information is provided by our data network, collected thanks to fingerprint technology anonymously, and stored directly on the blockchain, making it fully decentralized
        </Typography>
        <ProgressTable data={graphData} />
      </BlockContent>
    </Box>
  );
};

export default Interest;
