import { Box, LinearProgress, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { FlexBox } from "../../styled/Layout";
import { getStringNumber } from "../../utils/calc";
import { CustomProgressBar, ProgressTableDivider, ProgressTableItem } from "./ProgressTable.styles";

type TProps = {
  data: {

  title: string,
  value: number
}[]  }


const ProgressTable = ({data}: TProps) => {
  const sortedData = data?.sort((a, b) => b.value - a.value);

  return (
    <div>
      <ProgressTableDivider />
      {sortedData.map((el) => (
        <Fragment key={el.title}>
          <ProgressTableItem>
            <Typography>{el.title}</Typography>
            <CustomProgressBar className="desktopOnly">
              <Box width={(el.value / sortedData[0].value) * 80 + "%"} />
              {/* <LinearProgress variant="determinate" value={(el.value / sortedData[0].value) * 80} /> */}
            </CustomProgressBar>
            <Typography>{getStringNumber(el.value)}</Typography>
          </ProgressTableItem>
          <ProgressTableDivider />
        </Fragment>
      ))}
    </div>
  );
};

export default ProgressTable;
