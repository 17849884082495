import { Route, Routes } from "react-router-dom";
import OverviewPage from "../Overview/OverviewPage";
import MyCampaigns from "../Campaigns/MyCampaigns";
import PPCHeader from "./PPCHeader";
import {Page404} from "../Page404/Page404";

const PPC = () => {
  return (
    <>
      <PPCHeader />
      <Routes>
        <Route path={"/overview"} element={<OverviewPage />} />
        <Route path={"/mycampaigns/*"} element={<MyCampaigns />} />
          <Route path={'*'} element={<Page404/>} />
      </Routes>
    </>
  );
};

export default PPC;
