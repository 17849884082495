import {DEV_ONLY, STAGING} from "../utils/constants";
import axios from "axios";

const baseURL = `https://mvp-campaigns-${(DEV_ONLY || window?.origin === "https://dash.web3m.io") ? 'develop-' : STAGING ? 'staging-' : ''}dot-winky-apis.ew.r.appspot.com`
// const baseURL = 'http://10.0.0.3:3001'
// const baseURL = 'http://localhost:3003'

const campaignsClient = axios.create({
    baseURL,
});

export default campaignsClient;
