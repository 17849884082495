import { useEffect } from "react";
import { useSelector } from "react-redux";
import {getAdsByStatus, getCampaignList} from "../../api/statisticsRequests";
import { userSelector } from "../../utils/selectors";

export const useOverview = () => {
  const { id } = useSelector(userSelector);

  useEffect(() => {
    if (id) {
      void getAdsByStatus();
      void getCampaignList();
    }
  }, [id]);
  return {};
};
