// Utils
import { ContextViewMode } from "../../pages/Campaigns/ContextViewMode";

// Components
import { ExpandButton } from "../../styled/buttons";
import AdExpanded from "../AdExpanded/AdExpanded";
import CreativeCard from "../CreativeCard/CreativeCard";
import { CheckboxGroups } from "../CheckboxGroups/checkboxGroups";

// Styles
import { Typography } from "@mui/material";
import { BlockContainer, FlexBox } from "../../styled/Layout";
import { CreativesContainer } from "../CreativeCard/CreativeCard.styles";

// Hooks
import { useAdCreatives } from "./useAdCreatives";
import { useContext, useState } from "react";
import { TObjectBoolean } from "../../types/GeneralTypes";
import { useLoading } from "../../utils/hooks/useLoading";
import { usePathname } from "../../utils/hooks/usePathname";
import BlockContent from "../../layout/blockContent/BlockContent";

const AdCreatives = () => {
  const { creatives, filteredCreatives, expanded, setExpanded, handleSetExpanded, active, selectedGroups, setSelectedGroups } =
    useAdCreatives();
  const { isCampaignPage } = usePathname();
  const isLoading = useLoading(isCampaignPage ? "campaign" : "ad_data");

  if (!creatives.length) return <BlockContainer></BlockContainer>;

  return (
    <>
      <AdExpanded data={creatives.find((el) => el?.id === expanded)} resetExpanded={() => setExpanded("")} />
      <BlockContainer height={"fit-content !important"} sx={{boxShadow: 'none'}}>
        <FlexBox mb={"4.1rem"} flexDirection={{ xs: "column", mobL: "row" }} sx={{ alignItems: "flex-start" }} rowGap={"2rem"}>
          <Typography variant="h3">{"Ad Creatives"}</Typography>
          {active.campaign === 1 && <CheckboxGroups selectedGroups={selectedGroups} setSelectedGroups={setSelectedGroups} />}
        </FlexBox>
        <BlockContent loading={isLoading}>
          <CreativesContainer>
            {filteredCreatives?.map((cr: any, i) => (
              <CreativeCard
                key={cr?.id + i}
                data={cr}
                setExpanded={() => {
                  handleSetExpanded(cr?.id);
                }}
              />
            ))}
          </CreativesContainer>
          <ExpandButton>Expand</ExpandButton>
        </BlockContent>
      </BlockContainer>
    </>
  );
};

export default AdCreatives;
