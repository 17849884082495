// Styles
import { Stack, Typography } from "@mui/material";
import { Separator } from "../../styled/Layout";
import { TableListItem } from "./KeyValueTable.styles";

// Types and enums
import { TKeyData } from "../../types/TableTypes";

type PropTypes = {
  data: TKeyData[];
  spacing?: string;
};

const KeyValueTable = ({ data, spacing = "1.3rem" }: PropTypes) => {
  return (
    <Stack margin={"-.3rem 0"} spacing={spacing}>
      {data.map((item: TKeyData) => (
        <TableListItem key={item.title}>
          {item.icon}
          <Typography variant="body2">{item.title}</Typography>
          <Separator />
          <Typography variant="numbersLight">{item.value}</Typography>
        </TableListItem>
      ))}
    </Stack>
  );
};

export default KeyValueTable;
