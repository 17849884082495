import {
    TGeneralSettingsResponse,
    TUpdateUserBody,
} from "../types/reduxData/generalSettingsTypes";
import {store} from "../redux/store";
import {setUserDataAction, setUserField} from "../redux/user/userSlice";
import campaignsClient from "./campaignsClient";
import {TBillingHistory, TDepositResponse, TWithdrawBody, TWithdrawResponse} from "../types/billingTypes";
import {isSampleData, userId} from "../utils/constants";

// const userId = () => (typeof window !== 'undefined' && window?.origin === "https://dash.web3m.io")
//     ? "dashboard_sample_data"
//     : store.getState().user.id;


export const getUser = async (user_id?: string) => {
    try {
        const response = await campaignsClient.get(`/user/${isSampleData ? userId() : user_id ?? userId()}`);
        // console.log(response?.data)
        const dataUser: TGeneralSettingsResponse = response?.data;
        // console.log(dataUser)
        if (dataUser) {
            const data = {
                first_name: dataUser?.personal_info?.first_name ?? "",
                last_name: dataUser?.personal_info?.last_name ?? "",
                email: dataUser?.personal_info?.mail_address,
                wallet_address: isSampleData ? "0xb8d3015d3edc2b8ec8f85c0703d46a4aa95ea9a1" : dataUser?.payment_info?.wallet_address || "",
                has_nft: isSampleData ? true : !!dataUser?.payment_info?.has_nft,
                companies: dataUser?.companies || [
                    {
                        company_name: dataUser?.company_info?.company_name ?? "",
                        company_url: dataUser?.company_info?.company_url ?? "",
                    },
                ]
            };
            store.dispatch(setUserDataAction(data as any));
            store.dispatch(setUserField({
                budget_info: dataUser?.budget_info,
                billing_info: dataUser?.billing_info
            }))
            return dataUser as TGeneralSettingsResponse;
        }
    } catch (err) {
        console.log(err);
    }
};

export const checkHistory = async (user_id: string) => {
    // console.log(user_id)
    try {
        const response = await campaignsClient.get(`check_payment/${isSampleData ? userId() : user_id ?? userId()}`)
        // if (response?.data) {
            // const balance = response?.data?.balance
            // if (balance && balance !== store.getState().user.budget_info?.balance) {
            //     store.dispatch(setUserField({budget_info: response.data.budget_info }))
            // }
        // }
        return response?.data?.billing_history as TBillingHistory[]
    } catch (error) {
        console.log(error)
    }
}

export const updateUserField = async (user_id: string, data: TUpdateUserBody) => {
    try {
        const response = await campaignsClient.patch(`/user/${isSampleData ? userId() : user_id ?? userId()}`, data)
        // console.log(response)
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const getAvailableBalance = async () => {
    try {
        const response = await campaignsClient.get(`/user_available/${userId()}`)
        if (response?.data) {
            store.dispatch(setUserField({
                available_balance: response.data.available_balance,
                user_balance: response.data.user_balance,
            }))
            return response.data
        }
    } catch (error) {
        console.log(error)
    }
}

export const updateCampaignStatus = async (id: string, next_status: string, prev_status: string, notes?: string[]) => {
    const data = {
        status: "waiting",
        next_status: next_status,
        prev_status: prev_status,
        rejected_issues: notes || [],
        is_user_request: true,
    };
    try {
        const response = await campaignsClient.put(`/UpdateCampaignsByStatus/${id}`, data);
        console.log(response)
        return response?.data
        // if (response?.data?.status && response?.data?.status === 'success') {
        //     const newCampaignsList = store.getState().campaigns.list.map((campaign) => {
        //         if (campaign.campaign_id === id) return {
        //             ...campaign,
        //             campaign_status: {
        //                 status: 'waiting',
        //                 rejected_issues: notes || [],
        //                 status_obj: {
        //                     next_status: next_status,
        //                     prev_status: prev_status,
        //                 }
        //             }
        //         }
        //         return campaign
        //     })
        //     store.dispatch(setCampaignListData(newCampaignsList));
        // }
    } catch (error) {
        console.log(error)
    }
};

export const updateAdStatus = async (data: {campaign_id: string, group_id: string, ad_id: string, status: string}) => {
    try {
        const response = await campaignsClient.post(`/ad_status`, {...data, is_user_request: true})
        if (response) {
            return response.data
        }
    } catch (error) {
        console.log(error)
    }
}

export const makeWithdraw = async (data: TWithdrawBody) => {
    try {
        const response = await campaignsClient.post('Withdraw', data)
        return response?.data as TWithdrawResponse
    } catch (error) {
        console.log(error)
    }
}

export const makeDeposit = async (data: TWithdrawBody) => {
    try {
        const response = await campaignsClient.post('Deposit', {...data, available: false})
        return response?.data as TDepositResponse
    } catch (error) {
        console.log(error)
    }
}