import HtmlTemplate from "./HtmlTemplate";
import ImageTemplate from "./ImageTemplate";
import ResponsiveTemplate from "./ResponsiveTemplate";
import TextTemplate from "./TextTemplate";

export type TCretiveTemplateKey = "text" | "responsive" | "image" | "html";

export const creativeTemplates = {
  text: (props: any) => <TextTemplate {...props} />,
  responsive: (props: any) => <ResponsiveTemplate {...props} />,
  image: (props: any) => <ImageTemplate {...props} />,
  html: (props: any) => <HtmlTemplate {...props} />,
};
