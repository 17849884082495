import { Button, styled, TextField } from "@mui/material";
import { FlexBox } from "../../styled/Layout";
import theme from "../../theme/theme";

export const SubheaderContainer = styled(FlexBox)`
  height: 14rem;
  padding: 0 3.9rem 0 4.3rem;
  align-items: center;
  justify-content: space-between;
  
  ${theme.breakpoints.down('br1024')}{
    padding: 4rem;
    height: auto;
    flex-direction: column;
    row-gap: 2rem;
  }
  
  ${theme.breakpoints.down('mobL')}{
    margin-top: 6rem;
  }
`;

export const CheckboxContainer = styled(FlexBox)`
  & label {
    margin-right: 2.8rem;
  }

  ${theme.breakpoints.down("mobL")} {
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: flex-start;
    width: fit-content;
    margin: 5rem auto;

    label {
      margin-bottom: 5rem;
    }

    .MuiCheckbox-root {
      > span {
        width: 2.2rem;
        height: 2.2rem;
      }

      &.Mui-checked {
        > span:first-of-type {
          background-color: #fff;
          
          &:before{
            height: 1.7rem;
            width: 1.7rem;
            margin: .3rem auto 0;
          }
        }
      }
    }

    .MuiTypography-body1 {
      font-size: 1.96rem;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.61px;
      text-align: center;
      color: #fff;
    }
  }
`;

export const TextFieldStyled = styled(TextField)`
  &:first-of-type {
    margin-left: 1.9rem;
  }
  &:last-of-type {
    margin-left: 1.4rem;
  }
  max-width: 18.7rem;
`;

export const DisplayDataButton = styled(Button)`
  border-radius: 1rem;
  border-color: ${(props) =>
    props.color === "success"
      ? theme.palette.success.main
      : theme.palette.secondary.dark};
  color: ${(props) =>
    props.color === "success" ? "#FFFFFF" : "rgba(99,110,149,0.5)"};
  background-color: ${(props) =>
    props.color === "success"
      ? theme.palette.success.main
      : theme.palette.secondary.light};
  &:hover {
    border-color: ${theme.palette.success.main};
    background-color: ${(props) =>
      props.color === "success"
        ? theme.palette.success.main
        : theme.palette.secondary.light};
  }
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.44px;
  text-transform: capitalize;
  padding: 8.5px 17px 8px;
  margin-left: 1.4rem;
`;
