// Components
import CampaignsTable from "../../../../components/CampaignsTable/CampaignsTable";
// Styles
import { Container } from "@mui/system";
import BlockContent from "../../../../layout/blockContent/BlockContent";
// Hooks
import { useAllCampaignsSubHeader } from "./useAllCampaignsSubHeader";
import { useLoading } from "../../../../utils/hooks/useLoading";

export const AllCampaigns = () => {
  // const subHeaderData = useAllCampaignsSubHeader();
  const isLoading = useLoading("campaign_list");

  return (
      <BlockContent loading={isLoading}>
        <Container sx={{ mt: "7.5rem" }}>
          <CampaignsTable
              // datesRange={subHeaderData.datesRange}
          />
        </Container>
      </BlockContent>
  );
};
