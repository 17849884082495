import { styled } from "@mui/material";
import { FlexBox } from "../../../styled/Layout";
import theme from "../../../theme/theme";

export const DashboardFilterButtons = styled(FlexBox)`
  margin-top: 10rem;
  column-gap: 1rem;
  justify-content: center;

  > button {
    border-radius: 1rem;
    background-color: #636e95;
    padding: 1rem 2rem;
    height: 5.2rem;

    font-size: 1.8rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    text-transform: none;
    line-height: 1.22;
    letter-spacing: 0.2px;
    color: #fff;
    
    &:hover{
      background-color: #636e95;
    }

    svg {
      width: 3rem;
    }
  }
  
  ${theme.breakpoints.up('mobL')}{
    display: none;
  }
`;
