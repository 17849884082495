import { Box, styled } from "@mui/system";
import {mobileScreen} from "../../utils/breakpoints";

export const ComingSoonContainer = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 1rem;
  
  ${mobileScreen}{
    opacity: 1 !important;
    visibility: visible !important;
    height: fit-content !important;
    top: -1.5rem;
    right: -1.5rem;
    left: auto;
  }
`;
