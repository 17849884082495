import {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Box404, ButtonReport, LinkBox} from "./Page404.styles";
import Image404 from '../../assets/img/image404.webp'
import {Context404} from "../../Context404";
import {SupportForm} from "../../components/Forms/SupportForm/SupportForm";
import {Send404Form} from "../../api/web3Requests";

export const Page404 = () => {
    const [, setIs404] = useContext(Context404)
    const [formStatus, setFormStatus] = useState<boolean>(false)

    useEffect(() => {
        setIs404(true)
    }, [])

    return (
        <> {formStatus
            ? <SupportForm sendFormRequest={Send404Form} setIs404={setIs404}/>
            : <Box404>
                <img src={Image404} alt={'404'}/>
                <LinkBox>
                    <Link to={'/'} onClick={() => setIs404(false)}>{'Back to homepage'}</Link>
                    <ButtonReport variant={'text'} onClick={() => setFormStatus(true)}>{'Report error'}</ButtonReport>
                </LinkBox>
            </Box404>
        }
        </>
    )
}