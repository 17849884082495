import axios from "axios";
import {DEV_ONLY, STAGING} from "../utils/constants";

const baseURL = `https://web3m-beta.com/rewards/api/`;
// const baseURL = 'http://10.0.0.7:3002'

const pixelClient = axios.create({
    baseURL,
});

export default pixelClient;
