import axios from "axios";
import {DEV_ONLY, STAGING} from "../utils/constants";

const baseURL = `https://mvp-statistics-manager-${(DEV_ONLY  || window?.origin === "https://dash.web3m.io") ? 'develop-' : STAGING ? 'staging-' : ''}dot-winky-apis.ew.r.appspot.com`;
// const baseURL = 'http://10.0.0.3:3002'

const statisticsClient = axios.create({
    baseURL,
});

export default statisticsClient;
