import styled from "@emotion/styled";
import { FlexBox } from "../../styled/Layout";
import theme from "../../theme/theme";

export const CampaignProgressContainer = styled(FlexBox)`
  column-gap: 2.1rem;
  justify-content: flex-end;
  
  &.paused{
    color: #636e95;
    opacity: 0.32;

    .MuiLinearProgress-root .MuiLinearProgress-bar{
      background-color: #636e95;
    }
    
    > div > p{
      color: #636e95;

    }
  }

  > p {
    font-weight: 500;
  }

  .MuiLinearProgress-root {
    background-color: #e2e5f0;
    border-radius: 0.8rem;
    .MuiLinearProgress-bar {
      background-color: #0080ff;
      border-radius: 0.8rem;
    }
  }
  > div {
    align-items: center;
    > p {
      font-size: 1.6rem;
      font-weight: 500;
      letter-spacing: 0.5px;
      text-align: center;
      color: #0080ff;
      margin-left: 2.1rem;
    }
  }
  
  ${theme.breakpoints.down('mobL')}{
   flex-direction: column;
    align-items: center;
    > p{
      color: #a69f9f;
      //margin-right: auto;
      width: 100%;
      margin-bottom: 1rem;
    }
    
    > div{
      width: 100%;
      //margin-bottom: 7.5rem;
      
      > p{
        font-weight: bold;

      }
    }

    .MuiLinearProgress-root {
      height: 1rem;
      //flex: 1;
      //width: 100%;
      .MuiLinearProgress-bar {
        background-color: #0080ff;
        border-radius: 0.8rem;
      }
    }
  }
`;

export const BudgetSpentAndRemaining = styled(FlexBox)`
  justify-content: space-between;
  margin-top: .5rem;

  > p {
    font-size: 1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.31px;
    text-align: center;
    color: #636e95;
  }
  
`;
