// Components

// Styles
import { Box, IconButton, Typography } from "@mui/material";

import { ArrowForwardIos } from "@mui/icons-material";
import { channelIcons } from "../../assets/svg/channels";
import { FlexBox } from "../../styled/Layout";
import { AdContainer } from "./CreativeCard.styles";
import { TPublisher } from "../../types/reduxData/dataTypes";

type Tdata = {
  language?: string;
  title: string;
  cUrl: string;
  image: string;
  resolution?: string;
  channel: TPublisher;
  image_size?: { width: number; height: number };
};

const ImageTemplate = ({
  data,
  disabled = false,
  channel = "bitmedia",
}: {
  data: Tdata;
  disabled?: boolean;
  channel?: TPublisher;
}) => {
  if (!data) return null;

  // const [x, y] = data.resolution?.split("x") || ["100%", "100%"];

  return (
    <AdContainer sx={{ padding: 0 }} mt={"1rem"}>
      {/* <Box className="icon">
        <TriangleSvg />
      </Box> */}
      <FlexBox
        sx={{
          justifyContent: "space-between",
          padding: ".5rem",
          alignItems: "center",
          margin: "1rem 1.5rem",
        }}
      >
        <Typography className="title" sx={{ marginBottom: "0 !important" }}>
          {data.title || <span>Ad Title</span>}
        </Typography>
        <IconButton disabled={disabled} disableRipple className="resp">
          <ArrowForwardIos />
        </IconButton>
      </FlexBox>
      <img
        src={data.image}
        style={{ aspectRatio: `${data?.image_size?.width} / ${data?.image_size?.height}` }}
        alt={"Ad banner"}
      />
      <Box className="channelIcon">{channelIcons[data.channel]}</Box>
    </AdContainer>
  );
};

export default ImageTemplate;
