import { Box, Button } from "@mui/material";
import { styled } from "@mui/system";

export const ExpandButton = styled(Button)`
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;

  background-color: #04b78a;
  border-radius: 1rem 0 1rem 0;
  border: none;
  transform-origin: right bottom;
  transition: 0.3s;

  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0.38px;
  color: #fff;

  &:hover {
    background-color: #04b78a;
    border: none;
    scale: 1.03;
  }
`;

export const expandButtonUp = {
  display: { xs: "none", mobL: "block" },
  bottom: "auto",
  top: "0",
  borderRadius: " 0 1rem 0 1rem",
  zIndex: 1,
  transformOrigin: "right top",
};

export const ButtonComingSoon = <T,>(props: T) => {
  const BoxStyled = styled(Box)`
    width: fit-content;
    position: absolute;
    top: 3.2rem;
    right: 3.5rem;
  `;
  const ButtonStyled = styled(Button)`
    position: relative;
    font-family: "F37 Judge-Bold","F37 Judge", sans-serif;
    color: #fff;
    font-size: 2rem;
    letter-spacing: 1.47px;
    //text-shadow: 0 2px 4px #dca300;
    box-shadow: unset;
    border-radius: 4px;
    padding: .5rem 1.4rem;
    &.Mui-disabled {
      background-color: #a3c1d3;
      color: #fff;
    }
  `;
  const Triangle = styled("div")`
    position: relative;
    top: -4px;
    width: 0;
    height: 0;
    border-left: 0.5rem;
    border-right: 1rem solid transparent;
    border-top: 1rem solid #a3c1d3;
  `;
  return (
    <BoxStyled {...props}>
      <ButtonStyled variant={"contained"} color={"info"} disabled>
        {"Coming soon"}
      </ButtonStyled>
      <Triangle />
    </BoxStyled>
  );
};

export const EditCampaignButton = styled(Button)`
  border-radius: 1rem;
  background-color: #f2a626;
  border: none;
  padding: 1.3rem 2.6rem 1.3rem 2rem;

  font-size: 2rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.63px;
  color: #fff;
  text-transform: none;

  svg {
    width: 2.9rem;
    height: 2.9rem;
    margin-left: 0.2rem;
    margin-right: 0.3rem;
  }

  &:hover {
    background-color: #f2a626;
    border: none;
  }
`;

export const GreenButton = styled(Button)`
  opacity: 0.5;
  border-radius: 1rem;
  background-color: #04b78a;
  border: none;
  color: #fff;
  padding: 1rem 3rem;

  &:hover {
    background-color: #04b78a;
    border: none;
    color: #fff;
  }

  &:disabled {
    color: #fff;
    border: none;
    opacity: 0.3;
  }
`;

export const ThreeDotsButton = (props: any) => {
  const StyledButton = styled(Button)`
    color: #b3bcda;
    font-size: 5rem;
  `;

  return (
    <StyledButton>
      <svg viewBox="0 0 27 6" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
          d="M503.267 393.202a2.354 2.354 0 100-4.708 2.354 2.354 0 000 4.708zm10.482 0a2.354 2.354 0 100-4.708 2.354 2.354 0 000 4.708zm10.482 0a2.354 2.354 0 100-4.708 2.354 2.354 0 000 4.708z"
          transform="translate(-500 -388)"
          fill="#B3BCDA"
          stroke="none"
          strokeWidth={1}
          fillRule="evenodd"
        />
      </svg>
    </StyledButton>
  );
};

export const CrossButton = (props: any) => {
  const StyledButton = styled(Button)`
    color: #b3bcda;
    font-size: 5rem;
  `;

  return (
    <StyledButton>
      <svg viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
          d="M511.267 387.767a.916.916 0 011.293 0l6.658 6.66 6.658-6.66a.916.916 0 011.293 0 .916.916 0 010 1.293l-6.66 6.658 6.66 6.658a.916.916 0 010 1.293.916.916 0 01-1.293 0l-6.658-6.66-6.658 6.66a.916.916 0 01-1.293 0 .916.916 0 010-1.293l6.66-6.658-6.66-6.658a.916.916 0 010-1.293"
          transform="translate(-511 -387)"
          fill="#FFF"
          stroke="none"
          strokeWidth={1}
          fillRule="evenodd"
        />
      </svg>
    </StyledButton>
  );
};

export const TransparentButton = styled(Button)`
  border-radius: 3.26rem;
  border: solid 2px #fff;
  width: 100%;
  padding: 1.5rem;

  font-size: 2.34rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.26px;
  text-align: center;
  color: #fff;

  &:hover {
    border-color: unset;
  }
`;

export const SecondaryButton = styled(Button)`
  padding: .4rem 2.2rem;
  border-radius: 1.6rem;
  background-color: #fff;
  border: solid 1px #0080ff;

  font-size: 1.68rem;
  font-weight: 500;
  line-height: normal;
  text-transform: none;
  color: #0080ff;
  
  svg{
    width: 1.3rem;
    path{
      fill: #0080ff;
    }
  }
`