import {
    DashboardCardAccordionButton,
    DashboardCardBody,
    DashboardCardContainer,
    DashboardCardHead,
    DashboardCardMainValue,
    DashboardCardStats,
    DashboardCardTitle,
    DashboardStatisticsLine,
} from "../DashboardCard.styles";
import {CircularProgress, Typography} from "@mui/material";
import {ClickIcon} from "../DashboardCard.icons";
import Grid2 from "@mui/material/Unstable_Grid2";
import {usePPCCardData} from "./usePPCCardData";
import {TableListItem} from "../../../../../components/Tables/KeyValueTable.styles";
import {BlockContainer, FlexBox, Separator} from "../../../../../styled/Layout";
import {CartesianAxis, CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis} from "recharts";
import React from "react";
import {getStringNumber} from "../../../../../utils/calc";
import {GreenButton} from "../../../../../styled/buttons";
import {Link} from "react-router-dom";
import Loading from "../../../../../components/loading/Loading";
import BlockContent from "../../../../../layout/blockContent/BlockContent";

const PPCDashboardCard = () => {
    const {ppcCardsData, graphData, cardOpen, setCardOpen, ctr, loading, sample} = usePPCCardData();
    return (
        <DashboardCardContainer>
            <DashboardCardHead>
                <DashboardCardTitle>
                    <ClickIcon/>
                    <Typography variant={"h3"}>PPC Ads</Typography>
                </DashboardCardTitle>
                <DashboardCardStats container columnSpacing={"10%"} rowSpacing={"1rem"}
                                    className={!cardOpen ? "hidden" : ""}>
                    {ppcCardsData.map((item) => (
                        <Grid2 xs={12} mobL={6} key={item.title}>
                            <DashboardStatisticsLine>
                                {item.icon}
                                <Typography variant="body2">{item.title}</Typography>
                                <Separator/>
                                <Typography variant="numbersLight">{item.value}</Typography>
                            </DashboardStatisticsLine>
                        </Grid2>
                    ))}
                </DashboardCardStats>
                <DashboardCardAccordionButton className={"mobileOnly"} onClick={() => setCardOpen(!cardOpen)}>
                    {cardOpen ? "-" : "+"}
                </DashboardCardAccordionButton>
            </DashboardCardHead>
            <BlockContent loading={loading} minHeight='40rem'>
            <DashboardCardBody className={!cardOpen ? "hidden" : sample ? "sample" : ""}>
                    <DashboardCardMainValue>
                    <Typography>Click Through Rate</Typography>
                    <Typography>
                        <b> {ctr === 0 ? 0 : `${ctr}%`}</b>
                    </Typography>
                </DashboardCardMainValue>
                <ResponsiveContainer width={"100%"} height={300}>
                    <LineChart
                            data={graphData}
                            width={600}
                            height={300}
                            margin={{
                                top: 5,
                                left: -20,
                                right: 10,
                                bottom: 15,
                            }}
                        >
                            <XAxis dataKey="date" tickMargin={15} axisLine={false} tickLine={false}/>

                            <YAxis tickMargin={15} tickFormatter={(val: number) => getStringNumber(val) as string}
                                   axisLine={false}
                                   tickLine={false}/>
                            <Line dataKey={"value"} stroke={sample ? "rgba(0,0,0,0.3)" : "#f2a626"} strokeWidth={3} fill={sample ? "rgba(0,0,0,0.3)" : "#f2a626"}/>
                            <CartesianGrid strokeDasharray="3 3" vertical={false}/>
                        </LineChart>
                </ResponsiveContainer>
                <Link to={"/ppc/overview"}>
                    <GreenButton className={"mobileOnly"}>Open</GreenButton>
                </Link>
            </DashboardCardBody>
                </BlockContent>
        </DashboardCardContainer>
    );
};

export default PPCDashboardCard;
