// Styles
import { Box, Typography } from "@mui/material";
import { AdContainer } from "./CreativeCard.styles";
//Images
import { channelIcons } from "../../assets/svg/channels";
import { TriangleSvg } from "../../assets/svg/interface";
//Types
import { TPublisher } from "../../types/reduxData/dataTypes";

type TData = {
  language?: string;
  title: string;
  cUrl: string;
  image?: string;
  image_size: {
    width: number;
    height: number;
  };
  channel: TPublisher;
};

const HtmlTemplate = ({
  data,
  disabled = false,
  channel = "bitmedia",
}: {
  data: TData;
  disabled?: boolean;
  channel?: TPublisher;
}) => {
  if (!data) return null;

  return (
    <AdContainer sx={{ padding: 0 }} mt={"1rem"}>
      <Box className="icon">
        <TriangleSvg />
      </Box>
      <Typography
        className="title"
        sx={{
          margin: "1rem !important",
          position: "absolute",
          color: "#fff !important",
          zIndex: 1,
          width: "95%",
        }}
      >
        {data.title || <span>Ad Title</span>}
      </Typography>
      <img
        src={data.image as string}
        style={{ aspectRatio: `${data?.image_size?.width} / ${data?.image_size?.height}` }}
        alt={"Ad banner"}
      />
      <Box className="channelIcon">{channelIcons[data.channel]}</Box>
    </AdContainer>
  );
};

export default HtmlTemplate;
