export function Logo<T>(props: T) {
  return (
    <svg width="5.5rem" height="3.1rem" viewBox="0 0 55 31" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M88.494 23.989A8.85 8.85 0 0195.035 38.8a8.85 8.85 0 01-6.541 14.811H70.631a2.91 2.91 0 110-5.82h17.863a3.03 3.03 0 100-6.06h-5.383a2.91 2.91 0 01-2.91-2.91v-.042a2.91 2.91 0 012.91-2.91h5.383a3.03 3.03 0 100-6.06H70.631a2.91 2.91 0 110-5.82h17.863zM61.899 47.797a2.877 2.877 0 012.875 2.88v.054c0 1.591-1.287 2.88-2.875 2.88H45.91a2.877 2.877 0 01-2.876-2.88v-.054c0-1.59 1.288-2.88 2.875-2.88H61.9zm12.41-11.904c1.585 0 2.87 1.29 2.87 2.88v.054c0 1.59-1.285 2.88-2.87 2.88H45.904a2.875 2.875 0 01-2.87-2.88v-.054c0-1.59 1.285-2.88 2.87-2.88h28.405zM61.897 23.989a2.877 2.877 0 012.875 2.88v.054c0 1.59-1.287 2.88-2.875 2.88H45.908a2.877 2.877 0 01-2.875-2.88v-.054c0-1.591 1.287-2.88 2.875-2.88h15.99z"
        transform="translate(-43 -23)"
        fill="#fff"
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1}
      />
    </svg>
  );
}

export function HeaderTitleBG<T>(props: T) {
  return (
    <svg width="99px" height="31px" viewBox="0 0 99 31" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M112.677 23.989h78a9 9 0 019 9V44.61a9 9 0 01-9 9h-78a9 9 0 01-9-9v-1.703a4.864 4.864 0 00-1.58-3.589l-.568-.519.04-.032a5.563 5.563 0 002.108-4.36v-1.42a9 9 0 019-9z"
        transform="translate(-101 -23)"
        fill="#0080FF"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function FullLogo<T>(props: T) {
  return (
    <svg
      width="198px"
      height="51px"
      viewBox="0 0 198 51"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path
          d="M669.091 611.5c6.435 0 11.652 5.217 11.652 11.651a11.61 11.61 0 01-3.04 7.849 11.61 11.61 0 013.04 7.849c0 6.434-5.217 11.651-11.652 11.651h-23.517a3.831 3.831 0 010-7.662h23.517a3.99 3.99 0 000-7.979h-7.086a3.831 3.831 0 01-3.831-3.83v-.057a3.831 3.831 0 013.831-3.831h7.086a3.99 3.99 0 000-7.979h-23.517a3.831 3.831 0 010-7.662h23.517zm-35.013 31.346a3.788 3.788 0 013.785 3.791v.071a3.788 3.788 0 01-3.785 3.792h-21.053a3.788 3.788 0 01-3.784-3.792v-.07a3.788 3.788 0 013.784-3.792h21.053zm16.338-15.673a3.785 3.785 0 013.78 3.791v.072a3.785 3.785 0 01-3.78 3.791H613.02a3.785 3.785 0 01-3.78-3.791v-.072a3.785 3.785 0 013.78-3.791h37.396zm-16.34-15.673a3.788 3.788 0 013.784 3.792v.07a3.788 3.788 0 01-3.785 3.792h-21.05a3.788 3.788 0 01-3.784-3.791v-.071a3.788 3.788 0 013.784-3.792h21.05zm-32.55 0a3.827 3.827 0 013.828 3.827v19.791c0 8.495-6.887 15.382-15.382 15.382a15.345 15.345 0 01-11.555-5.228 15.343 15.343 0 01-11.554 5.228c-8.495 0-15.382-6.887-15.382-15.382v-19.79a3.827 3.827 0 117.654 0v19.79a7.727 7.727 0 0015.455 0v-19.79a3.827 3.827 0 117.654 0v19.79a7.727 7.727 0 0015.455 0v-19.79a3.827 3.827 0 013.827-3.828zm133.166 39a3.827 3.827 0 003.827-3.827v-19.791c0-8.495-6.887-15.382-15.382-15.382a15.345 15.345 0 00-11.554 5.228 15.343 15.343 0 00-11.555-5.228c-8.495 0-15.382 6.887-15.382 15.382v19.79a3.827 3.827 0 007.655 0v-19.79a7.727 7.727 0 1115.455 0v19.79a3.827 3.827 0 007.654 0v-19.79a7.727 7.727 0 0115.455 0v19.79a3.827 3.827 0 003.827 3.828z"
          id="b"
        />
        <filter x="-6.4%" y="-15.4%" width="112.8%" height="161.5%" filterUnits="objectBoundingBox" id="a">
          <feOffset dy={6} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation={3} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0665103939 0" in="shadowBlurOuter1" />
        </filter>
      </defs>
      <g transform="translate(-546 -611)" fillRule="nonzero" stroke="none" strokeWidth={1} fill="none">
        <use fill="#000" filter="url(#a)" xlinkHref="#b" />
        <use fill="#FFF" xlinkHref="#b" />
      </g>
    </svg>
  );
}
