import { differenceInDays, parse } from "date-fns";
import { DATE_FORMAT } from "./constants";

export const getStringNumber = (num: number) => {
  if (!num) return 0;

  if (Math.round(num) >= 1000000) {
    return Math.round(num / 100000) / 10 + "M";
  } else if (Math.round(num) >= 100000) {
    return Math.round(num / 1000) + "K";
  } else if (num >= 1000) {
    return Math.round(num / 100) / 10 + "K";
  } else {
    return Math.round(num * 100) / 100;
  }
};

export const getNumberFromString = (str: string) => {
  if (!str) return "";
  const multiplier = str?.includes("M") ? 1000000 : str.includes("K") ? 1000 : 1;

  return Number(str.replace("M", "").replace("K", "")) * multiplier;
};

export const calcCampaignProgress = (startDate: string, endDate: string) => {
  const parsedStartDate = parse(startDate as string, 'yyyy-MM-dd', Date.now());
  const parsedEndDate = parse(endDate as string, 'yyyy-MM-dd', Date.now());

  const duration = differenceInDays(parsedEndDate, parsedStartDate);
  return Math.round((differenceInDays(new Date(), parsedStartDate) / duration) * 100);
};
