import {Box, IconButton, styled, Typography} from "@mui/material";
import {FlexBox} from "../../styled/Layout";
import theme from "../../theme/theme";

export const CheckboxContainer = styled(FlexBox)`
  flex-wrap: wrap;
  width: 100%;
  & label {
    margin-right: 3rem;
  }
  ${theme.breakpoints.down('mobL')} {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    width: fit-content;
    margin: 0 auto;
  }
`

export const regionsStyle = {
    initial: {
        fill: 'rgba(165,174,186,0.43)',
        stroke: 'none',
        strokeWidth: 0,
        strokeOpacity: 1
    },
    hover: {
        fillOpacity: 0.7,
        cursor: 'pointer'
    },
    selected: {
        fill: `${theme.palette.primary.main}`
    },
    selectedHover: {}
}

export const MapContainer = styled(Box)`
  width: 48rem;
  height: 27rem;
  max-width: 100%;
   margin: 0 0 1rem 0;
  & .jvectormap-zoomin, .jvectormap-zoomout {
    display: none;
  }
`

export const SvgCountriesBox = styled(IconButton)`
  padding: 0;
  & svg {
    width: 4rem;
  }
`

export const RegionsList = styled(FlexBox)`
  flex-wrap: wrap;
`

export const RegionSelected = styled(Typography)`
  font-size: 1.6rem;
  line-height: normal;
  letter-spacing: 0.18px;
  color: #777F9A;
  display: inline-flex;
  align-items: center;
  margin: 0 2rem 1rem 0;
  
  span {
    width: 1.9rem;
    height: 2.5rem;
    display: inline-flex;
    margin-right: 1rem;
    flex-shrink: 0;
    svg {
      width: 100%;
      height: 100%;
    }
  }
`
