import {styled} from "@mui/material";
import theme from "../../theme/theme";

export const MainBody = styled('main')`
  //background: ${theme.palette.secondary.main};
  background: #fff;
  height: 100%;
  //width: 100vw;
  transition: .3s;
  
  &.login{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 30vh;
    
    > button{
      margin-top: 2rem;
    }
    
  }
`