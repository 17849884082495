// Components
import KeyValueTable from "../Tables/KeyValueTable";

// Styles
import { Typography } from "@mui/material";
import { BlockContainer } from "../../styled/Layout";

// Hooks
import { useTotal } from "./useTotal";
import BlockContent from "../../layout/blockContent/BlockContent";
import { useLoading } from "../../utils/hooks/useLoading";
import {usePathname} from "../../utils/hooks/usePathname";

export type TTotalData = {
    ads: number;
    avgCpc: number;
    budget: number;
    clicks: number;
    spent: number;
    views: number;
}

export type TTotalProps = {
  data?: TTotalData;
};

const Total = ({ data }: TTotalProps) => {
  const { totalData } = useTotal(data);
  const {isCampaignPage} = usePathname()
  const isLoading = useLoading(isCampaignPage ? 'campaign' : "ad_data");

  return (
    <BlockContainer>
      <Typography variant="h3" mb={"5rem"}>
          Overview
      </Typography>
      <BlockContent loading={isLoading}>
        <KeyValueTable data={totalData} spacing={"2.4rem"} />
      </BlockContent>
    </BlockContainer>
  );
};

export default Total;
