import {
    BillingContent, BillingInfoBox, ButtonInvoice, HistoryTableCell, HistoryTableHeader,
    InfoBoxContainer,
} from "../BillingCenterPage.styles";
import {Paper, Table, TableBody, TableContainer, TableRow, Typography} from "@mui/material";
import React from "react";
import {useBreakpoints} from "../../../utils/hooks/useBreakpoints";
import {ArrowPdfSvg} from "../../../assets/svg/icons";
import {useBillingHistory} from "./useBillingHistory";
import {useSelector} from "react-redux";
import {userSelector} from "../../../utils/selectors";
import Loading from "../../../components/loading/Loading";


export const History = () => {
    const {history, headerTable, headerTableMobile, downloadFile} = useBillingHistory()
    const {mobileLarge} = useBreakpoints()
    const {isLoadingUser} = useSelector(userSelector)

    return (
        <>
            {isLoadingUser
                ? <Loading/>
                : <BillingContent>
            <BillingInfoBox>
                {!mobileLarge && <Typography variant={'h3'}>{'Billing history'}</Typography>}
                <InfoBoxContainer className={'history'}>
                    <TableContainer component={Paper} sx={{boxShadow: 'unset'}}>
                        <Table aria-label="billing history">
                            <HistoryTableHeader>
                                <TableRow>
                                    {(mobileLarge ? headerTableMobile : headerTable).map((title) => (
                                        <HistoryTableCell key={title} className={'header'}>{title}</HistoryTableCell>
                                    ))}
                                </TableRow>
                            </HistoryTableHeader>
                            <TableBody>
                                {history.map((row, i) => (
                                    <TableRow key={i}>
                                        {!mobileLarge && <HistoryTableCell>{row.action}</HistoryTableCell>}
                                        <HistoryTableCell className={'value'}>
                                            {row.currency}
                                            {row.value}
                                        </HistoryTableCell>
                                        <HistoryTableCell>{row.date}</HistoryTableCell>
                                        {!mobileLarge && <HistoryTableCell>{row.time}</HistoryTableCell>}
                                        {!mobileLarge &&
                                            <HistoryTableCell className={row.status}>{row.status}</HistoryTableCell>}
                                        <HistoryTableCell className={'value'}>
                                            {row.status === 'completed'
                                                ? <ButtonInvoice startIcon={mobileLarge && <ArrowPdfSvg/>}
                                                                 onClick={() => downloadFile(row.invoice)}
                                                >
                                                    {mobileLarge ? 'PDF' : 'Download PDF'}
                                                </ButtonInvoice>
                                                : '. . .'
                                            }
                                        </HistoryTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </InfoBoxContainer>
            </BillingInfoBox>
        </BillingContent>
            }
            </>
    )
}