import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPixelData } from "../../api/pixelRequests";
import { userSelector } from "../../utils/selectors";

export const usePixel = (command: string) => {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const { id } = useSelector(userSelector);

  useEffect(() => {
    const getData = async () => {
      const res = await getPixelData(id, command);

      if (res) {
        setData(res);
      }
      setLoading(false);
    };

    if (id) {
      getData();
    }
  }, [id]);

  return { data, loading };
};
