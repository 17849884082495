import { DashboardFilterButtons } from "./DashboardFilters.styles";
import { Button } from "@mui/material";
import { CalendarIcon, EyeIcon } from "../../../assets/svg/icons";
import { useState } from "react";
import ViewModePopup from "../../../components/popups/ViewMode/ViewModePopup";
import DateRangePopup from "../../../components/popups/DateRange/DateRangePopup";

const DashboardFilters = () => {
  const [popup, setPopup] = useState("");

  return (
    <DashboardFilterButtons>
      <Button startIcon={<CalendarIcon />} onClick={() => setPopup('dateRange')}>Filter by date range</Button>
      <Button startIcon={<EyeIcon />} onClick={() => setPopup('viewMode')}>View mode</Button>
        <ViewModePopup open={popup === 'viewMode'} closePopup={() => setPopup('')}/>
        <DateRangePopup open={popup === 'dateRange'} closePopup={() => setPopup('')}/>
    </DashboardFilterButtons>
  );
};
export default DashboardFilters;
