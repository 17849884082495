import { CampaignProgressBlockMob, CampaignTitleBlock, PageTitle } from "../../../../styled/Layout";
import { Swiper, SwiperSlide } from "swiper/react";
import DashboardFilters from "../../../Dashboard/filters/DashboardFilters";
import Total from "../../../../components/Total/Total";
import { useCampaignPage } from "./useCampaignPage";
import AdsComparison from "../../../../components/AdsComparison/AdsComparison";
import { Avatar, Button, Stack, Typography } from "@mui/material";
import CampaignProgress from "../../../../components/CampaignProgress/CampaignProgress";
import Duration from "../../../../components/Duration/Duration";
import AdCreatives from "../../../../components/AdCreatives/AdCreatives";
import { ContextCheckbox } from "../../../../components/CheckboxGroups/ContextCheckbox";
import Countries from "../../../../components/Countries/Countries";
import Websites from "../../../../components/Websites/Websites";
import Devices from "../../../../components/Devices/Devices";
import { appLink } from "../../../../utils/constants";
import { useParams } from "react-router-dom";

const CampaignPageMobile = () => {
  const {
    campaignInList,
    channelsSelected,
    setChannelsSelected,
    channelRadio,
    setChannelRadio,
    handleSelected,
    filteredData,
    mainSelected,
    setMainSelected,
    campaignCardsData,
    progressData,
    active,
  } = useCampaignPage();
  const activePage = active.campaign;
  const params = useParams();

  return (
    <ContextCheckbox.Provider
      value={[mainSelected, setMainSelected, filteredData, channelsSelected, channelRadio, setChannelsSelected, setChannelRadio]}
    >
      <DashboardFilters />
      <CampaignTitleBlock>
        <Avatar src={campaignInList?.campaign_logo}>{campaignInList?.campaign_name[0]}</Avatar>
        <Stack>
          <Typography variant={"h3"}>{campaignInList?.campaign_name}</Typography>
          <Typography>{campaignInList?.campaign_url}</Typography>
        </Stack>
      </CampaignTitleBlock>
      <CampaignProgressBlockMob>
        <CampaignProgress data={progressData} />
        <Duration />
        <a href={`${appLink}/${params.id}/summary`} target={"app"}>
          <Button>Edit Campaign</Button>
        </a>
      </CampaignProgressBlockMob>
      <Swiper centeredSlides loop spaceBetween={15} slidesPerView={1.2} autoHeight style={{ paddingBottom: "5rem" }}>
        <SwiperSlide>
          <Total data={campaignCardsData} />
        </SwiperSlide>
        <SwiperSlide>
          <AdsComparison activePage={activePage} />
        </SwiperSlide>
        <SwiperSlide>
          <AdCreatives />
        </SwiperSlide>
        <SwiperSlide>
          <Countries />
        </SwiperSlide>
        <SwiperSlide>
          <Websites />
        </SwiperSlide>
        <SwiperSlide>
          <Devices />
        </SwiperSlide>
      </Swiper>
    </ContextCheckbox.Provider>
  );
};

export default CampaignPageMobile;
