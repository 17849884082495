import { Box, IconButton, styled, Typography } from "@mui/material";
import { FlexBox } from "../../../../styled/Layout";
import Grid2 from "@mui/material/Unstable_Grid2";
import { TableListItem } from "../../../../components/Tables/KeyValueTable.styles";
import { mobileScreen } from "../../../../utils/breakpoints";

export const DashboardCardContainer = styled(Box)`
  border-radius: 0.88rem;
  box-shadow: 0 1.5rem 1.8rem 0 rgba(28, 41, 90, 0.14);
  background-color: #fff;
  width: 100%;
  overflow: hidden;
`;

export const DashboardCardHead = styled(FlexBox)`
  position: relative;
  background-color: #242f57;

  ${mobileScreen} {
    flex-direction: column;
  }
`;

export const DashboardCardTitle = styled(FlexBox)`
  background-color: #121b3c;
  height: 100%;
  flex: 1 1 20%;

  padding: 3.2rem;

  h3 {
    font-family: "F37 Judge", sans-serif;
    font-size: 2.1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1.05px;
    color: #fff;
    margin-left: 1rem;
    white-space: nowrap;
  }

  ${mobileScreen} {
    width: 100%;
    background: none;
  }
`;

export const DashboardCardStats = styled(Grid2)`
  padding: 2rem 5% 2rem 10%;
  flex: 1 1 80%;
  transform-origin: top;
  transition: 0.3s;

  ${mobileScreen} {
    padding: 0rem 1.5rem 5rem;
    width: 100%;

    > div {
      transition: 0.3s;
    }

    &.hidden {
      height: 0;
      padding: 0.5rem;
      opacity: 0;
      //transform: scaleY(0);

      > div {
        height: 0;
        padding: 0;
      }
    }
  }
`;

export const DashboardStatisticsLine = styled(TableListItem)`
  > svg {
    width: 1.6rem;
    ${mobileScreen} {
      width: 1.8rem;
    }
  }

  p {
    font-size: 1.26rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.39px;
    color: #79b7f5;
    ${mobileScreen} {
      font-size: 1.37rem;
    }
  }

  > div {
    opacity: 0.32;
    ${mobileScreen} {
      margin: 0 0.5rem;
    }
  }

  span {
    font-family: "F37 Judge Bold", "F37 Judge", sans-serif;
    font-size: 1.42rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: 0.71px;
    text-align: right;
    color: #fff;

    ${mobileScreen} {
      font-size: 1.54rem;
    }
  }
`;

export const DashboardCardBody = styled(Box)`
  padding: 4rem;
  transform-origin: top;
  transition: 0.3s;

  .recharts-cartesian-axis-tick-value {
    font-size: 1.05rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.33px;
    color: #242f57;
  }
  
  &.sample {
    opacity: 0.5;
  }

  ${mobileScreen} {
    &.hidden {
      height: 0;
      padding: 0;
      transform: scaleY(0);
    }
    
    .MuiButtonBase-root{
      margin-top: 6rem;
      width: 100%;
      border-radius: 3.4rem;
      background-color: #04b78a;
      opacity: 1;
      font-size: 2.44rem;
      font-weight: 500;
      margin-bottom: 2rem;
    }
  }
`;

export const DashboardCardMainValue = styled(FlexBox)`
  justify-content: space-between;
  margin-bottom: 3rem;

  p {
    font-size: 2.28rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.44px;
    color: #242f57;

    b {
      font-weight: bold;
    }
  }
`;

export const DashboardCardAccordionButton = styled(IconButton)`
  position: absolute;
  right: 2.5rem;
  top: 2rem;
  color: #fff;

  font-size: 3rem;
`;

export const ComingSoonAccordionMob = styled(FlexBox)`
  position: relative;

  > div {
    border-radius: 1.2rem;
    border: solid 1px #242f57;
    opacity: 0.2;
  }
  svg {
    width: 3.5rem;
    path {
      fill: #242f57;
    }
  }

  h3 {
    color: #242f57;
  }

  .MuiIconButton-root {
    color: #242f57;
  }
`;

export const ComingSoonSign = styled(Typography)`
  position: absolute;
  right: -1rem;

  padding: 0.9rem 1.1rem;
  border-radius: 0.8rem;
  border: solid 1px #242f57;
  background-color: #fff;

  font-family: "F37 Judge Bold", "F37 Judge", sans-serif;
  font-size: 1.42rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.71px;
  text-align: right;
  color: #242f57;
`;
