import { format, isAfter, isBefore, parse } from "date-fns";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCampaignData,
  getComparisonCampaignData,
  getListData
} from "../../api/statisticsRequests";
import { setDates } from "../../redux/filters/filtersSlice";
import { AppState } from "../../redux/store";
import { DATE_FORMAT } from "../../utils/constants";
import { filtersSelector, overviewSelector } from "../../utils/selectors";
import {useBreakpoints} from "../../utils/hooks/useBreakpoints";
import {useParams} from "react-router-dom";
import {usePathname} from "../../utils/hooks/usePathname";

export const useDashboard = (noFetch=false) => {
  const { dates, status } = useSelector(filtersSelector);
  const { ads_list } = useSelector(overviewSelector);
  const { user: {id} } = useSelector((state: AppState) => state);
  const [datesParsed, setDatesParsed] = useState<{ [key: string]: Date }>({
    start: parse(dates.start, DATE_FORMAT, new Date()),
    end: new Date(),
  });
  const [noData, setNoData] = useState(false);
  const [dataFetching, setDataFetching] = useState(true);
  const dispatch = useDispatch();
  const params = useParams()
  const {isDashboardPage, isCampaignPage} = usePathname();

  const handleChangeDatesRange = (newData: Date, index: string) => {
    setDatesParsed({ ...datesParsed, [index]: newData });
  };

  const closePopup = () => {
    setNoData(false);
  };

  const refetchData = async () => {
    const formattedDates = {
      start: format(datesParsed.start, DATE_FORMAT),
      end: format(datesParsed.end, DATE_FORMAT),
    }
    if (isCampaignPage) {
      void getComparisonCampaignData({
        start_date: formattedDates.start,
        end_date: formattedDates.end,
        comparison: "ad",
        id: params?.id as string,
        channels: ["bitmedia", "coinzilla"],
      });
      void getComparisonCampaignData({
        start_date: formattedDates.start,
        end_date: formattedDates.end,
        comparison: "group",
        id: params?.id as string,
        channels: ["bitmedia", "coinzilla"],
      });
      void getCampaignData(params?.id as string, formattedDates);
    }
    if(isDashboardPage){
      dispatch(
          setDates({
            start: format(datesParsed.start, DATE_FORMAT),
            end: format(datesParsed.end, DATE_FORMAT),
          })
      );
      setDataFetching(true);
      await getListData();
      setDataFetching(false);
    }

  };
  useEffect(() => {
    if (!id || noFetch) return;
    const fetchData = async () => {

      setDataFetching(true);
      await getListData();
      setDataFetching(false);
    };
    void fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates, id, status]);

  useEffect(() => {
    if (dataFetching || noFetch) return;
    if (!ads_list?.length) {
      setNoData(true);
    } else {
      setNoData(false);
    }
  }, [dataFetching]);

  useEffect(() => {
    if(noFetch) return;
    const inputValid = !Object.values(datesParsed).find(
      (el) => String(el) === "Invalid Date" || isAfter(el, new Date()) || isBefore(el, new Date("January 1, 2015 00:00:00"))
    );

    if (!inputValid) return;

    const timeout = setTimeout(() => {
      const data = {
        start: format(datesParsed.start, DATE_FORMAT),
        end: format(datesParsed.end, DATE_FORMAT),
      };

      if (data.start === dates.start && data.end === dates.end) return;

      dispatch(
        setDates({
          start: format(datesParsed.start, DATE_FORMAT),
          end: format(datesParsed.end, DATE_FORMAT),
        })
      );
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datesParsed]);

  return {
    datesRange: datesParsed,
    handleChangeDatesRange,
    noData, closePopup,
    refetchData
  };
};
