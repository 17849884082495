//Core
import { Route, Routes } from "react-router-dom";
import { useState } from "react";
//Components
import { AllCampaigns } from "./campaignsComponents/allCampaigns/AllCampaigns";
import { CampaignPage } from "./campaignsComponents/campaign/CampaignPage";
import {Page404} from "../Page404/Page404";
import Loading from "../../components/loading/Loading";
//Context
import { ContextViewMode, TContext } from "./ContextViewMode";
//Hooks
import { useMyCampaigns } from "./useMyCampaigns";

const MyCampaigns = () => {
  const [active, setActive] = useState<TContext>({ all: 0, campaign: 0 });
  const {loading} = useMyCampaigns();

  // if(loading) return <Loading/>

  return (
    <ContextViewMode.Provider value={[active, setActive]}>
      <div>
        <Routes>
          <Route path={"/"} element={<AllCampaigns />} />
          <Route path={":id"} element={<CampaignPage />} />
          <Route path={'*'} element={<Page404/>} />
        </Routes>
      </div>
    </ContextViewMode.Provider>
  );
};

export default MyCampaigns;
