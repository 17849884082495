import {Button, Grid, styled, Typography} from "@mui/material";
import { Stack } from "@mui/system";
import { mobileScreen } from "../../utils/breakpoints";
import {LoadingButton} from "@mui/lab";

export const OverviewAdsGrid = styled(Grid)`
  //column-gap: 3rem;
  //row-gap: 2.8rem;
  margin-bottom: 3rem;

  > div {
    max-width: calc(50% - 1.5rem);
    position: relative;

    ${mobileScreen} {
      max-width: 100%;

    }
  }

  ${mobileScreen} {
    opacity: 1 !important;
    height: fit-content !important;
    visibility: visible !important;
    width: 125%;
    margin-left: -17.5%;
  }
`;

export const OverviewEditButton = styled(LoadingButton)`
  //position: absolute;
  //top: -2px;
  //right: -1rem;
  //margin: 3rem 1rem 2rem;

  border-radius: 1.32rem;
  //border: solid 1px #2f3c42;
  background-color: #0080ff;
  color: #fff;
  padding: 0.4rem 2.2rem;
  width: fit-content;

  font-size: 1.4rem;
  font-weight: 500;
  text-transform: none;
  line-height: normal;
  /* letter-spacing: normal; */
  
  &.delete {
    border: 1px solid #2F3C42;
    background-color: #ffffff;
    color: #2F3C42;
  }

  ${mobileScreen} {
    font-size: 2.8rem;
    border-radius: 2.66rem;
    padding: 0.8rem 4.4rem;
    margin-bottom: 1.5rem;
    min-width: 100%;
  }

  &:hover {
    background-color: #0080ff;
    &.delete {
      border-color: #2F3C42;
      background-color: #ffffff;
      color: #2F3C42;
    }
  }

  .MuiButton-startIcon {
    margin-right: 0.3rem;
    ${mobileScreen} {
      margin-right: 1.1rem;
    }
  }

  svg {
    height: 1.1rem;

    ${mobileScreen} {
      height: 2.2rem;
    }
  }
`;

export const OverviewAdContainer = styled(Stack)`
  border-radius: 0.6rem;
  background-color: #fbf9f9;
  padding: 0.7rem;
`;

export const OverviewReasonTitle = styled(Typography)`
  font-weight: bold;
  color: #000000;
  letter-spacing: 0;
  margin: 0 0 1rem;
`

export const OverviewReasonText = styled(Typography)`
  color: #000000;
  letter-spacing: 0;
`