import { BinocularIcon, CoinsIcon, DevelopGraphIcon, GasIcon, HoverIcon, LoudspeakerIcon } from "../../assets/svg/icons";
import { TKeyData } from "../../types/TableTypes";
import { getStringNumber } from "../calc";

export type TCardData = {
  spent?: number;
  views?: number;
  clicks?: number;
  ads?: number;
  budget?: number;
  avgCpc?: number;
};

export const useCardData = (data: TCardData) => {
  const channelCardData = [];

  if (data.spent) {
    channelCardData.push({ title: "Spent", value: `$${getStringNumber(data.spent) || 0}`, icon: <CoinsIcon /> });
  }
  if (data.views) {
    channelCardData.push({
      title: "Views",
      value: getStringNumber(data.views),
      icon: <BinocularIcon />,
    });
  }
  if (data.clicks) {
    channelCardData.push({
      title: "Clicks",
      value: getStringNumber(data.clicks),
      icon: <HoverIcon />,
    });
  }
  if (data.ads) {
    channelCardData.push({ title: "Ads", value: data.ads, icon: <LoudspeakerIcon /> });
  }
  if (data.budget) {
    channelCardData.push({
      title: "Budget",
      value: `$${getStringNumber(data.budget)}`,
      icon: <GasIcon />,
    });
  }
  if (data.avgCpc) {
    channelCardData.push({ title: "Avg. cpc", value: `$${data.avgCpc}`, icon: <DevelopGraphIcon /> });
  }

  // const channelCardData: TKeyData[] = [
  //   { title: "Spent", value: `$${getStringNumber(data.spent)}`, icon: <CoinsIcon /> },
  //   {
  //     title: "Views",
  //     value: getStringNumber(data.views),
  //     icon: <BinocularIcon />,
  //   },
  //   {
  //     title: "Clicks",
  //     value: getStringNumber(data.clicks),
  //     icon: <HoverIcon />,
  //   },
  //   { title: "Ads", value: data.ads, icon: <LoudspeakerIcon /> },
  //   {
  //     title: "Budget",
  //     value: `$${getStringNumber(data.budget)}`,
  //     icon: <GasIcon />,
  //   },
  //   { title: "Avg. cpc", value: `$${data.avgCpc}`, icon: <DevelopGraphIcon /> },
  // ];

  return { channelCardData };
};
