import Resizer from "react-image-file-resizer";
import {UploadLogo} from "../api/web3Requests";
import {TUploadLogoBody} from "../types/SupportTypes";

export const optimizeImage = async (file: File) => {
    const resizeFile = (file: any) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                300,
                300,
                "WEBP",
                90,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64"
            );
        });
    return await resizeFile(file) as string;
};


export const uploadImage = async (file: File, id: string) => {
    const optimizedFile: string = await optimizeImage(file);
    const data: TUploadLogoBody = { image: optimizedFile, logo_id: id };
    return UploadLogo(data)
};