import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import { FlexBox } from "../../styled/Layout";
import { BudgetSpentAndRemaining, CampaignProgressContainer } from "./CampaignProgress.styles";

type PropTypes = {
  data: {
    progress: number;
    spent: number;
    remain: number;
  };
  paused?: boolean;
};

const CampaignProgress = ({ data, paused }: PropTypes) => {
  const progressDisplay = data.progress < 100 ? data.progress : 100;

  return (
    <CampaignProgressContainer className={paused ? 'paused' : ''}>
      <Typography>Campaign Progress:</Typography>
        <FlexBox >
      <Stack width={{xs: '100%', mobL: "24.6rem"}}>
        <LinearProgress variant="determinate" value={progressDisplay} />
        {/*<BudgetSpentAndRemaining className={'desktopOnly'}>*/}
        {/*  <Typography>${data?.spent}</Typography>*/}
        {/*  <Typography>${data?.remain}</Typography>*/}
        {/*</BudgetSpentAndRemaining>*/}
      </Stack>
      <Typography>{progressDisplay}%</Typography>
        </FlexBox>
    </CampaignProgressContainer>
  );
};

export default CampaignProgress;
