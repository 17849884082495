import { Tooltip } from "@mui/material";
import { TooltipArrow } from "../assets/svg/interface";
// import { ChildrenType } from "../types/GeneralTypes";

type TCustomTooltip = {
  title: string | JSX.Element;
  sx?: object;
  disabled?: boolean | undefined;
  children: any;
};

export const CustomTooltip = ({ children, title, sx, disabled }: TCustomTooltip) => {
  return (
    <Tooltip
      title={title}
      arrow
      placement="top"
      components={{ Arrow: TooltipArrow }}
      disableHoverListener={disabled}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: "#fff",
            border: "solid 1px #cacdd6",
            borderRadius: "0.7rem",
            boxShadow: "0 4px 6px 0 rgba(0, 0, 0, 0.14)",
            color: "#000",
            padding: "3rem",
            ...sx,
          },
        },
        arrow: {
          style: {
            position: "absolute",
            top: "calc(100% - 2px)",
            left: "50%",
            translate: "-50%",
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};
