import {Checkbox, FormControlLabel, styled} from "@mui/material";
import theme from "../../theme/theme";
import React, {ReactNode} from "react";

export const IconCheckbox = styled("span")`
  font-size: unset;
  width: 1.6rem;
  height: 1.6rem;
  border: 1px solid #abb2c8;
  border-radius: 50%;
`;

export const CheckedIcon = styled(IconCheckbox)({
    border: `unset`,
    backgroundColor: theme.palette.primary.main,
    backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&.Mui-disabled": {
        "& span": {
            opacity: "0.5",
        },
    },
    "&:before": {
        display: "block",
        width: "1.4rem",
        height: "1.4rem",
        margin: "0.1rem auto 0",
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
    },
    "input:hover ~ &": {
        backgroundColor: theme.palette.primary.main,
    },
    "&.darkIcon": {
        "&:before": {
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23000'/%3E%3C/svg%3E\")",
        },
    },
});

export const CheckboxCustom = ({darkIcon, ...props}: any) => {
    return <Checkbox {...props}
                     icon={<IconCheckbox/>}
                     checkedIcon={<CheckedIcon className={darkIcon ? "darkIcon" : ""}/>}
                     sx={{pointerEvents: 'auto'}}
    />;
};

type TCheckboxWithLabel = {
    label: string | ReactNode;
    checked: boolean;
    name: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    labelPlacement?: "bottom" | "top" | "end" | "start" | undefined;
    disabled?: boolean;
    iconDark?: boolean;
    className?: string,
    onClick?: () => void
};

export const CheckboxWithLabel = (
    {
        label,
        checked,
        name,
        onChange,
        labelPlacement,
        disabled,
        iconDark,
        className,
        onClick
    }: TCheckboxWithLabel) => {
    const FormControlLabelStyled = styled(FormControlLabel)`
      display: flex;
      padding-left: ${labelPlacement === "end" && "1rem"};
      width: fit-content;

      &.comparison {
        pointer-events: none;
      }

      & p {
        align-self: center;
        font-weight: 500;
      }

      & .MuiFormControlLabel-label {
        opacity: ${checked ? 1 : 0.5};
      }

    `;
    return (
        <FormControlLabelStyled
            className={className}
            control={<CheckboxCustom checked={checked} name={name} onChange={onChange} disabled={disabled}
                                     darkIcon={iconDark}/>}
            label={label}
            labelPlacement={labelPlacement ? labelPlacement : "end"}
        />
    );
};

export const TransparentCheckbox = ({radio = false, ...props}: any) => {
    function Icon<T>(props: T) {
        return (
            <svg width={"2.7rem"} height={"2.7rem"} viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg" {...props}>
                <rect
                    x={-0.5}
                    y={-0.5}
                    width={25}
                    height={25}
                    rx={12}
                    transform="translate(-382 -775) translate(229.136 763.206) translate(154.728 13.15)"
                    stroke="#F4F7FC"
                    strokeWidth={1}
                    fill="none"
                    fillRule="evenodd"
                />
            </svg>
        );
    }

    function CheckedIcon() {
        return (
            <svg width={"2.7rem"} height={"2.7rem"} viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg">
                <g
                    transform="translate(-382 -775) translate(229.136 763.206) translate(154.728 13.15)"
                    stroke="none"
                    strokeWidth={1}
                    fill="none"
                    fillRule="evenodd"
                >
                    <rect stroke="#F4F7FC" x={-0.5} y={-0.5} width={25} height={25} rx={12}/>
                    <path
                        stroke="#FFF"
                        strokeWidth={1.5}
                        strokeLinecap="round"
                        d="M7.2869496 11.7581119L10.603965 15.4847244 16.7130504 8.51527559"
                    />
                </g>
            </svg>
        );
    }

    function RadioIcon() {
        return (
            <svg
                width="2.7rem"
                height="2.7rem"
                viewBox="0 0 27 27"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <g
                    transform="translate(-422 -783)"
                    stroke="#F4F7FC"
                    strokeWidth={1}
                    fill="none"
                    fillRule="evenodd"
                >
                    <rect
                        x={-0.5}
                        y={-0.5}
                        width={25}
                        height={25}
                        rx={12}
                        transform="translate(229.136 763.206) translate(194.728 21.15)"
                    />
                    <rect
                        fill="#FFF"
                        x={430.363872}
                        y={790.855617}
                        width={11}
                        height={11}
                        rx={5.5}
                    />
                </g>
            </svg>
        )
    }

    return <Checkbox icon={<Icon/>} checkedIcon={radio ? <RadioIcon/> : <CheckedIcon/>} {...props} />;
};
