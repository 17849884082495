import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getCampaignList } from "../../../../api/statisticsRequests";
import { campaignsSelector } from "../../../../utils/selectors";

export const useRunningCampaigns = () => {
  const { list } = useSelector(campaignsSelector);

  const runningAds = list.filter((el) => el.campaign_status.status === "running" || el.campaign_status.status === "active");

  return { runningAds, list };
};
