import { Box, Button, Container, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import React from "react";
import { CopyIcon, DocIcon } from "../../../../assets/svg/icons";
import { GreenButton } from "../../../../styled/buttons";
import { BlockContainer } from "../../../../styled/Layout";
import ConnectPixelPopup from "../../popups/ConnectPixelPopup";
import { usePixel } from "../../usePixel";
import { DataMiningMobileButtons, DataMiningPixelGuide, PixelCopyCode } from "./DataMiningPixel.styles";

const DataMiningPixel = () => {
  const { data, loading } = usePixel("get_js_pixel");

  return (
    <Container>
      <BlockContainer
        mt={{ mobL: "7rem" }}
        sx={{
          borderWidth: { xs: 0, mobL: 1 },
          boxShadow: { xs: "none", mobL: "0 1.3rem 2.3rem 0 rgb(0 0 0 / 8%)" },
          p: { xs: "1rem", mobL: "4rem 3.5rem" },
        }}
      >
        <Typography variant="h3" mb="8rem">
          Pixel Management
        </Typography>
        <DataMiningPixelGuide>
          <Grid2 container>
            <Grid2 xs={12} mobL={4}>
              <Typography variant="h4">Installation guide</Typography>
              <Typography>
                To incorporate the functionality of the js file, simply copy and paste the code into the html of your website. The js file
                serves a dual purpose, gathering valuable data for analytics on both the online activities of users, including their
                demographics and interests, as well as conducting an analysis of on-chain wallets. <br /> <br/>  By installing the pixel, you will
                become eligible to collect WEB3M tokens during the upcoming token airdrop. Don't miss out on this opportunity to enhance
                your website's analytics and potentially earn tokens - install the pixel today!
              </Typography>
            </Grid2>
            <Grid2 xs={12} mobL={8}>
              <Typography variant="h4">Integration Code</Typography>
              <PixelCopyCode>
                <Typography>{data?.js_pixel}</Typography>
                <Button className="desktopOnly" endIcon={<CopyIcon />} onClick={() => navigator.clipboard.writeText(data?.js_pixel ?? "")}>
                  Copy to clipboard
                </Button>
              </PixelCopyCode>
            </Grid2>
          </Grid2>
          <div className="divider desktopOnly" />
        </DataMiningPixelGuide>
        <DataMiningMobileButtons>
          <Button onClick={() => navigator.clipboard.writeText(data?.js_pixel ?? "")}>Copy to ClipBoard</Button>
          <Button>+ Add new pixel</Button>
        </DataMiningMobileButtons>
      </BlockContainer>
      <ConnectPixelPopup open={loading} />
    </Container>
  );
};

export default DataMiningPixel;
