import {ChangeEvent, useState} from "react";
import {T404RequestBody, TSupportRequestBody, TSupportResponse} from "../../../types/SupportTypes";
import {uploadImage} from "../../../utils/optimizeImage";
import {TObjectBoolean} from "../../../types/GeneralTypes";
import * as yup from 'yup';
import {useNavigate} from "react-router-dom";
import {TSupportForm} from "./SupportForm";


export const useSupportForm = (props: TSupportForm) => {
    const {sendFormRequest, setIs404} = props
    const [formData, setFormData] = useState<TSupportRequestBody>({
        first_name: '',
        last_name: '',
        email_to_return: '',
        issue_title: '',
        issue_desc: '',
        issue_screenshots_links: [],
    })
    const [loadingImage, setLoadingImage] = useState<TObjectBoolean>({})
    const [loadingForm, setLoadingForm] = useState<boolean>(false)
    //Status of request sent_support_form
    const [status, setStatus] = useState<string>('')

    const navigate = useNavigate()

    const validationSchema = yup.object({
        first_name: yup.string().min(1).required('Name is required'),
        email_to_return: yup.string().email('Email not valid').required('Email is required'),
        issue_title: yup.string().min(1).required('Title is required'),
        issue_desc: yup.string().min(1).required('Description is required')
    })

    const uploadFile = async (e: ChangeEvent<HTMLInputElement>, i: number) => {
        setLoadingImage({...loadingImage, [e.target.id]: true})
        const file = await uploadImage(e.target.files?.[0] as File, e.target.id)
        setLoadingImage({...loadingImage, [e.target.id]: false})
        console.log(file)
        const newImages = [...formData.issue_screenshots_links]
        newImages.splice(i, 1, file.image)
        setFormData({...formData, issue_screenshots_links: newImages})
    }

    const deleteFile = (i: number) => {
        const newImages = [...formData.issue_screenshots_links]
        newImages.splice(i, 1)
        setFormData({...formData, issue_screenshots_links: newImages})
    }

    const sendForm = async (values: TSupportRequestBody | T404RequestBody) => {
        setLoadingForm(true)
        console.log(values)
        const response = await sendFormRequest({...values, issue_screenshots_links: formData.issue_screenshots_links})
        setLoadingForm(false)
        if (response?.sent) {
            setFormData({
                first_name: '',
                last_name: '',
                email_to_return: '',
                issue_title: '',
                issue_desc: '',
                issue_screenshots_links: [],
            })
            setStatus('support')
        }
        if (setIs404) {
            setTimeout(() => {
                setIs404(false)
                navigate('/dashboard')
            }, 3000)
        }

    }

    const closePopup = () => {
        setStatus("")

    }

    return {
        formData,
        status,
        closePopup,
        uploadFile,
        deleteFile,
        loadingImage,
        loadingForm,
        sendForm,
        validationSchema,
    }
}