import {TParameter} from "../../types/AdsComparisonTypes";
import theme from "../../theme/theme";

export const parameterListComparison: { [key: string]: TParameter } = {
    ROI: "roi",
    Spent: "total_amount_spent",
    Clicks: "total_clicks",
    Views: "total_impressions",
};

export const colorsComparison: string[] = [
    theme.colors.graphBlue as string,
    theme.palette.success.main,
    theme.colors.graphOrange as string,
    theme.colors.graphPurple as string,
    theme.colors.circlePink as string,
];