// Components
import DataCard from "./DataCard";

// Styles
import { Grid } from "@mui/material";

// Hooks
import { useCardData } from "../../utils/hooks/useCardData";
import { TDataCards } from "./useDataCards";
import { useLoading } from "../../utils/hooks/useLoading";
import BlockContent from "../../layout/blockContent/BlockContent";

const DataCards = ({ data }: { data: TDataCards }) => {
  const { channelCardData } = useCardData(data);
  const isLoading = useLoading("campaign");

  return (
    <BlockContent loading={isLoading}>
      <Grid container spacing={"3.1rem"}>
        {channelCardData.map((el, i) => (
          <Grid item xs={2} key={el.title + i}>
            <DataCard data={el} />
          </Grid>
        ))}
      </Grid>
    </BlockContent>
  );
};

export default DataCards;
