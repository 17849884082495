// Styles
import { Box, IconButton, Typography } from "@mui/material";

// Hooks

// Images

// Types and enums

import { ArrowForwardIos } from "@mui/icons-material";
import { channelIcons } from "../../assets/svg/channels";
import { TriangleSvg } from "../../assets/svg/interface";
import { FlexBox } from "../../styled/Layout";
import { AdContainer } from "./CreativeCard.styles";
import { TPublisher } from "../../types/reduxData/dataTypes";

type Tdata = {
  language?: string;
  title: string;
  description?: string;
  description2?: string;
  cUrl: string;
  dUrl?: string;
  long_desc?: string;
  small_desc?: string;
  call_to_action?: string;
  channel: TPublisher;
};

const TextTemplate = ({
  data,
  disabled = false,
  channel = "bitmedia",
}: {
  data: Tdata;
  disabled?: boolean;
  channel?: TPublisher;
}) => {
  // const { url } = useCampaignData();

  if (!data) return null;

  return (
    <AdContainer mt={"1rem"}>
      <Box className="icon">
        <TriangleSvg />
      </Box>
      <Typography className="title">{data.title || <span>Ad Title</span>}</Typography>
      <Typography className="desc1">{data.description || <span>Lorem Ipsum</span>}</Typography>
      <Typography className="desc2">{data.description2 || <span>Lorem Ipsum</span>}</Typography>
      <FlexBox className="url">
        {/* <WebSvg /> */}
        <Typography>{data.dUrl || data.call_to_action || <span>example.com</span>}</Typography>
      </FlexBox>
      <IconButton disabled={disabled} disableRipple>
        <ArrowForwardIos />
      </IconButton>
      <Box className="channelIcon">{channelIcons[data.channel]}</Box>
    </AdContainer>
  );
};

export default TextTemplate;
