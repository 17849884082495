import { Dialog, DialogContent, styled } from "@mui/material";
import { mobileScreen } from "../../../utils/breakpoints";

export const ConnectPixelPopupContainer = styled(Dialog)`
  .MuiPaper-root {
    max-width: 80rem;
    width: 90%;

    border-radius: 1.7rem;
    box-shadow: 0 79px 97px 0 rgba(0, 0, 0, 0.14);
    
    ${mobileScreen}{
      //width: 95%;
      margin: 0;
    }
  }
`;

export const ConnectPixelPopupContent = styled(DialogContent)`
  padding: 4rem;

  h2 {
    font-size: 2.34rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.26px;
    text-align: left;
    color: #08513f;
    margin-bottom: 0.7rem;
  }

  h4 {
    font-size: 1.74rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.19px;
    text-align: left;
    color: #078767;

    b {
      font-weight: bold;
    }
  }

  p {
    font-size: 1.34rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.64;
    letter-spacing: 0.15px;
    text-align: left;
    color: #727272;
    width: 50%;

    ${mobileScreen} {
      width: 100%;
    }
  }

  > .MuiBox-root {
    width: 10rem;
    height: 10rem;
    margin: 8rem auto;
  }
`;
