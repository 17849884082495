import * as React from "react"

export function Trash(props: {svgcolor?: string}) {
    return (
        <svg
            viewBox="0 0 17 20"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M7.096.835c-.692 0-1.253.56-1.253 1.252v1.252h4.174V2.087c0-.692-.56-1.252-1.252-1.252h-1.67zm3.756 2.504V2.087A2.087 2.087 0 008.765 0h-1.67A2.087 2.087 0 005.01 2.087v1.252H.417a.418.418 0 000 .835H.88l1.463 13.17A2.086 2.086 0 004.416 19.2h7.029c1.063 0 1.957-.8 2.073-1.857l1.464-13.17h.461a.418.418 0 000-.834h-4.59zm3.29.835H1.72L3.17 17.252a1.252 1.252 0 001.245 1.113h7.029c.638 0 1.174-.48 1.245-1.113l1.452-13.078zm-8.177 5.13a.419.419 0 01.591 0L7.93 10.68l1.374-1.375a.419.419 0 01.592.592L8.52 11.27l1.375 1.374a.419.419 0 01-.592.59L7.93 11.86l-1.374 1.375a.419.419 0 01-.59-.591L7.34 11.27 5.965 9.896a.419.419 0 010-.592z"
                transform="translate(-1546 -1086) translate(1546.8 1086.037)"
                fill={props.svgcolor || 'red'}
                stroke="none"
                strokeWidth={1}
                fillRule="evenodd"
            />
        </svg>
    )
}

