// Components
import CampaignItem from "./CampaignItem";
// Styles
import { Stack } from "@mui/system";
import { CampaignsTablePagination } from "./CampaignsTable.styles";
// Utils
import {NUM_ITEMS_ON_PAGE, useCampaignTable} from "./useCampaignsTable";
import { popupList, TPopup } from "../../layout/popups/PopupList";



const CampaignsTable = () => {
  const {activePage, setActivePage, popup, closePopup, updateStatus, campaigns, paginationDots} = useCampaignTable();

  return (
      <Stack spacing={{xs: '2rem', mobL:".5rem"}} pb="14rem">
        {campaigns
          .slice(activePage * NUM_ITEMS_ON_PAGE, activePage * NUM_ITEMS_ON_PAGE + NUM_ITEMS_ON_PAGE)
          .map((el) => (
            <CampaignItem
              key={el.id}
              data={el}
              updateStatus={updateStatus}
            />
          ))}
        {campaigns.length > NUM_ITEMS_ON_PAGE && (
          <CampaignsTablePagination>
            {Array(paginationDots)
              .fill("")
              .map((el, i) => (
                <span key={i} className={i === activePage ? "active" : ""} onClick={() => setActivePage(i)} />
              ))}
          </CampaignsTablePagination>
        )}
        {popup && popupList[popup as TPopup] && popupList[popup as TPopup]({ closePopup })}
      </Stack>
  );
};

export default CampaignsTable;
