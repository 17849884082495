import {Typography} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {TextFieldStyled} from "../../layout/subheader/Subheader.styles";
import {FlexBox} from "../../styled/Layout";
import {TCampaignListItem} from "../../types/reduxData/campaignsTypes";
import {TDatesRange} from "../../pages/Campaigns/campaignsComponents/campaign/useCampaignSubheader";

type TDateFilterProps = {
    datesRange: TDatesRange | undefined;
    handleChangeDatesRange: (newData: Date, index: string) => void;
};
const DateFilter = ({datesRange, handleChangeDatesRange}:TDateFilterProps) => {
 return (
     <FlexBox>
         <Typography fontWeight={500}>{"Filter by date range:"}</Typography>
         <LocalizationProvider dateAdapter={AdapterDateFns}>
             <DatePicker
                 onChange={(newValue) => handleChangeDatesRange(newValue as Date, "start")}
                 value={datesRange?.start}
                 renderInput={(params) => <TextFieldStyled {...params} />}
                 maxDate={datesRange?.end}
                 // minDate={minDate || availableDates.start}
                 inputFormat={"dd/MM/yyyy"}
                 views={["year", "month", "day"]}
             />
         </LocalizationProvider>
         <LocalizationProvider dateAdapter={AdapterDateFns}>
             <DatePicker
                 onChange={(newValue) => handleChangeDatesRange(newValue as Date, "end")}
                 value={datesRange?.end}
                 renderInput={(params) => <TextFieldStyled {...params} />}
                 minDate={datesRange?.start}
                 maxDate={new Date()}
                 inputFormat={"dd/MM/yyyy"}
                 views={["year", "month", "day"]}
             />
         </LocalizationProvider>
     </FlexBox>
 )}

export default DateFilter;