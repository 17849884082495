export const formatCreatives = (rawCreatives: any) => {
  if (!rawCreatives?.length) return [];

  return [...rawCreatives]
    ?.sort((a, b) => b.total_clicks - a.total_clicks)
    .map((ad) => ({
      id: ad.ad_id,
      channel: ad.channel,
      campaign: ad?.campaign_name || "",
      group_name: ad.group_name,
      title: ad.creative?.title || ad.creative?.headline || " ",
      description: ad.creative?.desc || ad.creative?.small_desc || ad.creative?.desc_1 || "",
      description2: ad.creative?.long_desc || ad.creative?.desc_2 || "",
      image: ad.creative?.image,
      image_size: {
        width: ad.creative?.image_size?.width || 200,
        height: ad.creative?.image_size?.height || 200,
      },
      logo: ad.creative?.logo,
      dUrl: ad.creative?.dUrl || ad.creative?.businessName || ad.creative?.call_to_action,
      type: ad.creative?.type,
      numbers: [
        {
          name: "spent",
          value: ad.total_amount_spent,
        },
        {
          name: "views",
          value: ad.total_impressions,
        },
        {
          name: "clicks",
          value: ad.total_clicks,
        },
        {
          name: "budget",
          value: ad.budget,
        },
        {
          name: "avg. cpc",
          value: ad.cpc,
        },
      ],
    }));
};
