import { Box, styled, Typography } from "@mui/material";
import { FlexBox } from "../../styled/Layout";
import { mobileScreen } from "../../utils/breakpoints";

export const PPCHeaderLinks = styled(FlexBox)`
  ${mobileScreen} {
    //margin-left: -10%;
    margin-top: 4rem;
    //width: 100vw;
  }

  a {
    p {
      font-size: 1.3rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.14px;
      color: #6b6c72;
      opacity: 0.92;
      transition: 0.3s;

      &.active {
        color: #242f57;
        font-weight: 600;
        text-decoration: underline;
      }
    }

    ${mobileScreen} {
      width: 50vw;
      background-color: rgba(161, 165, 182, 0.09);

      p {
        padding: 3rem 1rem;
        font-size: 2.34rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.26px;
        color: #818698;
        text-align: center;

        &.active {
          background-color: rgba(161, 165, 182, 0.73);
          text-decoration: none;
          color: #fff;
          font-weight: 500;
        }
      }
    }
  }
`;

export const LinkDivider = styled(Typography)`
  margin: 0 2.5rem;
  color: #242f57;
  opacity: 0.37;

  ${mobileScreen} {
    display: none;
  }
`;
