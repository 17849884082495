export function ClickIcon<T>(props:T) {
    return (
        <svg
            width="2.4rem"
            height="3.2rem"
            viewBox="0 0 24 32"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M8.766 1.349A7.418 7.418 0 001.35 8.766a.675.675 0 01-1.349 0A8.766 8.766 0 018.766 0a8.766 8.766 0 018.766 8.766.675.675 0 01-1.348 0A7.418 7.418 0 008.766 1.35zM5.395 8.766a3.371 3.371 0 116.743 0v3.505l7.81 1.736a4.72 4.72 0 013.62 5.452l-.995 5.47a7.418 7.418 0 01-7.297 6.09h-4.581a7.415 7.415 0 01-5.833-2.835l-4.01-5.105a3.44 3.44 0 014.543-5.033v-9.28zm6.743 4.886v3.206a.675.675 0 01-1.349 0V8.766a2.024 2.024 0 00-4.046 0v10.79a.674.674 0 01-1.152.477l-.555-.557a2.093 2.093 0 00-3.086.14 2.096 2.096 0 00-.038 2.631l4.011 5.103a6.067 6.067 0 004.772 2.32h4.581a6.069 6.069 0 005.97-4.983l.996-5.469a3.373 3.373 0 00-2.587-3.895l-7.517-1.67z"
                transform="translate(-486 -328) translate(447.5 296.561) translate(38.754 32.41)"
                fill="#FFF"
                stroke="none"
                strokeWidth={1}
                fillRule="evenodd"
            />
        </svg>
    )
}

export const DiskIcon = (props: any) => (
    <svg
    width={'3.2rem'}
    height={'3.2rem'}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.37179 2.01441C3.14737 2.01441 2.15364 3.00667 2.15364 4.23257V27.8929C2.15364 29.1188 3.14737 30.111 4.37179 30.111H6.58994V21.9778C6.58994 19.9356 8.24469 18.2809 10.2869 18.2809H22.117C24.1592 18.2809 25.8139 19.9356 25.8139 21.9778V30.111H28.0321C29.2565 30.111 30.2503 29.1188 30.2503 27.8929V8.97498L23.2897 2.01441H21.3776V8.66888C21.3776 10.7111 19.7229 12.3658 17.6807 12.3658H10.2869C8.24469 12.3658 6.58994 10.7111 6.58994 8.66888V2.01441H4.37179ZM8.06871 2.01441V8.66888C8.06871 9.89478 9.06245 10.887 10.2869 10.887H17.6807C18.9051 10.887 19.8989 9.89478 19.8989 8.66888V2.01441H8.06871ZM24.3352 30.111V21.9778C24.3352 20.7519 23.3414 19.7596 22.117 19.7596H10.2869C9.06245 19.7596 8.06871 20.7519 8.06871 21.9778V30.111H24.3352ZM0.674866 4.23257C0.674866 2.19039 2.32961 0.535645 4.37179 0.535645H23.5958C23.7925 0.535645 23.9803 0.614019 24.1193 0.751545L31.5131 8.14539C31.6506 8.2844 31.729 8.4722 31.729 8.66888V27.8929C31.729 29.9351 30.0743 31.5898 28.0321 31.5898H4.37179C2.32961 31.5898 0.674866 29.9351 0.674866 27.8929V4.23257ZM16.2019 4.97195C16.6101 4.97195 16.9413 5.3032 16.9413 5.71134V7.19011C16.9413 7.59825 16.6101 7.92949 16.2019 7.92949C15.7938 7.92949 15.4626 7.59825 15.4626 7.19011V5.71134C15.4626 5.3032 15.7938 4.97195 16.2019 4.97195V4.97195Z"
      fill="white"
    />
  </svg>
  );