import { LinkDivider, PPCHeaderLinks } from "./PPCHeader.styles";
import { Typography } from "@mui/material";
import {FlexBox, SectionHeadBackground, SectionHeadContainer} from "../../styled/Layout";
import { Link } from "react-router-dom";
import { usePathname } from "../../utils/hooks/usePathname";

const DataMiningHeader = () => {
  const { isDataminingOverview, isDataminingPixel } = usePathname();

  return (
    <SectionHeadBackground>
      <SectionHeadContainer>
        <FlexBox flexDirection={{xs: 'column', mobL: 'row'}} width='100%'>
          <Typography variant={"h2"} alignSelf={'flex-start'}>Data Mining</Typography>
          <PPCHeaderLinks>
            <Link to={"/data_mining/overview"}>
              <Typography className={isDataminingOverview ? "active" : ""}>Overview</Typography>
            </Link>
            <LinkDivider>|</LinkDivider>
            <Link to={"/data_mining/pixel"}>
              <Typography className={isDataminingPixel ? "active" : ""}>Pixel</Typography>
            </Link>
          </PPCHeaderLinks>
        </FlexBox>
      </SectionHeadContainer>
    </SectionHeadBackground>
  );
};

export default DataMiningHeader;
