import { useContext, useEffect, useState } from "react";
import { ContextViewMode } from "../../../pages/Campaigns/ContextViewMode";
import { ContextCheckbox } from "../../CheckboxGroups/ContextCheckbox";
import {TPublisher} from "../../../types/reduxData/dataTypes";

export const useViewModeCampaign = (open: boolean, closePopup: () => void) => {
  const [active, setActive] = useContext(ContextViewMode);
  const [mainSelected, setMainSelected, filteredData, channelsSelected, channelRadio, setChannelsSelected, setChannelRadio] = useContext(ContextCheckbox);
  const [campaignMode, setCampaignMode] = useState(active.campaign || 0);
  const [localChannels, setLocalChannels] = useState(channelsSelected);

  const handleSelectChannels = (channel: TPublisher) => {
    if (campaignMode === 1) {
      const newData = { ...localChannels };
      Object.entries(newData).forEach(([key, value]) => {
        if (key === channel) {
          newData[key] = true;
        } else {
          newData[key] = false;
        }
      });
      setLocalChannels(newData);
      setChannelRadio(channel)
    } else {
      setLocalChannels({ ...localChannels, [channel]: !localChannels[channel] });
    }
  };

  const handleSelectCampaignMode = (index: number) => {
    if (campaignMode !== index) {
      setCampaignMode(index);
    }
    const selectedChannelsNum = Object.values(localChannels).filter((ch) => ch).length;
    if (index === 1 && selectedChannelsNum > 1) {
      const newData = { ...localChannels };
      Object.entries(newData).forEach(([key, value], index) => {
        if (index === 0) {
          newData[key] = true;
        } else {
          newData[key] = false;
        }
      });
      setLocalChannels(newData);
    }
  };

  useEffect(() => {
    setLocalChannels(channelsSelected);
  }, [open, channelsSelected]);

  const onSaveCampaign = () => {
    setActive({ ...active, campaign: campaignMode });
    setChannelsSelected(localChannels);
  };

  return { campaignMode, localChannels, handleSelectCampaignMode, handleSelectChannels, onSaveCampaign };
};
