import { Box, Typography } from "@mui/material";
import { Bar, BarChart, CartesianGrid, LabelList, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import BlockContent from "../../../../../layout/blockContent/BlockContent";
import { FlexBox, MobileBasicStats } from "../../../../../styled/Layout";
import { RadioWithLabel } from "../../../../../styled/radio/radio";
import { SelectCustom } from "../../../../../styled/select";
import { getStringNumber } from "../../../../../utils/calc";
import { useBreakpoints } from "../../../../../utils/hooks/useBreakpoints";
import { usePixel } from "../../../usePixel";
import {TPeriod, usePixelDateFilter} from "./usePixelDateFilter";

const defgraphData = [
  { date: "Sunday", value: 1000 },
  { date: "Monday", value: 2000 },
  { date: "Tuesday", value: 3200 },
  { date: "Wednesday", value: 3600 },
  { date: "Thursday", value: 2500 },
  { date: "Friday", value: 4300 },
  { date: "Saturday", value: 4500 },
];

const radioData = ["Daily", "Weekly", "Monthly"];

const VisitorsAmount = () => {
  const { data, loading } = usePixel("daily_visitors");
  // const graphData = data?.data?.map(([date, value]: [string, number]) => ({ date, value }));
  const { mobileLarge } = useBreakpoints();

  const {period, setPeriod, graphData} = usePixelDateFilter(data?.data)

  return (
    <Box minHeight={"30rem"}>
      <BlockContent loading={loading}>
        <FlexBox
          mb={{mobL: "7rem"}}
          justifyContent={"space-between"}
          flexDirection={{ xs: "column", mobL: "row" }}
          alignItems="flex-start"
          width="100%"
        >
          <Typography variant="h3">Visitors Amount</Typography>
          {!mobileLarge && <RadioWithLabel data={radioData} selected={period} onSelect={(e: any) => setPeriod(e?.target?.value as TPeriod)} />}
          {mobileLarge && (
            <Box mt={"3rem"} width={"100%"}>
              <SelectCustom value={period} setValue={setPeriod as any} data={radioData} width={"100%"} />
              <MobileBasicStats mt="2rem">
                <Typography variant="h5">Avg. daily amount</Typography>
                <Typography>{getStringNumber(data?.avg_daily_visitors ?? 0)}</Typography>
              </MobileBasicStats>
            </Box>
          )}
        </FlexBox>
        <ResponsiveContainer width={"100%"} height={300}>
          {!mobileLarge ? (
            <LineChart
              data={graphData}
              width={600}
              height={300}
              margin={{
                top: 5,
                left: -10,
                right: 10,
                bottom: 15,
              }}
            >
              <XAxis dataKey="date" tickMargin={15} axisLine={false} tickLine={false} />

              <YAxis tickMargin={15} tickFormatter={(val: number) => getStringNumber(val) as string} axisLine={false} tickLine={false} />
              <Line dataKey={"value"} stroke={"#f2a626"} strokeWidth={3} fill={"#f2a626"} />
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
            </LineChart>
          ) : (
            <BarChart data={graphData} width={600} height={300}>
              <XAxis dataKey="date" tickMargin={15} axisLine={false} tickLine={false} style={{fontSize: '1.2rem'}} />

              <Bar dataKey={"value"} fill={"#0080ff"} radius={[7, 7, 7, 7]} minPointSize={27} barSize={50}>
                <LabelList
                  dataKey={"value"}
                  position="insideTop"
                  offset={8}
                  formatter={(val: number) => getStringNumber(Math.round(val))}
                  style={{ fill: "#fff", fontWeight: "bold" }}
                />
              </Bar>
            </BarChart>
          )}
        </ResponsiveContainer>
      </BlockContent>
    </Box>
  );
};

export default VisitorsAmount;
