//Core
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
// Utils
import {appLink} from "../../utils/constants";
// Components
import CampaignProgress from "../CampaignProgress/CampaignProgress";
// Styles
import {Avatar, capitalize, Typography, Stack, Button, Box, Switch} from "@mui/material";
import {FlexBox} from "../../styled/Layout";
import {
    AddToList,
    CampaignItemActionsMob,
    CampaignItemBrandData,
    CampaignItemContainer,
    CampaignItemStatus
} from "./CampaignsTable.styles";
// Hooks
import {useBreakpoints} from "../../utils/hooks/useBreakpoints";
// Images
import {ClosePopupIcon, LineGraphIcon, MoonIcon, PencilIcon} from "../../assets/svg/icons";

const CampaignItem = ({data, updateStatus}: any) => {
    const {mobileLarge} = useBreakpoints();
    const [offset, setOffset] = useState(0);
    const [prevOffset, setPrevOffset] = useState(0);
    const [status, setStatus] = useState(data?.status || '');
    const [statusChanged, setStatusChanged] = useState(false);

    const isSwitch = data.status === 'waiting' &&
        (data.next_status === 'active' || data.next_status === 'pause') &&
        (data.prev_status !== 'draft')

    useEffect(() => {
        if (isSwitch) {
            setStatusChanged(true)
        }
    }, [data])

    const handleChangeStatus = async () => {
        if (status === 'active') {
            !mobileLarge && setStatus('pause')
            updateStatus('pause', 'active', data.id)
        } else {
            !mobileLarge && setStatus('active')
            updateStatus('active', 'pause', data.id)
        }
        setOffset(13)
        setStatusChanged(true)
    }

    const onTouchMove = (e: any) => {
        const pageX = e.targetTouches[0].clientX;
        if (pageX < prevOffset) {
            const newOffset = (status === "draft" || status === 'waiting') ? 13 : status === "active" ? 26 : 23;
            setOffset(newOffset);
        } else {
            setOffset(0);
        }
        setPrevOffset(pageX);
    };

    const navigate = useNavigate();

    return (
        <Box sx={{position: "relative"}}>
            <CampaignItemContainer
                status={data?.status}
                onClick={() => ((data?.status !== "draft" && data?.status !== 'waiting') || isSwitch) && navigate(`${data.id}`)}
                onTouchMove={onTouchMove}
                sx={{transform: `translateX(-${offset}rem)`}}
            >
                <FlexBox>
                    {/*{!mobileLarge && (*/}
                    {/*  <CheckboxCustom checked={selected} onChange={() => onSelect(data.id)} onClick={(e: any) => e.stopPropagation()} />*/}
                    {/*)}*/}
                    <CampaignItemBrandData>
                        <Avatar src={data?.logo}>{capitalize(data?.title?.[0] ?? "")}</Avatar>
                        <Stack className={"brand-info"}>
                            <Typography variant={"h5"} className={(status ?? "") + ' mobileOnly'}>
                                {statusChanged ? 'waiting' : status}
                            </Typography>
                            <Typography>{capitalize(data?.title ?? "Campaign Draft")}</Typography>
                            <Typography>{data?.url ?? ""}</Typography>
                        </Stack>
                        <CampaignItemStatus className={(isSwitch ? data.next_status : status) + (statusChanged ? ' disabled' : '')}
                                            onClick={(e) => e.stopPropagation()}>
                            <Typography>{isSwitch ? data.next_status : status}</Typography>
                            {(isSwitch || status === 'active' || status === 'pause') &&
                                <Switch checked={data.next_status === 'active'} disabled={statusChanged}
                                        onChange={handleChangeStatus}/>}
                            {/*{data.status === "pause" && <Switch checked={false} />}*/}
                            {(status === "draft" || (status === 'waiting' && data.prev_status === 'draft')) && <PencilIcon/>}
                            {status === "expired" && <MoonIcon/>}
                            {status === 'rejected' && <ClosePopupIcon/>}
                            {statusChanged &&
                                <Typography className={'waiting'}>Waiting for status update...</Typography>}
                        </CampaignItemStatus>
                    </CampaignItemBrandData>
                </FlexBox>
                <FlexBox>
                    {!mobileLarge &&
                        (data?.status === 'active') &&
                        <CampaignProgress data={data} paused={data.status !== "active"}/>
                    }
                    {!mobileLarge && (
                        <a href={`${appLink}/${data.id}/summary`} target={data.id} style={{marginLeft: "5.5rem"}}>
                            <AddToList onClick={(e: any) => e.stopPropagation()} disableRipple>
                                <Typography>. . .</Typography>
                                {/*{data?.status === "draft" ? <BoxIcon /> : <NotebookIcon />}*/}
                                {/*<Typography className={"desktopOnly"}>{data?.status === "draft" ? "Edit draft" : "Edit"}</Typography>*/}
                            </AddToList>
                        </a>
                    )}
                    {mobileLarge && (
                        <AddToList>
                            <LineGraphIcon/>
                        </AddToList>
                    )}
                </FlexBox>
            </CampaignItemContainer>
            <CampaignItemActionsMob className={!!offset ? "visible" : ""}>
                <Button onClick={() => window.open(`${appLink}/${data.id}/summary`, "app")}>Edit</Button>
                {data?.status !== "draft" && data?.status !== 'waiting' && !statusChanged && (
                    <Button
                        onClick={() => handleChangeStatus()}>
                        {data?.status === "active" ? "Pause" : "Run"}
                    </Button>
                )}
            </CampaignItemActionsMob>
        </Box>
    );
};

export default CampaignItem;
