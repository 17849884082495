import { setCampaignData, setCampaignListData, setComparisonData } from "../redux/campaigns/campaignsSlice";
import { setOverviewData } from "../redux/overview/overviewSlice";
import { store } from "../redux/store";
import statisticsClient from "./statisticsClient";
import { TGadget } from "../types/reduxData/dataTypes";
import { TAdDataBody, TComparisonBody, TComparisonOverviewData, TTargetInfoBody } from "../types/reduxData/overviewTypes";
import { TComparisonCampaignData } from "../types/reduxData/campaignsTypes";
import { setAvailableDates, setDates } from "../redux/filters/filtersSlice";
import { setHasCampaigns, setUserDataAction } from "../redux/user/userSlice";
import { TGeneralSettingsResponse, TUpdateUserRequestBody } from "../types/reduxData/generalSettingsTypes";
import { TCheckCampaignsResponse } from "../types/reduxData/userTypes";
import { setFetchError, setLoading, TRoute } from "../redux/app/appSlice";
import {userId} from "../utils/constants";

// const userId = () => store.getState().user.id;
// const userId = () => (typeof window !== 'undefined' && window?.origin === "https://dash.web3m.io") ? "dashboard_sample_data" : store.getState().user.id;

type TDatesRange = {
  start: string;
  end: string;
};

export const dashboardRequestFactory = async (route: TRoute, data: any) => {
  try {
    store.dispatch(setLoading({ route, loading: true }));
    const response = await statisticsClient.post(`/dashboard/${route}`, data);
    store.dispatch(setLoading({ route, loading: false }));
    return response?.data;
  } catch (err: any) {
    console.log(err.message);
    store.dispatch(setFetchError({ route, error: err.message }));
  }
};

export const getListData = async () => {
  const {
    dates: { start, end },
    status,
  } = store.getState().filters;

  const data: TAdDataBody = {
    user_id: userId(),
    start_date: start,
    end_date: end,
    // campaigns_type: Object.entries(status)
    //   .filter(([_, value]) => value)
    //   .map(([status, _]) => status),
  };

  const res = await dashboardRequestFactory("ad_data", data);

  if (res) {
    store.dispatch(setOverviewData(res));
  }
};

// export const getTargetInfoData = async () => {
//   const {
//     dates: { start, end },
//     status,
//   } = store.getState().filters;
//
//   const data: TTargetInfoBody = {
//     user_id: userId(),
//     start_date: start,
//     end_date: end,
//     type: "campaign",
//     campaigns_type: Object.entries(status)
//       .filter(([_, value]) => value)
//       .map(([status, _]) => status),
//   };
//
//   const res = await dashboardRequestFactory("target_info", data);
//
//   if (res) {
//     store.dispatch(
//       setOverviewData({
//         countries: res.countries as TGadget[],
//         devices: res.os as TGadget[],
//         websites: res.websites as TGadget[],
//       })
//     );
//   }
// };

export const getComparisonOverviewData = async (data: "campaign" | "channel") => {
  // console.log(data);
  const {
    dates: { start, end },
    status,
  } = store.getState().filters;
  const body: TComparisonBody = {
    user_id: userId(),
    start_date: start,
    end_date: end,
    comparison: data,
    campaigns_type: Object.entries(status)
        .filter(([_, value]) => value)
        .map(([status, _]) => status),
  };

  const res = await dashboardRequestFactory("graph_comparison", body);

  if (res) {
    store.dispatch(setOverviewData({ comparison: res as TComparisonOverviewData }));
  }
};

type TCampaignComparison = {
  comparison: "ad" | "group";
  id: string;
  channels: string[];
  start_date: string;
  end_date: string;
};

export const getComparisonCampaignData = async (data: TCampaignComparison) => {
  const { status } = store.getState().filters;
  const body: TComparisonBody = {
    user_id: userId(),
    start_date: data.start_date ?? null,
    end_date: data.end_date ?? null,
    comparison: data.comparison,
    campaign_id: data.id,
    campaigns_type: Object.entries(status)
        .filter(([_, value]) => value)
        .map(([status, _]) => status),
    channels_list: data.channels,
  };

  const res = await dashboardRequestFactory("graph_comparison", body);

  if (res) {
    store.dispatch(setComparisonData(res as TComparisonCampaignData));
  }
};

export const getCampaignList = async () => {
  const dates = store.getState().filters.dates_available;

  const data = {
    user_id: userId(),
    // start_date: dates.start,
    // end_date: dates.end,
  };

  const res = await dashboardRequestFactory("campaign_list", data);

  if (res) {
    store.dispatch(setCampaignListData(res.campaigns));
  }
};

export const getCampaignData = async (id: string, dates: TDatesRange) => {
  // store.dispatch(clea)
  const data = {
    user_id: userId(),
    start_date: dates.start,
    end_date: dates.end,
    campaign_id: id,
  };

  const res = await dashboardRequestFactory("campaign", data);

  if (res) {
    store.dispatch(setCampaignData({ id: id, data: res }));
  }
};

export const checkUserCampaigns = async () => {
  const response = await statisticsClient.get(`/dashboard/${userId()}`);
  if (response.data.has_campaigns) {
    store.dispatch(setDates({ start: response.data.date_range.start_date, end: response.data.date_range.end_date }));
    store.dispatch(
        setAvailableDates({
          start: response.data.date_range.start_date,
          end: response.data.date_range.end_date,
        })
    );
  }

  try {
    store.dispatch(setHasCampaigns(response.data as TCheckCampaignsResponse));

    return response.data.has_campaigns;
  } catch (err: any) {
    console.log(err.message);
    return false;
  }
};

export const getAdsByStatus = async () => {
  const data = {
    user_id: userId(),
    // user_id: 'google-oauth2|103092189971139114030',
  };

  const res = await dashboardRequestFactory("ad_status_list", data);

  if (res) {
    store.dispatch(setOverviewData({ ads_by_status: res }));
  }
};

export const withdrawBudget = async (campaignId: string, sum: number) => {
  const data = {
    user_id: userId(),
    amount: sum,
    currency: "USD",
    wallet_address: store.getState().user.userData.wallet_address,
  };

  try {
    const response = await statisticsClient.put(`budget/withdraw/${campaignId}`, data);
    return !!response;
  } catch (err) {
    return false;
  }
}