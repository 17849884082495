//Libraries
import {Box, Typography} from "@mui/material";
import {VectorMap} from "@react-jvectormap/core";
//Hooks
import {useCountriesHandler} from "./useCountriesHandler";
import {useCountriesData} from "./useCountriesData";
//Components
import {CheckboxWithLabel} from "../../styled/checkboxes/checkbox";
import {CheckboxGroups} from "../CheckboxGroups/checkboxGroups";
import MonitorTable from "../Tables/MonitorTable";
//Styles
import {
    CheckboxContainer,
    MapContainer,
    RegionSelected,
    RegionsList,
    regionsStyle,
    SvgCountriesBox
} from "./Countries.styles";
import {BlockContainer, FlexBox} from "../../styled/Layout";
//Data
import worldMill from "@react-jvectormap/world/dist/worldMill.json";
import {useLoading} from "../../utils/hooks/useLoading";
import {usePathname} from "../../utils/hooks/usePathname";
import BlockContent from "../../layout/blockContent/BlockContent";
import {useBreakpoints} from "../../utils/hooks/useBreakpoints";
import {FilterSvg, PlanetSvg, RegionLocationSvg} from "../../assets/svg/countries";

type TCountries = {
    adId?: string | null;
};

const Countries = ({adId = null}: TCountries) => {
    const {countriesData, active, selectedGroups, setSelectedGroups} = useCountriesData(adId);
    const {
        regionsName,
        regions,
        mapRef,
        onChangeRegions,
        visibleCode,
        countriesTable,
        regionsDisabled,
        isFilter,
        setIsFilter
    } = useCountriesHandler(countriesData);
    const {isCampaignPage} = usePathname();
    const isLoading = useLoading(isCampaignPage ? "campaign" : "target_info");
    const {mobileLarge} = useBreakpoints()


    return (
        <BlockContainer>
            <Box m={{xs: '0 -1rem', mobL: 0}}>
                <FlexBox mb={active.campaign === 1 ? "2.8rem" : "1.6rem"}
                         flexDirection={{xs: 'column', mobL: 'row'}}
                         alignItems={'flex-start'}
                         rowGap={'2rem'}
                >
                    <FlexBox justifyContent={'space-between'} width={{xs: '100%', mobL: 'auto'}}>
                        <Typography variant="h3">{"Countries"}</Typography>
                        {mobileLarge && (
                            <SvgCountriesBox onClick={() => setIsFilter(!isFilter)}>
                                {isFilter ? <PlanetSvg/> : <FilterSvg/>}
                            </SvgCountriesBox>
                        )}
                    </FlexBox>
                    {active.campaign === 1 && !adId && !isFilter && (
                        <CheckboxGroups selectedGroups={selectedGroups} setSelectedGroups={setSelectedGroups}/>
                    )}
                </FlexBox>
                <BlockContent loading={isLoading}>
                    {(!mobileLarge || (mobileLarge && isFilter)) &&
                        <CheckboxContainer>
                            {Object.entries(regions).map(([region, status], i) => {
                                // console.log(regionsDisabled)
                                return (
                                    <CheckboxWithLabel
                                        key={region + i}
                                        label={regionsName[region]}
                                        name={region}
                                        onChange={(e) => onChangeRegions(e, region)}
                                        checked={status}
                                        disabled={regionsDisabled.includes(region)}
                                    />
                                );
                            })}
                        </CheckboxContainer>}
                    {!isFilter && <>
                        {mobileLarge &&
                            <RegionsList>
                                {Object.entries(regions).filter(([_, status]) => status).map(([region, _]) => (
                                    <RegionSelected key={region}>
                                        <span><RegionLocationSvg/></span>{regionsName[region]}
                                    </RegionSelected>
                                ))}
                            </RegionsList>}
                        <MapContainer>
                            <VectorMap
                                mapRef={mapRef}
                                map={worldMill}
                                backgroundColor={"transparent"}
                                zoomOnScroll={false}
                                zoomMax={1}
                                regionStyle={regionsStyle}
                                selectedRegions={visibleCode}
                            />
                        </MapContainer>
                        {countriesTable.length !== 0 && <MonitorTable data={countriesTable}/>}
                    </>}
                </BlockContent>
            </Box>
        </BlockContainer>
    );
};

export default Countries;
