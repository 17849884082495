import { createSlice } from "@reduxjs/toolkit";

type TinitialState = {
  userLists: { [key: string]: string[] };
};

const initialState: TinitialState = {
  userLists: {},
};

const listsSlice = createSlice({
  name: "lists",
  initialState,
  reducers: {
    addCustomList: (state, { payload }) => {
      state.userLists[payload] = [];
    },
    addItemToList: (state, { payload }) => {
      state.userLists[payload.list].push(payload.id);
    },
    removeItemFromList: (state, { payload }) => {
      state.userLists[payload.list] = state.userLists[payload.list].filter((item) => item !== payload.id);
    },
  },
  //   extraReducers: {},
});

export const { addCustomList, addItemToList, removeItemFromList } = listsSlice.actions;

export default listsSlice.reducer;
