import {DialogContent, IconButton, Typography} from "@mui/material";
import {ClosePopupIcon} from "../../../assets/svg/icons";
import {PopupContainer, PopupContent} from "../Popup.styles";

export const GeneralSettingsPopup = ({ closePopup }: {closePopup: () => void}) => {
    return (
        <PopupContainer open onClose={closePopup}>
            <DialogContent sx={{ p: "1rem 0", textAlign: "center" }}>
                <IconButton className="close" onClick={closePopup}>
                    <ClosePopupIcon />
                </IconButton>
                <PopupContent>
                    <Typography>
                        {'Something went wrong! We were unable to save changes. Try again later.'}
                    </Typography>
                </PopupContent>
            </DialogContent>
        </PopupContainer>
    )
}