//Core
import React from "react";
import numeral from "numeral";
// Utils
import {LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer, BarChart, Bar, LabelList} from "recharts";
import {getStringNumber} from "../../utils/calc";
// Components
import {CheckboxBanner} from "../../styled/checkboxes/checkboxBanner";
// import {CheckboxBrand} from "../../styled/checkboxes/checkboxChannel";
import {CustomGraphDot} from "../../styled/customGraphDot";
import {popupList, TPopup} from "../../layout/popups/PopupList";
import {AdsComparisonMobile} from "./AdsComparisonMobile";
// Styles
import {BlockContainer, FlexBox, ImgBox} from "../../styled/Layout";
import {Box, Typography, Stack} from "@mui/material";
import {SelectCustom} from "../../styled/select";
import {
    CheckboxesContainer,
    ComparisonCombinedNameMob,
    ComparisonDotBrandInfoBox,
} from "./AdsComparison.styles";
import {CreativeNumber, CreativeNumberText} from "../CreativeCard/CreativeCard.styles";
import BlockContent from "../../layout/blockContent/BlockContent";
// Hooks
import {useAdsComparison} from "./useAdsComparison";
import {useLoading} from "../../utils/hooks/useLoading";
import {useBreakpoints} from "../../utils/hooks/useBreakpoints";
// Images
import {channelsLogo} from "../../assets/img/channels/channelsLogo";
// import {creativeTemplates, TCretiveTemplateKey} from "../CreativeCard/CreativeTemplates";


const AdsComparison = ({activePage}: { activePage?: number }) => {
    const {
        graphLines,
        graphData,
        comparison,
        handleComparison,
        parameter,
        handleParameter,
        parameterSelectList,
        comparisonSelectList,
        isCampaignPage,
        // isDashboardPage,
        checkboxData,
        handleCheckbox,
        brandCheckbox,
        getAdData,
        getAdTitle,
        popup,
        openPopup,
        setPopup,
        brandDataPopup,
        setBrandDataPopup,
        graphDataPopup
    } = useAdsComparison(activePage);
    const {mobileLarge} = useBreakpoints();

    const isLoading = useLoading("graph_comparison");

    const CustomDot = (e: any) => {
        const roundedValue =
            parameter === "ROI"
                ? Math.round(e.value * 100) / 100
                : numeral(Math.round(e.value * 100) / 100)
                    .format("0,0")
                    .replace(",", ".");

        return (
            <CustomGraphDot
                key={e.key}
                title={
                    <Stack>
                        {comparison === "ad" ? (
                            <Box m="2rem">{getAdData(e.dataKey)}</Box>
                        ) : (
                            <ComparisonDotBrandInfoBox>
                                {comparison !== "group" && (
                                    <ImgBox>
                                        <img
                                            src={
                                                comparison === "channel"
                                                    ? channelsLogo[e.dataKey]
                                                    : comparison === "campaign" && brandCheckbox
                                                        ? (brandCheckbox[e.dataKey]?.image as string)
                                                        : ""
                                            }
                                            alt="logo"
                                        />
                                    </ImgBox>
                                )}
                                <Typography>
                                    {comparison === "channel"
                                        ? e.dataKey
                                        : (comparison === "campaign" || comparison === "group") && brandCheckbox
                                            ? (brandCheckbox[e.dataKey]?.name as string)
                                            : ""}
                                </Typography>
                            </ComparisonDotBrandInfoBox>
                        )}
                        <CreativeNumber textAlign={"center"}>
                            {parameter === "Spent" && "$"}
                            {roundedValue}
                            {parameter === "ROI" && "%"}
                        </CreativeNumber>
                        <CreativeNumberText mb="1rem">{parameter}</CreativeNumberText>
                    </Stack>
                }
                x={e.cx}
                y={e.cy}
                color={e.stroke}
            />
        );
    };

    return (
        <BlockContainer>
            <FlexBox mb={"2.5rem"} flexDirection={{xs: 'column', mobL: 'row'}} sx={{justifyContent: {mobL: 'space-between', br768: 'flex-start'}}}>
                <Typography variant="h3" mb={{xs: '3rem', mobL: 0}}
                            alignSelf={'flex-start'}>{"Ads Comparison"}</Typography>
                <FlexBox ml={{xs: 0, mobL: "4.5rem"}} flexDirection={{xs: 'column', br768: 'row'}} rowGap={'2rem'}
                         width={{xs: '100%', mobL: 'auto'}}>
                    <SelectCustom value={comparison} setValue={handleComparison} data={comparisonSelectList}
                                  // disabled={isCampaignPage}
                    />
                    <SelectCustom value={parameter} setValue={handleParameter} data={parameterSelectList}/>
                </FlexBox>
            </FlexBox>
            <BlockContent loading={isLoading}>
                <ComparisonCombinedNameMob>
                    <span/>
                    <Typography>{comparison} {parameter}</Typography>
                    <span/>
                </ComparisonCombinedNameMob>
                <CheckboxesContainer>
                    <>{/*{isDashboardPage &&*/}
                    {/*    brandCheckbox &&*/}
                    {/*    Object.keys(brandCheckbox).map((line, i) => (*/}
                    {/*        <CheckboxBrand*/}
                    {/*            key={line}*/}
                    {/*            brand={brandCheckbox[line]}*/}
                    {/*            checked={checkboxData[line]}*/}
                    {/*            onChange={handleCheckbox}*/}
                    {/*            labelPlacement={"top"}*/}
                    {/*            color={brandCheckbox[line].color ?? ''}*/}
                    {/*            onClick={() => {*/}
                    {/*                setBrandDataPopup(brandCheckbox[line])*/}
                    {/*                openPopup(line)*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*    ))}*/}</>
                    {isCampaignPage &&
                        brandCheckbox &&
                        Object.keys(brandCheckbox).map((line, i) => (
                            <CheckboxBanner
                                key={line}
                                brand={brandCheckbox[line]}
                                bannerName={getAdTitle(line)}
                                checked={checkboxData[line]}
                                onChange={handleCheckbox}
                                onClick={() => {
                                    setBrandDataPopup(brandCheckbox[line])
                                    openPopup(line)
                                }}
                            />
                        ))}
                </CheckboxesContainer>

                {/*Desktop graph*/}
                <Box marginTop={"3rem"} marginBottom={"3rem"} paddingRight={"1rem"} className={'desktopOnly'}>
                    <ResponsiveContainer width={"100%"} height={320}>
                        <LineChart
                            width={1283}
                            height={320}
                            data={graphData}
                            margin={{
                                top: 5,
                                right: 10,
                                bottom: 15,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" vertical={false}/>
                            <XAxis dataKey="name" tickMargin={15} axisLine={false} tickLine={false}/>
                            <YAxis
                                tickMargin={15}
                                tickFormatter={(val: number) => getStringNumber(val) as string}
                                axisLine={false}
                                tickLine={false}
                            />
                            {brandCheckbox &&
                                graphLines?.map((line, i) => (
                                    <Line
                                        key={line}
                                        dataKey={line}
                                        stroke={brandCheckbox[line].color}
                                        fill={brandCheckbox[line].color}
                                        strokeWidth={3}
                                        dot={CustomDot}
                                    />
                                ))}
                        </LineChart>
                    </ResponsiveContainer>
                </Box>

                {/*Mobile graph*/}
                {!!Object.values(checkboxData).filter((el) => el).length && !!graphData.length &&
                    <AdsComparisonMobile graphData={graphData} brandCheckbox={brandCheckbox}/>
                }
            </BlockContent>
            {popupList[popup as TPopup] && mobileLarge && popupList[popup as TPopup]({
                closePopup: () => setPopup(""),
                brandData: brandDataPopup,
                comparison,
                parameter,
                graphData: graphDataPopup,
                brandCheckbox,
                ad: comparison === 'ad' ? (getAdData(brandDataPopup!.id)) : <></>

            })}
        </BlockContainer>
    );
};

export default AdsComparison;
