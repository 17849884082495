//Core
import React from 'react';
//Components
import { SupportForm } from "../../components/Forms/SupportForm/SupportForm";
//API
import { SendSupportForm } from "../../api/web3Requests";
//Styles
import { SectionHeadBackground, SectionHeadContainer } from "../../styled/Layout";
import { Typography } from "@mui/material";

export const Support = () => {
  return (
    <>
      <SectionHeadBackground>
        <SectionHeadContainer>
          <Typography variant={"h2"}>{'Support'}</Typography>
        </SectionHeadContainer>
      </SectionHeadBackground>
      <SupportForm sendFormRequest={SendSupportForm} />
    </>
  );
};
