import {PopupContainer, PopupContent} from "../Popup.styles";
import {DialogContent, IconButton, Stack} from "@mui/material";
import {ClosePopupIcon} from "../../../assets/svg/icons";
import {ButtonMetamask, ImageMetamaskBox, InfoMetamask, TitleMetamask } from "./noMetaMaskPopup.styles";
import MetamaskImage from '../../../assets/img/Metamask.webp'

export const NoMetaMaskPopup = ({ closePopup }: { closePopup: () => void }) => {
    return (
        <PopupContainer open onClose={closePopup}>
            <DialogContent sx={{ p: "0", width: '55rem'}}>
                <IconButton className="close" onClick={closePopup}>
                    <ClosePopupIcon />
                </IconButton>
                <PopupContent>
                    <Stack width={'85%'}>
                        <ImageMetamaskBox>
                            <img src={MetamaskImage} alt={'Metamask install'}/>
                        </ImageMetamaskBox>
                        <TitleMetamask>
                            {'Metamask add-on is not installed on your computer'}
                        </TitleMetamask>
                        <InfoMetamask>
                            {'To get access to Webm platform please use Metamask app to connect your crypto wallet.'}
                        </InfoMetamask>
                        <ButtonMetamask variant={'contained'} onClick={() => window.open("https://metamask.io/download/", '_blanket')}>
                            {'Install Metamask'}
                        </ButtonMetamask>
                    </Stack>
                </PopupContent>
            </DialogContent>
        </PopupContainer>
    )
}