import {
    BillingContent,
    BillingInfoBox,
    BillingInput, DetailsButton,
    InfoBoxContainer,
    InputLabel, UserNameTitle, UserRoleTitle
} from "../BillingCenterPage.styles";
import {Avatar, Grid, Link, Typography} from "@mui/material";
import {FlexBox} from "../../../styled/Layout";
import {useAuth0} from "@auth0/auth0-react";
import {useSelector} from "react-redux";
import {userSelector} from "../../../utils/selectors";
import {useBreakpoints} from "../../../utils/hooks/useBreakpoints";
import {useDetails} from "./useDetails";
import Loading from "../../../components/loading/Loading";

export const Details = () => {
    const {user} = useAuth0();
    const {userData, isLoadingUser} = useSelector(userSelector);
    const {mobileLarge} = useBreakpoints()
    const {isButton, info, setInfo, updateBillingDetails, isLoading} = useDetails()
    const userName = userData.first_name.length || userData.last_name.length ? `${userData.first_name} ${userData.last_name}` : " ";
    return (
        <>
            {isLoadingUser
                ? <Loading/>
                : <BillingContent>
            <Grid container spacing={3} flexDirection={mobileLarge ? 'column-reverse' : 'row'}>
                <Grid item xs={12} br1024={6}>
                    <BillingInfoBox className={'details'}>
                        <Typography variant={'h3'}>{'Billing details'}</Typography>
                        <InfoBoxContainer>
                            <InputLabel variant={'subtitle2'}>{'Company Name'}</InputLabel>
                            <BillingInput value={info.company_name}
                                          onChange={(e) => setInfo({...info, company_name: e.target.value})}
                                          placeholder={isButton ? 'Start typing...' : ''}
                                          disabled={!isButton}
                            />
                            <InputLabel variant={'subtitle2'}>{'Country of Incorporation'}</InputLabel>
                            <BillingInput value={info.company_incorporation}
                                          onChange={(e) => setInfo({...info, company_incorporation: e.target.value})}
                                          placeholder={isButton ? 'Start typing...' : ''}
                                          disabled={!isButton}
                            />
                            <InputLabel variant={'subtitle2'}>{'Company Address'}</InputLabel>
                            <BillingInput value={info.company_address}
                                          onChange={(e) => setInfo({...info, company_address: e.target.value})}
                                          placeholder={isButton ? 'Start typing...' : ''}
                                          disabled={!isButton}
                            />
                            <InputLabel variant={'subtitle2'}>{'Company Registration Number'}</InputLabel>
                            <BillingInput value={info.company_registration_number}
                                          onChange={(e) => setInfo({...info, company_registration_number: e.target.value})}
                                          placeholder={isButton ? 'Start typing...' : ''}
                                          disabled={!isButton}
                            />
                            {isButton &&
                                <DetailsButton variant={'contained'}
                                               color={'success'}
                                               loading={isLoading}
                                               onClick={() => updateBillingDetails()}
                                >
                                    {'Save changes'}
                                </DetailsButton>
                            }
                            <FlexBox maxWidth={'28rem'}>
                                <InputLabel variant={'subtitle2'} className={'info'}>
                                    {'Your billing details are verified. If you need to change them, please '}
                                    <Link href={'mailto:support@web3m.io'}>{'contact us.'}</Link>
                                </InputLabel>
                            </FlexBox>
                        </InfoBoxContainer>
                    </BillingInfoBox>
                </Grid>
                <Grid item xs={12} br1024={6}>
                    <BillingInfoBox className={'details'}>
                        <Avatar src={user?.picture} sx={{width: '19.4rem', height: '19.4rem'}}/>
                        <UserNameTitle>{userName}</UserNameTitle>
                        <UserRoleTitle>
                            {/*{'CEO Founder'}*/}
                        </UserRoleTitle>
                    </BillingInfoBox>
                </Grid>
            </Grid>
        </BillingContent>
            }
            </>
    )
}