import {useSelector} from "react-redux";
import {AppState} from "../../../redux/store";
import {makeDeposit, makeWithdraw, getAvailableBalance} from "../../../api/campaignsRequests";
import {useEffect, useState} from "react";
import {TObjectBoolean} from "../../../types/GeneralTypes";
// import {getAvailableBalance} from "../../../api/campaignsRequests";
import {currencyList} from "../../../utils/currencyList";

export const useDepositWithdraw = () => {
    const {user} = useSelector((state: AppState) => state)
    const user_id = user.id

    const [amountDeposit, setAmountDeposit] = useState<string>('')
    const [amountWithdraw, setAmountWithdraw] = useState<string>('')
    const [isLoading, setIsLoading] = useState<TObjectBoolean>({deposit: false, withdraw: false})


    useEffect(() => {
        user_id && void getAvailableBalance()
    },[user_id])

    const handleWithdraw = async () => {
        const amount = Number(amountWithdraw.split(',').join(''))
        if (user_id) {
            setIsLoading({...isLoading, withdraw: true})
            const response = await makeWithdraw({user_id, amount})
            if (response?.status === 'success') {
                setAmountWithdraw('')
            }
            setIsLoading({...isLoading, withdraw: false})
        }
    }

    const handleDeposit = async () => {
        const amount = Number(amountDeposit.split(',').join(''))
        if (user_id) {
            setIsLoading({...isLoading, deposit: true})
            const response = await makeDeposit({user_id, amount})
            if (response?.link) {
                setAmountDeposit('')
                window.open(response.link, '_self')
            }
            setIsLoading({...isLoading, deposit: false})
        }
    }

    return {
        amountDeposit,
        setAmountDeposit,
        handleWithdraw,
        amountWithdraw,
        setAmountWithdraw,
        handleDeposit,
        isLoading,
        available_balance: user.available_balance,
        currency: currencyList[user.budget_info.currency] ?? '$'
    }
}