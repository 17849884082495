import { FormControl, FormControlLabel, Radio, RadioGroup, styled } from "@mui/material";
import theme from "../../theme/theme";

const Label = styled(FormControlLabel)`
  text-transform: capitalize;

  & .MuiRadio-root {
    color: rgba(99, 110, 149, 0.5);
    & + span.MuiTypography-root {
      color: rgba(99, 110, 149, 0.5);
    }

    &.Mui-checked {
      color: ${theme.palette.primary.main};
      & + span.MuiTypography-root {
        color: ${theme.palette.primary.main};
      }
    }
  }
`;
type TRadioWithLabel = {
  data: string[];
  selected?: string;
  onSelect?: any;
};
export const RadioWithLabel = ({ data, selected, onSelect }: TRadioWithLabel) => {
  return (
    <FormControl>
      <RadioGroup
        sx={{ flexDirection: "row" }}
        // defaultValue={data[0]}
        value={selected}
        onChange={onSelect}
        name="radio-buttons-group"
      >
        {data.map((el, i) => (
          <Label key={el + i} value={el} control={<Radio />} label={el} />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
