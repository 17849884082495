//Core
import React, {Fragment, useEffect, useId} from "react";
import {Formik} from "formik";
//Styles
import {
    ButtonsBox,
    FormBox, FormInput, FormInputName, FormRequiredIcon, LoaderButton,
    SupportFileAdd, SupportFootnote, SupportFormGrid, SupportFormGridItem,
    SupportImageBox,
    SupportTitle
} from "../Forms.styles";
import {IconButton, Stack, Typography} from "@mui/material";
//Images
import AddIcon from "../../../assets/svg/addIcon";
import {Trash} from "../../../assets/svg/trash";
//Hooks
import {useSupportForm} from "./useSupportForm";
import {useBreakpoints} from "../../../utils/hooks/useBreakpoints";
//Types
import {T404RequestBody, TSupportRequestBody, TSupportResponse} from "../../../types/SupportTypes";
//Components
import RabbitTip from "../../rabbitTip/RabbitTip";
import {popupList, TPopup} from "../../../layout/popups/PopupList";
import {HeartSvg} from "../../../assets/svg/icons";


export type TSupportForm = {
    sendFormRequest: (value: TSupportRequestBody | T404RequestBody) => Promise<TSupportResponse>,
    setIs404?: (value: boolean) => void
}
export const SupportForm = ({sendFormRequest, setIs404}: TSupportForm) => {
    const {
        formData,
        uploadFile,
        deleteFile,
        loadingImage,
        loadingForm,
        sendForm,
        validationSchema,
        status,
        closePopup,
    } = useSupportForm({sendFormRequest, setIs404})
    const id = useId()
    const {mobileLarge} = useBreakpoints();
    const description = 'Fill out the form below to get in touch with our expert support team. We’ll be in touch shortly.'

    useEffect(() => {
        closePopup()
    }, [])

    const ScreenShotsButtons = () => {
        return (
            <ButtonsBox sx={{flexDirection: 'row !important'}}>
                {Array(mobileLarge ? 3 : 4).fill('').map((_, i) => (
                    <Fragment key={id + i}>{!formData.issue_screenshots_links[i]
                        ? <SupportFileAdd
                            component={'label'}
                            loading={loadingImage[id + i]}
                            loadingIndicator="Loading…"
                        >
                            <input hidden
                                   type={'file'}
                                   name={'issue_screenshots_links'}
                                   accept="image/*"
                                   id={id + i}
                                   onChange={(e) => uploadFile(e, i)}
                            />
                            {!loadingImage[id + i] && <>
                                <AddIcon/>
                                <Typography
                                    className={'desktopOnly'}>{'Click to add'}</Typography>
                            </>}
                        </SupportFileAdd>
                        : <SupportImageBox>
                            <IconButton onClick={() => deleteFile(i)}>
                                <Trash/>
                            </IconButton>
                            < img src={formData.issue_screenshots_links[i]}/>
                        </SupportImageBox>
                    }
                    </Fragment>
                ))}
            </ButtonsBox>
        )
    }

    return (
        <>
            <FormBox margin={{mobL: '8.2rem auto 22.5rem'}}>
                <SupportTitle variant={'h2'} className={'desktopOnly'}>
                    {/*<HeartSvg/>*/}
                    {description}
                </SupportTitle>
                <RabbitTip text={description}/>
                <Formik initialValues={formData}
                        validationSchema={validationSchema}
                        onSubmit={async (values, {setSubmitting, resetForm}) => {
                            await sendForm(values)
                            setSubmitting(false);
                            resetForm()
                        }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <SupportFormGrid container columnSpacing={{xs: 0, lg: 3, xl: 6}}>
                                <SupportFormGridItem item xs={12} lg={6}>
                                    <FormInputName>{'First Name *'}</FormInputName>
                                    <FormInput
                                        placeholder={'First name'}
                                        name={'first_name'}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.first_name}
                                        error={touched.first_name && Boolean(errors.first_name)}
                                        helperText={errors.first_name && touched.first_name && errors.first_name}
                                        InputProps={{
                                            endAdornment: mobileLarge ? <FormRequiredIcon>*</FormRequiredIcon> : false
                                        }}
                                    />
                                    <FormInputName>{'Last Name'}</FormInputName>
                                    <FormInput
                                        placeholder={'Last name'}
                                        name={'last_name'}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.last_name}
                                    />
                                    <FormInputName>{'Your email *'}</FormInputName>
                                    <FormInput
                                        placeholder={'Your Email'}
                                        name={'email_to_return'}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email_to_return}
                                        error={touched.email_to_return && Boolean(errors.email_to_return)}
                                        helperText={errors.email_to_return && touched.email_to_return && errors.email_to_return}
                                        InputProps={{
                                            endAdornment: mobileLarge ? <FormRequiredIcon>*</FormRequiredIcon> : false
                                        }}
                                    />
                                    <Stack mb={'2.4rem'} display={{xs: 'none', lg: 'flex'}}>
                                        <FormInputName>{'Add files ( screenshots )'}</FormInputName>
                                        <ScreenShotsButtons/>
                                    </Stack>
                                </SupportFormGridItem>
                                <SupportFormGridItem item xs={12} lg={6}>
                                    <FormInputName>{'Title *'}</FormInputName>
                                    <FormInput
                                        placeholder={'Title'}
                                        name={'issue_title'}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.issue_title}
                                        error={touched.issue_title && Boolean(errors.issue_title)}
                                        helperText={errors.issue_title && touched.issue_title && errors.issue_title}
                                        InputProps={{
                                            endAdornment: mobileLarge ? <FormRequiredIcon>*</FormRequiredIcon> : false
                                        }}
                                    />
                                    <FormInputName>{'How can we help? *'}</FormInputName>
                                    <FormInput
                                        placeholder={'How can we help?'}
                                        name={'issue_desc'}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.issue_desc}
                                        error={touched.issue_desc && Boolean(errors.issue_desc)}
                                        helperText={errors.issue_desc && touched.issue_desc && errors.issue_desc}
                                        multiline
                                        minRows={8.3}
                                        InputProps={{
                                            endAdornment: mobileLarge ? <FormRequiredIcon>*</FormRequiredIcon> : false
                                        }}
                                    />
                                </SupportFormGridItem>

                                <Stack  display={{xs: 'flex', lg: 'none'}} width={'100%'} mb={'5rem'}>
                                    <FormInputName>{'Add files ( screenshots )'}</FormInputName>
                                    <Typography variant={'h4'}
                                                className={'mobileOnly'}
                                                sx={{fontSize: '2.57rem'}}
                                                mb={'2rem'}
                                    >
                                        {'Attach screenshot'}
                                    </Typography>
                                    <ScreenShotsButtons/>
                                </Stack>
                            </SupportFormGrid>
                            <ButtonsBox>
                                <LoaderButton
                                    variant={'contained'}
                                    color={'success'}
                                    type={'submit'}
                                    loading={loadingForm}
                                    disabled={isSubmitting}
                                >
                                    {'Send'}
                                </LoaderButton>
                                <SupportFootnote className={'desktopOnly'}>{'* Required field'}</SupportFootnote>
                            </ButtonsBox>
                        </form>
                    )}
                </Formik>
            </FormBox>
            {popupList[status as TPopup] && popupList[status as TPopup]({closePopup: () => closePopup()})}
        </>
    )
}