//Core
import React, { isValidElement } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
//Styles
import { Masonry } from "@mui/lab";
import { Container } from "@mui/material";
import BlockContent from "../../layout/blockContent/BlockContent";
//Components
import RejectedAds from "./blocks/RejectedAds/RejectedAds";
import RunningCampaigns from "./blocks/RunningCampaigns/RunningCampaigns";
// import WaitingCampaigns from "./blocks/WaitingCampaigns/WaitingCampaigns";
import WaitingConfirmation from "./blocks/WaitingConfirmation/WaitingConfirmation";
import WhatsNew from "./blocks/WhatsNew/WhatsNew";
//Hooks
import { useOverview } from "./useOverview";
import { useBreakpoints } from "../../utils/hooks/useBreakpoints";
import { useLoading } from "../../utils/hooks/useLoading";

const OverviewWrapper = ({ children }: any) => {
  const { mobileLarge } = useBreakpoints();
  const listLoading = useLoading("campaign_list");
  const adStatusLoading = useLoading("ad_status_list");
  const isLoading = listLoading || adStatusLoading;

  // if(isLoading) return  null;

  if (mobileLarge) {
    return (
      <BlockContent loading={isLoading}>
        <Swiper centeredSlides spaceBetween={15} slidesPerView={1.2} style={{ paddingBottom: "10rem" }}>
          {children
            .filter((section: any) => isValidElement(section))
            .map((section: any, index: number) => (
              <SwiperSlide key={index}>{section}</SwiperSlide>
            ))}
        </Swiper>
      </BlockContent>
    );
  }

  return (
    <Masonry columns={{ xs: 1, br1024: 2 }} spacing={"2.8rem"} sx={{ width: "98%" }}>
      {children}
    </Masonry>
  );
};

const OverviewPage = () => {
  useOverview();

  return (
    <>
      {/*<SubheaderContainer sx={{ borderBottom: "solid 1px #d0d5dd" }}>*/}
      {/*  <Typography variant="h2">Overview</Typography>*/}
      {/*</SubheaderContainer>*/}
      <Container sx={{ mt: "6rem", p: "1rem" }}>
        <OverviewWrapper>
          <RunningCampaigns />
          {/* <WaitingCampaigns /> */}
          <WaitingConfirmation />
          <RejectedAds />
          <WhatsNew />
        </OverviewWrapper>
      </Container>
    </>
  );
};

export default OverviewPage;
