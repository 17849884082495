import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TGadget } from "../../types/reduxData/dataTypes";
import { overviewSelector } from "../../utils/selectors";
import { sortSliceForTable } from "../../utils/sortSliceForTable";
import { ContextCheckbox } from "../CheckboxGroups/ContextCheckbox";
import { ContextViewMode } from "../../pages/Campaigns/ContextViewMode";
import { TObjectBoolean } from "../../types/GeneralTypes";
import { usePathname } from "../../utils/hooks/usePathname";

export const useDevices = (adId: string | null) => {
  // const { devices } = useSelector(overviewSelector);
  // const devicesSort = devices ? sortSliceForTable(devices as TGadget[]) : [];
  const { isCampaignPage } = usePathname();
  const [active] = useContext(ContextViewMode);
  const [main, , filteredByChannels] = useContext(ContextCheckbox);
  //Groups checkboxes
  const [selectedGroups, setSelectedGroups] = useState<TObjectBoolean>({});
  //filtered data
  const [campData, setCampData] = useState<TGadget[]>([]);

  useEffect(() => {
    const selected = Object.entries(selectedGroups)
      .filter(([_, value]) => value)
      .map(([key, _]) => key);
    const groupsFilterData =
      active.campaign === 1 ? filteredByChannels?.filter((el) => selected.includes(el.group_name)) : filteredByChannels;
    const tmp = groupsFilterData?.reduce((acc: any, next) => {
      if (!next) return [];

      const newAcc = [...acc];

      next.os.forEach((os_el) => {
        const accInd = acc.findIndex((el: any) => el.name === os_el.name);

        if (accInd === -1) {
          newAcc.push(os_el);
        } else {
          const summedData = {
            total_amount_spent: newAcc[accInd].total_amount_spent + os_el.total_amount_spent,
            total_clicks: newAcc[accInd].total_clicks + os_el.total_clicks,
            total_impressions: newAcc[accInd].total_impressions + os_el.total_impressions,
          };

          newAcc.splice(accInd, 1, { ...newAcc[accInd], ...summedData });
        }
      });

      return newAcc;
    }, []);
    setCampData(tmp);
  }, [selectedGroups, filteredByChannels, main]);

  const adData = filteredByChannels.find((el) => el.ad_id === adId)?.os || [];

  const COLORS = ["#ef2f6e", "#0080ff", "#4540bb", "#f2a626", "#04b78a"];

  const rawDevices = adId ? sortSliceForTable(adData) :
      // isCampaignPage ?
          sortSliceForTable(campData)
          // : devicesSort;

  const desktopDevices = ["Desktop", "Windows", "MacOS", "Linux"];
  const mobileDevices = ["Mobile", "iOS", "Android"];

  const calcDevices = () => {
    const reduced = rawDevices.reduce(
      (acc, next) => {
        if (mobileDevices.includes(next.name)) {
          return {
            ...acc,
            Mobile: {
              roi: acc.Mobile.roi + (next?.roi || 0),
              total_amount_spent: acc.Mobile.total_amount_spent + next.total_amount_spent,
              total_clicks: acc.Mobile.total_clicks + next.total_clicks,
              total_impressions: acc.Mobile.total_impressions + next.total_impressions,
            },
          };
        } else {
          return {
            ...acc,
            Desktop: {
              roi: acc.Desktop.roi + (next?.roi || 0),
              total_amount_spent: acc.Desktop.total_amount_spent + next.total_amount_spent,
              total_clicks: acc.Desktop.total_clicks + next.total_clicks,
              total_impressions: acc.Desktop.total_impressions + next.total_impressions,
            },
          };
        }
      },
      {
        Mobile: {
          roi: 0,
          total_amount_spent: 0,
          total_clicks: 0,
          total_impressions: 0,
        },
        Desktop: {
          roi: 0,
          total_amount_spent: 0,
          total_clicks: 0,
          total_impressions: 0,
        },
      }
    );

    return Object.entries(reduced).map(([key, value]) => ({ ...value, name: key }));
  };

  const devicesData = rawDevices.some((el) => el.name === "Mobile" || el.name === "Desktop") ? calcDevices() : rawDevices;

  return {
    COLORS,
    devices: devicesData,
    active,
    selectedGroups,
    setSelectedGroups,
  };
};
