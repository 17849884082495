import { createSlice } from "@reduxjs/toolkit";

type TInitialState = {
  api: {
    ad_data: {
      loading: boolean;
      error: string | null;
    };
    target_info: {
      loading: boolean;
      error: string | null;
    };
    graph_comparison: {
      loading: boolean;
      error: string | null;
    };
    campaign_list: {
      loading: boolean;
      error: string | null;
    };
    campaign: {
      loading: boolean;
      error: string | null;
    };
    user: {
      loading: boolean;
      error: string | null;
    };
    user_campaigns: {
      loading: boolean;
      error: string | null;
    };
    ad_status_list: {
      loading: boolean;
      error: string | null;
    };
  };
};

const initialState: TInitialState = {
  api: {
    ad_data: {
      loading: true,
      error: null,
    },
    target_info: {
      loading: false,
      error: null,
    },
    graph_comparison: {
      loading: false,
      error: null,
    },
    campaign_list: {
      loading: false,
      error: null,
    },
    campaign: {
      loading: false,
      error: null,
    },
    user: {
      loading: false,
      error: null,
    },
    user_campaigns: {
      loading: false,
      error: null,
    },
    ad_status_list: {
      loading: false,
      error: null,
    },
  },
};

export type TRoute = keyof typeof initialState.api;

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (state, { payload }: { payload: { route: TRoute; loading: boolean } }) => {
      state.api[payload.route].loading = payload.loading;
    },
    setFetchError: (state, { payload }: { payload: { route: TRoute; error: string } }) => {
      state.api[payload.route] = { loading: false, error: payload.error };
    },
  },
});

export const { setLoading, setFetchError } = appSlice.actions;

export default appSlice.reducer;
