import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {AppState} from "../../redux/store";

export const useBillingCenter = () => {
    const { pathname } = useLocation();

    return {
        isDetails: (pathname === "/billing/details"),
        isDeposit: (pathname === "/billing/deposit_withdraw"),
        isHistory: (pathname === "/billing/history"),
    }
}