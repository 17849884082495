import {useSelector} from "react-redux";
import {overviewSelector} from "../../utils/selectors";
import {useContext, useEffect, useState} from "react";
import {ContextCheckbox} from "../CheckboxGroups/ContextCheckbox";
import {TGadget} from "../../types/reduxData/dataTypes";
import {ContextViewMode} from "../../pages/Campaigns/ContextViewMode";
import {sortCountries} from "./helpMethods";
import {TObjectBoolean} from "../../types/GeneralTypes";
import { usePathname } from "../../utils/hooks/usePathname";

export const useCountriesData = (adId: string | null) => {
    const {isCampaignPage} = usePathname();
    // const {countries} = useSelector(overviewSelector)
    const [, , filteredByChannels] = useContext(ContextCheckbox);
    const [active] = useContext(ContextViewMode)

    const [countriesData, setCountriesData] = useState<TGadget[]>([])
    //Groups checkboxes of block
    const [selectedGroups, setSelectedGroups] = useState<TObjectBoolean>({})

    useEffect(() => {
        if (isCampaignPage) {
            if (adId) {
                const adData: TGadget[] = filteredByChannels.find((el) => el.ad_id === adId)?.countries || [];
                setCountriesData(adData)
            } else {
                if (filteredByChannels) {
                    const selected = Object.entries(selectedGroups).filter(([_, value]) => value).map(([key, _]) => key)
                    const tmp: TGadget[] = active.campaign === 1
                        ? filteredByChannels.filter((el) => selected.includes(el.group_name)).map((data) => data.countries).flat()
                        : filteredByChannels.map((data) => data.countries).flat()
                    // console.log(tmp)
                    setCountriesData(sortCountries(tmp))
                } else setCountriesData([])
            }
            // setRegions(defaultRegions())
        // } else {
        //     if (countries) {
        //         setCountriesData(countries as TGadget[])
        //     } else setCountriesData([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        // countries,
        filteredByChannels, isCampaignPage, adId, selectedGroups])

    return {
        countriesData,
        setCountriesData,
        active,
        selectedGroups,
        setSelectedGroups,
    }
}