import {Bar, BarChart, LabelList, ResponsiveContainer, XAxis} from "recharts";
import {Box, Typography} from "@mui/material";
import React from "react";
import {Props} from "recharts/types/component/Label";
import {getStringNumber} from "../../utils/calc";
import {TBrand} from "../../types/AdsComparisonTypes";


type TProps = {
    graphData: { [p: string]: string | number }[],
    brandCheckbox?: { [p: string]: TBrand },
    popup?: boolean
}
export const AdsComparisonMobile = ({graphData, brandCheckbox, popup=false}: TProps) => {

    const CustomLabel = (props: Props) => {
        const {x, y, width, value} = props
        return (
                <g width={width}>
                    <foreignObject width={30}
                                   height={30}
                                   x={Number(x) + 10}
                                   y={Number(y) - 60}
                                   style={{
                                       border: `1px solid ${value ? brandCheckbox?.[value]?.color ?? '' : '#000000'}`,
                                       borderRadius: '50rem'
                                   }}
                    >
                        <img src={value ? brandCheckbox?.[value]?.image : ''}
                             alt={'logo'}
                             width={'100%'}
                             height={'100%'}
                             style={{objectFit: 'cover', scale: '1.05'}}/>
                    </foreignObject>

                    <foreignObject x={x}
                                   y={Number(y) - 25}
                                   width={53}
                                   height={30}
                    >
                        <Typography color={'rgba(0,0,0,0.8)'}
                                    overflow={'hidden'}
                                    textOverflow={'ellipsis'}
                                    textTransform={'capitalize'}
                                    whiteSpace={'nowrap'}
                                    textAlign={'center'}
                        >
                            {value ? brandCheckbox?.[value]?.name : ''}
                        </Typography>
                    </foreignObject>
                </g>
        )
    }

    return (
        <Box className={'mobileOnly'} mt={'3rem'}>
            <ResponsiveContainer width={"100%"} height={320}>
                <BarChart
                    data={graphData}
                    barSize={50}
                    margin={{left: -10, bottom: 5, top: popup ? 0 : 70}}
                >
                    <XAxis
                        dataKey={"name"}
                        axisLine={false}
                        tickLine={false}
                        tickMargin={15}
                        style={popup ? {fill: "#fff"} : {}}
                    />
                    {brandCheckbox &&
                        <Bar dataKey={'value'} fill={"#0080ff"} radius={[7, 7, 7, 7]} minPointSize={27}>
                            <LabelList
                                dataKey={'value'}
                                position="insideTop"
                                offset={8}
                                formatter={(val: number) => getStringNumber(Math.round(val))}
                                style={{fill: "#fff", fontWeight: "bold"}}
                            />
                            {!popup && <LabelList
                                dataKey={'key'}
                                content={(props) => <CustomLabel {...props}/>}
                                position="top"
                                offset={10}
                                height={20}
                                width={20}
                            />}
                        </Bar>
                    }
                </BarChart>
            </ResponsiveContainer>
        </Box>
    )
}